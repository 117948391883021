import React from "react";
import "./Skeleton.css";

function CustomSkeleton({ width, height }) {
  return (
    <div className="skeleton" style={{ width: width, height: height }}></div>
  );
}

export default CustomSkeleton;
