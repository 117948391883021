// company stats dashboard for company's admin
import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CustomBackdrop from "../components/Loader";
import Navbar from "../components/Navbar/Navbar";
import { auth, firestore } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import "./CompanyDashboard.css";
import CompanyStatsTable from "../components/CompanyStatsTable";
import ViewsTable from "../components/ViewedTitlesTable";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import CustomSkeleton from "../components/Skeleton";
import CompanyUsersModal from "../components/Modals/CompanyUsers";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CompanyDashboard() {
  let { uid } = auth.currentUser;
  const { userDetails } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState({
    main: false,
    botheringOptions: false,
    checkInLoading: false,
    updateCompanyName: false,
    getSubscribers: false,
    titlesLoading: false,
  });

  const [usersModal, setUsersModal] = useState(false);
  const [updateCompanyNameLoading, setUpdateCompanyName] = useState(false);
  const [botheringOptsLoading, setBotheringOptsLoading] = useState(true);
  const [checkInLoading, setCheckInLoading] = useState(true);
  const [subscribersLoading, setSubscribersLoading] = useState(true);
  const [titlesLoading, setTitlesLoading] = useState(true);
  const [companyName, setCompanyName] = useState("Loading...");
  const [subscribersData, setSubscribersData] = useState([]);
  const [viewedTitles, setViewedTitles] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [botheringOptions, setBotheringOptions] = useState([]);
  const [checkInData, setCheckInData] = useState([]);
  const [error, setError] = useState({
    message: "",
    isError: false,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    snackbarMessage: "",
  });

  useEffect(() => {
    if (userDetails === null) {
      return <CustomBackdrop />;
    }
    if (
      !(
        userDetails &&
        (userDetails?.isCorporateAdmin ||
          userDetails?.pricingPlan === "Individual")
      )
    ) {
      history.push("/welcome");
    } else if (
      userDetails &&
      userDetails.isCorporateAdmin &&
      userDetails.redemptionCode
    ) {
      setCompanyName(userDetails.companyName || "Company");
      getSubs(userDetails.redemptionCode);
      getBotheringOptions();
    }
  }, [userDetails]);

  const handleCloseSnackbar = (e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarInfo({
      open: false,
      snackbarMessage: "",
    });
  };

  const getSubs = async (code) => {
    try {
      if (!code) return false;

      const subsData = [];
      setSubscribersData([]);
      setSubscribersLoading(true);

      const redemptionCodeRef = await firestore
        .collection("validRedemptionCodes")
        .where("redemptionCode", "==", code)
        .get();

      await Promise.all(
        redemptionCodeRef.docs.map(async (snapshots) => {
          const { owner, subscribers } = snapshots.data();

          if (owner && owner === userDetails.email && subscribers) {
            await Promise.all(
              subscribers.map(async (subscriberEmail) => {
                const usersRef = await firestore
                  .collection("users")
                  .where("email", "==", subscriberEmail)
                  .get();

                if (!usersRef.empty) {
                  usersRef.forEach((subscriberSnapshot) => {
                    if (subscriberSnapshot.data().email !== owner) {
                      subsData.push({
                        ...subscriberSnapshot.data(),
                        uid: subscriberSnapshot.id,
                      });
                    }
                  });
                }
              })
            );
          }
        })
      );

      setSubscribersData(subsData);
      await getMoodCheckDetails(subsData);
      await getWatchedPosts(subsData);
      setSubscribersLoading(false);
    } catch (error) {
      console.log("Error getting subs: ", error.message);
      setSubscribersLoading(false);
    }
  };

  const getBotheringOptions = async () => {
    try {
      setBotheringOptsLoading(true);
      await firestore
        .collection("m_bothering_you_options")
        .doc("options")
        .get()
        .then((res) => {
          if (res.data()) {
            setBotheringOptions(res?.data()?.options);
          }
        });

      setBotheringOptsLoading(false);
    } catch (error) {
      setBotheringOptsLoading(false);

      console.log("error from getBotheringOptions: ", error.message);
    }
  };

  const getMoodCheckDetails = async (subscribersArr) => {
    try {
      if (!subscribersArr.length) return;

      const allCheckInDocs = [];
      setCheckInLoading(true);

      for (const subscriberObj of subscribersArr) {
        const { uid } = subscriberObj;
        // console.log("subscriberObj: ", uid);
        const querySnapshot = await firestore
          .collection("check-ins")
          .where("userId", "==", uid)
          .get();

        querySnapshot.forEach((doc) => {
          allCheckInDocs.push(doc.data());
        });
      }
      setCheckInData(allCheckInDocs);
      setCheckInLoading(false);
    } catch (error) {
      console.log("Error from getMoodCheckDetails: ", error.message);
      setCheckInLoading(false);
    }
  };

  function getMoodCheckCount(key, value) {
    if (!checkInData) return 0;

    const filteredArr = checkInData.filter((item) => {
      if (item.hasOwnProperty(key)) {
        return item[key] === value;
      } else {
        return false;
      }
    });

    return filteredArr.length;
  }

  function getBotheringCounts() {
    const counts = {};

    checkInData.forEach((obj) => {
      obj.botheringYou.forEach((each) => {
        counts[each] = (counts[each] || 0) + 1;
      });
    });

    return counts;
  }

  function MoodCheck() {
    return (
      <div className="mood-box my-4">
        <div className="mb-4">
          <h5>Total Check-Ins:</h5>
          <div className="text-container">
            {checkInLoading ? (
              <CustomSkeleton width="3rem" height="4rem" />
            ) : (
              <h2 className="font-weight-bold">
                {checkInData.length ? checkInData.length : 0}
              </h2>
            )}
          </div>
        </div>
        <div className="mood-box-sections">
          <div>
            <h5>Users Are Doing</h5>
            {checkInLoading ? (
              <CustomSkeleton width="7rem" height="8rem" />
            ) : (
              <>
                <div className="my-2">
                  <p className="mood-text">
                    Great:{" "}
                    <span className="font-weight-bold">
                      {getMoodCheckCount("doingToday", "Great")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("doingToday", "Great") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
                <div className="mb-2">
                  <p className="mood-text">
                    Okay:{" "}
                    <span className="font-weight-bold">
                      {getMoodCheckCount("doingToday", "Okay")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("doingToday", "Okay") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
                <div className="mb-2">
                  <p className="mood-text">
                    Blah:{" "}
                    <span className="font-weight-bold">
                      {getMoodCheckCount("doingToday", "Blah")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("doingToday", "Blah") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
              </>
            )}
          </div>

          <div>
            <h5>Stress Levels Of Users</h5>
            {checkInLoading ? (
              <CustomSkeleton width="7rem" height="8rem" />
            ) : (
              <>
                <div className="my-2">
                  <p className="mood-text">
                    low:{" "}
                    <span className="font-weight-bold">
                      {" "}
                      {getMoodCheckCount("stressLevel", "Low")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("stressLevel", "Low") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
                <div className="mb-2">
                  <p className="mood-text">
                    Medium:{" "}
                    <span className="font-weight-bold">
                      {getMoodCheckCount("stressLevel", "Medium")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("stressLevel", "Medium") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
                <div className="mb-2">
                  <p className="mood-text">
                    High:{" "}
                    <span className="font-weight-bold">
                      {getMoodCheckCount("stressLevel", "High")}
                    </span>{" "}
                    {"("}
                    {parseInt(
                      (getMoodCheckCount("stressLevel", "High") /
                        checkInData.length) *
                        100
                    )}
                    {")"}%
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-4">
          <h5 className="mb-2">Bothering Stats</h5>
          {botheringOptsLoading ? (
            <CustomSkeleton width="25rem" height="2rem" />
          ) : botheringOptions && botheringOptions.length ? (
            <>
              <div className="bothering-section">
                {botheringOptions.map((each) => {
                  return (
                    <>
                      <p className="mood-text m-0">
                        {each}:{" "}
                        <span className="font-weight-bold">
                          {getBotheringCounts()[each]}
                        </span>
                      </p>
                    </>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }

  const saveCompanyName = async () => {
    try {
      if (!companyName) {
        alert("Invalid Company Name");
        setCompanyName("Company");
        return;
      }
      setUpdateCompanyName(true);
      await firestore.collection("users").doc(uid).update({
        companyName,
        updatedAt: new Date(),
      });
      setIsEdit(false);
      setUpdateCompanyName(false);
    } catch (error) {
      console.log("Error saving company name", error.message);
      setUpdateCompanyName(false);
    }
  };

  function keepUniqueObjects(arr) {
    const uniqueIds = new Set(); // Set to store unique ids
    const uniqueObjects = arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        // Check if the id is already present in the Set
        uniqueIds.add(obj.id); // Add the id to the Set
        return true; // Keep the object in the filtered array
      }
      return false; // Skip the object as it's a duplicate
    });
    return uniqueObjects;
  }

  const getPosts = async (id) => {
    try {
      const doc = await firestore.collection("m_posts").doc(id).get();
      if (doc.exists) {
        // return Promise.resolve(doc.data());
        return doc.data();
      }
    } catch (error) {
      console.log("[1] Error fetching post: ", error.message);
    }
  };

  const getCorpAdminPosts = async (id) => {
    try {
      const doc = await firestore.collection("corp_a_posts").doc(id).get();
      if (doc.exists) {
        // return Promise.resolve(doc.data());
        return doc.data();
      }
    } catch (error) {
      console.log("[1] Error fetching post: ", error.message);
    }
  };

  async function getWatchedPosts(subsArr) {
    try {
      if (!subsArr.length) return;

      setTitlesLoading(true);

      console.log("Getting posts...");

      const _watchedPosts = [];

      for (const eachSub of subsArr) {
        if (!eachSub.watchedPosts) continue;

        // const postPromises = eachSub.watchedPosts.map((e) =>
        //   Promise.all([getPosts(e.postId), getCorpAdminPosts(e.postId)])
        // );

        // const resolvedPosts = await Promise.allSettled(postPromises);

        // Now you can log the resolvedPosts or process the data further
        // console.log("Resolved posts: ", resolvedPosts);

        for (const eachWatchedPost of eachSub.watchedPosts) {
          let post;

          try {
            post = await firestore
              .collection("m_posts")
              .doc(eachWatchedPost.postId)
              .get();
          } catch (error) {
            console.log("[1] Error fetching post: ", error.message);
          }

          if (!post.exists) {
            try {
              post = await firestore
                .collection("corp_a_posts")
                .doc(eachWatchedPost.postId)
                .get();
            } catch (error) {
              console.error("[2] Error fetching post: ", error.message);
            }
          }

          if (post.exists) {
            // console.log("Pushing post...");
            _watchedPosts.push(post.data());
          } else {
            console.warn(`[3] Post ${eachWatchedPost.postId} not found.`);
          }
        }
      }

      console.log("Final watchedPosts: ", _watchedPosts.length);

      const viewsObj = {};
      for (let index = 0; index < _watchedPosts.length; index++) {
        // for the count
        if (viewsObj[_watchedPosts[index].id]) {
          viewsObj[_watchedPosts[index].id] += 1;
        } else {
          viewsObj[_watchedPosts[index].id] = 1;
        }
      }

      const uniquePosts = keepUniqueObjects(_watchedPosts).map((eachPost) => ({
        ...eachPost,
        companyViews: viewsObj[eachPost.id],
      }));

      console.log("Unique Posts >> ", uniquePosts);
      setViewedTitles(uniquePosts);
      setTitlesLoading(false);
    } catch (error) {
      setTitlesLoading(false);
      console.error("Error getting watched posts: ", error.message);
    }
  }

  const openUsersModal = () => setUsersModal(true);
  const closeUsersModal = () => {
    setUsersModal(false);
  };

  return (
    <div>
      {loading.main ? (
        <CustomBackdrop />
      ) : (
        <div style={{ overflowX: "hidden" }}>
          <Navbar />
          <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />
          <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />

          <header id="home_header" className="position-relative">
            <div className="container">
              {error.isError && (
                <Snackbar
                  open={error.isError}
                  autoHideDuration={5000}
                  onClose={() => setError({ isError: false, errorMessage: "" })}
                >
                  <Alert
                    onClose={() =>
                      setError({ isError: false, errorMessage: "" })
                    }
                    severity="error"
                  >
                    {error.errorMessage}
                  </Alert>
                </Snackbar>
              )}
              <div className="row header_top_container position-relative">
                <div className="col-12 statistics-header">
                  <h2>Company Dashboard</h2>
                </div>
              </div>

              <div className="custom-box mt-2">
                <div>
                  <p>Company Name: </p>
                  <div className="text-container">
                    {updateCompanyNameLoading ? (
                      <CustomSkeleton width="10rem" height="1.5rem" />
                    ) : isEdit ? (
                      <>
                        <input
                          onChange={(e) => setCompanyName(e.target.value)}
                          name="companyName"
                          type="text"
                          className="form-control"
                          value={companyName}
                        />
                        <DoneIcon
                          fontSize="small"
                          onClick={() => saveCompanyName()}
                          style={{ cursor: "pointer" }}
                        />
                        <CloseIcon
                          fontSize="small"
                          onClick={() => {
                            setCompanyName(
                              userDetails.companyName || "Company"
                            );
                            setIsEdit(false);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    ) : (
                      <>
                        <span className="font-weight-bold">{companyName}</span>
                        <EditIcon
                          fontSize="small"
                          onClick={() => setIsEdit(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div>
                  <p>Total Users: </p>
                  {subscribersLoading ? (
                    <CustomSkeleton width="8rem" height="1.5rem" />
                  ) : (
                    <div className=" text-container">
                      <span className="font-weight-bold">
                        {subscribersData.length ? subscribersData.length : 0}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <p>Titles Viewed: </p>
                  {titlesLoading ? (
                    <CustomSkeleton width="8rem" height="1.5rem" />
                  ) : (
                    <div className="text-container">
                      <span className="font-weight-bold">
                        {viewedTitles && viewedTitles.length
                          ? viewedTitles.reduce(
                              (partialSum, a) =>
                                partialSum + Number(a.companyViews),
                              0
                            )
                          : 0 || 0}
                      </span>
                    </div>
                  )}
                </div>

                <div>
                  <p>Company Users: </p>
                  {subscribersLoading ? (
                    <CustomSkeleton width="8rem" height="1.5rem" />
                  ) : subscribersData && subscribersData.length ? (
                    <span
                      onClick={openUsersModal}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      View Users
                    </span>
                  ) : !subscribersLoading && !subscribersData.length ? (
                    <span style={{ color: "red", cursor: "not-allowed" }}>
                      No Associated Users
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="heading-margin col-12 statistics-header">
                <h4 className="m-0">Mood Check Details</h4>
              </div>
              <MoodCheck />

              <div className="tables-box">
                <div className="w-100">
                  <h4>Top 10 Viewed Titles</h4>
                  {titlesLoading ? (
                    <CustomSkeleton width="25em" height="30em" />
                  ) : (
                    <ViewsTable
                      data={viewedTitles.slice(0, 10)}
                      type="topTenTitles"
                    />
                  )}
                </div>
                <div className="w-100">
                  <h4>Views By Category</h4>
                  {titlesLoading ? (
                    <CustomSkeleton width="25em" height="30em" />
                  ) : (
                    <ViewsTable data={viewedTitles} type="viewsByCategory" />
                  )}
                </div>
              </div>
            </div>
          </header>
          {/* <div className="container mt-5">
            <div className="col-12 statistics-header">
              <h4>User Details</h4>
            </div>
            {subscribersLoading ? (
              <CustomSkeleton width="100%" height="5rem" />
            ) : subscribersData && subscribersData.length ? (
              <CompanyStatsTable
                data={subscribersData}
                getSubs={getSubs}
                // handleSorting={handleSorting}
              />
            ) : !subscribersLoading && !subscribersData.length ? (
              "No Associated Users"
            ) : null}
          </div> */}

          <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />
          <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />
        </div>
      )}
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error.isError ? "error" : "info"}
        >
          {snackbarInfo.snackbarMessage}
        </Alert>
      </Snackbar>
      {usersModal && (
        <CompanyUsersModal
          open={usersModal}
          close={closeUsersModal}
          data={subscribersData}
          getSubs={getSubs}
          _loading={subscribersLoading}
          handleSorting={() => {
            alert("Work in progress");
          }}
        />
      )}
    </div>
  );
}

export default CompanyDashboard;
