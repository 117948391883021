import { useEffect, useState } from "react";

export const getSortConditionBySortOrder = (
  sortOrder,
  resourceA,
  resourceB,
  isString = false
) => {
  if (isString) {
    if (sortOrder === "desc") {
      let temp = resourceA;
      resourceA = resourceB;
      resourceB = temp;
    }

    resourceA = resourceA.toLowerCase();
    resourceB = resourceB.toLowerCase();

    if (resourceA < resourceB)
      //sort string ascending
      return -1;
    if (resourceA > resourceB) return 1;
    return 0; //default return value (no sorting)
  }
  if (sortOrder === "desc") return resourceB - resourceA;
  return resourceA - resourceB;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const validSalesPersonId = (id) => {
  var pattern = /^[a-zA-Z]{2}\d{3}$/;
  return pattern.test(id);
};

export const ASCENDING_ORDER = "asc";
export const DESCENDING_ORDER = "desc";
export const DENIED_SUBSCRIPTION_STATUSES = [
  { status: "deleted", statusMessage: "Subscription Deleted" },
  { status: "unpaid", statusMessage: "Unpaid User" },
  { status: "canceled", statusMessage: "Subscription Canceled" },
];

export const GRANTED_SUBSCRIPTION_STATUSES = [
  { status: "trial", statusMessage: "User on Trial" },
  { status: "paid", statusMessage: "Paid User" },
  { status: "active", statusMessage: "Active Subscription" },
];

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function validateAndLowercaseString(str) {
  if (!str) {
    throw new Error("Provided value cannot be null or undefined.");
  }
  if (typeof str !== "string") {
    throw new Error("Invalid value type. Value must be a string.");
  }
  return str.toLowerCase().trim();
}

export function isStatusGranted(status) {
  status = validateAndLowercaseString(status);
  return GRANTED_SUBSCRIPTION_STATUSES.some((pkg) => pkg.status === status);
}

export function isStatusDenied(status) {
  status = validateAndLowercaseString(status);
  return DENIED_SUBSCRIPTION_STATUSES.some((pkg) => pkg.status === status);
}
