import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./VirtualGuru.css";
import { firestore, firebase } from "../firebase";
import ShareModal from "../components/ShareModal/ShareModal";
import { useDispatch } from "react-redux";
import { toggleShare } from "../store/actions/indexActions";
import { useSelector } from "react-redux";
import Gurus from "../components/Gurus/Gurus";
import Navbar from "../components/Navbar/Navbar";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import PostLink from "../components/PostLink/PostLink";
import moment from "moment";
import SortAscAndDesc from "../components/SortAscAndDesc/SortAscAndDesc";
import { ASCENDING_ORDER, DESCENDING_ORDER, getSortConditionBySortOrder } from "../util/helpers";

const VirtualGuru = () => {
  const history = useHistory();
  const [recommendedPosts, setRecommendedPosts] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("created");
  const [currentOrder, setCurrentOrder] = useState(DESCENDING_ORDER);
  const [guru, setGuru] = useState(null);
  const [contentType, setContentType] = useState("");
  const [loading, setLoading] = useState("");

  const dispatch = useDispatch();
  const { selectedAdventure } = useSelector((state) => state.index);

  useEffect(async () => {
    let isMounted = true;
    const pathname = history.location.pathname.split("/");
    const virtualGuruId = pathname[2];

    if (virtualGuruId) {
      firestore
        .collection("m_gurus")
        .doc(virtualGuruId)
        .get()
        .then((res) => {
          setGuru(res.data());
        });
    }

    loadRecommendedPosts(virtualGuruId);

    return () => (isMounted = false);
  }, [history.location.pathname]);

  const loadRecommendedPosts = async (guruId) => {
    setLoading(true);
    const res = await axios
      .post("/api/get-guru-recommendations", {
        guruId,
      })
      .finally(() => {
        setLoading(false);
      });
    if (res.data.status === 200) {
      setRecommendedPosts(res.data.data);
    }
  };

  const handleOnClickSort = (fieldName, order) => {
    let data = [...recommendedPosts];

    switch (fieldName) {
      case "created":
        data = data.sort((postA, postB) =>
          getSortConditionBySortOrder(
            order,
            new Date(postA?.dateAdded?._seconds || 1625147662 * 1000),
            new Date(postB?.dateAdded?._seconds || 1625147662 * 1000)
          )
        );
        break;
      case "topic":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.adventureTitle,
            postB?.adventureTitle,
            true
          );
        });
        break;
      case "rowTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.rowTitle,
            postB?.rowTitle,
            true
          );
        });
        break;
      case "postTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.postTitle,
            postB?.postTitle,
            true
          );
        });
        break;
    }
    setRecommendedPosts(data);
    setCurrentOrder(order);
    setCurrentSortField(fieldName);
  };

  return (
    <>
      <Navbar />
      <div className="virtual-guru-container">
        <Gurus heading="Virtual Gurus" size="small" />
        {guru && (
          <>
            <div className="first-container">
              <div>
                <h4>
                  Hi, I'm <b>{guru?.name}</b>
                </h4>
                <div
                  className="img-container"
                  style={{
                    backgroundImage: `url(${guru?.profilePictureURL})`,
                  }}
                >
                  {/* <img src={guru?.profilePictureURL} /> */}
                </div>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: guru?.description }}
              />
            </div>
            <div className="second-container">
              {loading ? (
                <>
                  <Skeleton height={130} />
                  <Skeleton height={130} />
                  <Skeleton height={130} />
                  <Skeleton height={130} />
                </>
              ) : (
                <>
                  {recommendedPosts.length > 0 && (
                    <table class="table history-table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            <SortAscAndDesc
                              isActive={currentSortField === "topic"}
                              onClickAsc={() =>
                                handleOnClickSort("topic", ASCENDING_ORDER)
                              }
                              onClickDesc={() =>
                                handleOnClickSort("topic", DESCENDING_ORDER)
                              }
                              isAscending={currentOrder === ASCENDING_ORDER}
                              title="Topic"
                            />
                          </th>
                          <th scope="col">
                            <SortAscAndDesc
                              isActive={currentSortField === "rowTitle"}
                              onClickAsc={() =>
                                handleOnClickSort("rowTitle", ASCENDING_ORDER)
                              }
                              onClickDesc={() =>
                                handleOnClickSort("rowTitle", DESCENDING_ORDER)
                              }
                              isAscending={currentOrder === ASCENDING_ORDER}
                              title="Category"
                            />
                          </th>

                          <th scope="col">
                            <SortAscAndDesc
                              isActive={currentSortField === "postTitle"}
                              onClickAsc={() =>
                                handleOnClickSort("postTitle", ASCENDING_ORDER)
                              }
                              onClickDesc={() =>
                                handleOnClickSort("postTitle", DESCENDING_ORDER)
                              }
                              isAscending={currentOrder === ASCENDING_ORDER}
                              title="Title"
                            />
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {recommendedPosts.map((likedPost, index) => (
                          <tr key={index}>
                            <td
                              className="img"
                              style={{ paddingTop: 5 }}
                              colSpan={1}
                              align="center"
                            >
                              <PostLink
                                post={likedPost}
                                rowId={likedPost.rowId}
                                postId={likedPost.postId}
                                adventureId={likedPost.adventureId}
                              >
                                <div className="post-thumbnail-container">
                                  <img src={likedPost?.thumbnailUrl} />
                                </div>
                              </PostLink>
                            </td>
                            <th scope="row">{likedPost.adventureTitle}</th>
                            <td>{likedPost.rowTitle}</td>
                            <td>
                              <Link
                                to={`/adventure/${likedPost.adventureId}/${likedPost.rowId}/${likedPost.postId}`}
                              >
                                {likedPost.postTitle}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VirtualGuru;
