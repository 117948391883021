import React, { useEffect, useState } from "react";
import Favourites from "../../pages/Favourites";

import { firestore, firebase, auth } from "../../firebase";
import { Link } from "react-router-dom";
import PostItem from "./PostItem";
import { Skeleton } from "@material-ui/lab";
import SortAscAndDesc from "../SortAscAndDesc/SortAscAndDesc";
import {
  ASCENDING_ORDER,
  DESCENDING_ORDER,
  getSortConditionBySortOrder,
} from "../../util/helpers";
import axios from "axios";

const FavouriteTable = () => {
  const [likedPosts, setLikedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSortField, setCurrentSortField] = useState("created");
  const [currentOrder, setCurrentOrder] = useState(DESCENDING_ORDER);
  useEffect(async () => {
    loadFavouritesPosts();
  }, []);

  const loadFavouritesPosts = async () => {
    setLoading(true);
    const res = await axios
      .post("/api/get-user-favourites", {
        email: auth?.currentUser?.email,
      })
      .finally(() => {
        setLoading(false);
      });
    if (res.data.status === 200) {
      setLikedPosts(res.data.data);
    }
  };

  const onClickHeartHandler = async (postIndex) => {
    setLoading(true);
    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;
      let updatedPosts = [...likedPosts];
      updatedPosts.splice(postIndex, 1);
      updatedPosts = updatedPosts.map((eachPost) => {
        const { adventureId, rowId, postId, dateAdded } = eachPost;
        return { adventureId, rowId, postId, date: dateAdded };
      });

      const arrRemove = documentRef
        .update({
          likedPosts: updatedPosts,
        })
        .then((res) => loadFavouritesPosts());
    }
  };

  const handleOnClickSort = (fieldName, order) => {
    let data = [...likedPosts];

    switch (fieldName) {
      case "created":
        data = data.sort((postA, postB) =>
          getSortConditionBySortOrder(
            order,
            new Date(postA?.dateAdded?._seconds || 1625147662 * 1000),
            new Date(postB?.dateAdded?._seconds || 1625147662 * 1000)
          )
        );
        break;
      case "topic":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.adventureTitle,
            postB?.adventureTitle,
            true
          );
        });
        break;
      case "rowTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.rowTitle,
            postB?.rowTitle,
            true
          );
        });
        break;
      case "postTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.postTitle,
            postB?.postTitle,
            true
          );
        });
        break;
    }
    setLikedPosts(data);
    setCurrentOrder(order);
    setCurrentSortField(fieldName);
  };

  return (
    <div className="px-5">
      <div className="my-5" style={{ height: "20px" }}>
        <h2 style={{ width: "300px", color: "#1e2864" }}>Your Favorites</h2>
      </div>
      {loading ? (
        <>
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </>
      ) : (
        <table class="table favourite-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "topic"}
                  onClickAsc={() => handleOnClickSort("topic", ASCENDING_ORDER)}
                  onClickDesc={() =>
                    handleOnClickSort("topic", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Topic"
                />
              </th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "rowTitle"}
                  onClickAsc={() =>
                    handleOnClickSort("rowTitle", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("rowTitle", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Category"
                />
              </th>

              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "postTitle"}
                  onClickAsc={() =>
                    handleOnClickSort("postTitle", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("postTitle", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Title"
                />
              </th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "created"}
                  onClickAsc={() =>
                    handleOnClickSort("created", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("created", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Date Added"
                />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {likedPosts.map((likedPost, index) => (
              <PostItem
                key={index}
                likedPost={likedPost}
                onClickHeartHandler={() => onClickHeartHandler(index)}
                currentSortField={currentSortField}
                handleOnClickSort={handleOnClickSort}
                postData={likedPost?.postData || {}}
              />
            ))}
          </tbody>
        </table>
      )}{" "}
    </div>
  );
};

export default FavouriteTable;
