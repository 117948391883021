import React, { useEffect, useState } from "react";
import SortAscAndDesc from "../SortAscAndDesc/SortAscAndDesc";
import moment from "moment";
import { ASCENDING_ORDER, DESCENDING_ORDER } from "../../util/helpers";

const topTenTitles = [
  { title: "Title", value: "title" },
  { title: "Total Views", value: "views" },
];
const viewsByCategory = [
  { title: "Category", value: "title" },
  { title: "Total Views", value: "views" },
];

const initVals = [
  {
    name: "Productivity",
    timesViewed: 0,
  },
  {
    name: "Wellness",
    timesViewed: 0,
  },
  {
    name: "Kids",
    timesViewed: 0,
  },
  {
    name: "Fun",
    timesViewed: 0,
  },
  {
    name: "Learn",
    timesViewed: 0,
  },
];
const ViewsTable = ({ data, handleSorting, type }) => {
  const [orderBy, setOrderBy] = useState("firstName");
  const [views, setViews] = useState(initVals);
  const [orderDirection, setOrderDirection] = useState(ASCENDING_ORDER);

  useEffect(() => {
    if (data && data.length) {
      if (type === "viewsByCategory") {
        let viewsCopy = views;
        data.forEach((each) => {
          const propertyName = each.category ? "category" : "adventureTitle";
          const index = viewsCopy.findIndex(
            (e) => e.name === each[propertyName]
          );
          if (index !== -1) {
            viewsCopy[index].timesViewed += each.companyViews;
          }
        });
        setViews(viewsCopy);
      } else if (type === "topTenTitles") {
        const sortedArray = data.sort(
          (a, b) => b.companyViews - a.companyViews
        );
        setViews(sortedArray);
      }
    }

    return () => {
      setViews(initVals);
    };
  }, [data]);

  const handleOnClickSort = async (fieldName, order) => {
    const nonSortingFields = [
      "subscriptionStatus",
      "invoiceStatus",
      "lastPaid",
      "numberOfSubs",
    ];
    if (nonSortingFields.includes(fieldName)) return;

    setOrderBy(fieldName);
    setOrderDirection(order);

    await handleSorting({
      orderValue: fieldName,
      orderDirection: order,
    });
  };

  return (
    <>
      {/* FILTERS AND OTHERS BELOW */}
      {/* <div className="container mb-5">
        <form onSubmit={(e) => console.log(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => console.log(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => console.log(e)}
                name="searchBy"
                className="form-control"
                value={"searchBy"}
              >
                <option value="0">Search By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="email">Email</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                value={"orderBy"}
                // onChange={(e) => onChangeFilteringHandler(e)}
                name="orderBy"
                className="form-control"
              >
                <option value="0">Order By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="createdAt">Created</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                value={"orderDirection"}
                // onChange={(e) => onChangeFilteringHandler(e)}
                name="orderDirection"
                className="form-control"
              >
                <option value="0">Order Direction</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </form>
      </div> */}

      <table class="table table-striped">
        <thead>
          <tr>
            {type === "viewsByCategory"
              ? viewsByCategory.map((each, index) => (
                  <th key={index} scope="col">
                    {each.title}
                  </th>
                ))
              : topTenTitles.map((each, index) => (
                  <th key={index} scope="col">
                    {each.title}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody>
          {views && views.length ? (
            views.map((each) => {
              const key = type === "topTenTitles" ? each.title : each.name;
              const value =
                type === "topTenTitles" ? each.companyViews : each.timesViewed;
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="2">No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      {/*  <div className="d-flex justify-content-center mt-5">
         <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination> 
      </div>*/}
    </>
  );
};

export default ViewsTable;
