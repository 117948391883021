export const TOGGLE_SIGNIN = "TOGGLE_SIGNIN";
export const TOGGLE_SIGNUP = "TOGGLE_SIGNUP";
export const TOGGLE_CONTACT = "TOGGLE_CONTACT";
export const TOGGLE_PAYMENT = "TOGGLE_PAYMENT";
export const SET_USER_INFO = "SET_USER_INFO";

export const SELECT_PLAN = "SELECT_PLAN";

export const TOGGLE_SHARE = "TOGGLE_SHARE";


export const SET_THEME = 'SET_THEME';
