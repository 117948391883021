import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { firestore } from "../../firebase";
import "./trialModal.css";
import { Paper } from "@material-ui/core";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "firebase";
import moment from "moment";

const TrialModal = () => {
  const [modal, setModal] = useState(false);
  const { userDetails } = useAuth();

  useEffect(() => {
    checkUserTrial();
  }, [userDetails]);

  const checkUserTrial = async () => {
    if (
      auth().currentUser &&
      userDetails &&
      userDetails?.package &&
      userDetails?.package?.type
    ) {
      const allowedTypes = ["paid", "deleted", "unpaid", "prepaid"];
      if (
        userDetails?.package?.type &&
        allowedTypes.includes(userDetails.package.type.toLowerCase())
      ) {
        return;
      }

      const packageExpiryDate = moment(
        new Date(userDetails?.package?.end?.seconds * 1000)
      );

      const currentDate = moment(); // Current date and time

      if (packageExpiryDate.isAfter(currentDate)) {
        const storedDate = localStorage.getItem("trialExpiryModalShown");
        if (storedDate) {
          const storedDateObj = new Date(storedDate);
          const currentDate = new Date();

          if (
            storedDateObj.getDate() === currentDate.getDate() &&
            storedDateObj.getMonth() === currentDate.getMonth() &&
            storedDateObj.getFullYear() === currentDate.getFullYear()
          ) {
            return;
          }
        }

        setModal(true);
      } else {
        // console.log("Package has expired.");
        return;
      }
    } else {
      // console.log("user details not found");
      return;
    }
  };

  const toggle = () => {
    const currentDate = new Date();
    localStorage.setItem("trialExpiryModalShown", currentDate.toISOString());
    setModal((prev) => !prev);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <div className="modal-top"></div>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <Paper>
          <div className="container mood-check-container">
            <div className="d-flex align-items-center">
              <h2>Enjoy your free trial!</h2>
            </div>
            <div className="d-flex align-items-center">
              <p>
                You have unlimited access, so take your time to explore all the
                features! Your trial expires on{" "}
                {moment(userDetails?.package?.end.toDate()).format(
                  "MM/DD/YYYY"
                )}
                . We hope you love what we have to offer, but if you want to
                cancel, you can do so from the Billing section.
              </p>
            </div>
            <button className={"customBtn"} onClick={toggle}>
              Close
            </button>
          </div>
        </Paper>
      </ModalBody>
    </Modal>
  );
};

export default TrialModal;
