import React from "react";
import ContentActions from "./ContentActions";

const ContentRow = ({ contentData, deleteHandler, editHandler }) => {
  const removeTags = (str) => {
    if (str === null || str === "" || !str || typeof str === "undefined")
      return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  return (
    <tr>
      <td>{contentData.title}</td>
      <td>{removeTags(contentData.description)}</td>
      <td>
        <img
          src={`${contentData.thumbnailUrl}`}
          style={{ width: "50px", height: "50px" }}
          alt="thumbnail here"
        />
      </td>
      <ContentActions
        id={contentData.id}
        onEditHandler={editHandler}
        onDeleteHandler={deleteHandler}
      />
    </tr>
  );
};

export default ContentRow;
