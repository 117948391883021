import React, { useState, useEffect } from "react";
import { firebase, firestore, storage, timestamp } from "../../firebase";
import usePagination from "../hooks/usePagination";

import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Tooltip,
} from "reactstrap";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

const initValues = {
  title: "",
  description: "",
};

const CheckIn = () => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [values, setValues] = useState({ ...initValues });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedBotheringYouOption, setSelectedBotheringYouOption] =
    useState(null);

  const [isMounted, setIsMounted] = useState(false);

  const [options, setOptions] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    options,
    itemsPerPage
  );

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    setError(true);

    const unsub = firestore
      .collection("m_bothering_you_options")
      .doc("options")
      .onSnapshot((snapshot) => {
        setOptions(snapshot?.data()?.options || []);
        setIsLoading(false);
        setError(null);
      });
    return () => unsub;
  }, []);

  const onAddNewHandler = () => {
    setIsAddNew(true);
  };

  const onEditHandler = (value, index) => {
    setIsEdit(true);
    setSelectedBotheringYouOption((currentPage - 1) * itemsPerPage + index);
    setValues({ name: value });
  };

  const onDeleteHandler = async (index) => {
    setIsDelete(true);
    setIsLoading(true);
    if (isMounted) {
      const result = window.confirm("Are you sure you want to delete it?");
      if (!result) {
        return;
      } else {
        try {
          let opt = [...options];
          opt.splice((currentPage - 1) * itemsPerPage + index, 1);
          await firestore
            .collection("m_bothering_you_options")
            .doc("options")
            .set({
              options: opt,
            });
          setMessage("Data deleted successfully");
        } catch (e) {
          console.log(e);
        } finally {
          setIsDelete(false);
          setValues(initValues);
          setIsLoading(false);
        }
      }
    }
  };

  const onSaveHandler = async () => {
    try {
      if (!values?.name) {
        alert("Option name is required!");
        return;
      }

      setIsLoading(true);

      if (isAddNew) {
        let opt = [...options, values.name];

        await firestore
          .collection("m_bothering_you_options")
          .doc("options")
          .set({
            options: opt,
          });

        setMessage("Option added successfully");
        setTimeout(() => setMessage(""), 2000);
        setIsAddNew(false);
        setValues(initValues);
        setIsLoading(false);
      }

      if (isEdit) {
        let opt = [...options];
        opt[selectedBotheringYouOption] = values.name;

        await firestore
          .collection("m_bothering_you_options")
          .doc("options")
          .set({
            options: opt,
          });

        setMessage("Data added successfully");
        setTimeout(() => setMessage(""), 2000);
        setIsEdit(false);
        setValues(initValues);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onCancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleMoveUp = async (index) => {
    let opt = [...options];
    let element = opt[(currentPage - 1) * itemsPerPage + index];
    opt.splice((currentPage - 1) * itemsPerPage + index, 1);
    opt.splice((currentPage - 1) * itemsPerPage + index - 1, 0, element);
    console.log(opt)
    await firestore.collection("m_bothering_you_options").doc("options").update({
      options: opt,
    }).catch(r=>console.log(r));
  };

  const handleMoveDown = async (index) => {
    let opt = [...options];
    let element = opt[(currentPage - 1) * itemsPerPage + index];
    opt.splice((currentPage - 1) * itemsPerPage + index, 1);
    opt.splice((currentPage - 1) * itemsPerPage + index === options.length - 1 ? 0 : (currentPage - 1) * itemsPerPage + index + 1, 0, element);
    await firestore.collection("m_bothering_you_options").doc("options").set({
      options: opt,
    });
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Mood Check : bothering you options</a>
          </li>
        </ol>
      </nav>

      <div className="position-relative">
        {isLoading && (
          <div style={spinnerDivStyleSheet}>
            <Spinner style={spinnerStyleSheet} />
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Option</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentData() &&
              currentData().map((botheryYouOption, index) => (
                <tr>
                  {selectedBotheringYouOption === index && isEdit ? (
                    <>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => onValueChangeHandler(e)}
                          className="form-control"
                          value={values.name}
                          name="name"
                          type="text"
                          placeholder="Name"
                        />{" "}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{botheryYouOption}</td>
                      <td>
                        <button
                          onClick={(e) =>
                            onEditHandler(botheryYouOption, index)
                          }
                          style={{
                            marginRight: "3px",
                            padding: "3px",
                            border: "0px",
                          }}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                        <button
                          onClick={(e) => onDeleteHandler(index)}
                          style={{ padding: "3px", border: "0px" }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                            <ArrowDropUp style={{cursor:'pointer'}} onClick={() => handleMoveUp(index)} />
                            <ArrowDropDown
                            style={{cursor:'pointer'}}
                              onClick={() => handleMoveDown(index)}
                            />
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            {isAddNew ? (
              <td>
                {" "}
                <input
                  onChange={(e) => onValueChangeHandler(e)}
                  className="form-control"
                  value={values.name}
                  name="name"
                  type="text"
                  placeholder="Name"
                />{" "}
              </td>
            ) : null}
          </tbody>
        </table>
        <div>
          {isAddNew || isEdit ? (
            <>
              <button
                onClick={() => onSaveHandler()}
                className="btn btn-sm btn-primary"
              >
                Save
              </button>
              <button
                onClick={() => onCancelHandler()}
                className="btn btn-sm btn-warning mx-3"
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => onAddNewHandler()}
              className="btn btn-sm btn-primary"
              disabled={error}
            >
              Add New
            </button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default CheckIn;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
