import React, { useState } from "react";
import "./PriceCard.css";
import { useDispatch } from "react-redux";
import {
  selectPricePlan,
  toggleSignup,
  toggleContact,
} from "../../store/actions/indexActions";

import Alert from "../Alert";

import { firestore } from "../../firebase";

import axios from "axios";

const borderColors = [
  "#C0B0FF",
  "linear-gradient(to bottom, #306aff59, #f36ae66e)",
  "#FFB0DB",
  "#DCB0FF",
  "#9CE7FF",
  "#DCB0FF",
];

const PriceCard = (props) => {
  const {
    title,
    description,
    price,
    checkbox,
    stripeProductId,
    stripePriceId,
    isSingleUser,
    numberOfUsers,
  } = props.plan;
  const { cardNumber } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onClickHandler = async (e) => {
    await dispatch(
      selectPricePlan({
        title,
        price,
        stripePriceId,
        stripeProductId,
        isSingleUser,
        numberOfUsers,
      })
    );

    const token = localStorage.getItem("token");

    try {
      const res = await axios.post("/api/verify-token", { token });

      const email = res.data.decoded && res.data.decoded.email;

      if (res.data.decoded && res.data.decoded.email && !checkbox) {
        const result = window.confirm(
          "Are you sure you want to change your current subscription?"
        );

        if (result) {
          setIsLoading(true);

          try {
            const res = await firestore
              .collection("users")
              .where("email", "==", `${email}`)
              .get();
            if (!res.empty) {
              res.docs.forEach(async (doc) => {
                const { stripeData } = doc.data();
                const id = doc.id;

                const result = await axios.post("/api/update-subscription", {
                  stripePriceId,
                  subscriptionId: stripeData.id,
                });
                const { updatedSubscription } = result.data;

                await firestore
                  .collection("users")
                  .doc(id)
                  .set({ stripeData: updatedSubscription }, { merge: true });

                setIsLoading(false);

                setIsAlertOpen(true);
                setAlertType("success");
                setAlertMessage("Subscription updated susccesfully");
              });
            }
          } catch (err) {
            setIsLoading(false);
            console.log(err);
            setIsAlertOpen(true);
            setAlertType("error");
            setAlertMessage("Something went wrong");
          }
        }
      } else if (checkbox) {
        dispatch(toggleContact());
      } else {
        dispatch(toggleSignup());
      }
    } catch (err) {
      console.log("Error from onClickHandler: ", err);
      if (err.response && err.response.status === 404) {
        dispatch(toggleSignup());
      }
    }
  };

  return (
    <div className="col-6 col-md-4 pricing_card_container_col">
      <div
        className="card pricing_card border-gradient"
        style={
          cardNumber % borderColors.length == 1
            ? { borderImageSource: `${borderColors[1]}` }
            : {
                borderColor: `${
                  borderColors[cardNumber % borderColors.length]
                }`,
              }
        }
      >
        <div
          className="card-body text-center"
          style={
            props.plan.hasOwnProperty("popular") &&
            props.plan.hasOwnProperty("popular") === true
              ? { padding: "0px" }
              : null
          }
        >
          {props.plan.hasOwnProperty("popular") &&
          props.plan.hasOwnProperty("popular") === true ? (
            <h5 className="popular">POPULAR</h5>
          ) : null}

          <h5 className="card-title">{title}</h5>
          <h3>
            <span className="currency" style={{ marginRight: "3px" }}>
              $
            </span>{" "}
            {price} <span className="period">/mo</span>{" "}
          </h3>
          <p className="card-text">{description}</p>
        </div>
        <div className="card-footer">
          <a className="btn hover-border" onClick={(e) => onClickHandler(e)}>
            {isLoading ? (
              <>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Please wait...
              </>
            ) : (
              <>CHOOSE</>
            )}
          </a>
        </div>
      </div>

      <Alert
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        alertType={alertType}
        alertMessage={alertMessage}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
      />
    </div>
  );
};

export default PriceCard;
