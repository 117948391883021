import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import CustomBackdrop from "./components/Loader";

export default function AdminRoute({ component: Component, ...rest }) {
  const { currentUser, isAdmin, issalesPerson } = useAuth();
  let redirectURL = "/";

  if (!currentUser) return <Redirect to={redirectURL} />;

  if (isAdmin === null) return <CustomBackdrop />;

  if (issalesPerson) redirectURL = `/salesportal/dashboard/${currentUser.uid}`;

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectURL} />
        );
      }}
    ></Route>
  );
}
