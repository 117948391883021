import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { firebase, firestore, storage, timestamp } from "../../firebase";
import "./MoodCheck.css";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import OptionGroup from "./OptionGroup";
import { IconButton, Paper, Tooltip } from "@material-ui/core";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "firebase";
import moment from "moment";

const MoodCheck = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doingToday, setDoingToday] = useState(null);
  const [stressLevel, setStressLevel] = useState(null);
  const [botheringYou, setBotheringYou] = useState([]);
  const [botheringYouOptions, setBotheringYouOptions] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const { userDetails } = useAuth();
  useEffect(() => {
    getBotheryYouOptions();
    setBotheringYou([]);
    setStressLevel(null);
    setDoingToday(null);
    setShowTooltip(false);

    checkUserMoodCheck();
  }, [modal]);

  const checkUserMoodCheck = async () => {
    if (auth().currentUser) {
      // get user details
      const userDetails = await firestore
        .collection("users")
        .doc(auth().currentUser.uid)
        .get()
        .then((res) => res.data());
      // get the user last mood checkin
      const lastMoodCheckin = userDetails?.lastMoodCheckin;
      const userCreatedAt = moment(
        new Date(userDetails?.createdAt?.seconds * 1000)
      );
      // if user mood check in date not exist or the date 14 days older
      if (
        moment(new Date()).diff(userCreatedAt, "days") >= 3 &&
        (!lastMoodCheckin ||
          moment(new Date()).diff(
            moment(new Date(lastMoodCheckin?.seconds * 1000)),
            "days"
          ) >= 14)
      ) {
        // set new date to 14 days after today date
        firestore.collection("users").doc(auth().currentUser.uid).update({
          lastMoodCheckin: firebase.firestore.FieldValue.serverTimestamp(),
        });
        // show the mood check in modal
        setModal(true);
      }
    }
  };
  const getBotheryYouOptions = () => {
    firestore
      .collection("m_bothering_you_options")
      .doc("options")
      .get()
      .then((res) =>
        setBotheringYouOptions(res?.data()?.options?.map((e) => ({ name: e })))
      );
  };

  const toggle = () => setModal((prev) => !prev);

  const onSave = async () => {
    // don't save any record if no option is selected
    if (!doingToday && !stressLevel && botheringYou.length === 0) return;

    setLoading(true);
    await firestore.collection("check-ins").add({
      userId: auth().currentUser.uid,
      ...(userDetails?.companyId && { companyId: userDetails?.companyId }),
      ...(userDetails?.corporateAdminId && {
        corporateAdminId: userDetails?.corporateAdminId,
      }),
      stressLevel,
      botheringYou,
      doingToday,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    setLoading(false);
    setModal(false);
  };

  return (
    <>
      <a onClick={() => setModal(true)}>
        <div
          className="d-flex justify-content-center align-items-center ml-2"
          role="button"
        >
          <button
            style={{ marginRight: "5px", outline: "none" }}
            className="heartBtnFeature"
          >
            <i class="fas fa-check"></i>
          </button>
          <p style={{ fontWeight: "500" }}>Check In</p>
        </div>
      </a>

      <a lassName="nav-link" to="/home"></a>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <Paper>
            <div className="container mood-check-container">
              {loading ? (
                <div className="spinner_container">
                  <Spinner />
                </div>
              ) : null}
              <div className="d-flex align-items-center">
                <h2>Check In</h2> &nbsp;
                <Tooltip
                  onClose={() => setShowTooltip(false)}
                  open={showTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="We'll check in with you from time to time to see how you're doing. Rest assured that your data is confidential and used to personalize your experience and analysis only."
                >
                  <IconButton
                    onClick={() => setShowTooltip((prev) => !prev)}
                    className="mb-2 p-1 pr-2"
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <OptionGroup
                title="How are you doing today?"
                options={[
                  {
                    name: "Great",
                    image: require("../../assets/emojis/smile.png").default,
                  },
                  {
                    name: "Okay",
                    image: require("../../assets/emojis/straight-face.png")
                      .default,
                  },
                  {
                    name: "Blah",
                    image: require("../../assets/emojis/sad.png").default,
                  },
                ]}
                setCurrentValue={setDoingToday}
                currentValue={doingToday}
              />
              <OptionGroup
                title="Stress Level"
                options={[
                  {
                    name: "Low",
                    color: "Green",
                  },
                  {
                    name: "Medium",
                    color: "Orange",
                  },
                  {
                    name: "High",
                    color: "Red",
                  },
                ]}
                setCurrentValue={setStressLevel}
                currentValue={stressLevel}
              />
              <OptionGroup
                title="Anything bothering you?"
                options={botheringYouOptions}
                setCurrentValue={setBotheringYou}
                currentValue={botheringYou}
                multiple={true}
              />
              <button
                type="submit"
                className={
                  // !(dirty && isValid) ? "disabled-btn customBtn" :
                  "customBtn"
                }
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </Paper>
          <p
            className="mb-5"
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "10px",
              lineHeight: "22px",
              color: "black",
              padding: "0px 7px",
            }}
          >
            Information shared is confidential and used to provide you a better
            experience and personalized recommendations. Aggregate data may also
            be used for analysis purposes without disclosing an individual
            user's identity.
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MoodCheck;
