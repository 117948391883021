import React,{useState,useEffect} from "react";
import { renderButton, checkSignedIn } from "../utils";

const Report = () => {
  const [data,setData] = useState([]);

  useEffect(() => {
    const queryReport = () => {//(1)
      window.gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: "251961609", //enter your view ID here
                dateRanges: [
                  {
                    startDate: "10daysAgo",
                    endDate: "today",
                  },
                ],
                metrics: [
                  {
                    expression: "ga:users",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:date",
                  },
                ],
              },
            ],
          },
        })
        .then(displayResults, console.error.bind(console));
    };

    const displayResults = (response) => {//(2)
      const queryResult = response.result.reports[0].data.rows;
      const result = queryResult.map((row) => {
        const dateSting = row.dimensions[0];
        const formattedDate = `${dateSting.substring(0, 4)}
        -${dateSting.substring(4, 6)}-${dateSting.substring(6, 8)}`;
        return {
          date: formattedDate,
          visits: row.metrics[0].values[0],
        };
      });
      setData(result);
    };

    queryReport();
  }, []);

  return data.map(row => (
    <div key={row.date}>{`${row.date}: ${row.visits} visits`}</div>
  ));
}

const Dashboard = () => {

  const [isSignedIn, setIsSignedIn] = useState(false);

  const updateSignin = (signedIn) => {
    setIsSignedIn(signedIn);
    if (!signedIn) {
      renderButton();
    }
  };

  const init = () => {
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignin);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.gapi.load("auth2", init);
  },[]);

  return (
    <>
      {
        isSignedIn ? <Report /> :
        <div id="signin-button"></div>
      }

      <iframe src="https://datastudio.google.com/embed/reporting/6e40d0a4-4b7b-46e6-8202-820f3ec9d9eb/page/xBGqB" frameborder="0" style={{border:"0",width:"100%",height:"100vh"}} allowfullscreen></iframe>
    </>
  );
}

export default Dashboard;
