import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Alert } from "reactstrap";

import "./SignUpModal.css";

import SignUpForm from "../../../components/SignUpForm/SignUpForm";
import CheckoutForm from "../../../components/CheckoutForm/CheckoutForm";

import TermsPaper from "../../../components/SignUpForm/TermsPaper";

import { Spinner } from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

import { toggleSignup } from "../../../store/actions/indexActions";
import { useParams } from "react-router-dom";
import { validSalesPersonId } from "../../../util/helpers";
import { useAuth } from "../../../contexts/AuthContext";

const SignUpModal = (props) => {
  const [isShowTerms, setIsShowTerms] = useState(false);
  const [salesPersonIdErr, setSalesPersonIdErr] = useState({
    error: false,
    errorMessage: "",
  });
  const { currentUser } = useAuth();

  const { isShowTermPaper, selectedPlanDetails } = useSelector(
    (state) => state.index
  );

  const { isSignupModalOpened } = useSelector((state) => state.index);

  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (currentUser) setModal(false);

    setModal(isSignupModalOpened);
  }, [isSignupModalOpened]);

  useEffect(() => {
    if (
      id &&
      (id !== "sign-in" || id !== "signin") &&
      !validSalesPersonId(id)
    ) {
      setSalesPersonIdErr({
        error: true,
        errorMessage: "Invalid Sales Person ID in URL",
      });
    }
  }, [id]);

  const toggle = () => {
    dispatch({ type: "DONT_SHOW_TERM_PAPER" });
    dispatch(toggleSignup());
  };

  const showSalesPersonErr = (isError, message) => {
    setSalesPersonIdErr({
      error: isError,
      errorMessage: message,
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody style={isShowTerms ? { padding: "10px" } : null}>
          {salesPersonIdErr.error && (
            <Alert
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              color="danger"
            >
              <h6 style={{ margin: 0 }}>
                Error: {salesPersonIdErr.errorMessage}
              </h6>
            </Alert>
          )}
          {isShowTermPaper ? (
            <TermsPaper />
          ) : (
            <SignUpForm
              sales_person_id={id}
              showSalesPersonErr={showSalesPersonErr}
            />
          )}
          <p
            className="mb-5"
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "10px",
              lineHeight: "22px",
              color: "black",
              padding: "0px 7px",
            }}
          >
            Payment information is securely housed by and payments are processed
            by Stripe, Inc. using the highest levels of security. For more
            information, please visit them at{" "}
            <a
              style={{ color: "#265376" }}
              href="https://www.stripe.com"
              target="_blank"
            >
              www.stripe.com
            </a>
            . Leviosa does not store payment information.
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SignUpModal;
