import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "../IndexHeader/IndexHeader.css";

const PageTabs = ({ tabChangeHandler }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let typeParam = queryParams.get("type");

  const [value, setValue] = useState(typeParam === "businesses" ? 0 : 1);

  useEffect(() => {
    if (typeParam && typeParam === "businesses") setValue(0);
  }, [typeParam]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabChangeHandler(newValue);
  };

  return (
    <>
      <hr
        className="my-4 d-none d-sm-block"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      />
      <hr
        className="my-4 d-none d-sm-block"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      />
      <hr
        className="my-3 d-block d-sm-none"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      />
      <header id="index_header">
        <div className="container-fluid">
          <div className="row header_svg_1_container">
            <div className="col-sm-1 col-md-2 d-none d-sm-block"></div>

            <div className="col-12 col-sm-4 col-md-4 d-flex align-items-center header_svg_1_text_container_col">
              <div className="text_container header_svg_1_text_container position-relative">
                <p className="m-0">See Solutions For:</p>
                <Paper square elevation={0}>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    <Tab label="Businesses" />
                    <Tab label="Individuals & Families" />
                  </Tabs>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PageTabs;
