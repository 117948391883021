import React, { useState, useEffect } from "react";
import "./IndexHeader.css";
import { firestore } from "../../firebase";

import { HashLink as Link } from "react-router-hash-link";

const initValues = {
  topSectionHeading: "",
  topSectionParagraph: "",
  topSectionBtnText: "",
  topSectionBtnLink: "",
  midSectionHeading: "",
  midSectionParagraph: "",
  midSectionBtnText: "",
  midSectionBtnLink: "",

  lowerMidSectionHeading: "",
  lowerMidSectionParagraph: "",
  lowerMidSectionBtnText: "",
  lowerMidSectionBtnLink: "",
  lowerMidSectionFileUrl: "",

  bottomSectionHeading: "",
  bottomSectionParagraph: "",
};

const IndexHeader = ({ pageType }) => {
  const [values, setValues] = useState({ ...initValues });

  useEffect(() => {
    let isMounted = true;
    let __collectionName =
      pageType === "businesses" ? "index" : "individualIndex";
    const unsub = firestore
      .collection(__collectionName)
      .doc("page")
      .get()
      .then((res) => {
        const data = res.data();
        if (isMounted) {
          setValues({ ...data });
        }
      });
    return () => {
      isMounted = false;
    };
  }, [pageType]);

  // useEffect(() => {
  //   console.log(window.location.href.split(".com")[0]);
  // },[])

  return (
    <>
      <hr
        className="my-2 d-none d-sm-block"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      />
      {/* <hr
        className="my-4 d-none d-sm-block"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      />
      <hr
        className="my-3 d-block d-sm-none"
        style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
      /> */}

      <header id="index_header">
        <div className="container-fluid">
          <div className="row header_svg_1_container">
            <div className="col-sm-1 col-md-2 d-none d-sm-block"></div>

            <div className="col-12 col-sm-4 col-md-4 d-flex align-items-center header_svg_1_text_container_col">
              <div className="text_container header_svg_1_text_container position-relative">
                <h3>{values.topSectionHeading}</h3>
                <hr
                  className="my-2"
                  style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
                />
                <p>{values.topSectionParagraph}</p>
                {values.topSectionBtnText &&
                window.location.href.split(".us")[0] !=
                  values.topSectionBtnLink.split(".us")[0] ? (
                  <a href={`${values.topSectionBtnLink}`} className="btn">
                    {values.topSectionBtnText}
                  </a>
                ) : null}

                {values.topSectionBtnText &&
                window.location.href.split(".us")[0] ==
                  values.topSectionBtnLink.split(".us")[0] ? (
                  <Link
                    to={`${values.topSectionBtnLink.split(".us")[1]}`}
                    className="btn"
                  >
                    {values.topSectionBtnText}
                  </Link>
                ) : null}

                {/* memphis 1 */}
                {/* <img
                  src="./assets/index/mem_lg/header_mem_svg1.svg"
                  alt="header_mem_svg1"
                  className="header_mem_svg1 d-none d-sm-block"
                />
                <img
                  src="./assets/index/mem_sm/header_svg1.svg"
                  alt="header_svg1"
                  className="header_svg1_sm d-block d-sm-none"
                /> */}
              </div>
            </div>

            <div className="col-12 col-sm-7 col-md-6 position-relative header_svg_1_img_container_col">
              <div className="header_svg_1_img_container position-relative">
                <img
                  src="./assets/index/header_svg_1.svg"
                  alt="header_svg_1"
                  className="header_svg_1 img-fluid w-100"
                />

                {/* memphis 2 */}
                <img
                  src="./assets/index/mem_lg/header_mem_svg2.svg"
                  alt="header_mem_svg2"
                  className="header_mem_svg2 d-none d-sm-block"
                />
                {/* memphis 4 */}
                <img
                  src="./assets/index/mem_lg/header_mem_svg4.svg"
                  alt="header_mem_svg4"
                  className="header_mem_svg4 d-none d-sm-block"
                />
              </div>
              {/* memphis 3 */}
              <img
                src="./assets/index/mem_lg/header_mem_svg3.svg"
                alt="header_mem_svg3"
                className="header_mem_svg3 d-none d-sm-block"
              />
            </div>
          </div>

          <hr
            className="my-5 d-none d-md-block"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />
          <hr
            className="my-5 d-none d-md-block"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />
          <hr
            className="my-3 d-block d-md-none"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />

          <div className="row header_svg_2_container">
            <div className="col-sm-1 col-md-2 d-none d-sm-block position-relative">
              {/* memphis 5 */}
              <img
                src="./assets/index/mem_lg/header_mem_svg5.svg"
                alt="header_mem_svg5"
                className="header_mem_svg5 d-none d-sm-block"
              />
            </div>

            <div className="col-12 col-sm-7 col-md-6 order-sm-2 d-flex align-items-center justify-content-center position-relative header_svg_2_text_container_col">
              <div className="text_container header_svg_2_text_container">
                <h3>{values.midSectionHeading}</h3>
                <hr
                  className="my-2"
                  style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
                />
                <p>{values.midSectionParagraph}</p>

                {values.midSectionBtnText &&
                window.location.href.split(".us")[0] !=
                  values.midSectionBtnLink.split(".us")[0] ? (
                  <a href={`${values.midSectionBtnLink}`} className="btn">
                    {values.midSectionBtnText}
                  </a>
                ) : null}

                {values.midSectionBtnText &&
                window.location.href.split(".us")[0] ==
                  values.midSectionBtnLink.split(".us")[0] ? (
                  <Link
                    to={`${values.midSectionBtnLink.split(".us")[1]}`}
                    className="btn"
                  >
                    {values.midSectionBtnText}
                  </Link>
                ) : null}

                {/* memphis sm 2 */}
              </div>
              <img
                src="./assets/index/mem_sm/header_svg2.svg"
                alt="header_svg2"
                className="header_svg2_sm d-block d-sm-none"
              />
            </div>

            <div className="col-12 col-sm-4 col-md-3 order-sm-1 header_svg_2_img_container_col">
              <div className="header_svg_2_img_container">
                <img
                  src="./assets/index/header_svg_2.svg"
                  alt="header_svg_2"
                  className="header_svg_2 img-fluid w-100"
                />
              </div>
            </div>
          </div>

          <hr
            className="my-5 d-none d-md-block"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />
          <hr
            className="my-5 d-none d-md-block"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />
          <hr
            className="my-3 d-block d-md-none"
            style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
          />

          <div className="row header_svg_1_container">
            <div className="col-sm-1 col-md-2 d-none d-sm-block"></div>

            <div className="col-12 col-sm-4 col-md-4 d-flex align-items-center header_svg_1_text_container_col">
              <div className="text_container header_svg_1_text_container position-relative">
                <h3>{values.lowerMidSectionHeading}</h3>
                <hr
                  className="my-2"
                  style={{ borderTop: "1px solid rgb(255 255 255 / 0%)" }}
                />
                <p>{values.lowerMidSectionParagraph}</p>
                {values.lowerMidSectionBtnText &&
                window.location.href.split(".us")[0] !=
                  values.lowerMidSectionBtnLink.split(".us")[0] ? (
                  <a href={`${values.lowerMidSectionBtnLink}`} className="btn">
                    {values.lowerMidSectionBtnText}
                  </a>
                ) : null}

                {values.lowerMidSectionBtnText &&
                window.location.href.split(".us")[0] ==
                  values.lowerMidSectionBtnLink.split(".us")[0] ? (
                  <Link
                    to={`${values.lowerMidSectionBtnLink.split(".us")[1]}`}
                    className="btn"
                  >
                    {values.lowerMidSectionBtnText}
                  </Link>
                ) : null}

                {/* memphis 1 */}
                <img
                  src="./assets/index/mem_lg/header_mem_svg1.svg"
                  alt="header_mem_svg1"
                  className="header_mem_svg1 d-none d-sm-block"
                />
                <img
                  src="./assets/index/mem_sm/header_svg1.svg"
                  alt="header_svg1"
                  className="header_svg1_sm d-block d-sm-none"
                />
              </div>
            </div>

            <div className="col-12 col-sm-7 col-md-6 position-relative header_svg_1_img_container_col">
              <div className="header_svg_1_img_container position-relative">
                {values.lowerMidSectionFileUrl && (
                  <img
                    src={`${values.lowerMidSectionFileUrl}`}
                    alt="header_svg_1"
                    className="header_svg_1 img-fluid w-100"
                  />
                )}

                {/* memphis 2 */}
                <img
                  src="./assets/index/mem_lg/header_mem_svg2.svg"
                  alt="header_mem_svg2"
                  className="header_mem_svg2 d-none d-sm-block"
                />
                {/* memphis 4 */}
                <img
                  src="./assets/index/mem_lg/header_mem_svg4.svg"
                  alt="header_mem_svg4"
                  className="header_mem_svg4 d-none d-sm-block"
                />
              </div>
              {/* memphis 3 */}
              <img
                src="./assets/index/mem_lg/header_mem_svg3.svg"
                alt="header_mem_svg3"
                className="header_mem_svg3 d-none d-sm-block"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default IndexHeader;
