import React,{useEffect,useState} from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useAuth } from "./contexts/AuthContext"
import {useDispatch} from "react-redux";

import {toggleSignin} from "./store/actions/indexActions";
import axios from 'axios';

export default function PrivateRouteRestricted({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const history = useHistory();
  const dispatch = useDispatch();

  const [access,setAccess] = useState(false);
  const [isFetchFinished,setIsFetchFinished] = useState(false);

  useEffect(async () => {
    const token = localStorage.getItem('token');
    
    try {
        const res = await axios.post('/api/verify-token',{token});
        // console.log(res)
        setAccess(res.data.decoded.access);
        setIsFetchFinished(true);
        localStorage.setItem('email',res.data.decoded.email)
    } catch (err) {
        setAccess(false);
        setIsFetchFinished(true);
    }
  },[])

  if(isFetchFinished){
    return (<Route
    {...rest}
    render={props => {
      return currentUser && access ? <Component {...props} /> : <Redirect to={{pathname:"/billing",state:{target:history.location.pathname}}} />
    }}
  ></Route>)
  }

  return <></>

}
