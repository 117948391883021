import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Row,
  Column,
  Alert,
  Spinner,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import OpenTabSwitch from "../../components/SliderRowPost/OpenTabSwitch";
import { auth, firestore, storage, timestamp } from "../../../firebase";

const AddContentModal = ({ open, close, isEdit, docId, getAllContent }) => {
  const initValues = {
    title: "",
    category: "",
    userPhase: "Beginner",
    audience: "All",
    description: "",
    wwli: "",
    isOpenNewTab: false,
    isPaid: false,
    isScheduled: false,
  };
  const contentType = [
    { name: "Video", key: "externalVideo" },
    { name: "Audio", key: "externalAudio" },
    { name: "Image", key: "externalImage" },
    { name: "Doc", key: "externalDoc" },
    { name: "Web Page", key: "externalWebPage" },
  ];

  const [values, setValues] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [isSelectFile, setIsSelectFile] = useState(true);
  const [isSelectUrl, setIsSelectUrl] = useState(false);
  const [file, setFile] = useState(null);
  const [urlContentType, setUrlContentType] = useState("externalVideo");
  const [url, setUrl] = useState("");
  const [contentCreators, setContentCreators] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [editContentDocId, setEditContentDocId] = useState("");
  const [loading, setLoading] = useState(false);
  const [adventures, setAdventures] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isEdit) {
      var docRef = firestore.collection("corp_a_posts").doc(docId);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const {
              title,
              category,
              userPhase,
              audience,
              description,
              wwli,
              isOpenNewTab,
              isPaid,
              isScheduled,
              contentLength,
              contentCreator,
              fileUrl,
            } = doc.data();

            setValues({
              title,
              category,
              userPhase,
              contentLength,
              audience,
              description,
              contentCreator,
              wwli,
              isOpenNewTab,
              isPaid,
              isScheduled,
            });
            if (fileUrl.length) {
              setUrl(fileUrl);
              setIsSelectUrl(true);
              setIsSelectFile(false);
            }
            setEditContentDocId(docId);
          } else {
            // doc.data() will be undefined in this case
            setMessage("Something went wrong");
            setError(true);
            console.log("No such document!");
          }
        })
        .catch((error) => {
          setMessage("Something went wrong");
          setError(true);
          console.log("Error getting document:", error);
        });
    }
  }, []);

  useEffect(() => {
    // get available content creators
    firestore
      .collection("m_content_creators")
      .get()
      .then((snapshot) => {
        setContentCreators(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });

    firestore
      .collection("adventures")
      .get()
      .then((snapshot) => {
        setAdventures(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data()?.title,
          }))
        );
      });
  }, []);

  const getRows = (adventure) => {
    let index = adventures.findIndex((each) => each.title === adventure);
    if (index > -1) {
      firestore
        .collection("adventures")
        .doc(adventures[index].id)
        .collection("rows")
        .get()
        .then((snapshot) => {
          setRows(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              title: doc.data().title,
            }))
          );
        });
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "isOpenNewTab") {
      setValues({ ...values, [name]: e.target.checked });
    } else if (e.target.name === "isPaid") {
      setValues({ ...values, [name]: e.target.checked });
    } else if (e.target.name === "adventureTitle") {
      let index = adventures.findIndex((each) => each.title === value);
      if (index > -1) {
        let idToSave = adventures[index].id;
        let titleToSave = adventures[index].title;
        setValues({
          ...values,
          adventureId: idToSave,
          adventureTitle: titleToSave,
        });
      }
    } else if (e.target.name === "rowTitle") {
      let index = rows.findIndex((each) => each.title === value);
      if (index > -1) {
        let idToSave = rows[index].id;
        let titleToSave = rows[index].title;
        setValues({
          ...values,
          rowId: idToSave,
          rowTitle: titleToSave,
        });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const onThumbnailChangehandler = (e) => {
    const selectedThumbnail = e.target.files[0];
    setThumbnail(selectedThumbnail);
  };

  const onFileChangehandler = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const toggleSelectUrlHandler = (e) => {
    setIsSelectUrl(true);
    setIsSelectFile(false);
  };

  const toggleUploadFileHandler = (e) => {
    setIsSelectUrl(false);
    setIsSelectFile(true);
  };

  // add new content handler
  const contentSubmitHandler = () => {
    if (!Object.keys(values).length) return setError("Please Enter Data!");

    if (!values.rowTitle) return setError("Please Select a Sub Category!");

    if (isSelectFile && (!thumbnail || !file)) {
      return setError("Select thumbnail and file");
    }
    if (isSelectUrl && (!thumbnail || !url)) {
      return setError("Select thumbnail and url");
    }

    if (isSelectFile && thumbnail && file) {
      setLoading(true);
      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `corp_a_posts/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("Thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          const timesCurrent = Date.now();

          const storageRefFile = storage.ref(
            `corp_a_posts/${timesCurrent}-${file.name}`
          );
          storageRefFile.put(file).on(
            "state_changed",
            (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              // setProgress(percentage);
            },
            (err) => {
              setError(err.message);
              setTimeout(() => setError(""), 2000);
            },
            async () => {
              const fileUrl = await storageRefFile.getDownloadURL();
              const metadata = await storageRefFile.getMetadata();
              const fileFullPath = metadata.fullPath;
              const contentType = metadata.contentType;
              setMessage("File uploaded successfully");
              setTimeout(() => setMessage(""), 2000);
              let uid = `${Math.random()
                .toString(36)
                .substr(2, 5)}id${new Date().getTime()}`;
              // make it of length 20
              uid = uid.slice(0, 21);
              try {
                await firestore
                  .collection("corp_a_posts")
                  .doc(uid)
                  .set({
                    ...values,
                    id: uid,
                    corporateAdminId: auth.currentUser.uid,
                    thumbnailUrl,
                    thumbnailFullPath,
                    fileUrl,
                    fileFullPath,
                    contentType,
                    created: timestamp(),
                    updated: timestamp(),
                  });
                setMessage("Doc uploaded successfully");
                setTimeout(() => setMessage(""), 2000);
              } catch (e) {
                setError(e.message);
                setTimeout(() => setError(""), 2000);
              } finally {
                setLoading(false);
                setValues(initValues);
                setFile(null);
                setThumbnail(null);
                getAllContent();
                close();
              }
            }
          );
        }
      );
    }

    if (isSelectUrl && url && thumbnail) {
      setLoading(true);

      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `corp_a_posts/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);
          let uid = `${Math.random()
            .toString(36)
            .substr(2, 5)}id${new Date().getTime()}`;
          // make it of length 20
          uid = uid.slice(0, 21);
          try {
            await firestore
              .collection("corp_a_posts")
              .doc(uid)
              .set({
                ...values,
                id: uid,
                corporateAdminId: auth.currentUser.uid,
                thumbnailUrl,
                thumbnailFullPath,
                fileUrl: url,
                fileFullPath: "",
                contentType: urlContentType,
                created: timestamp(),
                updated: timestamp(),
              });
            setMessage("Doc uploaded successfully");
            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
            getAllContent();
            close();
          }
        }
      );
    }
  };

  // edit content handler
  const contentEditHandler = () => {
    if (!thumbnail) {
      return setError("Select thumbnail");
    }
    if (isSelectFile && (!thumbnail || !file)) {
      return setError("Select thumbnail and file");
    }
    if (isSelectUrl && (!thumbnail || !url)) {
      return setError("Select thumbnail and url");
    }
    if (isSelectFile && thumbnail && file) {
      setLoading(true);
      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `corp_a_posts/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("Thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          const timesCurrent = Date.now();

          const storageRefFile = storage.ref(
            `corp_a_posts/${timesCurrent}-${file.name}`
          );
          storageRefFile.put(file).on(
            "state_changed",
            (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              // setProgress(percentage);
            },
            (err) => {
              setError(err.message);
              setTimeout(() => setError(""), 2000);
            },
            async () => {
              const fileUrl = await storageRefFile.getDownloadURL();
              const metadata = await storageRefFile.getMetadata();
              const fileFullPath = metadata.fullPath;
              const contentType = metadata.contentType;
              setMessage("File uploaded successfully");
              setTimeout(() => setMessage(""), 2000);

              try {
                await firestore
                  .collection("corp_a_posts")
                  .doc(editContentDocId)
                  .update({
                    ...values,
                    corporateAdminId: auth.currentUser.uid,
                    thumbnailUrl,
                    thumbnailFullPath,
                    fileUrl: "",
                    fileFullPath,
                    contentType,
                    created: timestamp(),
                    updated: timestamp(),
                  });
                setMessage("Doc updated successfully");

                const docRef = await firestore
                  .collection("corp_a_posts")
                  .where("corporateAdminId", "==", auth.currentUser.uid)
                  // .where("rowId", "==", selectedAdventureRow.id)
                  .get();

                const id = docRef.docs[0].id;

                await firestore
                  .collection("corp_a_posts")
                  .doc(id)
                  .update({ id: id });

                setTimeout(() => setMessage(""), 2000);
              } catch (e) {
                setError(e.message);
                setTimeout(() => setError(""), 2000);
              } finally {
                setLoading(false);
                setValues(initValues);
                setFile(null);
                setThumbnail(null);
                close();
                getAllContent();
              }
            }
          );
        }
      );
    }

    if (isSelectUrl && url && thumbnail) {
      setLoading(true);
      setFile(null);
      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `corp_a_posts/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          try {
            await firestore
              .collection("corp_a_posts")
              .doc(editContentDocId)
              .update({
                ...values,
                corporateAdminId: auth.currentUser.uid,
                thumbnailUrl,
                thumbnailFullPath,
                fileUrl: url,
                fileFullPath: "",
                contentType: urlContentType,
                created: timestamp(),
                updated: timestamp(),
              });
            setMessage("Doc updated successfully");

            const docRef = await firestore
              .collection("corp_a_posts")
              .where("corporateAdminId", "==", auth.currentUser.uid)
              // .where("rowId", "==", selectedAdventureRow.id)
              .get();

            const id = docRef.docs[0].id;

            await firestore
              .collection("corp_a_posts")
              .doc(id)
              .update({ id: id });

            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
            close();
            getAllContent();
          }
        }
      );
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      isOpen={open}
      toggle={close}
      // className={className}
      external={
        <button
          className="close"
          style={{ position: "absolute", top: "15px", right: "15px" }}
          onClick={close}
        >
          &times;
        </button>
      }
      centered
    >
      {loading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <ModalHeader toggle={close}>Add New Content</ModalHeader>
      <ModalBody
        className="px-3"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <FormGroup>
            <Label for="adventureTitle">Category</Label>
            <Input
              value={values.adventureTitle}
              onChange={(e) => {
                changeHandler(e);
                getRows(e.target.value);
              }}
              type="select"
              name="adventureTitle"
              id="adventureTitle"
            >
              <option selected>None</option>
              {adventures.map((each) => (
                <option value={each.title} key={each.id}>
                  {each.title}
                </option>
              ))}
            </Input>
          </FormGroup>

          {values.adventureTitle && (
            <FormGroup>
              <Label for="rowTitle">Sub Category</Label>
              <Input
                value={values.rowTitle}
                onChange={(e) => changeHandler(e)}
                type="select"
                name="rowTitle"
                id="rowTitle"
              >
                <option selected>None</option>
                {rows.map((each) => (
                  <option value={each.title} key={each.id}>
                    {each.title}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              value={values.title}
              onChange={(e) => changeHandler(e)}
              type="text"
              name="title"
              id="title"
            />
          </FormGroup>

          <FormGroup>
            <Label for="userPhase">User phase</Label>
            <Input
              value={values.userPhase}
              onChange={(e) => changeHandler(e)}
              type="select"
              name="userPhase"
              id="userPhase"
            >
              <option>Beginner</option>
              <option>Intermediate</option>
              <option>Advanced</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="contentLength">Content Length</Label>
            <Input
              value={values.contentLength}
              onChange={(e) => changeHandler(e)}
              type="select"
              name="contentLength"
              id="contentLength"
            >
              <option selected>None</option>
              <option> &lt;5 mins </option>
              <option> &lt;10 mins </option>
              <option> &lt;15 mins </option>
              <option> &lt;25 mins </option>
              <option> &lt;30 mins </option>
              <option> 30+ mins </option>
              <option> 45+ mins </option>
              <option> 1+ Hr </option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="audience">Audience</Label>
            <Input
              value={values.audience}
              onChange={(e) => changeHandler(e)}
              type="select"
              name="audience"
              id="audience"
            >
              <option>All</option>
              <option>Kids</option>
              <option>Adults</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="contentCreator">Content Creator</Label>
            <Input
              value={values?.contentCreator}
              onChange={(e) => changeHandler(e)}
              type="select"
              name="contentCreator"
              id="contentCreator"
            >
              <option>Select a Content Creator</option>
              {contentCreators.map((eachContentCreator) => (
                <option
                  key={eachContentCreator.id}
                  value={eachContentCreator.name}
                >
                  {eachContentCreator.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="wwli">Why we like it?</Label>
            <Input
              value={values.wwli}
              onChange={(e) => changeHandler(e)}
              type="textarea"
              name="wwli"
              id="wwli"
            />
          </FormGroup>

          <FormGroup>
            <div id="singlePost_desc">
              <Label for="description">Description</Label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "alignment",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                  ],
                }}
                data={values.description}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // if (isEdit) {
                  //   setValues({ ...selectedPost.post, description: data });
                  // } else {
                  setValues({ ...values, description: data });
                  // }
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="thumbnail">Select a thumbnail</Label>
            <Input
              onChange={(e) => onThumbnailChangehandler(e)}
              type="file"
              name="thumbnail"
              id="thumbnail"
            />
          </FormGroup>
          <FormGroup>
            <div className="btn-group mt-2">
              <button
                onClick={(e) => toggleUploadFileHandler(e)}
                className="btn btn-primary btn-sm"
              >
                Upload File
              </button>
              <button
                onClick={(e) => toggleSelectUrlHandler(e)}
                className="btn btn-warning btn-sm"
              >
                Insert Url
              </button>
            </div>
          </FormGroup>

          {isSelectFile ? (
            <FormGroup>
              <Label for="file">Select a file</Label>
              <Input
                onChange={(e) => onFileChangehandler(e)}
                type="file"
                name="file"
                id="file"
              />
            </FormGroup>
          ) : null}

          {isSelectUrl ? (
            <>
              <div className="row">
                <div className="col-8">
                  <FormGroup>
                    <Label for="url">Put a url</Label>
                    <Input
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      type="text"
                      name="url"
                      id="url"
                    />
                  </FormGroup>
                </div>
                <div className="col-4">
                  <FormGroup>
                    <Label for="">Content Type</Label>
                    <Input
                      type="select"
                      onChange={(e) => setUrlContentType(e.target.value)}
                    >
                      {contentType.map((each) => (
                        <option value={each.key}>{each.name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <OpenTabSwitch
                    onChangeHandler={changeHandler}
                    value={values.isOpenNewTab}
                    name="isOpenNewTab"
                    labelDescription="Open in a new tab?"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <OpenTabSwitch
                    onChangeHandler={changeHandler}
                    value={values.isPaid}
                    name="isPaid"
                    labelDescription="Is charged by 3rd party?"
                  />
                </div>
              </div>
            </>
          ) : null}

          <div>
            {error && <Alert color="danger">{error}</Alert>}
            {message && <Alert color="success">{message}</Alert>}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        {isEdit ? (
          <Button
            className="btn btn-sm btn-light"
            onClick={() => contentEditHandler()}
          >
            Update
          </Button>
        ) : (
          <Button
            className="btn btn-sm btn-light"
            onClick={() => contentSubmitHandler()}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AddContentModal;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
