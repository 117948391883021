import React, { useState, useEffect } from "react";
import { firebase, firestore, timestamp } from "../../firebase";
import Alert from "../Alert";
import { useAuth } from "../../contexts/AuthContext";

import { toggleSignin, togglePayment } from "../../store/actions/indexActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "axios";
import "./CheckoutForm.css";
import { userInfo } from "os";

export default function CheckoutForm(props) {
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    pricePlan,
    amount,
    stripePriceId,
    stripeProductId,
    isSingleUser,
    numberOfUsers,
  } = props;

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [message, setMessage] = useState("");
  const [userExists, setUserExists] = useState(false);

  const [subscribing, setSubscribing] = useState(false);
  const [accountInformation, setAccountInformation] = useState(null);

  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  // const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const { currentUser, logout } = useAuth();

  const cardElement = elements.getElement(CardElement);

  const dispatch = useDispatch();
  const history = useHistory();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  // invoked after create payment intent
  const handlePaymentThatRequiresCustomerAction = async ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    if (subscription && subscription.status === "active") {
      return Promise.resolve({ subscription, priceId, paymentMethodId });
    }

    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;
    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      try {
        const res = stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        });
        const { paymentIntent } = res.data;
        setAccountInformation({
          ...accountInformation,
          latest_invoice: invoice,
        });
        return Promise.resolve({
          paymentIntent,
          subscription: accountInformation,
        });
      } catch (err) {
        setIsAlertOpen(true);
        setAlertType("danger");
        setAlertMessage(`Payment failed: Your card was declined. ${err}`);
        setProcessing(false);
      }
    } else {
      return Promise.resolve({ subscription, priceId, paymentMethodId });
    }
  };

  // invoked after create payment intent
  const handleRequiresPaymentMethod = async ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === "active") {
      return Promise.resolve({ subscription, priceId, paymentMethodId });
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      localStorage.setItem(
        "latestCustomerId",
        subscription.latest_invoice.customer
      );
      localStorage.setItem("latestPriceId", subscription.plan.id);
      setIsAlertOpen(true);
      setAlertType("danger");
      setAlertMessage(`Payment failed: Your card was declined`);
      setProcessing(false);
    } else {
      return Promise.resolve({ subscription, priceId, paymentMethodId });
    }
  };

  const retryInvoiceWithNewPaymentMethod = async ({
    invoiceId,
    paymentMethodId,
    customerId,
    priceId,
  }) => {
    try {
      const res = await axios.post("/api/retry-invoice", {
        customerId,
        paymentMethodId,
        invoiceId,
      });
      const { result } = res.data;
      const obj = await handlePaymentThatRequiresCustomerAction({
        invoice: result,
        paymentMethodId,
        priceId,
        isRetry: true,
      });

      //complete subscription
      await onSubscriptionComplete({ ...obj });
    } catch (err) {
      setIsAlertOpen(true);
      setAlertType("danger");
      setAlertMessage(`Payment failed: ${err.message}`);
      setProcessing(false);
    }
  };

  const onSubscriptionComplete = async ({ subscription, paymentIntent }) => {
    let redemptionCode = getRedemptionKey(25);

    if (
      (subscription &&
        subscription.latest_invoice.payment_intent.status === "succeeded") ||
      (paymentIntent && paymentIntent.status === "succeeded")
    ) {
      const userResponse = await firestore
        .collection("users")
        .where("email", "==", `${email}`)
        .get();
      if (!userResponse.empty) {
        userResponse.docs.forEach(async (doc) => {
          const id = doc.id;

          if (!isSingleUser) {
            await axios.post("/api/send-redemption-email", {
              email,
              firstName,
              redemptionCode,
              redemptionCodeDocument: {
                redemptionCode: redemptionCode,
                numberOfUsers: numberOfUsers,
                owner: email,
                isBlocked: false,
                isCreatedByCompany: false,
                subscribers: [email],
              },
            });

            await firestore.collection("users").doc(id).update({
              stripeData: subscription,
              redemptionCode: redemptionCode,
            });
          } else {
            await firestore
              .collection("users")
              .doc(id)
              .update({
                stripeData: subscription,
              })
              .then((res) => console.log(res))
              .catch((res) => console.log(res));
          }

          await logout();
        });
      }

      setError(null);
      setProcessing(false);
      setSucceeded(true);

      setIsAlertOpen(true);
      setAlertType("success");
      if (!isSingleUser) {
        setAlertMessage(`Here is the
                redemption code for your plan that you can share with others. ${redemptionCode} This code can be used to create
                accounts, up to the maximum identified for your chosen plan. This code will also be sent to you
                via email. Please be sure to check your spam folder.`);
      } else {
        setAlertMessage("Payment succeeded");
      }
      cardElement.clear();

      localStorage.removeItem("userEmail");
    } else {
      setIsAlertOpen(true);
      setAlertType("danger");
      setAlertMessage(`Payment failed`);
      setProcessing(false);
      cardElement.clear();
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const onSuccess = async ({ subscription }) => {
    let redemptionCode = getRedemptionKey(25);
    const customerId = subscription.customer || "n/a";
    const subscriptionId = subscription.items.data[0].subscription || "n/a";

    const userResponse = await firestore
      .collection("users")
      .where("email", "==", `${email}`)
      .get();
    if (!userResponse.empty) {
      userResponse.docs.forEach(async (doc) => {
        const id = doc.id;

        if (!isSingleUser) {
          await axios.post("/api/send-redemption-email", {
            email,
            firstName,
            redemptionCode,
            redemptionCodeDocument: {
              redemptionCode,
              numberOfUsers,
              owner: email,
              isBlocked: false,
              isCreatedByCompany: false,
              subscribers: [email],
            },
          });

          await firestore.collection("users").doc(id).update({
            stripeData: subscription,
            redemptionCode,
            customerId,
            subscriptionId,
            updatedAt: new Date(),
          });
        } else {
          await firestore
            .collection("users")
            .doc(id)
            .update({
              stripeData: subscription,
              customerId,
              subscriptionId,
              updatedAt: new Date(),
            })
            .then((res) => console.log(res))
            .catch((res) => console.log(res));
        }

        await logout();
      });
    }

    setError(null);
    setProcessing(false);
    setSucceeded(true);

    setIsAlertOpen(true);
    setAlertType("success");
    if (!isSingleUser) {
      setAlertMessage(`Here is the
                redemption code for your plan that you can share with others. ${redemptionCode} This code can be used to create
                accounts, up to the maximum identified for your chosen plan. This code will also be sent to you
                via email. Please be sure to check your spam folder.`);
    } else {
      setAlertMessage("Success");
    }
    cardElement.clear();

    localStorage.removeItem("userEmail");
  };

  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setProcessing(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setIsAlertOpen(true);
        setAlertType("danger");
        setAlertMessage(`Payment failed ${error.message}`);
        setProcessing(false);
        cardElement.clear();
        return;
      }

      // console.log('[PaymentMethod]', paymentMethod);

      // If a previous payment was attempted, get the lastest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      );

      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        const customerId = localStorage.getItem("latestCustomerId");
        const priceId = localStorage.getItem("latestPriceId");
        await retryInvoiceWithNewPaymentMethod({
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
          customerId,
          priceId,
        });
        return;
      }

      const res = await axios.post("/api/create-payment-intent-on-signup", {
        token: {
          email,
          firstName,
          lastName,
          phoneNumber,
          pricePlan,
          amount,
          stripePriceId,
          stripeProductId,
          paymentMethod,
        },
      });

      const { result: subscription } = res.data;

      setAccountInformation({ subscription });

      if (subscription.status === "trialing") {
        await onSuccess({ subscription });
      }

      // const { subscription, priceId } =
      //   await handlePaymentThatRequiresCustomerAction({
      //     subscription: result,
      //     paymentMethodId: paymentMethod.id,
      //     priceId: stripePriceId,
      //   });
      // await handleRequiresPaymentMethod({
      //   subscription,
      //   priceId,
      //   paymentMethodId,
      // });
      // await onSubscriptionComplete({ subscription });
    } catch (error) {
      setIsAlertOpen(true);
      setAlertType("danger");
      console.log(`Payment failed, Error: ${error?.message}`);
      setAlertMessage("Something went wrong!");
      setProcessing(false);
      cardElement.clear();
    }
  };

  function getRedemptionKey(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  return (
    <div id="stripe" style={{ margin: "50px 0px 150px" }}>
      <div id="payment-form">
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
        <button
          onClick={handleSubmit}
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? <div className="spinner" id="spinner"></div> : "Pay"}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}

        <div className="mt-3" id="checkout-button-alert">
          <Alert
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            alertType={alertType}
            alertMessage={alertMessage}
            setAlertType={setAlertType}
            setAlertMessage={setAlertMessage}
          />
        </div>
        {succeeded && (
          <button
            onClick={() => {
              dispatch(togglePayment());
              dispatch(toggleSignin());
            }}
          >
            <span id="button-text">Continue To Sign In</span>
          </button>
        )}
      </div>
    </div>
  );
}
