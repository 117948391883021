import React, { useState } from "react";
import SmallFooter from "../components/SmallFooter/SmallFooter";
import { Alert, Spinner } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAuth } from "../contexts/AuthContext";
import CustomAlert from "../components/Alert";
import Navbar from "../components/Navbar/Navbar";

const SalesPersonRegistration = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    mailingAddress: "",
    password: "",
    confirmPassword: "",
    checkConfirm: false,
  };

  const salesPersonSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .required("Last Name is required."),
    mailingAddress: Yup.string().required("Mailing Address is required."),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
        "Invalid phone number"
      ),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    checkConfirm: Yup.bool().oneOf(
      [true],
      "Please Accept the Terms & Conditions!"
    ),
  });

  const forgotPassSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const [error, setError] = useState({
    error: false,
    errorMessage: "Something went wrong",
  });
  const [loading, setLoading] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { resetPassword } = useAuth();

  const history = useHistory();

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post("/api/sales-person/register", {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        mailingAddress: values.mailingAddress,
        password: values.password,
      });

      setLoading(false);
      if (response.status === 200 && response.data.redirectToLogin) {
        history.push("/salesportal/login");
      } else {
        setError({
          error: true,
          errorMessage: "OPS! Something went wrong",
        });
      }
    } catch (err) {
      console.log("Error from submit handler:", err);
      setLoading(false);

      setError({
        error: true,
        errorMessage: err.message,
      });
    }
  };

  const forgotPasswordSubmitHandler = async (values) => {
    try {
      const { email } = values;

      setLoading(true);
      await resetPassword(email);
      setLoading(false);
      setAlertType("success");
      setAlertMessage("Check your Email for further instructions");
      setIsAlertOpen(true);
    } catch (error) {
      console.log("error: ", error.message);
      setLoading(false);
      setAlertType("error");
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    }
  };
  return (
    <>
      <Navbar fromSalesAuth={true} />
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        {error.error && (
          <Alert
            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            color="danger"
          >
            <h6 style={{ margin: 0 }}>Error: {error.errorMessage}</h6>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={salesPersonSchema}
          onChange={() => {}}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty, values } = formik;
            return (
              <div className="container" id="signupForm">
                {loading ? (
                  <div className="spinner_container">
                    <Spinner />
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-12 mb-5">
                    <h2>Sales Person Registration</h2>
                  </div>
                </div>

                <Form>
                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter First Name"
                        className={
                          errors.firstName && touched.firstName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="firstName"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter Last Name"
                        className={
                          errors.lastName && touched.lastName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="lastName"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Email Address"
                        className={
                          errors.email && touched.email
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <Field
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="123-456-7890"
                        className={
                          errors.phoneNumber && touched.phoneNumber
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="form-group"></div>
                  <div className="form-group">
                    <label htmlFor="email">Mailing Address</label>
                    <Field
                      type="text"
                      name="mailingAddress"
                      id="mailingAddress"
                      placeholder="Enter Mailing Address"
                      className={
                        errors.email && touched.email
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="mailingAddress"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Password"
                      className={
                        errors.password && touched.password
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="checkConfirm"
                        className={
                          "form-check-input " +
                          (errors.checkConfirm && touched.checkConfirm
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkConfirm"
                      >
                        I acknowledge that I have received the
                        <span
                          className="ml-1"
                          style={{
                            fontWeight: "bold",
                            color: "#600170",
                          }}
                        >
                          'Independent Salesperson Agreement'
                        </span>
                        and agree to the terms and conditions outlined. If you
                        have not received the agreement, email us using the
                        Contact Us link before you register
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3rem",
                    }}
                  >
                    <div className="">
                      <button
                        type="submit"
                        className="customBtn"
                        style={{
                          cursor: !(dirty && isValid)
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={!(dirty && isValid)}
                      >
                        Sign Up
                      </button>
                      <button
                        className="customBtn"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => history.push("/salesportal/login")}
                      >
                        Login
                      </button>
                    </div>
                    <div
                      className=""
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => setForgotPasswordModal(true)}
                    >
                      Forgot Password?
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>

        <Modal
          isOpen={forgotPasswordModal}
          toggle={() => setForgotPasswordModal(false)}
          centered
        >
          <div className="modal-top"></div>
          <ModalHeader
            toggle={() => setForgotPasswordModal(false)}
          ></ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgotPassSchema}
              onSubmit={(values) => forgotPasswordSubmitHandler(values)}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <div className="container">
                    {loading ? (
                      <div className="spinner_container">
                        <Spinner />
                      </div>
                    ) : null}
                    <h2>Reset Password</h2>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter Email Address"
                          className={
                            errors.email && touched.email
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="error"
                        />
                      </div>

                      {isAlertOpen && (
                        <div className="row mt-2">
                          <div className="col">
                            <CustomAlert
                              isAlertOpen={isAlertOpen}
                              setIsAlertOpen={setIsAlertOpen}
                              alertType={alertType}
                              alertMessage={alertMessage}
                              setAlertType={setAlertType}
                              setAlertMessage={setAlertMessage}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row my-4">
                        <div className="col-8">
                          <button
                            type="submit"
                            className={
                              !(dirty && isValid)
                                ? "disabled-btn customBtn"
                                : "customBtn"
                            }
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default SalesPersonRegistration;
