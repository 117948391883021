import React, { useEffect, useState } from "react";
import Favourites from "../../pages/Favourites";

import { firestore, firebase, auth } from "../../firebase";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SortAscAndDesc from "../SortAscAndDesc/SortAscAndDesc";
import { getSortConditionBySortOrder } from "../../util/helpers";
import { Skeleton } from "@material-ui/lab";
import { Avatar, Typography } from "@material-ui/core";
import PostLink from "../PostLink/PostLink";
const ASCENDING_ORDER = "asc";
const DESCENDING_ORDER = "desc";

const HistoryTable = () => {
  const [watchedPosts, setWatchedPosts] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("dateWatched");
  const [currentOrder, setCurrentOrder] = useState(DESCENDING_ORDER);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const res = await axios
      .post("/api/get-user-history", {
        email: auth?.currentUser?.email,
      })
      .finally(() => {
        setLoading(false);
      });
    if (res.data.status === 200) {
      setWatchedPosts(res.data.data);
    }
  }, []);

  const handleOnClickSort = (fieldName, order) => {
    let data = [...watchedPosts];

    switch (fieldName) {
      case "dateWatched":
        data = data.sort((postA, postB) =>
          getSortConditionBySortOrder(
            order,
            new Date(postA?.dateWatched?._seconds * 1000),
            new Date(postB?.dateWatched?._seconds * 1000)
          )
        );
        break;
      case "topic":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.adventureTitle,
            postB?.adventureTitle,
            true
          );
        });
        break;
      case "rowTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.rowTitle,
            postB?.rowTitle,
            true
          );
        });
        break;
      case "postTitle":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.postTitle,
            postB?.postTitle,
            true
          );
        });
        break;
    }
    setWatchedPosts(data);
    setCurrentOrder(order);
    setCurrentSortField(fieldName);
  };

  return (
    <div className="px-5">
      <div className="my-5" style={{ height: "20px" }}>
        <h2 style={{ width: "300px", color: "#1e2864" }}>History</h2>
      </div>
      {loading ? (
        <>
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </>
      ) : (
        <table class="table history-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "topic"}
                  onClickAsc={() => handleOnClickSort("topic", ASCENDING_ORDER)}
                  onClickDesc={() =>
                    handleOnClickSort("topic", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Topic"
                />
              </th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "rowTitle"}
                  onClickAsc={() =>
                    handleOnClickSort("rowTitle", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("rowTitle", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Category"
                />
              </th>
              <th scope="col">
                {" "}
                <SortAscAndDesc
                  isActive={currentSortField === "postTitle"}
                  onClickAsc={() =>
                    handleOnClickSort("postTitle", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("postTitle", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Item title"
                />
              </th>
              <th scope="col">
                <SortAscAndDesc
                  isActive={currentSortField === "dateWatched"}
                  onClickAsc={() =>
                    handleOnClickSort("dateWatched", ASCENDING_ORDER)
                  }
                  onClickDesc={() =>
                    handleOnClickSort("dateWatched", DESCENDING_ORDER)
                  }
                  isAscending={currentOrder === ASCENDING_ORDER}
                  title="Date Watched"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {watchedPosts.map((likedPost, index) => (
              <tr key={index}>
                <td
                  className="img"
                  style={{ paddingTop: 5 }}
                  colSpan={1}
                  align="center"
                >
                  <PostLink
                    post={likedPost}
                    rowId={likedPost.rowId}
                    postId={likedPost.postId}
                    adventureId={likedPost.adventureId}
                  >
                    <div className="post-thumbnail-container">
                      <img src={likedPost?.thumbnailUrl} />
                    </div>
                  </PostLink>
                </td>
                <th scope="row">{likedPost.adventureTitle}</th>
                <td>{likedPost.rowTitle}</td>
                <td>
                  <Link
                    to={`/adventure/${likedPost.adventureId}/${likedPost.rowId}/${likedPost.postId}`}
                  >
                    {likedPost.postTitle}
                  </Link>
                </td>
                <td>
                  {likedPost?.dateWatched &&
                    moment(
                      new Date(likedPost?.dateWatched?._seconds * 1000)
                    ).format("MMM DD, YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}{" "}
    </div>
  );
};

export default HistoryTable;
