import React from "react";
import SliderRowActions from "./SliderRowActions";
import SliderRowRowEditor from "./SliderRowRowEditor";

import { useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";

const SliderRowRow = ({
  post,
  onShowHandler,
  onRowSelect,
  isChecked,
  onRowUnselect,
  onEditHandler,
  onDeleteHandler,
  onClickPositionChangeHandler,
  onFileChangeHandler,
  onValueChangeHandler,
  values,
  isEdit,
}) => {
  const { selectedAdventure, selectedAdventureRow } = useSelector(
    (state) => state.admin
  );
    
  return (
    <>
      <tr>
        <td>
          <Checkbox
            onClick={(e) => e.target.checked ? onRowSelect(post.id):onRowUnselect(post.id)}
            checked={isChecked}
          />
        </td>
        <td>{post.title}</td>
        <td>{post.category}</td>
        <td>{post.audience}</td>
        <td>{post.userPhase}</td>
        <td>
          <img
            src={`${post.thumbnailUrl}`}
            style={{ width: "50px", height: "50px" }}
          />
        </td>
        <td>
          <p
            style={{
              width: "300px",
              overflowWrap: "break-word",
              color: "#000",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {`https://leviosa.us/adventure/${selectedAdventure.id}/${selectedAdventureRow.id}/${post.id}`}
          </p>
        </td>
        <td>{post.contentType}</td>
        <SliderRowActions
          post={post}
          onShowHandler={onShowHandler}
          onEditHandler={onEditHandler}
          onDeleteHandler={onDeleteHandler}
          onClickPositionChangeHandler={onClickPositionChangeHandler}
        />
      </tr>
    </>
  );
};

export default SliderRowRow;
