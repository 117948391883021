import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function TileSwitch(props) {

  const {values,onChangeHandler} = props;

  return (
    <div>
      <label>Tile Section Visibility</label>
      <Switch
        checked={values.isShowTile}
        onChange={(e) => onChangeHandler(e)}
        name="isShowTile"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
}
