import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import HomeHeader from "../components/HomeHeader/HomeHeader";
import SmallFooter from "../components/SmallFooter/SmallFooter";
import Features from "../components/Features/Features";
import Gurus from "../components/Gurus/Gurus";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import "./Dashboard.css";
import OptionGroup from "../containers/moodCheck/OptionGroup";
import { auth, firestore, timestamp } from "../firebase";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { Link } from "react-router-dom";

const binddoingTodayData = (values) => {
  const images = {
    Great: require("../assets/emojis/smile.png").default,
    Okay: require("../assets/emojis/straight-face.png").default,
    Blah: require("../assets/emojis/sad.png").default,
  };
  return values?.map((eachValue) => ({
    name: eachValue,
    image: images[eachValue],
  }));
};
const bindStressLevelData = (values) => {
  const colors = {
    Low: "Green",
    Medium: "Orange",
    High: "Red",
  };

  return values?.map((eachValue) => ({
    name: eachValue,
    color: colors[eachValue],
  }));
};

const Dashboard = () => {
  const [timeSpan, setTimeSpan] = useState(7);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [timeSpan]);

  const getUser = () => {
    if (auth?.currentUser?.uid) {
      const docRef = firestore.collection("users").doc(auth?.currentUser?.uid);
      docRef.get().then((doc) => setUserData(doc.data()));
    }
  };

  const getData = () =>
    axios
      .post("/api/get-user-statistics", {
        timeSpan,
        email: auth.currentUser?.email,
        userId: auth.currentUser?.uid,
      })
      .then((res) => setData(res?.data?.data || {}))
      .finally(() => {
        setLoading(false);
      });

  const handleTimeChange = (e) => {
    setTimeSpan(e.target.value);
  };

  return (
    <>
      <Navbar />
      <>
        <hr
          className="my-5 d-none d-md-block"
          style={{ borderTop: "1px solid #fff0" }}
        />
        <hr
          className="my-5 d-none d-md-block"
          style={{ borderTop: "1px solid #fff0" }}
        />
        <hr
          className="my-3 d-block d-md-none"
          style={{ borderTop: "1px solid #fff0" }}
        />

        <header id="home_header" className="position-relative">
          <img
            src="./assets/home/mem_lg/header_svg3.svg"
            alt="header_svg3"
            className="header_svg3 d-none d-md-block"
          />
          <img
            src="./assets/home/mem_lg/header_svg5.svg"
            alt="header_svg5"
            className="header_svg5 d-none d-md-block"
          />
          <div className="container">
            <div className="row header_top_container position-relative">
              <div className="col-12 statistics-header">
                <h2>
                  {userData?.firstName ? userData?.firstName + "'s" : ""}{" "}
                  Dashboard
                  {/* <span style={{ color: "#6F4CDD" }}>{firstName}</span>{" "} */}
                </h2>
                <FormControl className="time-selector">
                  <InputLabel id="demo-simple-select-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeSpan}
                    label="Age"
                    onChange={handleTimeChange}
                    disabled={loading}
                  >
                    <MenuItem value={7}>Last week (7 days)</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={365}>Last year (365 days)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </header>
      </>

      {loading ? (
        <div className="container">
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className="container dashboardContainer">
          <div className="row header_top_container position-relative">
            <div className="col-12">
              <div className="row statisticsContainer">
                <div className="col-lg-4 col-md-12 col-sm-12 statisticCard">
                  <div className="count-container">
                    <h4>Total Views</h4>
                    <h2>{data?.totalView}</h2>
                  </div>
                  <div className="count-container">
                    <h5>First View</h5>
                    <h5>{data?.firstView}</h5>
                  </div>
                  <div className="count-container">
                    <h5>Last View</h5>
                    <h5>{data?.lastView}</h5>
                  </div>
                  <Link class="nav-link" to="/watched/all">
                    View All
                  </Link>
                </div>

                <div className=" col-lg-4 col-md-12 col-sm-12 statisticCard">
                  <h4>Top Themes</h4>
                  <div className="category-counts">
                    {data?.byCategory?.map((eachCategory) => {
                      return (
                        <div
                          key={eachCategory?.categoryName}
                          className="category-count-container"
                        >
                          <h6>{eachCategory?.categoryName}</h6>
                          <h6 className="countText">{eachCategory?.count}</h6>
                        </div>
                      );
                    })}
                  </div>
                  <Link class="nav-link" to="/watched/all">
                    View All
                  </Link>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12 statisticCard">
                  <h4>Top Categories</h4>
                  <div className="category-counts">
                    {data?.bySubCategory?.map((eachCategory) => {
                      return (
                        <div className="category-count-container">
                          <h6>{eachCategory?.categoryName}</h6>
                          <h6 className="countText">{eachCategory?.count}</h6>
                        </div>
                      );
                    })}
                  </div>
                  <Link class="nav-link" to="/watched/all">
                    View All
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h4 className="mt-4">Check Ins</h4>
              <div className="row statisticsContainer">
                <div className="col-lg-4 statisticCard">
                  <div className="category-counts">
                    <OptionGroup
                      title="How are you doing today?"
                      options={[
                        {
                          name: "Great",
                          image: require("../assets/emojis/smile.png").default,
                          count: data?.moodCheckIns?.doingToday?.great || 0,
                        },
                        {
                          name: "Okay",
                          image: require("../assets/emojis/straight-face.png")
                            .default,
                          count: data?.moodCheckIns?.doingToday?.okay || 0,
                        },
                        {
                          name: "Blah",
                          image: require("../assets/emojis/sad.png").default,
                          count: data?.moodCheckIns?.doingToday?.blah || 0,
                        },
                      ]}
                      withCounts={true}
                    />
                    <OptionGroup
                      title="Recent Trend"
                      options={binddoingTodayData(
                        data?.moodCheckIns?.doingTodayLatestTrends
                      )}
                      showName={false}
                      showSmallIcons={true}
                      className="mt-4"
                    />
                  </div>
                </div>

                <div className=" col-lg-4 statisticCard">
                  <div className="category-counts">
                    <OptionGroup
                      title="Stress Level"
                      options={[
                        {
                          name: "Low",
                          color: "Green",
                          count: data?.moodCheckIns?.stressLevel?.low || 0,
                        },
                        {
                          name: "Medium",
                          color: "Orange",
                          count: data?.moodCheckIns?.stressLevel?.medium || 0,
                        },
                        {
                          name: "High",
                          color: "Red",
                          count: data?.moodCheckIns?.stressLevel?.high || 0,
                        },
                      ]}
                      withCounts={true}
                    />
                    <OptionGroup
                      title="Recent Trend"
                      options={bindStressLevelData(
                        data?.moodCheckIns?.stressLevelLatestTrends
                      )}
                      showName={false}
                      showSmallIcons={true}
                      className="mt-4"
                    />
                  </div>
                </div>

                <div className="col-lg-4 statisticCard">
                  <div className="category-counts">
                    <OptionGroup
                      title="Anything bothering you?"
                      options={data?.moodCheckIns?.botheringYou?.map((e) => ({
                        name: e?.option,
                        count: e?.count,
                      }))}
                      multiple={true}
                      withCounts={true}
                    />
                  </div>
                </div>
              </div>

              {/* <h4>Number of titles viewed by Subcategory </h4>

            <h4></h4>
            <h4>Total no of mood check ins saved:</h4>
            <h4>Last Mood Check In</h4> */}
              {/* <Select>
                last week (7 days), last 30 days, or last year (365 days)
                </Select> */}
            </div>
          </div>
        </div>
      )}
      <SmallFooter />
    </>
  );
};

export default Dashboard;
