import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { firebase, firestore, storage, timestamp } from "../../firebase";
import usePagination from "../hooks/usePagination";

import SliderRowTable from "./SliderRow/SliderRowTable";
import SliderRowRow from "./SliderRow/SliderRowRow";

import {
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import {
  selectAdventure,
  selectAdventureRow,
  selectPost,
} from "../../store/actions/adminActions";

import { useSelector, useDispatch } from "react-redux";
import ChangeCategoryModal from "./Modals/ChangeCategoryModal/ChangeCategoryModal";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const initValues = {
  title: "",
  description: "",
  thumbnail: "",
};

const SliderRow = () => {
  const { selectedAdventure, selectedAdventureRow } = useSelector(
    (state) => state.admin
  );
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("rowNumber");

  const [searchBy, setSearchBy] = useState("title");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [changeCategoryModal, setChangeCategoryModal] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(async () => {
    const unsub = await firestore
      .collection("m_posts")
      .where("adventureId", "==", selectedAdventure.id)
      .where("rowId", "==", selectedAdventureRow.id)
      .orderBy(orderBy, orderDirection)
      .onSnapshot((snapshot) => {
        setPosts([]);
        snapshot.forEach((doc) => {
          setPosts((posts) => [...posts, { id: doc.id, ...doc.data() }]);
        });
        setIsLoading(false);
      });
    return () => unsub;
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setFilteredData(
      posts.filter((post) => {
        return post[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, posts]);

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const [values, setValues] = useState({ ...initValues });
  const [thumbnail, setThumbnail] = useState(null);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const onAddNewHandler = () => {
    setIsAddNew(true);
    let rowNumber = Number.parseInt(currentData().length) + 1;
    dispatch({
      type: "ADD_NEW_POST_ROW_NUM",
      payload: rowNumber,
    });
    history.push(
      `/a/categories/${selectedAdventure.id}/r/${selectedAdventureRow.id}/p/new`
    );
  };
  const onEditHandler = (post) => {
    setIsEdit(true);
    dispatch(selectPost(post));
    history.push(
      `/a/categories/${selectedAdventure.id}/r/${selectedAdventureRow.id}/p/${post.id}/edit`
    );
  };

  const onDeleteHandler = async (postId, post) => {
    if (isMounted) {
      const result = window.confirm("Are you sure you want to delete?");
      if (!result) {
        return;
      }

      setIsLoading(true);
      const doc = await firestore.collection("m_posts").doc(postId).get();
      const res = await doc.data();
      const { thumbnailFullPath, fileFullPath } = res;

      const storageThumbnailRef = storage.ref(thumbnailFullPath);
      const storageFileRef = storage.ref(fileFullPath);

      try {
        await firestore.collection("m_posts").doc(postId).delete();
        storageThumbnailRef.getDownloadURL();
        await storageThumbnailRef.delete();
      } catch (e) {
        console.log(e);
      }

      if (fileFullPath) {
        try {
          storageFileRef.getDownloadURL();
          await storageFileRef.delete();
        } catch (e) {
          console.log(e);
        }
      }

      try {
        const rowsRef = firestore
          .collection("m_posts")
          .where("adventureId", "==", selectedAdventure.id)
          .where("rowId", "==", selectedAdventureRow.id);
        const snapshot = await rowsRef
          .where("rowNumber", ">", post.rowNumber)
          .get();

        snapshot.docs.forEach(async (doc, i) => {
          let id = doc.id;
          await firestore
            .collection("m_posts")
            .doc(id)
            .update({
              rowNumber: firebase.firestore.FieldValue.increment(-1),
            });
        });

        await firestore.collection("m_posts").doc(postId).delete();
        setMessage("Doc deleted successfully");
        setTimeout(() => setMessage(""), 2000);
      } catch (e) {
        setError(e.message);
        setTimeout(() => setError(""), 2000);
      } finally {
        setIsLoading(false);
      }
    }

    ///
  };

  const onClickPositionChangeHandler = async (e, post) => {
    ////positions
    let selectedObjectPosition;
    let objectToBeInterchangedWithPosition;

    /////objects
    let selectedObject;
    let interchangedObject;

    switch (e.currentTarget.getAttribute("name")) {
      case "pushUp":
        if (post.rowNumber === 1) {
          console.log("cant push up");
          return;
        }

        selectedObjectPosition = currentData().indexOf(post);
        objectToBeInterchangedWithPosition = selectedObjectPosition - 1;

        selectedObject = {
          ...currentData()[selectedObjectPosition],
          rowNumber: currentData()[selectedObjectPosition].rowNumber - 1,
        };

        interchangedObject = {
          ...currentData()[objectToBeInterchangedWithPosition],
          rowNumber:
            currentData()[objectToBeInterchangedWithPosition].rowNumber + 1,
        };

        break;
      case "pushDown":
        if (post.rowNumber === currentData().length) {
          console.log("cant push down");
          return;
        }

        selectedObjectPosition = currentData().indexOf(post);
        objectToBeInterchangedWithPosition = selectedObjectPosition + 1;

        selectedObject = {
          ...currentData()[selectedObjectPosition],
          rowNumber: currentData()[selectedObjectPosition].rowNumber + 1,
        };

        interchangedObject = {
          ...currentData()[objectToBeInterchangedWithPosition],
          rowNumber:
            currentData()[objectToBeInterchangedWithPosition].rowNumber - 1,
        };

        break;
      default:
        return;
    }

    try {
      const batch = firestore.batch();

      const selectedObjectRef = firestore
        .collection("m_posts")
        .doc(selectedObject.id);
      batch.update(selectedObjectRef, {
        ...selectedObject,
        updated: timestamp(),
      });

      const interchangedObjectRef = firestore
        .collection("m_posts")
        .doc(interchangedObject.id);
      batch.update(interchangedObjectRef, {
        ...interchangedObject,
        updated: timestamp(),
      });

      const result = await batch.commit();
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveHandler = () => {};

  const onCancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
  };

  const onShowHandler = (post) => {
    dispatch(selectPost(post));
    history.push(
      `/a/categories/${selectedAdventure.id}/r/${selectedAdventureRow.id}/${post.id}`
    );
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...value, [name]: value });
  };

  const onFileChangeHandler = (e) => {
    const { name, value } = e.target.files[0];
    setThumbnail(value);
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem
          key={i}
          active={currentPage === i + 1 ? "active" : null}
        >
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("rowNumber");
        }
        setOrderBy(e.target.value);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("asc");
        }
        setOrderDirection(e.target.value);
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
    }
  };

  const onRowSelect = (id) => setSelectedRows((prev) => [...prev, id]);

  const onRowUnselect = (id) =>
    setSelectedRows((prev) => [...prev.filter((e) => e !== id)]);

  const onUpdateCategories = () => {
    setChangeCategoryModal(true);
  };
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Adventures</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">{selectedAdventure.title}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {selectedAdventureRow.title}
          </li>
        </ol>
      </nav>
      <div className="position-relative">
        {isLoading && (
          <div style={spinnerDivStyleSheet}>
            <Spinner style={spinnerStyleSheet} />
          </div>
        )}
        <SliderRowTable
          onSaveHandler={onSaveHandler}
          onCancelHandler={onCancelHandler}
          isEdit={isEdit}
          isAddNew={isAddNew}
          onAddNewHandler={onAddNewHandler}
          message={message}
          error={error}
          onFileChangeHandler={onFileChangeHandler}
          onValueChangeHandler={onValueChangeHandler}
          onChangeFilteringHandler={onChangeFilteringHandler}
          values={values}
          setSearchedData={setSearchedData}
          selectedRows={selectedRows}
          onUpdateCategories={onUpdateCategories}
        >
          {currentData() &&
            currentData().map((post) => (
              <SliderRowRow
                key={post.id}
                post={post}
                onShowHandler={onShowHandler}
                onRowSelect={onRowSelect}
                onRowUnselect={onRowUnselect}
                onDeleteHandler={onDeleteHandler}
                onEditHandler={onEditHandler}
                onClickPositionChangeHandler={onClickPositionChangeHandler}
                onValueChangeHandler={onValueChangeHandler}
                onFileChangeHandler={onFileChangeHandler}
                values={values}
                isEdit={isEdit}
                isChecked={selectedRows.includes(post?.id)}
              />
            ))}
        </SliderRowTable>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>

      {changeCategoryModal && (
        <ChangeCategoryModal
          selectedAdventureRow={selectedAdventureRow}
          selectedAdventure={selectedAdventure}
          show={changeCategoryModal}
          onClose={() => {
            setSelectedRows([]);
            setChangeCategoryModal(false);
          }}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
};

export default SliderRow;
