import React,{useState,useEffect} from "react";
import {Alert} from "reactstrap";
import AdventureRowEditor from "./AdventureRowEditor";

import {firestore} from "../../../firebase";

const AdventureTable = ({
  children
  ,isAddNew
  ,isEdit
  ,onSaveHandler
  ,onCancelHandler
  ,onAddNewHandler
  ,message
  ,setMessage
  ,error
  ,onValueChangeHandler
  ,onFileChangeHandler
  ,onChangeFilteringHandler
  ,setSearchedData
  ,isSetOrderSettings
  ,setOrderSettingsHandler
  ,canceOrderSettingslHandler
  ,orderBy
  ,orderDirection
  ,values}) => {

    const [scheduleDay,setScheduleDay] = useState(14);

    useEffect(async () => {
      const settingsRef = await firestore.collection("m_adventures").doc("settings").get();
      if (settingsRef.exists) {
        setScheduleDay(settingsRef.data().scheduleDay)
        
      }
    },[])

    const onSubmitHandler = (e) => {
      e.preventDefault();
    }

    const updateScheduleHandler = async (e) => {
      e.preventDefault();

      await firestore.collection("m_adventures").doc("settings").update({
        scheduleDay:scheduleDay
      })
      setMessage("Data updated successfully");
      setTimeout(() => setMessage(""),2000);
    }
    

  return (
    <>

    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-3">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="title">Title</option>
          <option value="description">Description</option>
        </select>
        </div>

        

      </div>
    </form>

    <form className="mt-5">
      <div className="form-row">
        <div className="col-sm-3">
          <label>Schedule After (days)</label>
          <input onChange={e => setScheduleDay(e.target.value)} value={scheduleDay} className="form-control" type="number"></input>
        </div>        
      </div>
      <div className="form-row mt-2">
        <div className="col-sm-3">
            <button onClick={e => updateScheduleHandler(e)} className="btn btn-primary">Update</button>
          </div>
      </div>
    </form>

    </div>

    {isSetOrderSettings ?
      <div className="ml-auto mt-3 btn-group">
      <button
      onClick={() => setOrderSettingsHandler()}
      className="btn btn-sm btn-primary">Set this order for front end</button>
      <button
       onClick={() => canceOrderSettingslHandler()}
       className="btn btn-sm btn-warning">Cancel</button>
       </div>
      : null
    }


    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Thumbnail</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          children
        }

        {
          isAddNew ?
          <tr>
            <AdventureRowEditor
            values={values}
            onFileChangeHandler={onFileChangeHandler}
            onValueChangeHandler={onValueChangeHandler}
             />
          </tr>
           : null
        }

      </tbody>
    </table>

    {message && <Alert color="success">{message}</Alert>}
    {error && <Alert color="danger">{error}</Alert>}

    <div>
    {
      isAddNew || isEdit ?
      <>
      <button
       onClick={() => onSaveHandler()}
       className="btn btn-sm btn-primary">Save</button>
       <button
        onClick={() => onCancelHandler()}
        className="btn btn-sm btn-warning mx-3">Cancel</button>
        </>
       :
       <button
       onClick={() => onAddNewHandler()}
       className="btn btn-sm btn-primary">Add New</button>
    }
    </div>
    </>
  );
}

export default AdventureTable;
