import React,{useState,useEffect} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {Spinner} from "reactstrap";

import Alert from "../Alert";

import "./ContactForm.css";

import {Link, useHistory} from "react-router-dom";

import axios from 'axios';

import {useAuth} from "../../contexts/AuthContext";
import {auth} from "../../firebase";

import {useDispatch} from "react-redux";
import {toggleContact} from "../../store/actions/indexActions";

import {useSelector} from 'react-redux';

const ContactSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),
});



const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
};

const ContactForm = (props) => {

  const [alertType,setAlertType] = useState("");
  const [alertMessage,setAlertMessage] = useState("");
  const [isAlertOpen,setIsAlertOpen] = useState(false);

  const disptach = useDispatch();

  const [isForgotPass,setIsForgotPass] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState("");
  const [message,setMessage] = useState("");
  const history = useHistory();
  const {login,resetPassword,isAdmin,issalesPerson} = useAuth();
  const [isMounted,setIsMounted] = useState(false);

  const {selectedPlanDetails} = useSelector(state => state.index);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  },[])

  return (
    <>
    
    <Formik
      initialValues={initialValues}
      validationSchema={ContactSchema}
      onSubmit={async (values) => {
        // console.log(values);

        const {firstName,lastName,email,phoneNumber} = values;

        setIsLoading(true);

        try {
            const res = await axios.post('/api/send-email',{firstName,lastName,email,phoneNumber,planTitle: selectedPlanDetails.title});
        
            const {success} = res.data;   
            setIsLoading(false);
            setIsAlertOpen(true);
            setAlertType("success");
            setAlertMessage("Thanks for your interest.  Someone from our Partner team will be in touch with you shortly.") 
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            setIsAlertOpen(true);
            setAlertType("error");
            setAlertMessage("Something went wrong.Try again!") 
        }
        

      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <div className="container">


            { isLoading ?
              <div className="spinner_container"><Spinner /></div> : null
            }

            <h2>Contact Us</h2>
            <Form>
            <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <Field
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Enter First Name"
                  className={
                    errors.firstName && touched.firstName ? "input-error form-control" : "form-control"
                  }
                />
                <ErrorMessage name="firstName" component="span" className="error" />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Enter Last Name"
                  className={
                    errors.lastName && touched.lastName ? "input-error form-control" : "form-control"
                  }
                />
                <ErrorMessage name="lastName" component="span" className="error" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email Address"
                  className={
                    errors.email && touched.email ? "input-error form-control" : "form-control"
                  }
                />
                <ErrorMessage name="email" component="span" className="error" />
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <Field
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  className={
                    errors.phoneNumber && touched.phoneNumber ? "input-error form-control" : "form-control"
                  }
                />
                <ErrorMessage name="phoneNumber" component="span" className="error" />
              </div>

              <div className="row mt-2">
                <div className="col">
                <Alert
                isAlertOpen={isAlertOpen}
                setIsAlertOpen={setIsAlertOpen}
                alertType={alertType}
                alertMessage={alertMessage}
                setAlertType={setAlertType}
                setAlertMessage={setAlertMessage}
                 />
                </div>
              </div>

              <div className="row my-4">
                <div className="col-8">
                <button
                  type="submit"
                  className={!(dirty && isValid) ? "disabled-btn customBtn" : "customBtn"}
                >
                  Send
                </button>
                </div>
               
              </div>
              

            </Form>
          </div>
        );
      }}
    </Formik>
    </>
  );
};

export default ContactForm;
