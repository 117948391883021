import React, { useEffect, useState } from "react";
import { firestore, firebase } from "../../firebase";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { getSortConditionBySortOrder } from "../../util/helpers";
import SortAscAndDesc from "../../components/SortAscAndDesc/SortAscAndDesc";
import PostLink from "../../components/PostLink/PostLink";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import usePagination from "../hooks/usePagination";
import { Checkbox } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
const ASCENDING_ORDER = "asc";
const DESCENDING_ORDER = "desc";

const GuruRecommendations = () => {
  const [guruRecommendations, setGuruRecommendations] = useState([]);
  const [guruData, setGuruData] = useState({});
  const [posts, setPosts] = useState([]);
  const [currentSortField, setCurrentSortField] = useState("name");
  const [currentOrder, setCurrentOrder] = useState(ASCENDING_ORDER);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const history = useHistory();
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    posts,
    30
  );
  useEffect(() => {
    setLoading(true);
    const pathname = history.location.pathname.split("/");
    let unsub;
    if (pathname.length > 2) {
      let guruId = pathname[3];
      unsub = firestore
        .collection("m_gurus")
        .doc(guruId)
        .onSnapshot((res) => {
          setGuruData({ ...res.data(), id: guruId });
          setGuruRecommendations(res.data()?.recommendations || []);

          firestore
            .collection("m_posts")
            .get()
            .then((r) => {
              let allPosts = r.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              let recommendedPosts = [];
              allPosts =allPosts.filter(
                (eachPost) =>{
                  if(!res.data()?.recommendations) return true;

                  if(res.data()?.recommendations.includes(eachPost.id)){
                    recommendedPosts.push(eachPost);
                    return false;
                  }
                  return true;
                }
              );
              setPosts([...recommendedPosts, ...allPosts]);
            })
            .finally(() => setLoading(false));
        });
    }

    return unsub;
  }, []);

  const handleOnClickAdd = (postId) => {
    if (guruRecommendations.includes(postId)) {
      setUpdating(true);
      firestore
        .collection("m_gurus")
        .doc(guruData.id)
        .update({
          recommendations: firebase.firestore.FieldValue.arrayRemove(postId),
        })
        .finally((res) => setUpdating(false));
    } else {
      setUpdating(true);
      firestore
        .collection("m_gurus")
        .doc(guruData.id)
        .update({
          recommendations: firebase.firestore.FieldValue.arrayUnion(postId),
        })
        .finally((res) => setUpdating(false));
    }
  };

  const handleOnClickSort = (fieldName, order) => {
    let data = [...posts];

    switch (fieldName) {
      case "created":
        data = data.sort((postA, postB) =>
          getSortConditionBySortOrder(
            order,
            new Date(
              postA?.created?.toDate
                ? postA?.created?.toDate()
                : postA?.created?._seconds * 1000
            ),
            new Date(
              postB?.created?.toDate
                ? postB?.created?.toDate()
                : postB?.created?._seconds * 1000
            )
          )
        );
        break;
      case "title":
        data = data.sort((postA, postB) => {
          return getSortConditionBySortOrder(
            order,
            postA?.title,
            postB?.title,
            true
          );
        });
        break;
    }
    setPosts(data);
    setCurrentOrder(order);
    setCurrentSortField(fieldName);
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem
          key={i}
          active={currentPage === i + 1 ? "active" : null}
        >
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  return (
    <>
      <div>
        <button
          onClick={() => history.goBack()}
          style={{ border: "none", backgroundColor: "white" }}
        >
          <i
            class="fas fa-arrow-circle-left fa-3x"
            id="single_content_arrow_icon"
          ></i>
        </button>
        <div className="my-5" style={{ height: "20px" }}>
          <h2 style={{ color: "#1e2864" }}>
            {guruData?.name} Recommendations ({guruRecommendations.length})
          </h2>
        </div>
        {loading ? (
          <>
            <Skeleton height={130} />
            <Skeleton height={130} />
            <Skeleton height={130} />
            <Skeleton height={130} />
          </>
        ) : (
          <table class="table history-table">
            {/* {updating && (
              <div style={spinnerDivStyleSheet}>
                <Spinner style={spinnerStyleSheet} />
              </div>
            )} */}
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">
                  <SortAscAndDesc
                    isActive={currentSortField === "title"}
                    onClickAsc={() =>
                      handleOnClickSort("title", ASCENDING_ORDER)
                    }
                    onClickDesc={() =>
                      handleOnClickSort("title", DESCENDING_ORDER)
                    }
                    isAscending={currentOrder === ASCENDING_ORDER}
                    title="Title"
                  />
                </th>
                <th scope="col">
                  <SortAscAndDesc
                    isActive={currentSortField === "created"}
                    onClickAsc={() =>
                      handleOnClickSort("created", ASCENDING_ORDER)
                    }
                    onClickDesc={() =>
                      handleOnClickSort("created", DESCENDING_ORDER)
                    }
                    isAscending={currentOrder === ASCENDING_ORDER}
                    title="Date Created"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData().map((eachPost, index) => (
                <tr key={index}>
                  <td>
                    <Checkbox
                      onClick={() => handleOnClickAdd(eachPost.id)}
                      checked={guruRecommendations.includes(eachPost.id)}
                    />
                  </td>
                  <td
                    className="img"
                    style={{ paddingTop: 5 }}
                    colSpan={1}
                    align="center"
                  >
                    <PostLink
                      post={eachPost}
                      rowId={eachPost.rowId}
                      postId={eachPost.id}
                      adventureId={eachPost.adventureId}
                    >
                      <div className="post-thumbnail-container">
                        <img src={eachPost?.thumbnailUrl} />
                      </div>
                    </PostLink>
                  </td>
                  <td>
                    <Link
                      to={`/adventure/${eachPost.adventureId}/${eachPost.rowId}/${eachPost.id}`}
                    >
                      {eachPost.title}
                    </Link>
                  </td>
                  <td>
                    {eachPost?.created &&
                      moment(
                        eachPost?.created?.toDate
                          ? eachPost?.created?.toDate()
                          : new Date(eachPost?.created?._seconds * 1000)
                      ).format("MMM DD, YYYY")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}{" "}
        <div className="d-flex justify-content-center mt-5">
          <Pagination aria-label="Page navigation example">
            <PaginationItem>
              <PaginationLink previous href="#" onClick={(e) => prev()} />
            </PaginationItem>
            {showPaginationItems()}
            <PaginationItem>
              <PaginationLink next href="#" onClick={() => next()} />
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default GuruRecommendations;
