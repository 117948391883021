import React from "react";
import { Alert } from "reactstrap";
import SliderRowRowEditor from "./SliderRowRowEditor";

const SliderRowTable = ({
  children,
  isAddNew,
  isEdit,
  onSaveHandler,
  onCancelHandler,
  onAddNewHandler,
  message,
  error,
  onValueChangeHandler,
  onFileChangeHandler,
  onChangeFilteringHandler,
  setSearchedData,
  selectedRows,
  onUpdateCategories,
  values,
}) => {
  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="container mb-5">
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => setSearchedData(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="searchBy"
                className="form-control"
              >
                <option value="0">Search By</option>
                <option value="title">Title</option>
                <option value="category">Category</option>
                <option value="audience">Audience</option>
                <option value="userPhase">User Phase</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="orderBy"
                className="form-control"
              >
                <option value="0">Order By</option>
                <option value="title">Title</option>
                <option value="category">Category</option>
                <option value="audience">Audience</option>
                <option value="userPhase">User Phase</option>
                <option value="contentType">File Type</option>
                <option value="created">Date</option>
                <option value="updated">Updated</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="orderDirection"
                className="form-control"
              >
                <option value="0">Order Direction</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </form>
      </div>

      {selectedRows.length > 0 && (
        <button
          onClick={onUpdateCategories}
          className="btn btn-sm btn-primary mb-4"
        >
          Update Adventure/Category
        </button>
      )}

      <table className="table table-striped" style={{ overflowX: "scroll" }}>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Title</th>
            <th scope="col">Category</th>
            <th scope="col">Audience</th>
            <th scope="col">User phase</th>
            <th scope="col">Thumbnail</th>
            <th scope="col">Post url</th>
            <th scope="col">File type</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>

      {message && <Alert color="success">{message}</Alert>}
      {error && <Alert color="danger">{error}</Alert>}

      <div>
        <button
          onClick={() => onAddNewHandler()}
          className="btn btn-sm btn-primary"
        >
          Add New
        </button>
      </div>
    </>
  );
};

export default SliderRowTable;
