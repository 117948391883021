import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Row,
  Column,
  Alert,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { storage, firestore, timestamp } from "../../../firebase";

import { useSelector, useDispatch } from "react-redux";

import "./AddSinglePost.css";

import OpenTabSwitch from "./OpenTabSwitch";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
const initValues = {
  title: "",
  category: "",
  userPhase: "Beginner",
  audience: "All",
  description: "",
  wwli: "",
  isOpenNewTab: false,
  isPaid: false,
  isScheduled: false,
};

const AddSinglePost = ({ isEdit }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [contentCreators, setContentCreators] = useState([]);

  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [url, setUrl] = useState("");

  const [values, setValues] = useState({ ...initValues });

  const [isSelectFile, setIsSelectFile] = useState(true);
  const [isSelectUrl, setIsSelectUrl] = useState(false);

  const [urlContentType, setUrlContentType] = useState("externalVideo");

  const {
    selectedAdventure,
    selectedAdventureRow,
    selectedPost,
    addNewPostRowNum,
  } = useSelector((state) => state.admin);

  // console.log("addNewPostRowNum ", addNewPostRowNum)
  const types = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
  const fileTypes = [
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "video/mp4",
    "application/pdf",
    "audio/mpeg",
    "audio/mp4",
    "audio/vnd.wav",
    "video/x-flv",
    "video/x-msvideo",
    "video/x-ms-wmv",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    setIsMounted(true);
    if (selectedAdventure) {
      setValues({ ...values, category: selectedAdventure.title });
    }
    if (isEdit) {
      setValues({
        ...values,
        ...selectedPost.post,
        category: selectedAdventure.title,
      });
    }

    // load available content creators
    firestore
      .collection("m_content_creators")
      .get()
      .then((snapshot) => {
        setContentCreators(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });

    return () => {
      dispatch({ type: "CLEAR_ADD_NEW_POST_ROW_NUM" });
      setIsMounted(false);
    };
  }, []);

  const onClickHandler = async () => {};

  const onValueChangehandler = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "isOpenNewTab") {
      setValues({ ...values, [name]: e.target.checked });
    } else if (e.target.name === "isPaid") {
      setValues({ ...values, [name]: e.target.checked });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const onFileChangehandler = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const onThumbnailChangehandler = (e) => {
    const selectedThumbnail = e.target.files[0];
    setThumbnail(selectedThumbnail);
  };

  //////////////////
  const onUpdateHandler = async () => {
    setIsLoading(true);

    if (!thumbnail && !file) {
      try {
        await firestore
          .collection("m_posts")
          .doc(selectedPost.post.id)
          .update({ ...values, updated: timestamp() });
        setMessage("Doc uploaded successfully");
        setTimeout(() => setMessage(""), 2000);
      } catch (e) {
        setError("Doc update failed");
        setTimeout(() => setError(""), 2000);
      } finally {
        setIsLoading(false);
        setValues(initValues);
        setFile(null);
        setThumbnail(null);
        setIsLoading(false);
      }
    }

    if (!file && !url && thumbnail) {
      if (!types.includes(thumbnail.type)) {
        return setError("Select a jpeg, jpg, png or svg file");
      }
      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();
      const res = await doc.data();
      const { thumbnailFullPath } = res;
      const storageRef = storage.ref(thumbnailFullPath);
      await storageRef.delete();

      const times = Date.now();
      const storageRefNew = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );

      storageRefNew.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const url = await storageRefNew.getDownloadURL();
          const metadata = await storageRefNew.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          await firestore
            .collection("m_posts")
            .doc(selectedPost.post.id)
            .update({
              ...values,
              thumbnailUrl: url,
              thumbnailFullPath,
              updated: timestamp(),
            });
          setMessage("Data updated successfully");
          setTimeout(() => setMessage(""), 2000);
          setValues(initValues);
          setThumbnail(null);
          setIsLoading(false);
        }
      );
    }
    ///

    if (file && values.fileFullPath && !thumbnail) {
      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();
      const res = await doc.data();
      const { fileFullPath } = res;
      const storageRef = storage.ref(fileFullPath);
      await storageRef.delete();

      const times = Date.now();
      const storageRefNew = storage.ref(`adventures/${times}-${file.name}`);

      storageRefNew.put(file).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const url = await storageRefNew.getDownloadURL();
          const metadata = await storageRefNew.getMetadata();
          const fullPath = metadata.fullPath;
          await firestore
            .collection("m_posts")
            .doc(selectedPost.post.id)
            .update({
              ...values,
              fileUrl: url,
              fileFullPath: fullPath,
              updated: timestamp(),
            });
          setMessage("Data updated successfully");
          setTimeout(() => setMessage(""), 2000);
          setValues(initValues);
          setThumbnail(null);
          setIsLoading(false);
        }
      );
    }
    ////

    ////
    if (file && !thumbnail && !values.fileFullPath) {
      setIsLoading(true);
      const times = Date.now();
      const storageRefFile = storage.ref(`adventures/${times}-${file.name}`);
      storageRefFile.put(file).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const fileUrl = await storageRefFile.getDownloadURL();
          const metadata = await storageRefFile.getMetadata();
          const fileFullPath = metadata.fullPath;
          const contentType = metadata.contentType;
          setMessage("File uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          try {
            await firestore
              .collection("m_posts")
              .doc(selectedPost.post.id)
              .update({
                ...values,
                fileUrl,
                fileFullPath,
                contentType,
                updated: timestamp(),
              });
            setMessage("Doc uploaded successfully");
            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setIsLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
            history.goBack();
          }
        }
      );
    }
    /////

    /////
    if (file && values.fileFullPath && thumbnail) {
      const times = Date.now();

      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();
      const res = await doc.data();
      const { thumbnailFullPath } = res;
      //console.log(fullPath);
      const storageRef = storage.ref(thumbnailFullPath);
      await storageRef.delete();

      const storageRefThumbnail = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          const timesCurrent = Date.now();

          const doc = await firestore
            .collection("m_posts")
            .doc(selectedPost.post.id)
            .get();
          const res = await doc.data();
          const { fileFullPath } = res;
          //console.log(fullPath);
          const storageRef = storage.ref(fileFullPath);
          await storageRef.delete();

          const storageRefFile = storage.ref(
            `adventures/${timesCurrent}-${file.name}`
          );
          storageRefFile.put(file).on(
            "state_changed",
            (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              // setProgress(percentage);
            },
            (err) => {
              setError(err.message);
              setTimeout(() => setError(""), 2000);
            },
            async () => {
              const fileUrl = await storageRefFile.getDownloadURL();
              const metadata = await storageRefFile.getMetadata();
              const fileFullPath = metadata.fullPath;
              const contentType = metadata.contentType;
              setMessage("File uploaded successfully");
              setTimeout(() => setMessage(""), 2000);

              try {
                await firestore
                  .collection("m_posts")
                  .doc(selectedPost.post.id)
                  .update({
                    ...values,
                    thumbnailUrl,
                    thumbnailFullPath,
                    fileUrl,
                    fileFullPath,
                    contentType,
                    updated: timestamp(),
                  });
                setMessage("Doc uploaded successfully");
                setTimeout(() => setMessage(""), 2000);
              } catch (e) {
                setError(e.message);
                setTimeout(() => setError(""), 2000);
              } finally {
                setIsLoading(false);
                setValues(initValues);
                setFile(null);
                setThumbnail(null);
              }
            }
          );
        }
      );
    }
    ///

    //////
    if (isSelectUrl && url && !values.fileFullPath) {
      try {
        await firestore
          .collection("m_posts")
          .doc(selectedPost.post.id)
          .update({
            ...values,
            contentType: urlContentType,
            fileUrl: url,
            updated: timestamp(),
          });
        setMessage("Doc uploaded successfully");
        setTimeout(() => setMessage(""), 2000);
      } catch (e) {
        setError(e.message);
        setTimeout(() => setError(""), 2000);
      } finally {
        setIsLoading(false);
        setValues(initValues);
        setFile(null);
        setThumbnail(null);
        history.goBack();
      }
    }
    //////

    //////
    if (isSelectUrl && url && !values.fileFullPath && thumbnail) {
      if (!types.includes(thumbnail.type)) {
        return setError(
          "Unsupported thumbnail file. Choose from jpg, jpeg, png or svg file. "
        );
      }

      const times = Date.now();
      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();

      const res = await doc.data();
      const { thumbnailFullPath } = res;

      const storageRef = storage.ref(thumbnailFullPath);
      await storageRef.delete();

      const storageRefThumbnail = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail updated successfully");
          setTimeout(() => setMessage(""), 2000);

          try {
            await firestore
              .collection("m_posts")
              .doc(selectedPost.post.id)
              .update({
                ...values,
                thumbnailUrl,
                thumbnailFullPath,
                contentType: urlContentType,
                fileUrl: url,
                fifileFullPath: "",
                updated: timestamp(),
              });
            setMessage("Doc uploaded successfully");
            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setIsLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
          }
        }
      );
    }
    /////

    ////
    if (isSelectUrl && url && values.fileFullPath && !thumbnail) {
      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();
      const res = await doc.data();
      const { fileFullPath } = res;
      const storageRef = storage.ref(fileFullPath);
      await storageRef.delete();

      try {
        await firestore
          .collection("m_posts")
          .doc(selectedPost.post.id)
          .update({
            ...values,
            fileUrl: url,
            fileFullPath: "",
            contentType: urlContentType,
            updated: timestamp(),
          });
        setMessage("Doc uploaded successfully");
        setTimeout(() => setMessage(""), 2000);
      } catch (e) {
        setError(e.message);
        setTimeout(() => setError(""), 2000);
      } finally {
        setIsLoading(false);
        setValues(initValues);
        setFile(null);
        setThumbnail(null);
      }
    }
    /////

    /////
    if (isSelectUrl && url && values.fileFullPath && thumbnail) {
      const times = Date.now();

      const doc = await firestore
        .collection("m_posts")
        .doc(selectedPost.post.id)
        .get();

      const res = await doc.data();
      const { thumbnailFullPath } = res;
      const storageRef = storage.ref(thumbnailFullPath);
      await storageRef.delete();

      const storageRefThumbnail = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail updated successfully");
          setTimeout(() => setMessage(""), 2000);

          try {
            await firestore
              .collection("m_posts")
              .doc(selectedPost.post.id)
              .update({
                ...values,
                thumbnailUrl,
                thumbnailFullPath,
                fileUrl: url,
                fileFullPath: "",
                contentType: urlContentType,
                updated: timestamp(),
              });
            setMessage("Doc uploaded successfully");
            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setIsLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
          }
        }
      );
    }
    /////
    history.goBack();
  };

  // console.log(urlContentType);
  /////////////////////
  const onSaveHandler = () => {
    setError("");
    if (isSelectFile && (!thumbnail || !file)) {
      return setError("Select thumbnail and file");
    }

    if (isSelectUrl && (!thumbnail || !url)) {
      return setError("Select thumbnail and url");
    }

    /////
    if (isSelectFile && thumbnail && file) {
      setIsLoading(true);
      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          const timesCurrent = Date.now();

          const storageRefFile = storage.ref(
            `adventures/${timesCurrent}-${file.name}`
          );
          storageRefFile.put(file).on(
            "state_changed",
            (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              // setProgress(percentage);
            },
            (err) => {
              setError(err.message);
              setTimeout(() => setError(""), 2000);
            },
            async () => {
              const fileUrl = await storageRefFile.getDownloadURL();
              const metadata = await storageRefFile.getMetadata();
              const fileFullPath = metadata.fullPath;
              const contentType = metadata.contentType;
              setMessage("File uploaded successfully");
              setTimeout(() => setMessage(""), 2000);

              try {
                await firestore
                  .collection("m_posts")
                  .doc()
                  .set({
                    ...values,
                    adventureId: selectedAdventure.id,
                    rowId: selectedAdventureRow.id,
                    rowNumber: addNewPostRowNum,
                    thumbnailUrl,
                    thumbnailFullPath,
                    fileUrl,
                    fileFullPath,
                    contentType,
                    created: timestamp(),
                    updated: timestamp(),
                  });
                setMessage("Doc uploaded successfully");

                const docRef = await firestore
                  .collection("m_posts")
                  .where("adventureId", "==", selectedAdventure.id)
                  .where("rowId", "==", selectedAdventureRow.id)
                  .get();

                const id = docRef.docs[0].id;

                const addIDField = await firestore
                  .collection("m_posts")
                  .doc(id)
                  .update({ id: id });

                setTimeout(() => setMessage(""), 2000);
              } catch (e) {
                setError(e.message);
                setTimeout(() => setError(""), 2000);
              } finally {
                setIsLoading(false);
                setValues(initValues);
                setFile(null);
                setThumbnail(null);
                history.goBack();
              }
            }
          );
        }
      );
    }
    ////

    //////
    if (isSelectUrl && url && thumbnail) {
      setIsLoading(true);

      const times = Date.now();
      const storageRefThumbnail = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );
      storageRefThumbnail.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const thumbnailUrl = await storageRefThumbnail.getDownloadURL();
          const metadata = await storageRefThumbnail.getMetadata();
          const thumbnailFullPath = metadata.fullPath;
          setMessage("thumbnail uploaded successfully");
          setTimeout(() => setMessage(""), 2000);

          try {
            await firestore
              .collection("m_posts")
              .doc()
              .set({
                ...values,
                rowNumber: addNewPostRowNum,
                adventureId: selectedAdventure.id,
                rowId: selectedAdventureRow.id,
                thumbnailUrl,
                thumbnailFullPath,
                fileUrl: url,
                fileFullPath: "",
                contentType: urlContentType,
                created: timestamp(),
                updated: timestamp(),
              });
            setMessage("Doc uploaded successfully");

            const docRef = await firestore
              .collection("m_posts")
              .where("adventureId", "==", selectedAdventure.id)
              .where("rowId", "==", selectedAdventureRow.id)
              .get();

            const id = docRef.docs[0].id;

            const addIDField = await firestore
              .collection("m_posts")
              .doc(id)
              .update({ id: id });

            setTimeout(() => setMessage(""), 2000);
          } catch (e) {
            setError(e.message);
            setTimeout(() => setError(""), 2000);
          } finally {
            setIsLoading(false);
            setValues(initValues);
            setFile(null);
            setThumbnail(null);
            history.goBack();
          }
        }
      );
    }
    //////
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const toggleSelectUrlHandler = (e) => {
    setIsSelectUrl(true);
    setIsSelectFile(false);
  };

  const toggleUploadFileHandler = (e) => {
    setIsSelectUrl(false);
    setIsSelectFile(true);
  };

  return (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input
            value={values.title}
            onChange={(e) => onValueChangehandler(e)}
            type="text"
            name="title"
            id="title"
          />
        </FormGroup>

        <FormGroup>
          <Label for="userPhase">User phase</Label>
          <Input
            value={values.userPhase}
            onChange={(e) => onValueChangehandler(e)}
            type="select"
            name="userPhase"
            id="userPhase"
          >
            <option>Beginner</option>
            <option>Intermediate</option>
            <option>Advanced</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="contentLength">Content Length</Label>
          <Input
            value={values.contentLength}
            onChange={(e) => onValueChangehandler(e)}
            type="select"
            name="contentLength"
            id="contentLength"
          >
            <option selected>None</option>
            <option> &lt;5 mins </option>
            <option> &lt;10 mins </option>
            <option> &lt;15 mins </option>
            <option> &lt;25 mins </option>
            <option> &lt;30 mins </option>
            <option> 30+ mins </option>
            <option> 45+ mins </option>
            <option> 1+ Hr </option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="audience">Audience</Label>
          <Input
            value={values.audience}
            onChange={(e) => onValueChangehandler(e)}
            type="select"
            name="audience"
            id="audience"
          >
            <option>All</option>
            <option>Kids</option>
            <option>Adults</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="contentCreator">Content Creator</Label>
          <Input
            value={values?.contentCreator}
            onChange={(e) => onValueChangehandler(e)}
            type="select"
            name="contentCreator"
            id="contentCreator"
          >
            <option>Select a Content Creator</option>
            {contentCreators.map((eachContentCreator) => (
              <option value={eachContentCreator.id}>
                {eachContentCreator.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="wwli">Why we like it?</Label>
          <Input
            value={values.wwli}
            onChange={(e) => onValueChangehandler(e)}
            type="textarea"
            name="wwli"
            id="wwli"
          />
        </FormGroup>

        <FormGroup>
          <div id="singlePost_desc">
            <Label for="description">Description</Label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "alignment",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "undo",
                  "redo",
                ],
              }}
              data={values.description}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                if (isEdit) {
                  setValues({ ...selectedPost.post, description: data });
                } else {
                  setValues({ ...values, description: data });
                }
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label for="thumbnail">Select a thumbnail</Label>
          <Input
            onChange={(e) => onThumbnailChangehandler(e)}
            type="file"
            name="thumbnail"
            id="thumbnail"
          />
        </FormGroup>

        <hr style={{ borderTop: "2px solid #d1bef7" }} className="mt-5" />

        <FormGroup>
          <div className="btn-group mt-2">
            <button
              onClick={(e) => toggleUploadFileHandler(e)}
              className="btn btn-primary btn-sm"
            >
              Upload File
            </button>
            <button
              onClick={(e) => toggleSelectUrlHandler(e)}
              className="btn btn-warning btn-sm"
            >
              Insert Url
            </button>
          </div>
        </FormGroup>

        {isSelectFile ? (
          <FormGroup>
            <Label for="file">Select a file</Label>
            <Input
              onChange={(e) => onFileChangehandler(e)}
              type="file"
              name="file"
              id="file"
            />
          </FormGroup>
        ) : null}

        {isSelectUrl ? (
          <>
            <div className="row">
              <div className="col-10">
                <FormGroup>
                  <Label for="url">Put a url</Label>
                  <Input
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    type="text"
                    name="url"
                    id="url"
                  />
                </FormGroup>
              </div>
              <div className="col-2">
                <FormGroup>
                  <Label for="">Content Type</Label>
                  <Input
                    type="select"
                    onChange={(e) => setUrlContentType(e.target.value)}
                  >
                    <option value="externalVideo">Video</option>
                    <option value="externalAudio">Audio</option>
                    <option value="externalImage">Image</option>
                    <option value="externalDoc">Doc</option>
                    <option value="externalWebpage">Web Page</option>
                  </Input>
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <OpenTabSwitch
                  onChangeHandler={onValueChangehandler}
                  value={values.isOpenNewTab}
                  name="isOpenNewTab"
                  labelDescription="Open in a new tab?"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <OpenTabSwitch
                  onChangeHandler={onValueChangehandler}
                  value={values.isPaid}
                  name="isPaid"
                  labelDescription="Is charged by 3rd party?"
                />
              </div>
            </div>
          </>
        ) : null}

        <div>
          {error && <Alert color="danger">{error}</Alert>}
          {message && <Alert color="success">{message}</Alert>}
        </div>

        {isEdit ? (
          <Button onClick={() => onUpdateHandler()}>Update</Button>
        ) : (
          <Button onClick={() => onSaveHandler()}>Save</Button>
        )}
      </Form>
    </div>
  );
};

export default AddSinglePost;
