import React from "react";

import "./FeatureCard.css";
import {Link} from "react-router-dom";

import {useDispatch} from "react-redux";

import {selectAdventure} from "../../store/actions/indexActions";

const FeatureCard = ({title,image,id,children,adventure}) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(selectAdventure(adventure));
  }

  return (
    <div className="col-6 col-md-4 feature_card_container_col">
      <div className="card feature_card">
        <img className="card-img-top w-100 img-fluid" src={`${image}`} alt="features_svg_"/>
        <div className="card-body text-center">
          <h5 className="card-title">{title}</h5>
          {children ? children : null}
          <Link onClick={()=>onClickHandler()} to={{pathname:`/${id}`,state:{adventure}}} className="stretched-link"></Link>
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
