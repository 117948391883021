import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import WatchedTable from "../components/HistoryTable/HistoryTable";
import SmallFooter from "../components/SmallFooter/SmallFooter";

const stylesheet = {
  overflow: "hidden",
};

const Watched = () => {
  return (
    <>
      <Navbar />

      <WatchedTable />

      <SmallFooter />
    </>
  );
};

/**
 * @param {number[]} height
 * @return {number}
 */
var trap = function (height) {
  // initialize left and right pointers/index, result/sum
  let left = 0;
  let right = height.length - 1;
  let result = 0;
  // initialize left and right max count containers
  let leftMax = height[0];
  let rightMax = height[right];
  console.log(left, right, leftMax, rightMax);
  // loop through the element , while left < right
  while (left < right) {
    // if left max < right max
    if (leftMax < rightMax) {
      console.log("leftMax < rightMax", leftMax, rightMax, result);
      // calculate result, y subtracting left max from the next element
      result += leftMax - height[left];
      // get max value from current index and prev max value,
      leftMax = Math.max(leftMax, height[left]);
      // increament left
      left++;
    }
    // else
    else {
      console.log("leftMax >= rightMax", leftMax, rightMax, result);

      // get result by, subtracting rightMax from current Index
      result += rightMax - height[right];
      // get right max, by comparing current index value and prev max
      rightMax = Math.max(rightMax, height[right]);
      // decreament right
      right--;
    }
  }
  // return result
  return result;
};

export default Watched;
