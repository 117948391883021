import React from "react";

const SliderRowActions = ({post,onShowHandler,onEditHandler,onDeleteHandler,onClickPositionChangeHandler}) => {
  return (
    <>
    <td>
    <button
     onClick={(e) => onShowHandler(post)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-eye"></i></button>
    <button
     onClick={(e) => onEditHandler(post)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-edit"></i></button>
    <button
     onClick={(e) => onDeleteHandler(post.id,post)}
     style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>

    <button
      onClick={e => onClickPositionChangeHandler(e,post)}
      name="pushUp"
      className="ml-2"
      style={{padding:"3px",border:"0px"}}
      ><i class="fas fa-arrow-circle-up"></i></button>
      <button
      name="pushDown"
      onClick={e => onClickPositionChangeHandler(e,post)}
      style={{padding:"3px",border:"0px"}}
      ><i class="fas fa-arrow-circle-down"></i></button>

     </td>
    </>
  );
}

export default SliderRowActions;
