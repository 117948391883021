import React from "react";

const SingleAdventureActions = ({row,onShowHandler,onEditHandler,onDeleteHandler,onClickPositionChangeHandler}) => {
  return (
    <>
    <td>
    <button
     onClick={(e) => onShowHandler(row)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-eye"></i></button>
    <button
     onClick={(e) => onEditHandler(row)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-edit"></i></button>
    <button
     onClick={(e) => onDeleteHandler(row)}
     style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>

    <button
      onClick={e => onClickPositionChangeHandler(e,row)}
      name="pushUp"
      className="ml-2"
      style={{padding:"3px",border:"0px"}}
      ><i class="fas fa-arrow-circle-up"></i></button>
      <button
      name="pushDown"
      onClick={e => onClickPositionChangeHandler(e,row)}
      style={{padding:"3px",border:"0px"}}
      ><i class="fas fa-arrow-circle-down"></i></button>

     </td>
    </>
  );
}

export default SingleAdventureActions;
