import React, { useState, useEffect } from "react";
import { firestore, storage, timestamp } from "../../firebase";
import AdminIndexTabs from "./AdminIndex/AdminIndexTabs";
import Spinner from "./AdminIndex/Spinner";
import Snackbar from "./AdminIndex/SnackBar";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const initValues = {
  topSectionHeading: "",
  topSectionParagraph: "",
  topSectionBtnText: "",
  topSectionBtnLink: "",
  midSectionHeading: "",
  midSectionParagraph: "",
  midSectionBtnText: "",
  midSectionBtnLink: "",
  lowerMidSectionHeading: "",
  lowerMidSectionParagraph: "",
  lowerMidSectionBtnText: "",
  lowerMidSectionBtnLink: "",
  lowerMidSectionImageSrc: "",
  lowerMidSectionFileUrl: "",
  lowerMidSectionFileFullPath: "",
  bottomSectionHeading: "",
  bottomSectionParagraph: "",
  terms: "",
};

const IndividualIndex = () => {
  const [values, setValues] = useState({ ...initValues });
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarType, setSnackBarType] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [lowerMidSectionFile, setLowerMidSectionFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const individualIndexRef = firestore
          .collection("individualIndex")
          .doc("page");
        const resp = await individualIndexRef.get();
        const data = resp.data();
        setValues({ ...data });
      } catch (error) {
        console.log("Error fetching page data", error.message);
      }
    };

    fetchPageData();
  }, []);

  const onClickHandler = async (e) => {
    try {
      const individualIndexRef = firestore
        .collection("individualIndex")
        .doc("page");
      switch (e.currentTarget.getAttribute("name")) {
        case "topSectionButton":
          setIsLoading(true);
          await individualIndexRef.update({
            topSectionHeading: values.topSectionHeading,
            topSectionParagraph: values.topSectionParagraph,
            topSectionBtnText: values.topSectionBtnText,
            topSectionBtnLink: values.topSectionBtnLink,
            updatedAt: new Date(),
          });
          setIsLoading(false);
          setSnackBarType("success");
          setSnackBarMessage("Updated Successfully");
          setIsSnackBarOpen(true);

          break;
        case "midSectionButton":
          setIsLoading(true);
          await individualIndexRef.update({
            midSectionHeading: values.midSectionHeading,
            midSectionParagraph: values.midSectionParagraph,
            midSectionBtnText: values.midSectionBtnText,
            midSectionBtnLink: values.midSectionBtnLink,
          });
          setIsLoading(false);
          setSnackBarType("success");
          setSnackBarMessage("Updated Successfully");
          setIsSnackBarOpen(true);
          break;
        case "lowerMidSectionButton":
          setIsLoading(true);

          if (lowerMidSectionFile) {
            const doc = await individualIndexRef.get();
            const res = doc.data();

            if (res && res.lowerMidSectionFileUrl) {
              setIsLoading(true);
              const { lowerMidSectionFileFullPath } = res;
              const storageRefOld = storage.ref(lowerMidSectionFileFullPath);
              await storageRefOld.delete();

              const times = Date.now();
              const storageRef = storage.ref(
                `adventures/${lowerMidSectionFile.name}-${times}`
              );

              storageRef.put(lowerMidSectionFile).on(
                "state_changed",
                (snap) => {
                  let percentage =
                    (snap.bytesTransferred / snap.totalBytes) * 100;
                  // setProgress(percentage);
                },
                (err) => {
                  setIsLoading(false);
                  setSnackBarType("error");
                  setSnackBarMessage("Update Failed");
                  setIsSnackBarOpen(true);
                },
                async () => {
                  const url = await storageRef.getDownloadURL();
                  const metadata = await storageRef.getMetadata();
                  const fullPath = metadata.fullPath;
                  await individualIndexRef.update({
                    ...values,
                    lowerMidSectionFileUrl: url,
                    lowerMidSectionFileFullPath: fullPath,
                    created: timestamp(),
                    updatedAt: new Date(),
                  });
                  setSnackBarType("success");
                  setSnackBarMessage("Updated Successfully");
                  setIsSnackBarOpen(true);

                  setLowerMidSectionFile(null);
                  setIsLoading(false);
                }
              );
            } else {
              const times = Date.now();
              const storageRef = storage.ref(
                `adventures/${lowerMidSectionFile.name}-${times}`
              );

              storageRef.put(lowerMidSectionFile).on(
                "state_changed",
                (snap) => {
                  let percentage =
                    (snap.bytesTransferred / snap.totalBytes) * 100;
                  // setProgress(percentage);
                },
                (err) => {
                  setIsLoading(false);
                  setSnackBarType("error");
                  setSnackBarMessage("Update Failed");
                  setIsSnackBarOpen(true);
                },
                async () => {
                  const url = await storageRef.getDownloadURL();
                  const metadata = await storageRef.getMetadata();
                  const fullPath = metadata.fullPath;
                  await individualIndexRef.update({
                    ...values,
                    lowerMidSectionFileUrl: url,
                    lowerMidSectionFileFullPath: fullPath,
                    created: timestamp(),
                    updatedAt: new Date(),
                  });
                  setSnackBarType("success");
                  setSnackBarMessage("Updated Successfully");
                  setIsSnackBarOpen(true);

                  setLowerMidSectionFile(null);
                  setIsLoading(false);
                }
              );
            }
          } else {
            await individualIndexRef.update({
              lowerMidSectionHeading: values.lowerMidSectionHeading,
              lowerMidSectionParagraph: values.lowerMidSectionParagraph,
              lowerMidSectionBtnText: values.lowerMidSectionBtnText,
              lowerMidSectionBtnLink: values.lowerMidSectionBtnLink,
              lowerMidSectionFileUrl: values.lowerMidSectionFileUrl,
              lowerMidSectionFileFullPath: values.lowerMidSectionFileFullPath,
              updatedAt: new Date(),
            });
            setIsLoading(false);

            setSnackBarType("success");
            setSnackBarMessage("Updated Successfully");
            setIsSnackBarOpen(true);
          }
          break;
        case "bottomSectionButton":
          setIsLoading(true);
          await individualIndexRef.update({
            bottomSectionHeading: values.bottomSectionHeading,
            bottomSectionParagraph: values.bottomSectionParagraph,
          });
          setIsLoading(false);

          setSnackBarType("success");
          setSnackBarMessage("Updated Successfully");
          setIsSnackBarOpen(true);

          break;
        case "termsButton":
          setIsLoading(true);
          await individualIndexRef.update({
            terms: values.terms,

            updatedAt: new Date(),
          });
          setIsLoading(false);

          setSnackBarType("success");
          setSnackBarMessage("Updated Successfully");
          setIsSnackBarOpen(true);

          break;
        default:
          setIsLoading(false);
          setSnackBarType("success");
          setSnackBarMessage("Nothing to update");
          setIsSnackBarOpen(true);
          console.error("Nothing matched");
      }
    } catch (error) {
      console.log("error while updating data: ", error.message);
      setIsLoading(false);
      setSnackBarType("error");
      setSnackBarMessage("Update Failed");
      setIsSnackBarOpen(true);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            background: "#f5f5f5",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "100",
            opacity: "0.7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : null}

      <Snackbar
        isSnackBarOpen={isSnackBarOpen}
        setIsSnackBarOpen={setIsSnackBarOpen}
        snackBarMessage={snackBarMessage}
        snackBarType={snackBarType}
      />

      <div className="mb-4">
        <h4>Individual Index</h4>
      </div>

      <AdminIndexTabs
        values={values}
        setValues={setValues}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        setLowerMidSectionFile={setLowerMidSectionFile}
        lowerMidSectionFile={lowerMidSectionFile}
      />
    </>
  );
};

export default IndividualIndex;
