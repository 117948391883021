import React from "react";
import AdventureActions from "./AdventureActions";
import AdventureRowEditor from "./AdventureRowEditor";

const AdventureRow = ({
  title
  ,description
  ,thumbnail
  ,id
  ,adventure
  ,onShowHandler
  ,onEditHandler
  ,onDeleteHandler
  ,onFileChangeHandler
  ,onValueChangeHandler
  ,onClickPositionChangeHandler
  ,selectedAdventureId
  ,values
  ,isEdit}) => {
  return (
    <>
        <tr>
      { selectedAdventureId === id && isEdit ?

        <>
        <AdventureRowEditor
        values={values}
        onFileChangeHandler={onFileChangeHandler}
        onValueChangeHandler={onValueChangeHandler}
        />
        </>
        :
        <>
          <td>{title}</td>
          <td>{description}</td>
          <td><img src={`${thumbnail}`} style={{width:"50px",height:"50px"}} /></td>
          <AdventureActions
          id={id}
          adventure={adventure}
          onShowHandler={onShowHandler}
          onEditHandler={onEditHandler}
          onDeleteHandler={onDeleteHandler}
          onClickPositionChangeHandler={onClickPositionChangeHandler}
           />
          </>
        }
        </tr>

    </>
  );
}


export default AdventureRow;
