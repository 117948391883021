import React from "react";
import ContentCreatorActions from "./ContentCreatorActions";
import ContentCreatorRowEditor from "./ContentCreatorRowEditor";

const ContentCreatorRow = ({
  name,
  website,
  description,
  id,
  contentCreator,
  onShowHandler,
  onEditHandler,
  onDeleteHandler,
  onFileChangeHandler,
  onValueChangeHandler,
  onClickPositionChangeHandler,
  selectedContentCreatorId,
  values,
  isEdit,
}) => {
  return (
    <>
      <tr>
        {selectedContentCreatorId === id && isEdit ? (
          <>
            <ContentCreatorRowEditor
              values={values}
              onFileChangeHandler={onFileChangeHandler}
              onValueChangeHandler={onValueChangeHandler}
            />
          </>
        ) : (
          <>
            <td>{name}</td>
            <td>{description}</td>
            <td>{website}</td>
            <ContentCreatorActions
              id={id}
              contentCreator={contentCreator}
              onShowHandler={onShowHandler}
              onEditHandler={onEditHandler}
              onDeleteHandler={onDeleteHandler}
            />
          </>
        )}
      </tr>
    </>
  );
};

export default ContentCreatorRow;
