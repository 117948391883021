import React,{useState,useEffect, useRef} from "react";
import {Alert,Spinner,Pagination, PaginationItem, PaginationLink} from "reactstrap";

import usePagination from "../hooks/usePagination";
import {firestore} from "../../firebase";

import {Link} from "react-router-dom";

import {useHistory} from "react-router-dom";

const Users = () => {

  const [users,setUsers] = useState([]);
  const [itemsPerPage,setItemsPerPage] = useState(10);



  const [orderDirection,setOrderDirection] = useState("desc");
  const [orderBy,setOrderBy] = useState("firstName");

  const [tempRows,setTempRows] = useState([]);
  const [searchBy,setSearchBy] = useState("firstName");

  const [filteredData,setFilteredData] = useState([]);
  const [searchedData,setSearchedData] = useState("");

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(filteredData,itemsPerPage);

  const history = useHistory();

  useEffect(async () => {
    let isMounted = true;

    const redemptionCode = history.location.state.redemptionCode;

    if (isMounted) {
      setUsers([]);

      const redemptionCodeOwnersRef = await firestore.collection('validRedemptionCodes').where("redemptionCode","==",redemptionCode).get();
      redemptionCodeOwnersRef.docs.forEach(async ownerSnapshots => {
        const {subscribers} = ownerSnapshots.data();
        if (subscribers) {
            subscribers.forEach(async subscriberEmail => {
                const usersRef = await firestore.collection("users").where("email","==",subscriberEmail).get();

                if (!usersRef.empty) {
                    usersRef.forEach(snapshot => {
                        const data = snapshot.data();

                        setUsers([...users,data])
                    });
                }
            });
        }

        

      });


      
    }

    return () => (isMounted=false);

  },[orderDirection,orderBy])


  useEffect(() => {
    setFilteredData(users.filter((user) => {
      return (
        user[`${searchBy}`].toLowerCase().includes(searchedData.toLowerCase())
      );
    }))
  },[searchedData,users])

  const convertTimestamp = (time) => {
    const timestamp = new Date(time * 1000);
    const date = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();
    
    
    return `${month}-${date}-${year}`
}


  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i+1 ? true : false}><PaginationLink href="#" onClick={()=>jump(i+1)}>{i + 1}</PaginationLink></PaginationItem>
      )
    }
    return arr;
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
  }

  const onChangeFilteringHandler = (e) => {
      switch (e.target.name) {
        case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("firstName")
        }
        setOrderBy(e.target.value)
        break;
        case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("desc")
        }
        setOrderDirection(e.target.value)
        break;
        case "searchBy":
          setSearchBy(e.target.value)
          break;
      }
  }

  


  

  return (
    <>

    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-3">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="phoneNumber">Phone Number</option>
          <option value="email">Email</option>
        </select>
        </div>

       

      </div>
    </form>

    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Phone Number</th>
          <th scope="col">Email</th>
        </tr>
      </thead>
      <tbody>

      {
      currentData() &&  currentData().map(user => (
          <tr key={user.uid}>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.phoneNumber}</td>
            <td>{user.email}</td>
          </tr>
        ))
      }



      </tbody>
      </table>

      <div className="d-flex justify-content-center mt-5">
      <Pagination aria-label="Page navigation example">
        <PaginationItem>
          <PaginationLink previous href="#" onClick={(e) => prev()} />
        </PaginationItem>
        {showPaginationItems()}
        <PaginationItem>
          <PaginationLink next href="#" onClick={() => next()} />
        </PaginationItem>
      </Pagination>
      </div>

    </>
  );
}

export default Users;
