import React, { useState, useEffect } from "react";

import { firestore, timestamp, firebase } from "../../firebase";
import { selectAdventureRow } from "../../store/actions/adminActions";

import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

import SingleAdventureTable from "./SingleAdventure/SingleAdventureTable";
import SingleAdventureRow from "./SingleAdventure/SingleAdventureRow";

const initValues = {
  rowNumber: 0,
};

const SingleAdventure = () => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [title, setTitle] = useState("");
  const [values, setValues] = useState({ ...initValues });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { selectedAdventure } = useSelector((state) => state.admin);

  const [selectedAdventureRowId, setSelectedAdventureRowId] = useState(null);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const history = useHistory();

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("rowNumber");

  const [searchBy, setSearchBy] = useState("title");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(async () => {
    const unsub = firestore
      .collection("m_rows")
      .where("adventureId", "==", selectedAdventure.id)
      .orderBy(orderBy, orderDirection)
      .onSnapshot((snapshot) => {
        setRows([]);
        snapshot.forEach((doc) => {
          setRows((rows) => [...rows, { id: doc.id, ...doc.data() }]);
        });
        setIsLoading(false);
      });
    return () => unsub;
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setFilteredData(
      rows.filter((row) => {
        return row[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, rows]);

  useEffect(() => {
    setValues({ ...values, rowNumber: rows.length });
  }, [rows]);

  const onAddNewHandler = () => {
    setIsAddNew(true);
    setValues({});
    let rowNumb = values.rowNumber++;
  };
  const onEditHandler = (row) => {
    setIsEdit(true);
    setValues({ ...row });
    setSelectedAdventureRowId(row.id);
  };

  const onDeleteHandler = async (row) => {
    setIsDelete(true);
    if (isMounted) {
      firestore
        .collection("m_posts")
        .where("rowId", "==", row.id)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            const result = window.confirm(
              "Are you sure you want to delete it?"
            );
            if (!result) {
              return;
            } else {
              const rowsRef = firestore
                .collection("m_rows")
                .where("adventureId", "==", selectedAdventure.id);
              const snapshot = await rowsRef
                .where("rowNumber", ">", row.rowNumber)
                .get();

              console.log(snapshot);

              snapshot.docs.forEach(async (doc, i) => {
                let id = doc.id;
                await firestore
                  .collection("m_rows")
                  .doc(id)
                  .update({
                    rowNumber: firebase.firestore.FieldValue.increment(-1),
                  });
              });

              firestore.collection("m_rows").doc(row.id).delete();
            }
          } else {
            setMessage(
              "Delete everything inside manually before proceeding this step."
            );
          }
        });
    }

    ///////
  };

  const onClickPositionChangeHandler = async (e, row) => {
    ////positions
    let selectedObjectPosition;
    let objectToBeInterchangedWithPosition;

    /////objects
    let selectedObject;
    let interchangedObject;

    switch (e.currentTarget.getAttribute("name")) {
      case "pushUp":
        if (row.rowNumber === 1) {
          console.log("cant push up");
          return;
        }

        selectedObjectPosition = filteredData.indexOf(row);
        objectToBeInterchangedWithPosition = selectedObjectPosition - 1;

        selectedObject = {
          ...filteredData[selectedObjectPosition],
          rowNumber: filteredData[selectedObjectPosition].rowNumber - 1,
        };

        interchangedObject = {
          ...filteredData[objectToBeInterchangedWithPosition],
          rowNumber:
            filteredData[objectToBeInterchangedWithPosition].rowNumber + 1,
        };

        break;
      case "pushDown":
        if (row.rowNumber === filteredData.length) {
          console.log("cant push down");
          return;
        }

        selectedObjectPosition = filteredData.indexOf(row);
        objectToBeInterchangedWithPosition = selectedObjectPosition + 1;

        selectedObject = {
          ...filteredData[selectedObjectPosition],
          rowNumber: filteredData[selectedObjectPosition].rowNumber + 1,
        };

        interchangedObject = {
          ...filteredData[objectToBeInterchangedWithPosition],
          rowNumber:
            filteredData[objectToBeInterchangedWithPosition].rowNumber - 1,
        };

        break;
      default:
        return;
    }

    try {
      const batch = firestore.batch();

      const selectedObjectRef = firestore
        .collection("m_rows")
        .doc(selectedObject.id);
      batch.update(selectedObjectRef, {
        ...selectedObject,
        updated: timestamp(),
      });

      const interchangedObjectRef = firestore
        .collection("m_rows")
        .doc(interchangedObject.id);
      batch.update(interchangedObjectRef, {
        ...interchangedObject,
        updated: timestamp(),
      });

      const result = await batch.commit();
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveHandler = async () => {
    if (isAddNew) {
      let rowNumber = Number.parseInt(filteredData.length) + 1;
      await firestore
        .collection("m_rows")
        .doc()
        .set({
          ...values,
          adventureId: selectedAdventure.id,
          rowNumber,
          created: timestamp(),
          updated: timestamp(),
        });

      const docRef = await firestore
        .collection("m_rows")
        .where("adventureId", "==", selectedAdventure.id)
        .where("rowNumber", "==", rowNumber)
        .get();

      const id = docRef.docs[0].id;

      const addIDField = await firestore
        .collection("m_rows")
        .doc(id)
        .update({ id: id });

      setIsAddNew(false);
    }

    if (isEdit) {
      await firestore
        .collection("m_rows")
        .doc(selectedAdventureRowId)
        .set({ ...values, updated: timestamp() });
      setIsEdit(false);
    }
  };

  const onCancelHandler = () => {
    if (isAddNew) {
      let rowNumber = values.rowNumber--;
    }
    setIsAddNew(false);
    setIsEdit(false);
  };

  const onShowHandler = (row) => {
    dispatch(selectAdventureRow(row));
    history.push(`/a/categories/${selectedAdventure.id}/r/${row.id}`);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const match = (s) => {
    const p = Array.from(s).reduce(
      (a, v, i) => `${a}[^${s.substr(i)}]*?${v}`,
      ""
    );
    const re = RegExp(p);

    return values.filter((v) => v.match(re));
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("rowNumber");
        }
        setOrderBy(e.target.value);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("asc");
        }
        setOrderDirection(e.target.value);
        break;
      case "search":
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Adventures</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {selectedAdventure.title}
          </li>
        </ol>
      </nav>

      <SingleAdventureTable
        onSaveHandler={onSaveHandler}
        onCancelHandler={onCancelHandler}
        isEdit={isEdit}
        isAddNew={isAddNew}
        onAddNewHandler={onAddNewHandler}
        message={message}
        error={error}
        onValueChangeHandler={onValueChangeHandler}
        values={values}
        onChangeFilteringHandler={onChangeFilteringHandler}
        setSearchedData={setSearchedData}
      >
        {filteredData.map((row) => (
          <SingleAdventureRow
            title={row.title}
            rowNumber={row.rowNumber}
            row={row}
            onShowHandler={onShowHandler}
            onDeleteHandler={onDeleteHandler}
            onEditHandler={onEditHandler}
            onValueChangeHandler={onValueChangeHandler}
            values={values}
            isEdit={isEdit}
            selectedAdventureRowId={selectedAdventureRowId}
            onClickPositionChangeHandler={onClickPositionChangeHandler}
          />
        ))}
      </SingleAdventureTable>
    </>
  );
};

export default SingleAdventure;
