import moment from "moment";
import React from "react";
// import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { atcb_init } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';
import "./AddToCalendar.css";

const startDatetime = new Date();
const endDatetime = new Date();
const duration = startDatetime.getHours() - endDatetime.getHours();
export const event = {
  // title: "Wellness time - Leviosa”, Location: “www.Leviosa.us”",
  // description: "Don’t forget, a little time for wellness goes a long way! When you’re ready, head over to www.Leviosa.us. We can’t wait to have you back!",
  // location: "",
  // duration,
  // endDatetime: moment(endDatetime).format("YYYYMMDDTHHmmssZ"),
  // startDatetime: moment(startDatetime).format("YYYYMMDDTHHmmssZ"),
  "name":"Wellness time - Leviosa”, Location: “www.Leviosa.us”",
        "description":"Don’t forget, a little time for wellness goes a long way! When you’re ready, head over to www.Leviosa.us. We can’t wait to have you back!",
        "startDate":"2022-02-21",
        "endDate":"2022-03-24",
        "startTime":moment(startDatetime).format("HH:MM"),
        "endTime":moment(endDatetime).format("HH:MM"),
        "location":"",
        "label":"Add to Calendar",
        "options":[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "Outlook.com",
          "Yahoo"
        ],
        "timeZone":"Europe/Berlin",
        "iCalFileName":"Leviosa-Reminder-Event"
};

const AddToCalendar = (props) => {

  React.useEffect(atcb_init, []);

//   const ATCDropdown = (args) => {
//     let list = [args.children[0],args.children[3]];
//     return (
//     <ul className="Cdropdown-content">
//       {list.map((link, i) => (
//         <span key={i}>{link}</span>
//       ))}
//     </ul>
//   );
//       }

//   const ATCWrapper = (args) => {
    
//     return (
//     <a onClick={args.onClick} className="atc-item" href="/">
//       {args.children}
//     </a>
//   )
// };

//   const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

  return (
    <div className="atcb" style={{ fontWeight: "500" }}>
      { JSON.stringify(event)}
    </div>
  )
  return (
    <div>
      <div
        className="d-flex justify-content-center align-items-center ml-2 Cdropdown"
        role="button"
      >
        {/* <button
          style={{ marginRight: "5px", outline: "none" }}
          className="heartBtnFeature"
        >
          <i class="fas fa-calendar"></i>
        </button>
        <p style={{ fontWeight: "500" }}> */}
          {/* <AddToCalendarDropdown  event={event} /> */}
          <div className="atcb">
      { JSON.stringify(event)}
    </div>
        {/* </p> */}
      </div>
    </div>
  );
};

export default AddToCalendar;
