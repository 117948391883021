import React from "react";

const ContentCreatorActions = ({
  id,
  contentCreator,
  onShowHandler,
  onEditHandler,
  onDeleteHandler,
}) => {
  return (
    <>
      <td>
        <button
          onClick={(e) => onEditHandler(id, contentCreator)}
          style={{ marginRight: "3px", padding: "3px", border: "0px" }}
        >
          <i className="far fa-edit"></i>
        </button>
        <button
          onClick={(e) => onDeleteHandler(id, contentCreator)}
          style={{ padding: "3px", border: "0px" }}
        >
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </>
  );
};

export default ContentCreatorActions;
