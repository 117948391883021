import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../components/Navbar/Navbar";
import SmallFooter from "../components/SmallFooter/SmallFooter";

import Alert from "../components/Alert";

import axios from "axios";

import { auth, firestore } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { togglePayment, setUserInfo } from "../store/actions/indexActions";

import { useHistory } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import SiteCustomization from "../containers/SiteCustomization/SiteCustomization";
import MyContent from "../containers/MyContent/MyContent.js";
import AddContentModal from "../admin/containers/CorpAdminContent/AddContentModal";
import ContentPolicyModal from "../admin/containers/CorpAdminContent/ContentPolicyModal";
import { copyToClipboard } from "../util/helpers";
import PastInvoicesModal from "../components/Modals/PastInvoices/PastInvoices";
import CompanyUsersModal from "../components/Modals/CompanyUsers/index.js";

const Billing = () => {
  const dispatch = useDispatch();
  let { uid } = auth.currentUser;

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { color: customThemeColor } = useSelector((state) => state.theme);
  const [currentPeriodStart, setCurrentPeriodStart] = useState("");
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState("");
  const [currentPlanPrice, setCurrentPlanPrice] = useState("");
  const [currentPlanTitle, setCurrentPlanTitle] = useState("");

  const [paymentIntent, setPaymentIntent] = useState({});
  const [modal, setModal] = useState(false);
  const [addContentModal, setAddContentModal] = useState(false);

  const [latestInvoice, setLatestInvoice] = useState(null);

  const [stripeData, setStripeData] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState(null);

  const [upcomingInvoice, setUpcomingInvoice] = useState(null);

  const [user, setUser] = useState(null);

  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [isRedemptionCodeOwner, setIsRedemptionCodeOwner] = useState(false);
  const [subscribersList, setSubscribersList] = useState([]);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [policyModalLoader, setPolicyModalLoader] = useState(false);
  const [buttonText, setButtonText] = useState("Copy To Clipboard");
  const [viewPastInvoicesModal, setViewPastInvoicesModal] = useState(false);
  const [pastInvoicesLoader, setPastInvoicesLoader] = useState(false);
  const [pastInvoices, setPastInvoices] = useState([]);
  const [disablePrevButton, setDisablePrevButton] = useState(0);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(false);
  const [usersModal, setUsersModal] = useState(false);
  const [subscribersData, setSubscribersData] = useState([]);
  const [subscribersLoading, setSubscribersLoading] = useState(false);

  const { userDetails, vouchDetails } = useAuth();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const Styles = {
    button: {
      fontFamily: "DM Sans",
      background:
        "linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%)",
      boxShadow: "0px 7.20913px 12.616px rgba(124, 121, 121, 0.25)",
      borderRadius: "27.0342px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "50px",
      paddingLeft: "50px",
      color: "#fff",
      cursor: "pointer",
      outline: "none",
      border: "0px",
    },
    cardHeader: {
      background: customThemeColor
        ? customThemeColor
        : "linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%)",
      color: "white",
    },
    planActionButtons: {
      fontSize: 13,
      color: "var(--theme-color)",
      cursor: "pointer",
      display: "flex",
    },
    disabledPlanActionButtons: {
      color: "gray",
      fontSize: 13,
      textDecoration: "none",
      cursor: "not-allowed",
    },
    userInviteButtons: {
      fontSize: 16,
      color: "var(--theme-color)",
      cursor: "pointer",
      display: "flex",
    },
    cardHeaderWithButton: {
      background: customThemeColor
        ? customThemeColor
        : "linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%)",
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  useEffect(() => {
    // to get corp admin posts
    getAllContent();
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    let resProduct;

    const token = localStorage.getItem("token");
    const res = await axios.post("/api/verify-token", { token });

    const email = res.data.decoded && res.data.decoded.email;
    const firstName = res.data.decoded && res.data.decoded.firstName;
    const lastName = res.data.decoded && res.data.decoded.lastName;
    const phoneNumber = res.data.decoded && res.data.decoded.phoneNumber;
    const title = res.data.decoded && res.data.decoded.pricingPlan;
    const amount = res.data.decoded && res.data.decoded.pricingAmount;
    const stripePriceId = res.data.decoded && res.data.decoded.stripePriceId;
    const stripeProductId =
      res.data.decoded && res.data.decoded.stripeProductId;

    const isPaidOnLogin = res.data.decoded && res.data.decoded.isPaidOnLogin;

    if (!isPaidOnLogin) {
      dispatch(
        setUserInfo({
          email,
          firstName,
          lastName,
          phoneNumber,
          title,
          amount,
          stripePriceId,
          stripeProductId,
        })
      );

      if (res.data && res.data.decoded && !res.data.decoded.access) {
        dispatch(togglePayment());
        history.push("/");
      }
    }

    firestore
      .collection("users")
      .where("email", "==", `${email}`)
      .onSnapshot((res) => {
        if (!res.empty) {
          res.docs.forEach(async (doc) => {
            if (!doc.data().stripeData) {
              return;
            }
            if (doc.data().isRedemptionCodeOwner) {
              setIsRedemptionCodeOwner(true);

              setSubscribersList([]);
              const redemptionCode = doc.data().redemptionCode;
              const redemptionCodeOwnersRef = await firestore
                .collection("validRedemptionCodes")
                .where("redemptionCode", "==", redemptionCode)
                .get();
              redemptionCodeOwnersRef.docs.forEach(async (ownerSnapshots) => {
                const { subscribers } = ownerSnapshots.data();
                if (subscribers) {
                  subscribers.forEach(async (subscriberEmail) => {
                    const usersRef = await firestore
                      .collection("users")
                      .where("email", "==", subscriberEmail)
                      .get();
                    // console.log(usersRef);

                    if (!usersRef.empty) {
                      usersRef.forEach((snapshot) => {
                        const data = snapshot.data();
                        setSubscribersList((subscribersList) => [
                          ...subscribersList,
                          data,
                        ]);
                      });
                    }
                  });
                }
              });
            }

            const { stripeData } = doc.data();
            setUser(doc.data());

            if (doc.data().isPrepaidUser) {
              setCurrentPlanTitle("Prepaid");
              setCurrentPlanPrice(`N/A`);
              // return;
            }

            if (
              Object.values(stripeData).length &&
              stripeData.status.includes("trial")
            ) {
              setCurrentPlanTitle("Trial");
              setCurrentPlanPrice(
                `\$${stripeData.plan.amount / 100}/${stripeData.plan.interval}`
              );
              // return;
            }

            if (
              Object.values(stripeData).length &&
              stripeData &&
              stripeData?.status === "canceled"
            ) {
              setCurrentPlanPrice(
                `\$${stripeData.plan.amount / 100}/${stripeData.plan.interval}`
              );
              setCurrentPeriodStart("");
              setCurrentPeriodEnd("");
              setCurrentPlanTitle("Not subscribed to any plan!");
            } else {
              if (Object.values(stripeData).length) {
                let { plan, customer } = stripeData;
                if (
                  !plan &&
                  stripeData?.lines &&
                  stripeData?.lines?.data &&
                  stripeData?.lines?.data[0] &&
                  stripeData?.lines?.data[0].plan
                ) {
                  plan = stripeData?.lines?.data[0].plan;
                }
                getPastInvoices({}, customer);

                setStripeData(stripeData);
                setCurrentPlanPrice(`\$${plan.amount / 100}/${plan.interval}`);
                resProduct = await axios.post("/api/retrieve-product", {
                  productId: plan.product,
                  customerId: stripeData?.customer,
                });
                const { product, paymentMethod, invoice } = resProduct.data;

                setPaymentMethod(paymentMethod);

                setCurrentPlanTitle(product.name);

                setUpcomingInvoice(invoice);
              }

              //invoice
              // if (
              //   stripeData?.latest_invoice &&
              //   stripeData?.latest_invoice.status === "paid"
              // ) {
              // } else

              if (
                // stripeData?.latest_invoice &&
                // stripeData?.latest_invoice.status === "open"

                (stripeData?.status &&
                  stripeData?.status === "paid" &&
                  stripeData.amount_paid &&
                  Number(stripeData.amount_paid) > 0) ||
                (stripeData?.status && stripeData?.status.includes("trial"))
              ) {
                let paymentIntentId = null;
                if (stripeData?.payment_intent) {
                  paymentIntentId = stripeData?.payment_intent;
                } else if (stripeData?.latest_invoice.payment_intent) {
                  paymentIntentId = stripeData?.latest_invoice.payment_intent;
                }

                if (paymentIntentId) {
                  const resultPaymentIntent = await axios.post(
                    "/api/retrieve-payment-intent",
                    { paymentIntentId }
                  );
                  const { paymentIntent } = resultPaymentIntent.data;
                  setPaymentIntent(paymentIntent);
                }
              }
            }
          });
        }
      });

    return () => resProduct;
  }, []);

  useEffect(() => {
    if (
      !userDetails &&
      (!userDetails?.isCorporateAdmin ||
        userDetails?.pricingPlan !== "Individual" ||
        !userDetails?.isPrepaidUser)
    ) {
      history.push("/");
    }
    if (
      userDetails &&
      userDetails.stripeData &&
      ["incomplete", "incomplete_expired", "unpaid", "past_due"].includes(
        userDetails.stripeData.status
      )
    ) {
      alert("There was an issue with payment. Please update billing details.");
      dispatch(togglePayment());
      history.push("/");
    }
  }, [userDetails]);

  const convertTimestamp = (time) => {
    const timestamp = new Date(time * 1000);
    const date = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();

    return `${month}/${date}/${year}`;
  };

  const handleCancelClick = (e) => {
    if (userDetails && userDetails.isPrepaidUser) {
      e.preventDefault();
      return;
    }
    cancelSubscription(e);
  };

  const cancelSubscription = async (e) => {
    e.preventDefault();
    const result = window.confirm(
      "Are you sure you want to cancel the subscription?"
    );
    if (result) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post("/api/verify-token", { token });

        const { email } = response.data.decoded;

        if (!email) {
          setAlertType("error");
          setAlertMessage("Email not found");
          setIsAlertOpen(true);
          return;
        }

        const res = await firestore
          .collection("users")
          .where("email", "==", email)
          .get();

        if (!res.empty) {
          res.forEach(async (doc) => {
            const { stripeData } = doc.data();
            const id = doc.id;

            let subscriptionId = null;

            if (!stripeData) {
              alert("Stipe Data Not Found.");
              return;
            } else {
              if (
                stripeData.subscription &&
                stripeData.subscription.includes("sub_")
              ) {
                subscriptionId = stripeData.subscription;
              } else if (stripeData.id && stripeData.id.includes("sub_")) {
                subscriptionId = stripeData.id;
              } else {
                alert("Subscription ID Not Found.");
                return;
              }
            }

            if (!subscriptionId) {
              alert("Subscription ID Not Found.");
              return;
            }

            const res = await axios.post("/api/cancel-subscription", {
              subscriptionId,
            });
            if (res.status === 200) {
              const { deletedSubscription } = res.data;

              setAlertType("success");
              setAlertMessage("Subscription canceled successfully");
              setIsAlertOpen(true);

              await firestore.collection("users").doc(id).update({
                stripeData: deletedSubscription,
                updatedAt: new Date(),
                "package.type": "deleted",
                "package.end": new Date(),
              });
            } else {
              setAlertType("error");
              setAlertMessage("Something went wrong");
              setIsAlertOpen(true);
            }
          });
        } else {
          alert("User not found");
          return;
        }
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Error: " + error.message);
        setIsAlertOpen(true);
        console.log("Error while canceling the subscription", error.message);
      }
    }
  };

  const scrollHandler = (el) => {
    const offsetTop = document.querySelector(`#${el.id}`).offsetTop;

    window.scroll({
      top: offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[createPaymentMethod error]", error);
      setIsAlertOpen(true);
      setAlertType("danger");
      setAlertMessage(`Failed to updated payment method ${error.message}`);
      setProcessing(false);
      cardElement.clear();
    }

    try {
      await axios.post("/api/update-payment-method", {
        paymentMethod,
        customerId: stripeData?.customer,
      });
      setIsAlertOpen(true);
      setAlertType("success");
      setAlertMessage(`Payment method updated successfully`);
      setProcessing(false);
      cardElement.clear();
      setProcessing(false);
    } catch (error) {
      setIsAlertOpen(true);
      setAlertType("danger");
      setAlertMessage(`Failed to updated payment method ${error.message}`);
      setProcessing(false);
      cardElement.clear();
      setProcessing(false);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const closeAddContentModal = () => {
    setAddContentModal(false);
  };

  const closePolicyModal = async () => {
    history.push("/");
    setPolicyModal(false);
    await firestore.collection("users").doc(uid).set(
      {
        agreedOnContentPolicy: false,
      },
      { merge: true }
    );
  };

  const policyButtonHandler = async (buttonType) => {
    switch (buttonType) {
      case "cancel":
        closePolicyModal();
        break;
      case "agree":
        setPolicyModalLoader(true);
        await firestore.collection("users").doc(uid).set(
          {
            agreedOnContentPolicy: true,
          },
          { merge: true }
        );
        setPolicyModal(false);
        setPolicyModalLoader(false);
        setAddContentModal(true);
        break;
      default:
        closePolicyModal();
    }
  };

  async function getAllContent() {
    setLoading(true);
    const snapshot = await firestore
      .collection("corp_a_posts")
      .where("corporateAdminId", "==", uid)
      .get();
    let contentData = snapshot.docs.map((doc) => doc.data());
    setContent(contentData);
    setLoading(false);
  }

  const copyText = () => {
    copyToClipboard(userDetails.redemptionCode);
    setButtonText("Copied!");
    setTimeout(() => {
      setButtonText("Copy To Clipboard");
    }, 5000);
  };

  const closeViewPastInvoicesModal = () => {
    setViewPastInvoicesModal(false);
  };

  const getPastInvoices = async (params, id) => {
    setPastInvoicesLoader(true);
    let obj = {
      ...params,
      customer: id,
    };
    try {
      await axios
        .post("/api/get-past-invoices", {
          ...obj,
        })
        .then((res) => {
          if (res && res.data) {
            setPastInvoices(res.data.data);
            setHasMoreInvoices(res.data.has_more);
            setPastInvoicesLoader(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setPastInvoices([]);
          setPastInvoicesLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getNextInvoices = () => {
    setDisablePrevButton(disablePrevButton + 1);
    let starting_after =
      pastInvoices &&
      pastInvoices.length &&
      pastInvoices[pastInvoices.length - 1].id;
    getPastInvoices({ starting_after }, stripeData?.customer);
  };

  const getPreviousInvoices = () => {
    setDisablePrevButton(disablePrevButton - 1);
    let ending_before = pastInvoices && pastInvoices[0].id;
    getPastInvoices({ ending_before }, stripeData?.customer);
  };

  const downloadFlyer = async () => {
    try {
      if (userDetails && userDetails.isCorporateAdmin) {
        const urlRef = firestore.collection("access").doc("flyerURL");
        const doc = await urlRef.get();
        if (doc.exists) {
          const { url } = doc.data();
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank"; // Open in a new tab
          link.click();
        } else {
          console.log("No such document!");
        }
      } else {
        alert("Unauthorized!");
      }
    } catch (error) {
      console.log("Error downloading flyer", error.message);
    }
  };

  const sendInviteEmail = () => {
    try {
      const recipient = "";
      const subject = encodeURIComponent("Introducing A New Wellness Benefit");
      const body = encodeURIComponent(
        `We are pleased to announce a new wellness benefit for employees!\n\nLeviosa Wellness helps improve total wellness by providing rich content in 15+ categories, from fitness/workouts, yoga, meditation, stress relief, work productivity, virtual vacations, and more.\nUsers have unlimited access to their ever-growing content.\n\nHow to Sign Up\n\n1. Go to www.leviosa.us.\n2. Go to the sign up section, and click on "Have a Redemption Code"\n3. Create account. Copy and paste the redemption code below into the sign up form (do not share with anyone outside the company): ${userDetails?.redemptionCode}\n4. You're now ready to access Leviosa Wellness.\n\nThank you\n\n- Your Benefits team`
      );
      const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    } catch (error) {
      console.log("Error from sendInviteEmail: ", error.message);
    }
  };

  const openUsersModal = () => {
    getSubscribers();
    setUsersModal(true);
  };

  const closeUsersModal = () => setUsersModal(false);

  const getSubscribers = async () => {
    try {
      if (
        !userDetails ||
        !userDetails.isCorporateAdmin ||
        !userDetails.redemptionCode
      ) {
        alert("Invalid user!");
        return;
      }

      const subsData = [];
      setSubscribersData([]);
      setSubscribersLoading(true);

      const redemptionCodeRef = await firestore
        .collection("validRedemptionCodes")
        .where("redemptionCode", "==", userDetails.redemptionCode)
        .get();

      await Promise.all(
        redemptionCodeRef.docs.map(async (snapshots) => {
          const { owner, subscribers } = snapshots.data();

          if (owner && owner === userDetails.email && subscribers) {
            await Promise.all(
              subscribers.map(async (subscriberEmail) => {
                const usersRef = await firestore
                  .collection("users")
                  .where("email", "==", subscriberEmail)
                  .get();

                if (!usersRef.empty) {
                  usersRef.forEach((subscriberSnapshot) => {
                    if (subscriberSnapshot.data().email !== owner) {
                      subsData.push({
                        ...subscriberSnapshot.data(),
                        uid: subscriberSnapshot.id,
                      });
                    }
                  });
                }
              })
            );
          }
        })
      );

      setSubscribersData(subsData);
      setSubscribersLoading(false);
    } catch (error) {
      console.log("Error getting subscribers: ", error.message);
      setSubscribersLoading(false);
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />
      <hr className="my-5 d-none d-md-block" style={{ border: "0px" }} />

      <div className="mt-5 pt-1 container">
        <div className="mt-4 mb-4">
          <h2 style={{ color: "#1e2864" }}>My Account</h2>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div class="card" style={{ width: "inherit" }}>
              <div style={Styles.cardHeader} class="card-header">
                <b>Plan Details</b>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Plan: </div>
                    <div className="col-6 d-flex flex-column">
                      <span>
                        {userDetails && userDetails.isPrepaidUser ? (
                          `Prepaid User Until: 
                          ${
                            vouchDetails
                              ? new Date(
                                  vouchDetails?.validTill?.seconds * 1000
                                ).toDateString()
                              : "-"
                          }
                          `
                        ) : (
                          <>
                            {currentPlanTitle}, {currentPlanPrice}
                          </>
                        )}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                        }}
                      >
                        <Link
                          to={
                            userDetails && userDetails.isPrepaidUser
                              ? "#"
                              : "/#scrollToPricingPlans"
                          }
                          style={
                            userDetails && userDetails.isPrepaidUser
                              ? Styles.disabledPlanActionButtons
                              : Styles.planActionButtons
                          }
                          onClick={(e) =>
                            userDetails &&
                            userDetails.isPrepaidUser &&
                            e.preventDefault()
                          }
                        >
                          Change
                        </Link>{" "}
                        |{" "}
                        <a
                          onClick={handleCancelClick}
                          style={
                            userDetails && userDetails.isPrepaidUser
                              ? Styles.disabledPlanActionButtons
                              : Styles.planActionButtons
                          }
                          href="#"
                        >
                          Cancel
                        </a>
                      </span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Current cycle started: </div>
                    <div className="col-6">
                      {userDetails && userDetails.isPrepaidUser
                        ? new Date(
                            userDetails?.createdAt?.seconds * 1000
                          ).toDateString()
                        : convertTimestamp(
                            pastInvoices[0]?.lines.data[0].period.start
                          )}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Current cycle ends: </div>
                    <div className="col-6">
                      {userDetails && userDetails.isPrepaidUser
                        ? vouchDetails
                          ? new Date(
                              vouchDetails?.validTill?.seconds * 1000
                            ).toDateString()
                          : "-"
                        : convertTimestamp(
                            pastInvoices[0]?.lines.data[0].period.end
                          )}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Last payment: </div>
                    <div className="col-6">
                      {userDetails && userDetails.isPrepaidUser
                        ? "-"
                        : convertTimestamp(
                            pastInvoices[0]?.lines.data[0].period.start
                          )}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Current invoice number: </div>
                    <div className="col-6">
                      {userDetails && userDetails.isPrepaidUser
                        ? "-"
                        : pastInvoices[0]?.number}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Status: </div>
                    <div className="col-6">
                      {userDetails && userDetails.isPrepaidUser ? (
                        <span class="py-1 px-4 badge rounded-pill bg-primary text-light">
                          Prepaid
                        </span>
                      ) : stripeData && stripeData?.cancel_at_period_end ? (
                        <span class="py-1 px-4 badge rounded-pill bg-danger text-light">
                          Cancelled
                        </span>
                      ) : stripeData?.status.includes("trial") ? (
                        <span class="py-1 px-4 badge rounded-pill bg-primary text-light">
                          Free Trial
                        </span>
                      ) : (
                        <>
                          {pastInvoices[0]?.paid ? (
                            <span class="py-1 px-4 badge rounded-pill bg-success text-light">
                              Paid
                            </span>
                          ) : (
                            <span class="py-1 px-4 badge rounded-pill bg-danger text-light">
                              Unpaid
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="row">
                    <div className="col-6">Past Invoices:</div>
                    <div
                      className="col-6"
                      style={{
                        color: "var(--theme-color)",
                        cursor: "pointer",
                        display: "flex",
                      }}
                      onClick={() => {
                        if (userDetails && userDetails.isPrepaidUser) {
                          return;
                        }
                        setViewPastInvoicesModal(true);
                      }}
                    >
                      View Here
                    </div>
                  </div>
                </li>

                {userDetails && userDetails.isPrepaidUser ? (
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Prepaid Subscription Ends:</div>
                      <div className="col-6 d-flex flex-column">
                        <span>
                          {vouchDetails
                            ? new Date(
                                vouchDetails?.validTill?.seconds * 1000
                              ).toDateString()
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                ) : userDetails?.package?.type &&
                  userDetails?.package?.type?.toLowerCase() === "trial" ? (
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Trial Ends:</div>
                      <div className="col-6 d-flex flex-column">
                        <span>
                          {moment(userDetails?.package?.end.toDate()).format(
                            "MM-DD-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </li>
                ) : null}

                {stripeData && stripeData?.cancel_at_period_end ? (
                  <>
                    <li class="list-group-item">
                      <div className="row">
                        <div className="col-6">
                          Cancel at current cycle ends:{" "}
                        </div>
                        <div className="col-6">
                          <span class="py-1 px-4 badge rounded-pill bg-danger text-light">
                            Yes
                          </span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div className="row">
                        <div className="col-6">Subscription canceled on: </div>
                        <div className="col-6">
                          <span class="py-1 px-4 badge rounded-pill bg-danger text-light">
                            {convertTimestamp(stripeData?.canceled_at)}
                          </span>
                        </div>
                      </div>
                    </li>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-between">
            <div
              class="card"
              style={{ width: "inherit", marginBottom: "1rem" }}
            >
              <div style={Styles.cardHeader} class="card-header">
                <b>Card Details</b>
              </div>
              {userDetails && userDetails.isPrepaidUser ? (
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Card:</div>
                      <div className="col-6 d-flex flex-column">N/A</div>
                    </div>
                  </li>
                </ul>
              ) : (
                <>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div className="row">
                        <div className="col-6">Card:</div>
                        <div className="col-6 d-flex flex-column">
                          <span>
                            {paymentMethod
                              ? paymentMethod?.card?.brand[0]?.toUpperCase() +
                                paymentMethod.card.brand.slice(1)
                              : null}
                          </span>
                          <span style={{ fontSize: 13 }}>
                            {paymentMethod
                              ? "XXXX-" + paymentMethod.card.last4
                              : null}
                          </span>

                          <a onClick={toggle} style={Styles.planActionButtons}>
                            Change
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div className="row">
                        <div className="col-6">Expires (mm/yyyy): </div>
                        <div className="col-6">
                          <div className="d-flex flex-column">
                            <div style={{ display: "flex" }}>
                              {paymentMethod
                                ? `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>
            {userDetails && !userDetails.isPrepaidUser && (
              <div class="card" style={{ width: "inherit" }}>
                <div style={Styles.cardHeader} class="card-header">
                  <b>User Management</b>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Redemption Code:</div>

                      {userDetails && (
                        <div className="col-6 d-flex flex-column">
                          <span>{userDetails.redemptionCode}</span>
                          <p
                            onClick={() => copyText()}
                            style={Styles.planActionButtons}
                          >
                            {buttonText}
                          </p>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>

                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Current Users:</div>
                      <div className="col-6 d-flex">
                        <span
                          style={{
                            ...Styles.userInviteButtons,
                            cursor: "pointer",
                            marginRight: "0.5rem",
                          }}
                          onClick={() => {
                            openUsersModal();
                          }}
                        >
                          View Users
                        </span>
                        <>|</>
                        <span
                          style={{
                            ...Styles.userInviteButtons,
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                          }}
                          onClick={() => {
                            history.push("/company-dashboard");
                          }}
                        >
                          View User Stats
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div className="row">
                      <div className="col-6">Invite Users:</div>
                      <div className="col-6">
                        <div className="d-flex flex-column">
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                ...Styles.userInviteButtons,
                                cursor: "pointer",
                                marginRight: "0.5rem",
                              }}
                              onClick={sendInviteEmail}
                            >
                              Invite by Email
                            </span>
                            <>|</>
                            <span
                              style={{
                                ...Styles.userInviteButtons,
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                              }}
                              onClick={downloadFlyer}
                            >
                              Download Flyer
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}

            <Modal
              isOpen={modal}
              toggle={toggle}
              // className={className}
              external={
                <button
                  className="close"
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                  onClick={toggle}
                >
                  &times;
                </button>
              }
              centered
            >
              <div className="modal-top"></div>
              <ModalHeader toggle={toggle}></ModalHeader>
              <ModalBody className="px-3">
                <div className="mb-2" id="stripe">
                  <h4 className="mb-2">Enter new card</h4>
                  <div id="payment-form">
                    <CardElement
                      id="card-element"
                      options={cardStyle}
                      onChange={handleChange}
                    />
                    <button
                      onClick={handleSubmit}
                      disabled={processing || disabled || succeeded}
                      id="submit"
                      className="mt-2"
                    >
                      <span id="button-text">
                        {processing ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Change Card Details"
                        )}
                      </span>
                    </button>
                    {error && (
                      <div className="card-error" role="alert">
                        {error}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-2 mb-4">
                  <div className="col">
                    <Alert
                      isAlertOpen={isAlertOpen}
                      setIsAlertOpen={setIsAlertOpen}
                      alertType={alertType}
                      alertMessage={alertMessage}
                      setAlertType={setAlertType}
                      setAlertMessage={setAlertMessage}
                    />
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <div class="card" style={{ width: "inherit" }}>
              <div style={Styles.cardHeader} class="card-header">
                <b>Site Customization</b>
              </div>
              <SiteCustomization />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div class="card" style={{ width: "inherit" }}>
              <div style={Styles.cardHeaderWithButton} class="card-header">
                <b>My Content</b>
                <button
                  onClick={() => setPolicyModal(true)}
                  className="btn btn-sm btn-light"
                >
                  Add New
                </button>
              </div>
              <MyContent content={content} getAllContent={getAllContent} />
            </div>
          </div>
        </div>

        <p
          className="mb-5 mt-4"
          style={{
            textAlign: "center",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize:
              "calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)))",
            lineHeight: "22px",
            color: "#265376",
          }}
        >
          Payment information is securely housed by and payments are processed
          by Stripe, Inc. using the highest levels of security. For more
          information, please visit them at{" "}
          <a href="https://www.stripe.com" target="_blank">
            www.stripe.com
          </a>
          . Leviosa does not store payment information.
        </p>
      </div>

      {isRedemptionCodeOwner ? (
        <div className="container mt-5">
          <h4 className="text-center">Subscribers</h4>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {subscribersList &&
                subscribersList.map((user) => (
                  <tr key={user.email}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : null}
      {addContentModal && (
        <AddContentModal
          open={addContentModal}
          close={closeAddContentModal}
          getAllContent={getAllContent}
        />
      )}
      {uid && policyModal && (
        <ContentPolicyModal
          open={policyModal}
          close={closePolicyModal}
          buttonClickHandler={policyButtonHandler}
          loading={policyModalLoader}
        />
      )}
      {viewPastInvoicesModal && (
        <PastInvoicesModal
          data={pastInvoices}
          next={getNextInvoices}
          previous={getPreviousInvoices}
          open={viewPastInvoicesModal}
          close={closeViewPastInvoicesModal}
          loading={pastInvoicesLoader}
          hasMoreInvoices={hasMoreInvoices}
          disablePrevButton={disablePrevButton}
        />
      )}
      {usersModal && (
        <CompanyUsersModal
          open={usersModal}
          close={closeUsersModal}
          data={subscribersData}
          getSubs={getSubscribers}
          _loading={subscribersLoading}
          handleSorting={() => {
            alert("Work in progress");
          }}
        />
      )}
      <SmallFooter />
    </div>
  );
};

export default Billing;
