import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertItem = (props) => {
  const {snackBarMessage,snackBarType,isSnackBarOpen,setIsSnackBarOpen} = props;
  return (
    <Alert onClose={(e) => setIsSnackBarOpen(!isSnackBarOpen)} severity={`${snackBarType}`}>{snackBarMessage}</Alert>
  );
}

export default function SnackBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const {snackBarMessage,snackBarType,isSnackBarOpen,setIsSnackBarOpen} = props;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isSnackBarOpen} autoHideDuration={5000} onClose={(e) => setIsSnackBarOpen(!isSnackBarOpen)}>
        <AlertItem
        isSnackBarOpen={isSnackBarOpen}
        setIsSnackBarOpen={setIsSnackBarOpen}
        snackBarType={snackBarType}
        snackBarMessage={snackBarMessage} />
      </Snackbar>
    </div>
  );
}
