import React, { useState, useEffect } from "react";
import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import usePagination from "../hooks/usePagination";
import { firestore } from "../../firebase";

import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("firstName");

  const [tempRows, setTempRows] = useState([]);
  const [searchBy, setSearchBy] = useState("firstName");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  const history = useHistory();

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      setUsers([]);

      const redemptionCodeOwnersRef = await firestore
        .collection("validRedemptionCodes")
        .get();
      redemptionCodeOwnersRef.docs.forEach(async (ownerSnapshots) => {
        const { owner } = ownerSnapshots.data();
        if (owner) {
          const usersRef = await firestore
            .collection("users")
            .where("email", "==", owner)
            .get();
          if (!usersRef.empty) {
            let ownerData = usersRef.docs[0].data();
            ownerData.isBlocked = ownerSnapshots.data().isBlocked;
            setUsers([...users, ownerData]);
          }
        }
      });
    }

    return () => (isMounted = false);
  }, [orderDirection, orderBy]);

  useEffect(() => {
    setFilteredData(
      users.filter((user) => {
        return user[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, users]);

  const convertTimestamp = (time) => {
    const timestamp = new Date(time * 1000);
    const date = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();

    return `${month}-${date}-${year}`;
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("firstName");
        }
        setOrderBy(e.target.value);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("desc");
        }
        setOrderDirection(e.target.value);
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
    }
  };

  const onShowHandler = (redemptionCode) => {
    history.push({
      pathname: "/a/subscriberslist",
      state: {
        redemptionCode: redemptionCode,
      },
    });
  };

  const onBlockHandler = async (redemptionCode) => {
    const validRedemptionCodesRef = await firestore
      .collection("validRedemptionCodes")
      .where("redemptionCode", "==", redemptionCode)
      .get();

    if (!validRedemptionCodesRef.empty) {
      const doc = validRedemptionCodesRef.docs[0];
      const { id } = doc;
      const data = doc.data();

      await firestore
        .collection("validRedemptionCodes")
        .doc(id)
        .update({ isBlocked: !data.isBlocked });

      if (!data.isBlocked) {
        setMessage("User blocked");
        setTimeout(() => setMessage(""), 2000);
      } else {
        setMessage("User unblocked");
        setTimeout(() => setMessage(""), 2000);
      }
    }
  };

  return (
    <>
      <div className="container mb-5">
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => setSearchedData(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="searchBy"
                className="form-control"
              >
                <option value="0">Search By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="email">Email</option>
              </select>
            </div>
          </div>
        </form>
      </div>

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Email</th>
            <th scope="col">Pricing Plan</th>
            <th scope="col">Subscription Status</th>
            <th scope="col">Invoice Status</th>
            <th scope="col">Last Payment Date</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentData() &&
            currentData().map((user) => (
              <tr key={user.uid}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.email}</td>
                <td>{user.pricingPlan}</td>
                <td>{user.stripeData && user.stripeData.status}</td>
                <td>
                  {user.stripeData &&
                    (user.stripeData.paid ||
                      user.stripeData.latest_invoice.status)}
                </td>
                <td>
                  {user.stripeData &&
                  user.package &&
                  user.stripeData.status_transitions &&
                  user.stripeData.status_transitions.paid_at &&
                  user.stripeData.status === "paid"
                    ? convertTimestamp(
                        user.stripeData.status_transitions.paid_at
                      )
                    : user.stripeData && user.package
                    ? "n/a"
                    : user.stripeData &&
                      user.stripeData.latest_invoice.status_transitions.paid_at
                    ? convertTimestamp(
                        user.stripeData.latest_invoice.status_transitions
                          .paid_at
                      )
                    : null}
                </td>
                <td>{user.isBlocked ? "Blocked" : "Active"}</td>
                <td>
                  <button
                    onClick={(e) => onShowHandler(user.redemptionCode)}
                    style={{
                      marginRight: "3px",
                      padding: "3px",
                      border: "0px",
                    }}
                  >
                    <i className="far fa-eye"></i>
                  </button>
                  <button
                    onClick={(e) => onBlockHandler(user.redemptionCode)}
                    style={{
                      marginRight: "3px",
                      padding: "3px",
                      border: "0px",
                    }}
                  >
                    <i className="fa fa-ban"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>

      {message && <Alert color="success">{message}</Alert>}
      {error && <Alert color="danger">{error}</Alert>}
    </>
  );
};

export default Users;
