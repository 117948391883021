import React from "react";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
function SortAscAndDesc({
  onClickAsc,
  onClickDesc,
  title,
  isAscending = false,
  isActive = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <span>{title}</span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip placement="top" title="Ascending">
          <ArrowDropUp
            className={`sort-icon ${isActive && isAscending && "active"}`}
            onClick={onClickAsc}
          />
        </Tooltip>
        <Tooltip title="Descending">
          <ArrowDropDown
            className={`sort-icon ${isActive && !isAscending && "active"}`}
            onClick={onClickDesc}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default SortAscAndDesc;
