import React, { useState, useEffect } from "react";
import "./Navbar.css";

import { renderToStaticMarkup } from "react-dom/server";

import { Alert, Spinner } from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../contexts/AuthContext";

// import store actions
import { toggleSignin, toggleSignup } from "../../store/actions/indexActions";

const SvgRect = () => {
  const history = useHistory();

  return (
    <svg
      className="logo_svg"
      viewBox="0 0 532 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="158.5"
        cy="13"
        rx="164.5"
        ry="127"
        fill="url(#paint0_linear)"
      />
      <ellipse
        cx="384.5"
        cy="-4.5"
        rx="147.5"
        ry="122.5"
        fill="url(#paint1_linear)"
      />
      <ellipse cx="248" cy="77" rx="136" ry="88" fill="url(#paint2_linear)" />
      <g
        style={{ cursor: "pointer", pointerEvents: "bounding-box" }}
        onClick={() => history.push("/")}
      >
        <path
          d="M150.572 97H169.42V92.82H155.512V70.4H150.572V97ZM183.2 92.858V85.524H196.044V81.458H183.2V74.542H197.678V70.4H178.26V97H198.21V92.858H183.2ZM227.666 70.4L218.736 90.92L209.882 70.4H204.524L216.152 97H221.016L232.606 70.4H227.666ZM240.353 97H245.293V70.4H240.353V97ZM269.854 97.38C278.1 97.38 284.18 91.604 284.18 83.7C284.18 75.796 278.1 70.02 269.854 70.02C261.608 70.02 255.528 75.834 255.528 83.7C255.528 91.566 261.608 97.38 269.854 97.38ZM269.854 93.048C264.496 93.048 260.506 89.134 260.506 83.7C260.506 78.266 264.496 74.352 269.854 74.352C275.212 74.352 279.202 78.266 279.202 83.7C279.202 89.134 275.212 93.048 269.854 93.048ZM302.602 97.38C309.898 97.38 313.356 93.732 313.356 89.476C313.356 79.71 297.586 83.396 297.586 77.734C297.586 75.72 299.258 74.124 303.362 74.124C305.68 74.124 308.302 74.808 310.696 76.214L312.254 72.376C309.936 70.856 306.592 70.02 303.4 70.02C296.104 70.02 292.684 73.668 292.684 77.962C292.684 87.842 308.492 84.08 308.492 89.818C308.492 91.794 306.744 93.276 302.602 93.276C299.334 93.276 295.952 92.06 293.824 90.35L292.114 94.188C294.318 96.088 298.46 97.38 302.602 97.38ZM343.106 97H348.274L336.266 70.4H331.402L319.432 97H324.524L327.146 90.844H340.446L343.106 97ZM328.818 86.968L333.796 75.34L338.812 86.968H328.818Z"
          fill="white"
        />
        <path
          d="M241.558 34.9182C242.056 32.8869 244.944 32.8869 245.442 34.9183L246.49 39.1879C246.722 40.1361 247.606 40.7778 248.579 40.706L252.964 40.3827C255.05 40.2289 255.942 42.9758 254.164 44.0774L250.427 46.3929C249.597 46.9071 249.26 47.9454 249.629 48.8492L251.291 52.9191C252.082 54.8554 249.745 56.553 248.148 55.2025L244.791 52.364C244.046 51.7336 242.954 51.7336 242.209 52.364L238.852 55.2025C237.255 56.553 234.918 54.8554 235.709 52.9191L237.371 48.8492C237.74 47.9454 237.403 46.9071 236.573 46.3929L232.836 44.0774C231.058 42.9758 231.95 40.2289 234.036 40.3827L238.421 40.706C239.394 40.7778 240.278 40.1361 240.51 39.1879L241.558 34.9182Z"
          fill="white"
        />
      </g>

      <defs>
        <linearGradient
          id="paint0_linear"
          x1="145.698"
          y1="4.80263"
          x2="198.963"
          y2="78.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6144D6" />
          <stop offset="1" stopColor="var(--theme-color)" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="373.021"
          y1="-12.4069"
          x2="425.461"
          y2="55.0406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6144D6" />
          <stop offset="1" stopColor="var(--theme-color)" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="237.416"
          y1="71.3199"
          x2="271.867"
          y2="128.192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6144D6" />
          <stop offset="1" stopColor="var(--theme-color)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Navbar = ({ fromSales = false, fromSalesAuth = false }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const { currentUser, logout, isAdmin, issalesPerson, userDetails } =
    useAuth();
  const history = useHistory();
  const { color: customThemeColor } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const theme = useSelector(({ theme }) => theme);

  const handleLogOut = async () => {
    setError("");
    setIsLoading(true);
    try {
      const redirectURL =
        currentUser && issalesPerson ? "/salesportal/login" : "/welcome";
      await logout();
      localStorage.removeItem("userEmail");
      setIsLoading(false);
      history.push(redirectURL);
    } catch {
      setError("Failed to log out");
    } finally {
      setIsLoading(false);
    }
  };

  const onClickScrollHandler = (e) => {
    e.preventDefault();
    const href = e.target.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;

    window.scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  // redirect to /welcome screen
  const logoutAndRedirect = async () => {
    if (currentUser && issalesPerson) {
      await logout();
      localStorage.removeItem("userEmail");
      setIsLoading(false);
      history.push("/welcome/sign-in");
    } else return;
  };

  return (
    <>
      <div id="nav_section" className="position-relative">
        {isLoading ? (
          <div className="spinner_container">
            <Spinner />
          </div>
        ) : null}

        <div className="nav-container position-relative">
          <nav className="navbar navbar-expand-md navbar-light">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i
                  className="fas fa-bars"
                  style={{ color: "var(--theme-color)", fontSize: "28px" }}
                ></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                {currentUser && !fromSales ? (
                  <>
                    <Link className="nav-link" to="/home">
                      Home
                    </Link>
                    {userDetails && (
                      <Link className="nav-link" to="/dashboard">
                        Dashboard
                      </Link>
                    )}
                    {userDetails &&
                    (userDetails?.isCorporateAdmin ||
                      userDetails?.pricingPlan === "Individual" ||
                      userDetails.isPrepaidUser) ? (
                      <Link className="nav-link" to="/billing">
                        Account
                      </Link>
                    ) : null}
                  </>
                ) : null}
                {currentUser && isAdmin && !fromSales ? (
                  <Link className="nav-link" to="/a/categories">
                    Admin
                  </Link>
                ) : null}

                {currentUser && issalesPerson && fromSales ? (
                  <a
                    onClick={logoutAndRedirect}
                    className="btn nav-link btn_signup"
                    {...(customThemeColor && {
                      style: {
                        background: customThemeColor,
                        color: "white",
                      },
                    })}
                  >
                    Main Site
                  </a>
                ) : null}

                {currentUser ? (
                  <a
                    onClick={() => handleLogOut()}
                    className="btn nav-link btn_signup"
                    {...(customThemeColor && {
                      style: {
                        background: customThemeColor,
                        color: "white",
                      },
                    })}
                  >
                    Log Out
                  </a>
                ) : !fromSalesAuth ? (
                  <>
                    <a
                      onClick={() => dispatch(toggleSignin())}
                      className="btn nav-link btn_login"
                    >
                      Log In
                    </a>
                    <a
                      onClick={(e) => onClickScrollHandler(e)}
                      href="#scrollToPricingPlans"
                      className="btn nav-link btn_signup"
                    >
                      Sign Up
                    </a>
                  </>
                ) : null}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <a href="#"></a>

      {!theme?.logo && !theme.color && (
        <img
          src={require("../../assets/logo_svg_cloud_2.svg").default}
          alt="logo_svg_cloud_2"
          className="logo_svg_cloud_2 d-none d-md-block"
        />
      )}

      {theme?.logo ? (
        <img
          style={{ top: 30 }}
          className="logo_svg custom_logo"
          src={theme.logo}
        />
      ) : (
        <SvgRect />
      )}
    </>
  );
};

export default Navbar;
