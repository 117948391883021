import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import "./Pricing.css";
import PriceCard from "../PriceCard/PriceCard";
import { useFirestoreGetAllPricings } from "../../hooks/useFirestore";
import { Spinner } from "reactstrap";

import {
  toggleSignup,
  selectPricePlan,
} from "../../store/actions/indexActions";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function SimpleAlerts() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onClickHandler = (e) => {
    dispatch(toggleSignup());
    dispatch(selectPricePlan({}));
  };

  return (
    <div className={classes.root}>
      <Alert
        id="pricingAlertId"
        style={{ backgroundColor: "var(--theme-color-light2)" }}
        severity="info"
      >
        Have a redemption code?{" "}
        <span
          style={{ cursor: "pointer", fontWeight: "600", color: "#600170" }}
          onClick={(e) => onClickHandler(e)}
        >
          Click here to sign up<span style={{ marginLeft: "3px" }}>!</span>
        </span>
      </Alert>
    </div>
  );
}

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const initValues = {
  bottomSectionHeading: "",
  bottomSectionParagraph: "",
};

const Pricing = ({ pageType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [values, setValues] = useState({ ...initValues });

  const { pricings } = useFirestoreGetAllPricings(setIsLoading);

  useEffect(() => {
    setIsMounted(true);
    setIsLoading(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    let __collectionName =
      pageType === "businesses" ? "index" : "individualIndex";

    const unsub = firestore
      .collection(__collectionName)
      .doc("page")
      .get()
      .then((res) => {
        const data = res.data();
        setValues({ ...data });
      });
    return () => unsub;
  }, []);

  return (
    <section id="pricing_section">
      <hr className="my-4" style={{ borderTtop: "1px solid #fff" }} />

      <div className="row price_svg_people_container position-relative">
        <div className="col-md-2 d-block d-sm-block"></div>

        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center price_svg_people_text_container_col">
          <div className="text_container price_svg_people_text_container">
            <h3>{values.bottomSectionHeading}</h3>
            <hr className="my-3" style={{ borderTtop: "1px solid #fff" }} />
            <p>{values.bottomSectionParagraph}</p>
          </div>
        </div>

        <div className="col-12 col-md-6 price_svg_people_img_container_col">
          <div className="price_svg_people_img_container">
            <img
              src="./assets/index/price_svg_people.svg"
              alt="price_svg_people"
              className="price_svg_people img-fluid w-100"
            />
          </div>
        </div>

        <img
          src="./assets/index/mem_sm/price_svg_people.svg"
          alt="price_svg_people"
          className="price_svg_people_sm d-block d-md-none"
        />
      </div>

      <div className="container">
        <hr className="mt-5" style={{ borderTop: "1px solid #fff0" }} />

        <div className="section_title text-center">
          <h3 id="scrollToPricingPlans">Pricing Plans</h3>
          <div className="text_container mt-2">
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize:
                  "calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)))",
                lineHeight: "22px",
                color: "#265376",
              }}
            >
              Free Trial for 7 Days!
            </p>
          </div>
        </div>
        <hr className="mb-4" style={{ borderTop: "1px solid #fff0" }} />

        <div className="pricing_simple_alerts row">
          <div className="col-12">
            <SimpleAlerts />
          </div>
        </div>

        <div
          id="pricingPlans"
          className="row pricing_cards_container position-relative mt-3 mx-auto"
        >
          <img
            src="./assets/index/price_svg_cloud.svg"
            alt="price_svg_cloud"
            className="price_svg_cloud d-none d-md-block"
          />

          {pricings &&
            pricings.map((plan, key) => {
              return <PriceCard key={plan.id} plan={plan} cardNumber={key} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
