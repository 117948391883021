import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MultiLineTextField from "./MultiLineTextField";
import SingleLineTextField from "./SingleLineTextField";
import SaveButton from "./SaveButton";
import UploadButton from "./UploadButton";
import TileSwitch from "./TileSwitch";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  //for text fields
  const {values,setValues,onChangeHandler,onClickHandler,tileSectionFile,setTileSectionFile} = props;

  const [previewImageFileSrc,setPreviewImageFileSrc] = useState("");

  useEffect(() => {
    tileSectionFile && previewImageFile(tileSectionFile);
  },[tileSectionFile])

  const previewImageFile = (tileSectionFile) => {
      const reader = new FileReader();
      reader.readAsDataURL(tileSectionFile);
      reader.onloadend = () => {
          setPreviewImageFileSrc(reader.result);
      }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {/*<Tab label="Main Section" {...a11yProps(1)} />*/}
          <Tab label="Tile Section" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >


       


        <TabPanel value={value} index={0} dir={theme.direction}>
          <TileSwitch
          onChangeHandler={onChangeHandler}
          values={values}
           />
          <SingleLineTextField
          name="tileSectionHeading"
          values={values}
          label="Header Text"
          onChangeHandler={onChangeHandler}
           />
          <MultiLineTextField
          onChangeHandler={onChangeHandler}
          label="Description"
          name="tileSectionParagraph"
          values={values}
           />
          <SingleLineTextField
          name="tileSectionPostUrl"
          values={values}
          label="Tile URL"
          onChangeHandler={onChangeHandler}
           />

           <UploadButton
           setTileSectionFile={setTileSectionFile}
            />


        {previewImageFileSrc && (
            <img src={previewImageFileSrc} alt="chosen" style={{height:"300px"}} />
            )}

          <SaveButton
          onClickHandler={onClickHandler}
          name="tileSectionButton"
           />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
