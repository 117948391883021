import React, { useState, useEffect } from "react";
import { useFirestoreAdventuresGET } from "../hooks/useAdventures";

import { firebase, firestore, storage, timestamp } from "../../firebase";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { selectAdventure } from "../../store/actions/adminActions";
import usePagination from "../hooks/usePagination";

import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import ContentCreatorRowEditor from "./ContentCreators/ContentCreatorRowEditor";
import ContentCreatorRow from "./ContentCreators/ContentCreatorRow";

const initValues = {
  title: "",
  description: "",
};

const ContentCreators = () => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [values, setValues] = useState({ ...initValues });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedContentCreatorId, setSelectedContentCreatorId] =
    useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [tempRows, setTempRows] = useState([]);
  const [searchBy, setSearchBy] = useState("name");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");

  const [contentCreators, setContentCreators] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  const [settings, setSettings] = useState({
    orderBy: "rowNumber",
    orderDirection: "asc",
  });

  const [isSetOrderSettings, setIsSetOrderSettings] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const unsub = firestore
      .collection("m_content_creators")
      .orderBy(orderBy, orderDirection)
      .onSnapshot((snapshot) => {
        setContentCreators([]);
        snapshot.forEach((doc) => {
          setContentCreators((contentCreators) => [
            ...contentCreators,
            { id: doc.id, ...doc.data() },
          ]);
        });
        setIsLoading(false);
      });
    return () => unsub;
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setFilteredData(
      contentCreators.filter((contentCreator) => {
        return contentCreator[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, contentCreators]);

  // console.log(filteredData)

  const onAddNewHandler = () => {
    setIsAddNew(true);
  };
  const onEditHandler = (contentCreatorId, contentCreator) => {
    setIsEdit(true);
    setSelectedContentCreatorId(contentCreatorId);
    setValues({ ...contentCreator });
  };

  const onDeleteHandler = async (contentCreatorId, contentCreator) => {
    setIsDelete(true);
    setIsLoading(true);
    if (isMounted) {
      const result = window.confirm("Are you sure you want to delete it?");
      if (!result) {
        return;
      } else {
        try {
          const doc = await firestore
            .collection("m_content_creators")
            .doc(contentCreatorId);

          const contentCreatorPosts = await firestore
            .collection("m_posts")
            .where("contentCreator", "==", contentCreatorId)
            .get();
          if (!contentCreatorPosts.empty) {
            // empty content creator from the assigned posts
            await Promise.all(
              contentCreatorPosts.docs.map((eachPost) => {
                return eachPost.ref.update({
                  contentCreator: "",
                });
              })
            );
          }
          await doc.delete();
          setMessage("Data deleted successfully");
          setTimeout(() => setMessage(""), 2000);
        } catch (e) {
          console.log(e);
          setError("Data delete failed");
          setTimeout(() => setError(""), 2000);
        } finally {
          setIsDelete(false);
          setValues(initValues);
          setIsLoading(false);
        }
      }
    }
  };

  const onSaveHandler = async () => {
    if (isAddNew) {
      const times = Date.now();

      await firestore
        .collection("m_content_creators")
        .doc()
        .set({
          ...values,
          created: timestamp(),
          updated: timestamp(),
        });
      setMessage("Data added successfully");
      setTimeout(() => setMessage(""), 2000);
      setIsAddNew(false);
      setValues(initValues);
      setIsLoading(false);
    }
    ////////////////////////////

    if (isEdit) {
      const doc = await firestore
        .collection("m_content_creators")
        .doc(selectedContentCreatorId)
        .get();
      const res = await doc.data();

      const times = Date.now();

      await firestore
        .collection("m_content_creators")
        .doc(selectedContentCreatorId)
        .update({
          ...values,
          updated: timestamp(),
        });
      setMessage("Data added successfully");
      setTimeout(() => setMessage(""), 2000);
      setIsEdit(false);
      setValues(initValues);
      setIsLoading(false);
    }

    ////////////////////////////////////
  };

  const onCancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Content Creators</a>
          </li>
        </ol>
      </nav>

      <div className="position-relative">
        {isLoading && (
          <div style={spinnerDivStyleSheet}>
            <Spinner style={spinnerStyleSheet} />
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Website URL</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentData() &&
              currentData().map((contentCreator) => (
                <ContentCreatorRow
                  name={contentCreator.name}
                  description={contentCreator.description}
                  website={contentCreator.website}
                  id={contentCreator.id}
                  contentCreator={contentCreator}
                  key={contentCreator.id}
                  onDeleteHandler={onDeleteHandler}
                  onEditHandler={onEditHandler}
                  onValueChangeHandler={onValueChangeHandler}
                  selectedContentCreatorId={selectedContentCreatorId}
                  values={values}
                  isEdit={isEdit}
                />
              ))}
            {isAddNew ? (
              <tr>
                <ContentCreatorRowEditor
                  values={values}
                  onValueChangeHandler={onValueChangeHandler}
                />
              </tr>
            ) : null}
          </tbody>
        </table>
        <div>
          {isAddNew || isEdit ? (
            <>
              <button
                onClick={() => onSaveHandler()}
                className="btn btn-sm btn-primary"
              >
                Save
              </button>
              <button
                onClick={() => onCancelHandler()}
                className="btn btn-sm btn-warning mx-3"
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => onAddNewHandler()}
              className="btn btn-sm btn-primary"
            >
              Add New
            </button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default ContentCreators;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
