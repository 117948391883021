import React,{useEffect,useState} from 'react';
import Navbar from "../components/Navbar/Navbar";
import SmallFooter from "../components/SmallFooter/SmallFooter";

import { useHistory } from "react-router-dom";

import SearchResultItem from "../components/SearchResultItem/SearchResultItem";

import { firestore } from "../firebase";

const stylesheet = {
  overflow: "hidden"
}

const contentLengthsList = [{type:"contentLength",title:"<5 mins",value:"<5 mins"},{type:"contentLength",title:"<10 mins",value:"<10 mins"},{type:"contentLength",title:"<15 mins",value:"<15 mins"},
                            {type:"contentLength",title:"<25 mins",value:"<25 mins"},{type:"contentLength",title:"<30 mins",value:"<30 mins"},{type:"contentLength",title:"30+ mins",value:"30+ mins"},
                            {type:"contentLength",title:"45+ mins",value:"45+ mins"},{type:"contentLength",title:"1+ Hr",value:"1+ Hr"}]
const audiencesList = [{type:"audience",title:"All",value:"All"},{type:"audience",title:"Kids",value:"Kids"},{type:"audience",title:"Adults",value:"Adults"}]
const userPhasesList = [{type:"userPhase",title:"Beginner",value:"Beginner"},{type:"userPhase",title:"Intermediate",value:"Intermediate"},{type:"userPhase",title:"Advanced",value:"Advanced"}]
const contentTypesList = [{type:"contentType",title:"Webpage",value:"externalWebpage"},{type:"contentType",title:"Audio",value:"audio"},
                      {type:"contentType",title:"Image",value:"image"},{type:"contentType",title:"Document",value:"externalDoc"},{type:"contentType",title:"Video",value:"video"}];

const SearchResult = () => {

  const history = useHistory();

  const [rootPostResults,setRootPostResults] = useState([]);
  const [postResults,setPostResults] = useState([]);
  const [rowResults,setRowResults] = useState([]);

  const [selectedFilter,setSelectedFilter] = useState([]);
  const [adventuresList,setAdventuresList] = useState([]);

  useEffect(async () => {
    if (!history.location.state) {
      return
    }
    if(history.location.state && history.location.state.postResults || history.location.state.rowResults){
      setRootPostResults([...history.location.state.postResults]);
      setPostResults([...history.location.state.postResults]);
      setRowResults([...history.location.state.rowResults]);
    }

    let tempArr = [];

    const adventuresRef = await firestore.collection("m_adventures").get();
    adventuresRef.docs.forEach(d => d.id !== 'settings' && tempArr.push(d.data()));
    setAdventuresList([...tempArr]);

    tempArr = [];

  },[])

  useEffect(() => {
    if (rootPostResults.length !== 0 && selectedFilter.length === 0) {
      setPostResults([...rootPostResults]);
      return;
    }

    
    
    let tempArr = [];
    let adventureIdExists = false;

    let tempArrForAdventureExists = [];
    let prevArr = [];

    

    selectedFilter.forEach(filter => {
      if (filter.type === "adventureId") {
        adventureIdExists = true;
        tempArr = rootPostResults.filter(post => {
          if(filter.value === 'application' || filter.value === 'externalDoc'){
            return (post[`${filter.type}`] === 'externalDoc' || post[`${filter.type}`] === "application")
          }
         return post[`${filter.type}`] === filter.value || post[`${filter.type}`].includes(filter.value.charAt(0).toUpperCase() + filter.value.slice(1))
        }
        );
        return;
      }
    })

    selectedFilter.forEach(filter => {
      if (adventureIdExists) {
        if(filter.type === "adventureId"){
          
        }else{
          tempArrForAdventureExists = [...tempArrForAdventureExists,...tempArr.filter(post => {
            if(filter.value === 'application' || filter.value === 'externalDoc'){
              return (post[`${filter.type}`] === 'externalDoc' || post[`${filter.type}`] === "application")
            }
            return !tempArrForAdventureExists.includes(post) && ((post[`${filter.type}`] === filter.value) || post[`${filter.type}`].includes(filter.value.charAt(0).toUpperCase() + filter.value.slice(1)))
          }
          )]
        }
      }else{
        prevArr = [...tempArrForAdventureExists];
        tempArrForAdventureExists = rootPostResults.filter(post => {
          if(tempArrForAdventureExists.includes(post)){

          }else{

            if(filter.value === 'application' || filter.value === 'externalDoc'){
              return (post[`${filter.type}`] === 'externalDoc' || post[`${filter.type}`] === "application")
            }

            return ((post[`${filter.type}`] === filter.value) || post[`${filter.type}`].includes(filter.value.charAt(0).toUpperCase() + filter.value.slice(1)));
          }
        })
      }
    })

    console.log("tempArrForAdventureExists",tempArrForAdventureExists);

    

    if (adventureIdExists) {
      if (selectedFilter.length === 1) {
        setPostResults([...tempArr,...tempArrForAdventureExists]);
      }else{
        setPostResults([...tempArrForAdventureExists]);
      }
      
      adventureIdExists = false;
    }else{
      if (prevArr.length === 0 && tempArrForAdventureExists.length === 0 && selectedFilter.length === 0) {
        return
      }
      setPostResults([...prevArr,...tempArrForAdventureExists]);
    }

    

    
  },[selectedFilter])



  const onChangeSetFilterHandler = e => {
    if (e.target.name === "adventureId") {
      let adventure = selectedFilter.find(filter => filter.type === e.target.name);  
      let index = selectedFilter.indexOf(adventure)
      if (index > -1) {
        document.querySelector(`#${selectedFilter[index].value}`).checked = false;
        selectedFilter.splice(index,1);
      }
    }
    if (e.target.checked) {
      setSelectedFilter([...selectedFilter,{
        type: e.target.name,
        value: e.target.value,
      }])
      // console.log(e.target.name,e.target.value);
    }else{
      setSelectedFilter(selectedFilter.filter(item => item.value !== e.target.value))
    }
  }

  // console.log(postResults);

  return (
    <>
      <Navbar />

      <div className="container">
        <div>
          <h2>Results</h2>
        </div>

        {/* FILTER */}
        <div className="mt-4">
          <h5>Filter by</h5>

          <form className="mt-3">
          <div class="form-group row">
              <div className="col-2">
                <h6>Adventure</h6>
              </div>
              <div className="col-10">
                <div className="row">
                  {
                    adventuresList.map(a => (
                      <div class="form-check col-md-2">
                        <input onChange={e => onChangeSetFilterHandler(e)} 
                        name="adventureId"
                         class="form-check-input" type="checkbox" id={`${a.id}`} value={`${a.id}`} />
                        <label class="form-check-label" for="gridCheck">
                          {a.title}
                        </label>
                      </div>
                    ))
                  }

                </div>

              </div>              
            </div>
            

            <div className="form-group row">
              <div className="col-2">
                <h6>Content Type</h6>
              </div>
              <div className="col-10">
                

              <div className="row">
                  {
                    contentTypesList.map(c => (
                      <div class="form-check col-md-2">
                        <input onChange={e => onChangeSetFilterHandler(e)} 
                        name="contentType"
                         class="form-check-input" type="checkbox" value={`${c.value}`} />
                        <label class="form-check-label" for="gridCheck">
                          {c.title}
                        </label>
                      </div>
                    ))
                  }

                </div>

              </div>
            </div>

            <div className="form-group row">
              <div className="col-2">
                <h6>User phase</h6>
              </div>
              <div className="col-10">
                

              <div className="row">
                  {
                    userPhasesList.map(u => (
                      <div class="form-check col-md-2">
                        <input onChange={e => onChangeSetFilterHandler(e)} 
                        name="userPhase"
                         class="form-check-input" type="checkbox" value={`${u.value}`} />
                        <label class="form-check-label" for="gridCheck">
                          {u.title}
                        </label>
                      </div>
                    ))
                  }

                </div>

              </div>
            </div>

            <div className="form-group row">
              <div className="col-2">
                <h6>Audience</h6>
              </div>
              <div className="col-10">
                

              <div className="row">
                  {
                    audiencesList.map(a => (
                      <div class="form-check col-md-2">
                        <input onChange={e => onChangeSetFilterHandler(e)} 
                        name="audience"
                         class="form-check-input" type="checkbox" value={`${a.value}`} />
                        <label class="form-check-label" for="gridCheck">
                          {a.title}
                        </label>
                      </div>
                    ))
                  }

                </div>

              </div>
            </div>

            <div className="form-group row">
              <div className="col-2">
                <h6>Content length</h6>
              </div>
              <div className="col-10">
                

              <div className="row">
                  {
                    contentLengthsList.map(c => (
                      <div class="form-check col-md-2">
                        <input onChange={e => onChangeSetFilterHandler(e)} 
                        name="contentLength"
                         class="form-check-input" type="checkbox" value={`${c.value}`} />
                        <label class="form-check-label" for="gridCheck">
                          {c.title}
                        </label>
                      </div>
                    ))
                  }

                </div>

              </div>
            </div>

          </form>
        </div>

        {/* FILTER */}

        <div className="row">
          {
            postResults.map(postResult => (
              <div className="col-md-3">
                <SearchResultItem
                  type="featured"
                  adventureId={postResult.adventureId}
                  post={postResult}
                  likedPosts={[]}
                  watchedPosts={[]}
                />
              </div>
            ))
          }
        </div>
        
      </div>

        
       <SmallFooter />

    </>
  );
}

export default SearchResult;
