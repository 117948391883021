import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";
import PostLink from "../PostLink/PostLink";

function PostItem({ likedPost, onClickHeartHandler }) {
  return (
    <tr>
      <td className="img" colSpan={1} align="center">
        <PostLink
          post={likedPost}
          rowId={likedPost.rowId}
          postId={likedPost.postId}
          adventureId={likedPost.adventureId}
        >
          <div className="post-thumbnail-container">
            <img src={likedPost?.thumbnailUrl} />
          </div>
        </PostLink>
      </td>
      <th scope="row">{likedPost.adventureTitle}</th>
      <td>{likedPost.rowTitle}</td>
      <td>
        <Link
          to={`/adventure/${likedPost.adventureId}/${likedPost.rowId}/${likedPost.postId}`}
        >
          {likedPost.postTitle}
        </Link>
      </td>
      <td>
        {moment(
          new Date((likedPost?.dateAdded?._seconds || 1625147662) * 1000)
        ).format("MMM DD, YYYY")}
      </td>
      <td>
        <button
          style={{ position: "unset" }}
          onClick={() =>
            onClickHeartHandler({
              adventureId: likedPost.adventureId,
              rowId: likedPost.rowId,
              postId: likedPost.postId,
              ...(likedPost.date ? { date: likedPost.date } : null),
            })
          }
          className="heartBtn ml-auto"
        >
          {<i class="fas fa-heart"></i>}
        </button>
      </td>
    </tr>
  );
}

export default PostItem;
