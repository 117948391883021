import React from "react";
import TextBody from "./TextBody";
import Paper from "./Paper";

const ImageViewer = ({fileUrl,contentType,post}) => {
  return (
    <>
      <div className="container">
          <Paper>
          <div className="d-flex justify-content-center">
            <img className="card-img-top" src={`${fileUrl}`} alt="Card image cap" style={{maxWidth:"500px",objectFit:"contain"}} />
          </div>

            <div className="textBody">
              <TextBody post={post} />
            </div>
          </Paper>
      </div>
    </>
  );
}

export default ImageViewer;


