import React, { useState, useEffect } from "react";
import { firestore, storage, timestamp } from "../../firebase";
import AdminIndexTabs from "./AdminIndex/AdminIndexTabs";
import Spinner from "./AdminIndex/Spinner";
import Snackbar from "./AdminIndex/SnackBar";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const initValues = {
  topSectionHeading: "",
  topSectionParagraph: "",
  topSectionBtnText: "",
  topSectionBtnLink: "",
  midSectionHeading: "",
  midSectionParagraph: "",
  midSectionBtnText: "",
  midSectionBtnLink: "",

  lowerMidSectionHeading: "",
  lowerMidSectionParagraph: "",
  lowerMidSectionBtnText: "",
  lowerMidSectionBtnLink: "",
  lowerMidSectionImageSrc: "",
  lowerMidSectionFileUrl: "",
  lowerMidSectionFileFullPath: "",

  bottomSectionHeading: "",
  bottomSectionParagraph: "",
  terms: "",
};

const AdminIndex = () => {
  const [values, setValues] = useState({ ...initValues });

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarType, setSnackBarType] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [lowerMidSectionFile, setLowerMidSectionFile] = useState(null);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // console.log(values.terms);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(async () => {
    const unsub = await firestore.collection("index").doc("page").get();
    const data = unsub.data();
    setValues({ ...data });

    return () => unsub;
  }, []);

  const onClickHandler = async (e) => {
    switch (e.currentTarget.getAttribute("name")) {
      case "topSectionButton":
        setIsLoading(true);
        setMessage("");
        firestore
          .collection("index")
          .doc("page")
          .set(
            {
              topSectionHeading: values.topSectionHeading,
              topSectionParagraph: values.topSectionParagraph,
              topSectionBtnText: values.topSectionBtnText,
              topSectionBtnLink: values.topSectionBtnLink,
            },
            { merge: true }
          )
          .then((res) => {
            setIsLoading(false);

            setSnackBarType("success");
            setSnackBarMessage("Updated Successfully");
            setIsSnackBarOpen(true);
            // setMessage("Updated Successfully");
          })
          .catch((err) => {
            setIsLoading(false);

            setSnackBarType("error");
            setSnackBarMessage("Update Failed");
            setIsSnackBarOpen(true);
          });
        break;
      case "midSectionButton":
        setIsLoading(true);
        setMessage("");
        firestore
          .collection("index")
          .doc("page")
          .set(
            {
              midSectionHeading: values.midSectionHeading,
              midSectionParagraph: values.midSectionParagraph,
              midSectionBtnText: values.midSectionBtnText,
              midSectionBtnLink: values.midSectionBtnLink,
            },
            { merge: true }
          )
          .then((res) => {
            setIsLoading(false);

            setSnackBarType("success");
            setSnackBarMessage("Updated Successfully");
            setIsSnackBarOpen(true);
          })
          .catch((err) => {
            setMessage("");
            setMessage("Update Failed");
          });
        break;
      case "lowerMidSectionButton":
        setIsLoading(true);
        setMessage("");

        if (lowerMidSectionFile) {
          const doc = await firestore.collection("index").doc("page").get();
          const res = await doc.data();

          if (res && res.lowerMidSectionFileUrl) {
            setIsLoading(true);
            const { lowerMidSectionFileFullPath } = res;
            const storageRefOld = storage.ref(lowerMidSectionFileFullPath);
            await storageRefOld.delete();

            const times = Date.now();
            const storageRef = storage.ref(
              `adventures/${lowerMidSectionFile.name}-${times}`
            );

            storageRef.put(lowerMidSectionFile).on(
              "state_changed",
              (snap) => {
                let percentage =
                  (snap.bytesTransferred / snap.totalBytes) * 100;
                // setProgress(percentage);
              },
              (err) => {
                setIsLoading(false);
                setSnackBarType("error");
                setSnackBarMessage("Update Failed");
                setIsSnackBarOpen(true);
              },
              async () => {
                const url = await storageRef.getDownloadURL();
                const metadata = await storageRef.getMetadata();
                const fullPath = metadata.fullPath;
                await firestore
                  .collection("index")
                  .doc("page")
                  .set(
                    {
                      ...values,
                      lowerMidSectionFileUrl: url,
                      lowerMidSectionFileFullPath: fullPath,
                      created: timestamp(),
                    },
                    { merge: true }
                  );
                setSnackBarType("success");
                setSnackBarMessage("Updated Successfully");
                setIsSnackBarOpen(true);

                setLowerMidSectionFile(null);
                setIsLoading(false);
              }
            );
          } else {
            const times = Date.now();
            const storageRef = storage.ref(
              `adventures/${lowerMidSectionFile.name}-${times}`
            );

            storageRef.put(lowerMidSectionFile).on(
              "state_changed",
              (snap) => {
                let percentage =
                  (snap.bytesTransferred / snap.totalBytes) * 100;
                // setProgress(percentage);
              },
              (err) => {
                setIsLoading(false);
                setSnackBarType("error");
                setSnackBarMessage("Update Failed");
                setIsSnackBarOpen(true);
              },
              async () => {
                const url = await storageRef.getDownloadURL();
                const metadata = await storageRef.getMetadata();
                const fullPath = metadata.fullPath;
                await firestore
                  .collection("index")
                  .doc("page")
                  .set(
                    {
                      ...values,
                      lowerMidSectionFileUrl: url,
                      lowerMidSectionFileFullPath: fullPath,
                      created: timestamp(),
                    },
                    { merge: true }
                  );
                setSnackBarType("success");
                setSnackBarMessage("Updated Successfully");
                setIsSnackBarOpen(true);

                setLowerMidSectionFile(null);
                setIsLoading(false);
              }
            );
          }
        } else {
          ///
          firestore
            .collection("index")
            .doc("page")
            .set(
              {
                lowerMidSectionHeading: values.lowerMidSectionHeading,
                lowerMidSectionParagraph: values.lowerMidSectionParagraph,
                lowerMidSectionBtnText: values.lowerMidSectionBtnText,
                lowerMidSectionBtnLink: values.lowerMidSectionBtnLink,
                lowerMidSectionFileUrl: values.lowerMidSectionFileUrl,
                lowerMidSectionFileFullPath: values.lowerMidSectionFileFullPath,
              },
              { merge: true }
            )
            .then((res) => {
              setIsLoading(false);

              setSnackBarType("success");
              setSnackBarMessage("Updated Successfully");
              setIsSnackBarOpen(true);
            })
            .catch((err) => {
              setIsLoading(false);

              setSnackBarType("error");
              setSnackBarMessage("Update Failed");
              setIsSnackBarOpen(true);
            });
          ////
        }
        break;
      case "bottomSectionButton":
        setIsLoading(true);
        setMessage("");
        firestore
          .collection("index")
          .doc("page")
          .set(
            {
              bottomSectionHeading: values.bottomSectionHeading,
              bottomSectionParagraph: values.bottomSectionParagraph,
            },
            { merge: true }
          )
          .then((res) => {
            setIsLoading(false);

            setSnackBarType("success");
            setSnackBarMessage("Updated Successfully");
            setIsSnackBarOpen(true);
          })
          .catch((err) => {
            setIsLoading(false);

            setSnackBarType("error");
            setSnackBarMessage("Update Failed");
            setIsSnackBarOpen(true);
          });
        break;
      case "termsButton":
        setIsLoading(true);
        setMessage("");
        firestore
          .collection("index")
          .doc("page")
          .set(
            {
              terms: values.terms,
            },
            { merge: true }
          )
          .then((res) => {
            setIsLoading(false);

            setSnackBarType("success");
            setSnackBarMessage("Updated Successfully");
            setIsSnackBarOpen(true);
          })
          .catch((err) => {
            setIsLoading(false);

            setSnackBarType("error");
            setSnackBarMessage("Update Failed");
            setIsSnackBarOpen(true);
          });
        break;
      default:
        console.error("Nothing Matched");
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            background: "#f5f5f5",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "100",
            opacity: "0.7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : null}

      <Snackbar
        isSnackBarOpen={isSnackBarOpen}
        setIsSnackBarOpen={setIsSnackBarOpen}
        snackBarMessage={snackBarMessage}
        snackBarType={snackBarType}
      />

      <div className="mb-4">
        <h4>Business Index</h4>
      </div>

      <AdminIndexTabs
        values={values}
        setValues={setValues}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        setLowerMidSectionFile={setLowerMidSectionFile}
        lowerMidSectionFile={lowerMidSectionFile}
      />
    </>
  );
};

export default AdminIndex;
