import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import VideoPlayer from "../components/ContentPlayers/VideoPlayer";
import AudioPlayer from "../components/ContentPlayers/AudioPlayer";
import PdfViewer from "../components/ContentPlayers/PdfViewer";
import ImageViewer from "../components/ContentPlayers/ImageViewer";

import { firestore, firebase, auth } from "../firebase";
import ShareModal from "../components/ShareModal/ShareModal";
import { useDispatch } from "react-redux";
import { toggleShare } from "../store/actions/indexActions";
import { useSelector } from "react-redux";

const ViewContent = () => {
  const history = useHistory();
  const [likedPosts, setLikedPosts] = useState([]);
  const [post, setPost] = useState({});
  const [contentCreator, setContentCreator] = useState({});
  const [contentType, setContentType] = useState("");
  const dispatch = useDispatch();
  const { selectedAdventure } = useSelector((state) => state.index);
  useEffect(async () => {
    let isMounted = true;
    const pathname = history.location.pathname.split("/");
    const adventureId = pathname[2];
    const rowId = pathname[3];
    const postId = pathname[4];

    const postRef = firestore.collection("m_posts").doc(postId);
    let postData = await postRef.get();
    if (!postData.exists) {
      const corpAdminPostRef = firestore.collection("corp_a_posts").doc(postId);
      postData = await corpAdminPostRef.get();
    }

    // if content creator exist for the post then fetch it's info
    if (postData.data()?.contentCreator)
      firestore
        .collection("m_content_creators")
        .doc(postData.data()?.contentCreator)
        .get()
        .then((res) => setContentCreator(res.data()));

    setPost(postData.data());

    const mimeType = postData.data().contentType;
    const contentType = mimeType.split("/")[0];
    setContentType(contentType);

    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${auth?.currentUser?.email}`)
      .get();

    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;
      setLikedPosts(snapshot.data()?.likedPosts || []);
      postRef.update({
        views: [
          ...(postData.data().views || []),
          {
            userId: snapshot.id,
            pricingPlan: snapshot.data()?.pricingPlan,
          },
        ],
      });

      let date = firebase.firestore.Timestamp.now();
      // if (!snapshot.data().watchedPost) {
      const arrUnion = documentRef.update({
        watchedPosts: firebase.firestore.FieldValue.arrayUnion({
          adventureId,
          rowId,
          postId,
          views: 1,
          date,
        }),
      });
      // }

      // let prevWatchPostsIndex = (
      //   snapshot.data()?.watchedPosts || []
      // )?.findIndex((watchedPost) => watchedPost.postId === postId);

      // if (snapshot.data().watchedPosts && prevWatchPostsIndex === -1) {
      //   const arrUnion = documentRef.update({
      //     watchedPosts: firebase.firestore.FieldValue.arrayUnion({
      //       adventureId,
      //       rowId,
      //       postId,
      //       views: 1,
      //       date,
      //     }),
      //   });
      // } else {
      //   let prevWatchPostsList = [...(snapshot.data()?.watchedPosts || [])];
      //   prevWatchPostsList[prevWatchPostsIndex].views =
      //     ++prevWatchPostsList[prevWatchPostsIndex].views || 1;
      //   const arrUnion = documentRef.update({
      //     watchedPosts: prevWatchPostsList,
      //   });
      // }
    }

    return () => (isMounted = false);
  }, []);

  const onClickHandler = () => {
    history.goBack();
  };
  const stylesheetdiv = {
    height: "100%",
    display: "flex",
    alignItems: "center",
  };

  const onclickShareHandler = async (e) => {
    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0].data();
      localStorage.setItem("firstName", snapshot.firstName);
    }
    dispatch(toggleShare());
  };

  const onClickHeartHandler = async (e) => {
    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;
      let likedPosts = querySnapshot.docs[0].data().likedPosts || [];
      let date = firebase.firestore.Timestamp.now();
      let isAlreadyLikedPost = likedPosts.find(
        (likedPost) => likedPost.postId === post.id
      );

      if (isAlreadyLikedPost) {
        setLikedPosts((prev) =>
          prev.filter((likedPost) => likedPost.postId !== post.id)
        );
        const arrRemove = await documentRef.update({
          likedPosts:
            firebase.firestore.FieldValue.arrayRemove(isAlreadyLikedPost),
        });
      } else {
        setLikedPosts((prev) => [...prev, { postId: post.id }]);
        const arrUnion = await documentRef.update({
          likedPosts: firebase.firestore.FieldValue.arrayUnion({
            adventureId: selectedAdventure.id,
            rowId: post.rowId,
            postId: post.id,
            date,
          }),
        });
      }

      // const userData = await firestore
      //   .collection("users")
      //   .where("email", "==", `${u_email}`)
      //   .get();
      // setLikedPosts(userData.docs[0].data()?.likedPosts || []);
    }
  };

  return (
    <>
      <div
        style={{ background: "#f5f5f5", height: "100%" }}
        className="position-relative"
      >
        <button
          onClick={() => onClickHandler()}
          id="single_content_arrow_icon_button"
        >
          <i
            class="fas fa-arrow-circle-left fa-3x"
            id="single_content_arrow_icon"
          ></i>
        </button>
        <div className="container" style={stylesheetdiv}>
          {
            {
              image: (
                <ImageViewer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              video: (
                <VideoPlayer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              audio: (
                <AudioPlayer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              application: (
                <PdfViewer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              externalWebpage: (
                <PdfViewer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              externalVideo: (
                <VideoPlayer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              externalDoc: (
                <PdfViewer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              externalImage: (
                <ImageViewer
                  post={{
                    ...post,
                    contentCreator,
                    onclickShareHandler,
                    likedPosts,
                    onClickHeartHandler,
                  }}
                  fileUrl={post.fileUrl}
                />
              ),
              externalAudio: (
                <AudioPlayer
                  post={{ ...post, contentCreator, onclickShareHandler }}
                  fileUrl={post.fileUrl}
                />
              ),
            }[contentType]
          }
        </div>

        <div id="single_content_leviosa_logo">
          <img src="/assets/leviosa.svg" alt="logo" />
        </div>

        <ShareModal
          contentTitle={post?.title}
          postId={post?.id}
          adventureId={post?.adventureId}
          rowId={post?.rowId}
        />
      </div>
    </>
  );
};

export default ViewContent;
