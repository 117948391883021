import React from "react";

import {useSelector} from "react-redux";


const ViewSinglePost = () => {

  const {selectedPost,selectedAdventure, selectedAdventureRow} = useSelector(state => state.admin);

  

  return (
    <>
    <div className="container">
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Title</th>
          <td>{selectedPost.post.title}</td>
        </tr>
        <tr>
          <th scope="row">Category</th>
          <td>{selectedPost.post.category}</td>
        </tr>
        <tr>
          <th scope="row">Audience</th>
          <td>{selectedPost.post.audience}</td>
        </tr>
        <tr>
          <th scope="row">User Phase</th>
          <td>{selectedPost.post.userPhase}</td>
        </tr>
        <tr>
          <th scope="row">Thumbnail Url</th>
          <td>{selectedPost.post.thumbnailUrl}</td>
        </tr>
        <tr>
          <th scope="row">Thumbnail Full Path</th>
          <td>{selectedPost.post.thumbnailFullPath}</td>
        </tr>
        <tr>
          <th scope="row">File Url</th>
          <td style={{wordBreak: "break-all"}}>{selectedPost.post.fileUrl}</td>
        </tr>

        <tr>
          <th scope="row">File Full Path</th>
          <td>{selectedPost.post.fileFullPath}</td>
        </tr>

        <tr>
          <th scope="row">File Type</th>
          <td>{selectedPost.post.contentType}</td>
        </tr>

        <tr>
          <th scope="row">Post URL</th>
          <td style={{wordBreak: "break-all"}}>
            {`https://leviosa.us/adventure/${selectedAdventure.id}/${selectedAdventureRow.id}/${selectedPost.post.id}`}
          </td>
        </tr>

        <tr>
          <th scope="row">Created</th>
          <td>{selectedPost.post.created.toDate().toString()}</td>
        </tr>



      </tbody>
    </table>
    </div>
    </>
  );
}

export default ViewSinglePost;
