import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import {Link} from "react-router-dom";

import {useDispatch} from "react-redux";
import {toggleSignup} from "../../store/actions/indexActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function TransitionAlerts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {setIsAlertOpen,alertType,alertMessage,selectedPlanDetails,redemptionCode,isShowCustomAlert,setIsShowCustomAlert} = props;

  const onClickHandler = (e) => {
    e.preventDefault();

    const href = e.target.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;

    window.scroll({
      top: offsetTop,
      behavior: "smooth"
    });

    dispatch(toggleSignup());
  }

  return (
    <div className={classes.root}>
      <Collapse in={isShowCustomAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsShowCustomAlert(!isShowCustomAlert);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="filled"
          severity={`${alertType}`}
        >
          {alertMessage}
            <a onClick={(e) => onClickHandler(e)} href="#scrollToPricingPlans" className="ml-2" style={{color: "#37406f",fontWeight: "600"}}>Check here</a>

        </Alert>
      </Collapse>
    </div>
  );
}
