import React from "react";


const CompanyTable = ({children,onAddNewHandler,onChangeFilteringHandler,setSearchedData}) => {
  const onSubmitHandler = (e) => {
    e.preventDefault();
  }
  return (
    <>


    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-3">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="title">Title</option>
          <option value="companyContactName">Company Contact Name</option>
          <option value="companyContactEmail">Company Email</option>
        </select>
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="orderBy" className="form-control">
          <option value="0">Order By</option>
          <option value="companyName">Company Name</option>
          <option value="companyContactEmail">Company Email</option>
          <option value="companySubLevel">Subs Level</option>
          <option value="companyMaxUsers">Max Users</option>
          <option value="created">Date</option>
        </select>
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="orderDirection" className="form-control">
          <option value="0">Order Direction</option>
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        </div>

      </div>
    </form>

    </div>

    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Company Name</th>
          <th scope="col">Email</th>
          <th scope="col">Contact Phone</th>
          <th scope="col">Sub. Level</th>
          <th scope="col">Max. Users</th>
          <th scope="col">Sub. Amount</th>
          <th scope="col">...</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          children
        }
      </tbody>
      <thead>
        <tr>
        <th scope="col">Company Name</th>
        <th scope="col">Email</th>
        <th scope="col">Contact Phone</th>
        <th scope="col">Sub. Level</th>
        <th scope="col">Max. Users</th>
        <th scope="col">Sub. Amount</th>
        <th scope="col">...</th>
        <th scope="col">Actions</th>
        </tr>
      </thead>
      </table>

         <button
         onClick={() => onAddNewHandler()}
         className="btn btn-sm btn-primary">Add New</button>

      </>



  );
}

export default CompanyTable;
