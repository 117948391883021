import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./PaymentFormModal.css";

import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";

import { useSelector, useDispatch } from "react-redux";

import { togglePayment } from "../../store/actions/indexActions";
import { useAuth } from "../../contexts/AuthContext";
import { isStatusGranted } from "../../util/helpers";

const PaymentFormModal = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { userDetails } = useAuth();

  const { isPaymentModalOpened, userInfo } = useSelector(
    (state) => state.index
  );

  useEffect(() => {
    setModal(isPaymentModalOpened);
  }, []);

  useEffect(() => {
    setModal(isPaymentModalOpened);
  }, [isPaymentModalOpened]);

  const toggle = () => {
    dispatch(togglePayment());
  };

  const isPaidUser = () => {
    if (
      userDetails &&
      ((userDetails.stripeData &&
        userDetails.stripeData.status &&
        isStatusGranted(userDetails.stripeData.status)) ||
        userDetails.isPrepaidUser)
    )
      return true;

    return false;
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="container">
            <h2>Payment</h2>
            <div className="d-flex align-items-center">
              <p>
                {isPaidUser()
                  ? `You will not be charged until the end of the free trial`
                  : `Your subscription has expired. Please enter payment details to continue`}
              </p>
            </div>
            <CheckoutForm
              email={userInfo.email}
              firstName={userInfo.firstName}
              lastName={userInfo.lastName}
              phoneNumber={userInfo.phoneNumber}
              pricePlan={userInfo.title}
              amount={userInfo.price}
              stripePriceId={userInfo.stripePriceId}
              stripeProductId={userInfo.stripeProductId}
              isSingleUser={userInfo.isSingleUser}
              numberOfUsers={userInfo.numberOfUsers}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentFormModal;
