import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Spinner } from "reactstrap";

import Alert from "../Alert";

import "./SignInForm.css";

import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase";

import { useDispatch } from "react-redux";
import { toggleSignin, toggleSignup } from "../../store/actions/indexActions";
import axios from "axios";

const signInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars min"),
});

const forgotPassSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const SignInForm = (props) => {
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const disptach = useDispatch();

  const [isForgotPass, setIsForgotPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const history = useHistory();
  const { login, resetPassword, isAdmin, issalesPerson } = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [corporateAdminError, setCorporateAdminError] = useState(false);
  const [corporateAdminData, setCorporateAdminData] = useState({});

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <>
      {isForgotPass ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPassSchema}
          onSubmit={async (values) => {
            // console.log(values);

            const { email } = values;

            try {
              setIsLoading(true);
              await resetPassword(email);
              setIsAlertOpen(true);
              setAlertType("success");
              setAlertMessage("Check your inbox for further instructions");
            } catch {
              setIsLoading(false);
              setIsAlertOpen(true);
              setAlertType("error");
              setAlertMessage("Failed to reset password");
            } finally {
              if (isMounted) {
                setIsLoading(false);
              }
            }
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <div className="container">
                {isLoading ? (
                  <div className="spinner_container">
                    <Spinner />
                  </div>
                ) : null}

                <h2>Reset Password</h2>
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Email Address"
                      className={
                        errors.email && touched.email
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="row mt-2">
                    <div className="col">
                      <Alert
                        isAlertOpen={isAlertOpen}
                        setIsAlertOpen={setIsAlertOpen}
                        alertType={alertType}
                        alertMessage={alertMessage}
                        setAlertType={setAlertType}
                        setAlertMessage={setAlertMessage}
                      />
                    </div>
                  </div>

                  <div className="row my-4">
                    <div className="col-8">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid)
                            ? "disabled-btn customBtn"
                            : "customBtn"
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={signInSchema}
          onSubmit={async (values) => {
            // console.log(values);

            const { email, password } = values;

            try {
              setIsLoading(true);

              // check user access who has a corporate admin
              const response = await axios.post("/api/check-user-access", {
                email,
              });

              if (!response.data || response.data.error) {
                setIsLoading(false);
                setIsAlertOpen(true);
                setAlertType("error");
                if (response.data.isCorporateAdminNotActive) {
                  setCorporateAdminError(true);
                  setCorporateAdminData(response.data.corporateAdminData);
                } else setAlertMessage(response.data.message);
                setIsLoading(false);
                return;
              }

              const { user } = await login(email, password);
              const { claims } = await user.getIdTokenResult();

              disptach(toggleSignin());

              if (claims.type === "admin") {
                history.push("/a/categories");
              } else if (
                claims.type === "salesPerson" ||
                claims.type === "isSalesPerson"
              ) {
                history.push(`/salesportal/dashboard/${user.uid}`);
              } else if (history.location.state) {
                history.push(`${history.location.state.target}`);
              } else {
                history.push("/home");
              }
            } catch (e) {
              console.log(e);
              setIsLoading(false);
              setIsAlertOpen(true);
              setAlertType("error");
              setAlertMessage(e.message);
            }
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <div className="container">
                {isLoading ? (
                  <div className="spinner_container">
                    <Spinner />
                  </div>
                ) : null}

                <h2>Log In</h2>
                <p className="mb-3">Log in to get started</p>
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Email Address"
                      className={
                        errors.email && touched.email
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Password"
                      className={
                        errors.password && touched.password
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="row mt-2">
                    <div className="col">
                      <Alert
                        isAlertOpen={isAlertOpen}
                        setIsAlertOpen={setIsAlertOpen}
                        alertType={alertType}
                        alertMessage={
                          corporateAdminError ? (
                            <span>
                              Oops! Looks like the master account for your group
                              plan isn’t active any more. Please contact your
                              corporate admin{" "}
                              {corporateAdminData?.firstName ? (
                                <b>
                                  {" "}
                                  {corporateAdminData?.firstName}{" "}
                                  {corporateAdminData?.lastName} at{" "}
                                  {corporateAdminData?.email}{" "}
                                </b>
                              ) : null}
                              . Once this account is reenabled, your access will
                              resume. Alternatively, you can create a new
                              Individual account by visiting{" "}
                              <Link to="/">www.leviosa.us</Link>
                            </span>
                          ) : (
                            alertMessage
                          )
                        }
                        setAlertType={setAlertType}
                        setAlertMessage={setAlertMessage}
                      />
                    </div>
                  </div>

                  <div className="row my-4">
                    <div className="col-8">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid)
                            ? "disabled-btn customBtn"
                            : "customBtn"
                        }
                      >
                        Log In
                      </button>
                    </div>
                    <div className="col-4">
                      <a
                        onClick={() => setIsForgotPass(!isForgotPass)}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#600170",
                        }}
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12">
                      <p>Don't have an account? Create with</p>
                      <a
                        onClick={() =>
                          disptach(toggleSignin()).then((res) =>
                            disptach(toggleSignup())
                          )
                        }
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#600170",
                        }}
                      >
                        Sign up
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default SignInForm;
