import React,{useState,useEffect} from "react";

import {Alert, Spinner} from "reactstrap";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";

import {useAuth} from "../../contexts/AuthContext";
import {firestore,timestamp,firebase} from "../../firebase";


const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom:"0",
  background: "#00000047",
  zIndex: "100"
}

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100"
}

const signInSchema = Yup.object().shape({
  userName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("User name is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars min"),
  confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
});

const initialValues = {
  userName:"",
  email: "",
  password: "",
  confirmPassword: ""
}

const AccessControl = () => {

  const [values,setValues] = useState({uid: ""})
  const [message,setMessage] = useState("");
  const [error,setError] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const {signup,logout} = useAuth();

  const [accessUsers,setAccessUsers] = useState([]);

  useEffect(async () => {
    setAccessUsers([]);
    const arr = [];
    const userRef = firestore.collection('access');
    const userSnapshot = await userRef.get();
    userSnapshot.forEach((user) => {
      arr.push(user.data())
    });
    setAccessUsers(accessUsers => [...accessUsers,...arr]);

    return () => userSnapshot;
  },[])


  const onSubmitHandler = (e) => {
    e.preventDefault();
  }

  const onChangeHandler = (e) => {
    const {name,value} = e.target;
    setValues({
      ...values,
      [name]: value
    });
  }

  const onClickBtnHandler = (e) => {
    switch (e.target.getAttribute('name')) {
      case "adminBtn":
      setIsLoading(true);
      axios.post('/api/setAdmin',{...values}).then(async res => {
        try {
          await firestore.collection("access").doc(values.uid).update({role:"Admin"});
        } catch (e) {
          console.log(e);
        } finally {
          setMessage(res.data.message);
          setIsLoading(false);
        }
      }).catch(err => {
        setIsLoading(false);
        setError(err.message);
      })
        break;
      case "salesPersonBtn":
        setIsLoading(true);
        axios.post('/api/setSalesPerson',{...values}).then(async res => {
          try {
            await firestore.collection("access").doc(values.uid).update({role:"Sales Person"});
          } catch (e) {
            console.log(e);
          } finally {
            setMessage(res.data.message);
            setIsLoading(false);
          }
        }).catch(err => {
          setIsLoading(false);
          setError(err.message);
        })
        break;
      case "userBtn":
      setIsLoading(true);
      axios.post('/api/removeAuthority',{...values}).then(async res => {
        try {
          await firestore.collection("access").doc(values.uid).update({role:"N/A"});
        } catch (e) {
          console.log(e);
        } finally {
          setMessage(res.data.message);
          setIsLoading(false);
        }
      }).catch(err => {
        setIsLoading(false);
        setError(err.message);
      })
        break;
      default:
        return ;
    }
    setTimeout(() => setMessage(""),3000);
    setTimeout(() => setError(""),3000);
  }

  return (
    <>
    <div className="container">
      { isLoading && <div style={spinnerDivStyleSheet}><Spinner style={spinnerStyleSheet} /></div> }

      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-8">
          <Formik
          initialValues={initialValues}
          validationSchema={signInSchema}
          onSubmit={async (values) => {

            const {userName,email,password} = values;

            signup(values.email,values.password).then(res => {
              return firestore.collection('access').doc(res.user.uid).set({
                uid: res.user.uid,
                userName,
                email,
                role: "",
                createdAt: timestamp()
              })
            }).catch(e => {
              setError(e.message);
              setIsLoading(false);
            })



          }}
          >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <div className="container">

              <div className="col-8"><h5>Generate account for panel access</h5></div>

              {error ? <Alert color="danger">{error}</Alert> : null}
              { isLoading ?
                <div className="spinner_container"><Spinner /></div> : null
              }
              <Form>
              <div className="form-group">
              <Field
                type="text"
                name="userName"
                id="userName"
                placeholder="User Name"
                className={
                  errors.userName && touched.userName ? "input-error form-control" : "form-control"
                }
              />
              <ErrorMessage name="userName" component="span" className="error" />
              </div>

              <div className="form-group">
              <Field
              type="email"
              name="email"
              id="email"
              placeholder="Enter Email Address"
              className={
                errors.email && touched.email ? "input-error form-control" : "form-control"
              }
              />
              <ErrorMessage name="email" component="span" className="error" />
              </div>

              <div className="form-row">
              <div className="col">
              <Field
              type="password"
              name="password"
              id="password"
              placeholder="Enter Password"
              className={
                errors.password && touched.password ? "input-error form-control" : "form-control"
              }
              />
              <ErrorMessage
              name="password"
              component="span"
              className="error"
              />
              </div>

              <div className="col">
              <Field
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                className={
                  errors.confirmPassword && touched.confirmPassword ? "input-error form-control" : "form-control"
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="span"
                className="error"
              />
              </div>

              </div>

              <div className="row my-2">
              <div className="col-8">
              <button
              type="submit"
              className={!(dirty && isValid) ? "disabled-btn" : "btn btn-primary btn-sm"}
              >
              Generate Account
              </button>
              </div>
              </div>


              </Form>
              </div>
            );
          }}
          </Formik>
        </div>
      </div>


      <div className="row mt-5 d-flex justify-content-center align-items-center">
        <div className="col-8">

        <form onSubmit={(e) => onSubmitHandler(e)}>
          <div className="form-row">
            <div className="col">
              <label>Provide a user id to set him/his role.</label>
              <input onChange={(e) => onChangeHandler(e)} name="uid" type="text" className="form-control" placeholder="uID" />
              </div>
            </div>
          </form>

          <div className="btn-group" role="group" style={{marginTop:"10px"}}>
            <button name="adminBtn" onClick={(e) => onClickBtnHandler(e)} className="btn btn-primary btn-md">Set as Admin</button>
            <button name="salesPersonBtn" onClick={(e) => onClickBtnHandler(e)} className="btn btn-warning btn-md">Set as Sales Person</button>
            <button name="userBtn" onClick={(e) => onClickBtnHandler(e)} className="btn btn-danger btn-md">Set as User</button>
          </div>

          <div className="mt-3">
            {message ? <Alert color="success">{message}</Alert> : null}
            {error ? <Alert color="danger">{error}</Alert> : null}
          </div>


        </div>
      </div>


      <div className="row d-flex justify-content-center align-items-center mt-5">
        <div className="col-10">
        <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">uid</th>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
              </tr>
            </thead>
            <tbody>

            {
              accessUsers && accessUsers.map(user => {
                return (
                  <tr key={user.uid}>
                    <th scope="row">{user.uid}</th>
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                  </tr>
                );
              })
            }


            </tbody>
            </table>
        </div>
      </div>



    </div>
    </>
  );
}

export default AccessControl;
