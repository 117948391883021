import React,{useState} from "react";
import TextBody from "./TextBody";
import Paper from "./Paper";
import Spinner from "./Spinner";

import {useHistory} from "react-router-dom";

const PdfViewer = ({fileUrl,post}) => {

  const [isLoading,setIsLoading] = useState(true);

  const history = useHistory();



  return (
    <>

    <div className="container">
    <Paper>
        <div className="position-relative">
        {
          isLoading ?
          <div style={{
            position: "absolute",
            background: "#f5f5f5",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "100",
            opacity: "0.7",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}><Spinner /></div>
          : null
        }

          <embed
          onLoad={() => setIsLoading(false)}
          style={{width:"100%",height:"75vh"}}
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`}
          ></embed>
        </div>


        <div className="textBody">
          <TextBody post={post} />
        </div>
    </Paper>
    </div>

    </>
  );
}

export default PdfViewer;



// src={`${fileUrl}`}

// import React from "react";
// import TextBody from "./TextBody";
//
// const PdfViewer = ({fileUrl,post}) => {
//
//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col">
//           <iframe
//           style={{width:"100%",height:"100vh"}}
//           src={`${fileUrl}`}
//           ></iframe>
//         </div>
//       </div>
//       <div className="row">
//
//
//       <div class="card" style={{width: "100%"}}>
//         <div class="card-body">
//         <TextBody post={post} />
//         </div>
//       </div>
//
//       </div>
//     </div>
//   );
// }
//
// export default PdfViewer;
