import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFirestoreGetAllAdventuresById } from "../../hooks/useFirestore";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [error, setError] = useState("");
  const { logout, isAdmin } = useAuth();
  const history = useHistory();

  const sidebarRef = useRef(null);
  const { isSidebarToggled } = useSelector((state) => state.admin);

  const { adventures } = useFirestoreGetAllAdventuresById(setIsLoading);

  useEffect(() => {
    setIsMounted(true);
    sidebarRef.current.classList.add("active");
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (sidebarRef.current.classList.value === "")
      sidebarRef.current.classList.add("active");
    else sidebarRef.current.classList.remove("active");
  }, [isSidebarToggled]);

  const handleLogout = async () => {
    setError("");
    setIsLoading(true);
    try {
      await logout();
      setIsLoading(false);
      history.push("/");
    } catch {
      setError("Failed to log out");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <nav id="sidebar" ref={sidebarRef}>
        <div className="sidebar_container">
          <div className="sidebar-header">
            <h3>Leviosa - Admin Interface</h3>
          </div>

          <ul className="list-unstyled components">
            {isAdmin ? <p>Admin Panel</p> : <p>Sales Panel</p>}

            {isAdmin ? (
              <li>
                <Link to="/a/categories">Categories</Link>
              </li>
            ) : null}
            {isAdmin ? (
              <li>
                <Link to="/a/content-creators">Content Creators</Link>
              </li>
            ) : null}
            {isAdmin ? (
              <li>
                <Link to="/a/gurus">Gurus</Link>
              </li>
            ) : null}
            {isAdmin ? (
              <li>
                <Link to="/a/check-in">Mood Check</Link>
              </li>
            ) : null}

            <li>
              <Link to="/a/pricings">Pricing</Link>
            </li>

            {isAdmin ? (
              <li className="active">
                <a
                  href="#pageMenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  Pages
                </a>
                <ul className="collapse list-unstyled" id="pageMenu">
                  <li>
                    <Link to="/a/p/index">Business Index</Link>
                  </li>
                  <li>
                    <Link to="/a/p/individual-index">Individual Index</Link>
                  </li>
                  <li>
                    <Link to="/a/p/home">Home</Link>
                  </li>
                </ul>
              </li>
            ) : null}

            <li>
              <Link to="/a/company_details">Company details</Link>
            </li>
            <li>
              <Link to="/a/users">Users</Link>
            </li>
            {isAdmin ? (
              <li>
                <Link to="/a/prepaid-users">Prepaid Users</Link>
              </li>
            ) : null}
            {isAdmin ? (
              <li>
                <Link to="/a/sales-people">Sales People</Link>
              </li>
            ) : null}

            <li>
              <Link to="/a/subscribers">Subscribers</Link>
            </li>
          </ul>

          <ul className="list-unstyled CTAs">
            {isAdmin ? (
              <li>
                <Link to="/a/access_control">Access Control</Link>
              </li>
            ) : null}
            <li>
              <Link to="/">Visit Frontend</Link>
            </li>
            <li>
              <a
                onClick={() => handleLogout()}
                style={{ cursor: "pointer" }}
                className="article"
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
