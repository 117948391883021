export const selectAdventure = (adventure) => dispatch => {
  dispatch({
    type: "SELECT_ADVENTURE",
    payload: adventure
  })
}

export const selectAdventureRow = (row) => dispatch => {
  dispatch({
    type: "SELECT_ADVENTURE_ROW",
    payload: row
  })
}

export const selectPost = (post) => dispatch => {
  dispatch({
    type: "SELECT_POST",
    payload: {post}
  })
}
