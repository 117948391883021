import React, { useState } from "react";
import SmallFooter from "../components/SmallFooter/SmallFooter";
import { Alert, Spinner } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAuth } from "../contexts/AuthContext";
import CustomAlert from "../components/Alert";
import Navbar from "../components/Navbar/Navbar";

const PrepaidUserLogin = () => {
  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const forgotPassSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const [error, setError] = useState({
    error: false,
    errorMessage: "Something went wrong",
  });
  const [loading, setLoading] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { login, resetPassword } = useAuth();

  const history = useHistory();

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const { email, password } = values;
      const response = await axios.post("/api/check-user-access", {
        email,
      });
      if (!response.data || response.data.error) {
        setError({ error: true, errorMessage: "Something went wrong" });
        return;
      }

      const { user } = await login(email, password);
      const { claims } = await user.getIdTokenResult();
      const unAuthorizedClaims = ["admin", "salesPerson", "isSalesPerson"];

      if (unAuthorizedClaims.includes(claims.type)) {
        history.push("/");
      } else if (history.location.state) {
        history.push(`${history.location.state.target}`);
      } else {
        history.push("/home");
      }

      setLoading(false);
    } catch (err) {
      console.log("Error from submit handler:", err.message);
      setLoading(false);

      setError({
        error: true,
        errorMessage: err.message,
      });
    }
  };

  const forgotPasswordSubmitHandler = async (values) => {
    try {
      const { email } = values;

      setLoading(true);
      await resetPassword(email);
      setLoading(false);
      setAlertType("success");
      setAlertMessage("Check your Email for further instructions");
      setIsAlertOpen(true);
    } catch (error) {
      console.log("error: ", error.message);
      setLoading(false);
      setAlertType("error");
      setAlertMessage(error.message);
      setIsAlertOpen(true);
    }
  };
  return (
    <>
      <Navbar fromSalesAuth={true} />

      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        {error.error && (
          <Alert
            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            color="danger"
          >
            <h6 style={{ margin: 0 }}>Error: {error.errorMessage}</h6>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={loginSchema}
          onChange={() => {}}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty, values } = formik;
            return (
              <div className="container" id="signupForm">
                {loading ? (
                  <div className="spinner_container">
                    <Spinner />
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-6 mb-5">
                    <h2>Prepaid User Login</h2>
                  </div>
                </div>

                <Form>
                  <div className="form-row">
                    <div className="form-group col-6">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Email Address"
                        className={
                          errors.email && touched.email
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label htmlFor="password">Password</label>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter Password"
                        className={
                          errors.password && touched.password
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3rem",
                    }}
                  >
                    <div>
                      <button
                        type="submit"
                        className="customBtn"
                        style={{
                          cursor: !(dirty && isValid)
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={!(dirty && isValid)}
                      >
                        Login
                      </button>
                      <button
                        className="customBtn"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => history.push("/redeem")}
                      >
                        Sign Up
                      </button>
                    </div>
                    <div
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => setForgotPasswordModal(true)}
                    >
                      Forgot Password?
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>

        <Modal
          isOpen={forgotPasswordModal}
          toggle={() => setForgotPasswordModal(false)}
          centered
        >
          <div className="modal-top"></div>
          <ModalHeader
            toggle={() => setForgotPasswordModal(false)}
          ></ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgotPassSchema}
              onSubmit={(values) => forgotPasswordSubmitHandler(values)}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <div className="container">
                    {loading ? (
                      <div className="spinner_container">
                        <Spinner />
                      </div>
                    ) : null}
                    <h2>Reset Password</h2>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter Email Address"
                          className={
                            errors.email && touched.email
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="error"
                        />
                      </div>

                      {isAlertOpen && (
                        <div className="row mt-2">
                          <div className="col">
                            <CustomAlert
                              isAlertOpen={isAlertOpen}
                              setIsAlertOpen={setIsAlertOpen}
                              alertType={alertType}
                              alertMessage={alertMessage}
                              setAlertType={setAlertType}
                              setAlertMessage={setAlertMessage}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row my-4">
                        <div className="col-8">
                          <button
                            type="submit"
                            className={
                              !(dirty && isValid)
                                ? "disabled-btn customBtn"
                                : "customBtn"
                            }
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default PrepaidUserLogin;
