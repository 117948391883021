import React, { useState } from "react";
import * as Yup from "yup";
import { Alert, Spinner } from "reactstrap";

import { Formik, Form, Field, ErrorMessage } from "formik";
import Navbar from "../components/Navbar/Navbar";
import { firestore } from "../firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { auth } from "firebase";

const initialValues = {
  email: "",
  password: "",
};
const signupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),
  salesPersonId: Yup.string(),

  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),

  email: Yup.string().email().required("Email is required"),
  voucherNumber: Yup.string().required("Voucher Number is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const PrepaidUserSignup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    error: false,
    errorMessage: "Something went wrong",
  });
  const history = useHistory();

  const submitHandler = async (values) => {
    try {
      console.log(values);
      const {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        voucherNumber,
        confirmPassword,
      } = values;

      if (
        !email ||
        !password ||
        !firstName ||
        !lastName ||
        !phoneNumber ||
        !voucherNumber ||
        !confirmPassword
      ) {
        setError({
          error: true,
          errorMessage: "All fields are required",
        });
        return;
      }

      if (password !== confirmPassword) {
        setError({
          error: true,
          errorMessage: "Passwords do not match",
        });
        return;
      }

      console.log(values);

      const response = await axios.post("/api/signup", values);
      console.log("response: ", response.data);

      if (response.status === 200 && response.data.redirectToLogin) {
        // todo: handle login or redirect
        // await auth().signInWithCustomToken(response.data.token);
        history.push("/redeem/login");
      } else {
        setError({
          error: true,
          errorMessage: "OPS! Something went wrong",
        });
      }

      // Proceed with your async operation here
      // Example: await yourAsyncFunction(values);
    } catch (error) {
      setError({
        error: true,
        errorMessage: error.message,
      });
      console.log("Error from submitHandler: ", error.message);
    }
  };

  return (
    <>
      <Navbar fromSalesAuth={true} />

      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        {error.error && (
          <Alert
            style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            color="danger"
          >
            <h6 style={{ margin: 0 }}>Error: {error.errorMessage}</h6>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={signupSchema}
          onChange={() => {}}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty, values } = formik;
            return (
              <div className="container" id="signupForm">
                {loading ? (
                  <div className="spinner_container">
                    <Spinner />
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-6 mb-5">
                    <h2>Prepaid User Registration</h2>
                  </div>
                </div>

                <Form>
                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter First Name"
                        className={
                          errors.firstName && touched.firstName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="firstName"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter Last Name"
                        className={
                          errors.lastName && touched.lastName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="lastName"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Email Address"
                      className={
                        errors.email && touched.email
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <Field
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="123-456-7890"
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="voucherNumber">Voucher Number</label>
                    <Field
                      type="text"
                      name="voucherNumber"
                      id="voucherNumber"
                      placeholder="Enter Voucher Number"
                      className={
                        errors.voucherNumber && touched.voucherNumber
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="voucherNumber"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Password"
                      className={
                        errors.password && touched.password
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="span"
                      className="error"
                    />
                  </div>
                  {/* <div className="form-group">
                  <div className="form-check">
                    <Field
                      type="checkbox"
                      name="checkConfrm"
                      className={
                        "form-check-input " +
                        (errors.checkConfrm && touched.checkConfrm
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label className="form-check-label" htmlFor="checkConfrm">
                      By clicking I agree to the
                      <span
                        onClick={(e) => {
                          values.checkConfrm = false;
                          dispatch(storeUserSignupInfo(values)).then((res) =>
                            dispatch({ type: "SHOW_TERM_PAPER" })
                          );
                        }}
                        className="ml-1"
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#600170",
                        }}
                      >
                        terms and conditions
                      </span>
                      .
                    </label>
                  </div>
                </div> */}

                  <div className="row my-4">
                    <div className="col-6">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid)
                            ? "disabled-btn customBtn"
                            : "customBtn"
                        }
                        disabled={!(dirty && isValid)}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>

        {/* <Modal
          isOpen={forgotPasswordModal}
          toggle={() => setForgotPasswordModal(false)}
          centered
        >
          <div className="modal-top"></div>
          <ModalHeader
            toggle={() => setForgotPasswordModal(false)}
          ></ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={forgotPassSchema}
              onSubmit={(values) => forgotPasswordSubmitHandler(values)}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <div className="container">
                    {loading ? (
                      <div className="spinner_container">
                        <Spinner />
                      </div>
                    ) : null}
                    <h2>Reset Password</h2>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter Email Address"
                          className={
                            errors.email && touched.email
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="error"
                        />
                      </div>

                      {isAlertOpen && (
                        <div className="row mt-2">
                          <div className="col">
                            <CustomAlert
                              isAlertOpen={isAlertOpen}
                              setIsAlertOpen={setIsAlertOpen}
                              alertType={alertType}
                              alertMessage={alertMessage}
                              setAlertType={setAlertType}
                              setAlertMessage={setAlertMessage}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row my-4">
                        <div className="col-8">
                          <button
                            type="submit"
                            className={
                              !(dirty && isValid)
                                ? "disabled-btn customBtn"
                                : "customBtn"
                            }
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal> */}
      </div>
    </>
  );
};

export default PrepaidUserSignup;
