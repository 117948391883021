const OptionGroup = ({
  title = "",
  currentValue = null,
  setCurrentValue = () => {},
  options = [
    {
      name: "Great",
      image: require("../../assets/emojis/smile.png").default,
    },
    {
      name: "Okay",
      image: require("../../assets/emojis/straight-face.png").default,
    },
    {
      name: "Blah",
      image: require("../../assets/emojis/sad.png").default,
    },
  ],
  multiple = false,
  withCounts = false,
  showName = true,
  showSmallIcons = false,
  className=null
}) => {
  const handleOnChangeForMultiple = (value) => {
    currentValue?.includes(value)
      ? setCurrentValue((prev) => [...prev.filter((it) => it !== value)])
      : setCurrentValue((prev) => [...prev, value]);
  };
  const renderOption = (option) => {
    const { name, image, color } = option;
    return (
      <div
        onClick={() =>
          multiple ? handleOnChangeForMultiple(name) : setCurrentValue(name)
        }
        className={`${!image && !color ? "option" : "option-with-image "} ${
          (multiple ? currentValue?.includes(name) : currentValue === name) &&
          "active"
        } ${showSmallIcons ? "small" : ""}`}
      >
        {withCounts && (image || color) && (
          <span className="count">{option?.count}</span>
        )}
        {image && <img src={image} />}
        {color && (
          <div
            className="color-container"
            style={{ backgroundColor: color }}
          ></div>
        )}
        {showName && <span>{name}</span>}
        {withCounts && !image && !color && <span>{option?.count}</span>}
      </div>
    );
  };
  return (
    <div className={`option-container  ${className}`}>
      <h4>{title}</h4>
      <div
        className={`option-group ${withCounts ? `with-counts` : ""} ${
          showSmallIcons ? `small-icons` : ""
        }`}
      >
        {options.map((option) => renderOption(option))}
      </div>
    </div>
  );
};

export default OptionGroup;
