import {useEffect,useState} from "react";
import {firestore} from "../../firebase";

export const useFirestoreAdventuresGET = (setIsLoading) => {
  const [adventures,setAdventures] = useState([]);

  useEffect(() => {

      const unsub = firestore
                    .collection("adventures")
                    .onSnapshot(snapshot => {
                      setAdventures([]);
                      snapshot.forEach((doc) => {
                        setAdventures(adventures => [...adventures,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false);
                    });
       return () => unsub;
  },[]);
  return {adventures};
}
