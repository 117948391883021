import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button } from "reactstrap";

const ContentPolicyModal = ({ open, close, buttonClickHandler, loading }) => {
  const buttonHandler = (buttonType) => {
    buttonClickHandler(buttonType);
  };

  return (
    <Modal
      isOpen={open}
      toggle={close}
      // className={className}
      external={
        <button
          className="close"
          style={{ position: "absolute", top: "15px", right: "15px" }}
          onClick={close}
        >
          &times;
        </button>
      }
      centered
    >
      {loading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <ModalHeader toggle={close}>Content Policy</ModalHeader>
      <ModalBody
        className="px-3"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
          background: "white",
        }}
      >
        <p style={{ color: "black" }} className="pt-3">
          This capability is provided as a courtesy for you to post appropriate
          content for your users. You are responsible for the content you post
          here for your members. You certify that the content is legal, not
          copyrighted or that you own the copyright, not subject to
          confidentiality agreements, free from royalties and available for
          intended use. You also certify that the content is not objectionable
          to your users and doesn't pose safety risks. You agree to be
          completely and solely liable for lawsuits, damages and civil action
          resulting from posted content. Misuse of this capability may result in
          deactivation of your and your members' accounts without a refund.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-sm btn-light"
          onClick={() => buttonHandler("cancel")}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-sm btn-light"
          onClick={() => buttonHandler("agree")}
        >
          I Agree
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContentPolicyModal;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
