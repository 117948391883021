import React from "react";
import { Link } from "react-router-dom";
import "./GuruItem.css";

function GuruItem({ size = "large", guru }) {
  return (
    <Link to={`/virtual-gurus/${guru?.id}`} className={`each-guru ${size}`}>
      <div className="img-container" style={{
        backgroundImage: `url(${guru?.profilePictureURL})`
      }}>
        {/* <img src={guru?.profilePictureURL} /> */}
      </div>
      <span>{guru?.name}</span>
    </Link>
  );
}

export default GuruItem;
