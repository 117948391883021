import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: "100%",
      minHeight: "90vh",
    },
  },
}));

export default function SimplePaper({children}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        {children}
      </Paper>
    </div>
  );
}
