import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CLoader from "../../admin/components/CLoader/CLoader";
import { auth, firestore, storage } from "../../firebase";
import { setTheme } from "../../store/actions/themeActions";
import Styles from "./SiteCustomization.module.css";
import { SketchPicker } from "react-color";
import { THEME_COLOR } from "../../util/theme";

const SiteCustomization = () => {
  const theme = useSelector(({ theme }) => theme);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [showChangeLogoModal, setShowChangeLogoModal] = useState(false);
  const [showChangeColorModal, setShowChangeColorModal] = useState(false);
  const [uploadingLogoLoading, setUploadingLogoLoading] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(true);
  const [color, setColor] = useState(theme.color || THEME_COLOR);
  const dispatch = useDispatch();

  const onFileChangeHandler = (e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
  };

  const onLogoSubmit = async () => {
    try {
      setUploadingLogoLoading(true);

      const storageRef = storage.ref(`themes/${auth.currentUser?.uid}/logo`);
      const snap = await storageRef.put(selectedLogo);

      const url = await storageRef.getDownloadURL();
      const metadata = await storageRef.getMetadata();
      const fullPath = metadata.fullPath;

      const themeDocRef = firestore
        .collection("themes")
        .doc(auth.currentUser.uid);

      const prevThemesDoc = await themeDocRef.get();

      if (prevThemesDoc.exists)
        await themeDocRef.update({
          logoPath: fullPath,
          logo: url,
        });
      else
        themeDocRef.set({
          logoPath: fullPath,
          logo: url,
          color: "",
        });

      setUploadingLogoLoading(false);
      setShowChangeLogoModal(false);
      setSelectedLogo(null);
      dispatch(
        setTheme({
          logo: url,
        })
      );
    } catch (err) {
      console.log(err);
      setUploadingLogoLoading(false);
    }
  };

  const onColorSubmit = async () => {
    try {
      setUploadingLogoLoading(true);

      const themeDocRef = firestore
        .collection("themes")
        .doc(auth.currentUser.uid);

      const prevThemesDoc = await themeDocRef.get();

      if (prevThemesDoc.exists)
        await themeDocRef.update({
          color
        });
      else
        themeDocRef.set({
          color
        });

      setUploadingLogoLoading(false);
      setShowChangeColorModal(false);

      dispatch(
        setTheme({
          color
        })
      );

    } catch (err) {
      console.log(err);
      setUploadingLogoLoading(false);
    }
  };

  const toggleLogoModal = () => {
    if (!uploadingLogoLoading) {
      setShowChangeLogoModal((prev) => !prev);
      setSelectedLogo(null);
      setShowChangeColorModal(false);
    }
  };
 
  const toggleColorModal = () => {
    if (!uploadingLogoLoading) {
      setColor(theme.color || THEME_COLOR)
      setShowChangeColorModal((prev) => !prev);
    }
  };

  const handleChangeComplete = (color, event) => setColor(color.hex);

  return (
    <>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div className="row">
            <div className="col-6">Change Logo: </div>
            <div className="col-6 d-flex flex-column">
              {theme?.logo && (
                <span>
                  <img
                    className="mb-2"
                    width={180}
                    src={theme?.logo}
                    alt="selected logo"
                  />
                </span>
              )}
              <a onClick={toggleLogoModal} className={Styles.ChangeLink}>
                Change
              </a>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div className="row">
            <div className="col-6">Theme Color: </div>
            <div className="col-6 d-flex align-items-center">
            {color && (
                <div style={{width:30,height:30,background: color, marginRight: 15}}>
                </div>
              )}
              <a onClick={toggleColorModal} className={Styles.ChangeLink}>
                Change
              </a>
            </div>
          </div>
        </li>
      </ul>

      <Modal
        isOpen={showChangeLogoModal}
        toggle={toggleLogoModal}
        // className={className}
        external={
          <button
            className="close"
            style={{ position: "absolute", top: "15px", right: "15px" }}
            onClick={toggleLogoModal}
          >
            &times;
          </button>
        }
        centered
      >
        <div className="modal-top"></div>
        <ModalHeader toggle={toggleLogoModal}></ModalHeader>
        <ModalBody className="px-4">
          <div className="mb-2 mx-4">
            <h4 className="mb-4">Change Logo </h4>
            {uploadingLogoLoading && <CLoader />}
            {selectedLogo && (
              <span>
                <img
                  className="mb-2"
                  width={180}
                  src={URL.createObjectURL(selectedLogo)}
                  alt="selected logo"
                />
              </span>
            )}

            <input
              onChange={(e) => onFileChangeHandler(e)}
              className="pb-4"
              style={{ background: "unset", padding: 10 }}
              name="Change"
              type="file"
            />
            <br />
            <button
              onClick={onLogoSubmit}
              id="submit"
              className="mt-2 customBtn"
            >
              Upload
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showChangeColorModal}
        toggle={toggleColorModal}
        // className={className}
        external={
          <button
            className="close"
            style={{ position: "absolute", top: "15px", right: "15px" }}
            onClick={toggleColorModal}
          >
            &times;
          </button>
        }
        centered
      >
        <div className="modal-top"></div>
        <ModalHeader toggle={toggleColorModal}></ModalHeader>
        <ModalBody className="px-4">
          <div className="mb-2 mx-4 text-center">
            <h4 className="mb-4">Change Color</h4>
            {uploadingLogoLoading && <CLoader />}
           
            {showColorPicker && (
                <SketchPicker
                  onAccept={() => {
                    setShowColorPicker(false);
                  }}
                  className="mx-auto"
                  onCancel={() => {
                    setShowColorPicker(false);
                  }}
                  color={color}
                  onChangeComplete={handleChangeComplete}
                />
              )}
            <br />
            <button
              onClick={onColorSubmit}
              id="submit"
              className="mt-2 customBtn"
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SiteCustomization;
