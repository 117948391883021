import firebase from "firebase";
import React from "react";
import { Link } from "react-router-dom";
import { auth, firestore } from "../../firebase";

function PostLink({ post, children, postId, rowId, adventureId }) {
  const onClickHandler = async (e) => {
    openUpNewTab(post.fileUrl);

    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;
      let date = firebase.firestore.Timestamp.now();
      const arrUnion = documentRef.update({
        watchedPosts: firebase.firestore.FieldValue.arrayUnion({
          adventureId,
          rowId,
          postId: postId,
          date,
        }),
      });
    }
  };

  const openUpNewTab = (url) => {
    const win = window.open(url, "_blank");
  };

  return (
    <>
      {post.isOpenNewTab ? (
        <a onClick={(e) => onClickHandler(e)}>{children}</a>
      ) : (
        <Link
          to={{
            pathname: `/adventure/${adventureId}/${rowId}/${postId}`,
            state: post,
          }}
        >
          {children}
        </Link>
      )}
    </>
  );
}

export default PostLink;
