import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function SingleLineTextField(props) {
  const classes = useStyles();

  const  {values,name,onChangeHandler,label} = props;


  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="standard-multiline-flexible"
          label={`${label}`}
          multiline
          rowsMax={4}
          value={values[`${name}`]}
          name={name}
          onChange={(e) => onChangeHandler(e)}
        />
      </div>
    </form>
  );
}
