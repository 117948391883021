import React from "react";

const SingleAdventureRowEditor = ({row,values,onValueChangeHandler}) => {
  return (
    <>
    <tr>
    <td> <input
       className="form-control"
       value={values.rowNumber}
       readOnly
       name="rowNumber"
       type="text"
       placeholder="Row number" /> </td>
      <td> <input
       onChange={(e)=>onValueChangeHandler(e)}
       className="form-control"
       value={values.title}
       name="title"
       type="text"
       placeholder="Title"></input> </td>
      </tr>
    </>
  );
}

export default SingleAdventureRowEditor;
