import React from "react";
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';

const TopNavbar = () => {

  const dispatch = useDispatch()
  const toggleSidebarHandler = () => {
    dispatch({type:"TOGGLE_SIDEBAR"});
  }


  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">

            <button
             onClick={() => toggleSidebarHandler()}
             type="button"
             id="sidebarCollapse"
             className="btn btn-info">
                <i className="fas fa-align-left"></i>

            </button>


        </div>
    </nav>
    </>
  );
}

export default TopNavbar;
