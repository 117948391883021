import React,{useState,useEffect} from "react";
import {useFirestoreGetAllPricings} from "../../hooks/useFirestore";
import {firestore,timestamp,firebase} from "../../firebase";

import ConfirmationModal from "../components/ConfirmationModal";

import {Spinner,Alert} from "reactstrap";

import axios from "axios";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom:"0",
  background: "#00000047",
  zIndex: "100"
}

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100"
}

const initValues = {
  title: "",
  price: 0,
  description: "",
  checkbox: false,
  isSingleUser: false,
  numberOfUsers: 1
}

const Pricings = () => {

  const [isAddNew,setIsAddNew] = useState(false);
  const [error,setError] = useState("");
  const [message,setMessage] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [values,setValues] = useState({...initValues});
  const [isMounted,setIsMounted] = useState(false);

  const [isEdit,setIsEdit] = useState(false);
  const [currentId,setCurrentId] = useState(null);
  const [currentPricing,setCurrentPricing] = useState({});

  const [isDelete,setIsDelete] = useState(false);
  const [isConfirmDelete,setIsConfirmDelete] = useState(false);

  // const {pricings} = useFirestoreGetAllPricings(setIsLoading);
  const [pricings,setPricings] = useState([]);


  const [orderDirection,setOrderDirection] = useState("asc");
  const [orderBy,setOrderBy] = useState("rowNumber");

  const [tempRows,setTempRows] = useState([]);
  const [searchBy,setSearchBy] = useState("title");

  const [filteredData,setFilteredData] = useState([]);
  const [searchedData,setSearchedData] = useState("");

  const [settings,setSettings] = useState({orderBy:"rowNumber",orderDirection:"asc"});

  const [isSetOrderSettings,setIsSetOrderSettings] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsMounted(true);

    return () => setIsMounted(false);
  },[])

  useEffect(() => {
    const unsub = firestore
                  .collection("pricings")
                  .doc('settings')
                  .get()
                  .then(res => {
                    if(res.data()){
                      const {orderBy,orderDirection} = res.data();
                      setOrderBy(orderBy);
                      setOrderDirection(orderDirection);
                    }
                    
                  });
    return () => unsub;

  },[])

  

  useEffect(() => {
      const unsub = firestore
                    .collection("pricings")
                    .orderBy(orderBy,orderDirection)
                    .onSnapshot(snapshot => {
                      setPricings([]);
                      snapshot.forEach((doc) => {
                        setPricings(pricings => [...pricings,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false)
                    });
       return () => unsub;
  },[orderBy,orderDirection]);


  useEffect(() => {
    setFilteredData(pricings.filter((pricing) => {
      return (
        pricing[`${searchBy}`].toLowerCase().includes(searchedData.toLowerCase())
      );
    }))
  },[searchedData,pricings])


  useEffect(async () => {
    if (isConfirmDelete) {
      try {
        await firestore.collection("pricings").doc(currentId).delete();
        setIsConfirmDelete(false);
        setIsDelete(false);
        setCurrentId(null);
      } catch (e) {
        setTimeout(() => {
          setError("");
        },2000);
        setError(e.message);
      } finally {
        if (isMounted) {
          setTimeout(() => {
            setMessage("");
          },2000)
          setMessage("Data deleted successfully");
        }
      }
    }
  },[isConfirmDelete,isDelete])


  const onClickPositionChangeHandler = async (e,pricing) => {

    ////positions
    let selectedObjectPosition;
    let objectToBeInterchangedWithPosition;

    /////objects
    let selectedObject;
    let interchangedObject;


    switch (e.currentTarget.getAttribute('name')) {
      case "pushUp":
        if (pricing.rowNumber === 1) {
          console.log("cant push up");
          return ;
        }

        selectedObjectPosition = filteredData.indexOf(pricing);
        objectToBeInterchangedWithPosition = selectedObjectPosition - 1

        selectedObject = {
          ...filteredData[selectedObjectPosition],
          rowNumber: filteredData[selectedObjectPosition].rowNumber-1
        }

        interchangedObject = {
          ...filteredData[objectToBeInterchangedWithPosition],
          rowNumber: filteredData[objectToBeInterchangedWithPosition].rowNumber+1
        }


        break;
      case "pushDown":
        if (pricing.rowNumber === filteredData.length) {
          console.log("cant push down");
          return ;
        }

        selectedObjectPosition = filteredData.indexOf(pricing);
        objectToBeInterchangedWithPosition = selectedObjectPosition + 1

        selectedObject = {
          ...filteredData[selectedObjectPosition],
          rowNumber: filteredData[selectedObjectPosition].rowNumber+1
        }

        interchangedObject = {
          ...filteredData[objectToBeInterchangedWithPosition],
          rowNumber: filteredData[objectToBeInterchangedWithPosition].rowNumber-1
        }

        break;
      default:
        return ;
    }

    try {

      const batch = firestore.batch();

      const selectedObjectRef = firestore.collection("pricings").doc(selectedObject.id);
      batch.update(selectedObjectRef,{...selectedObject,updated:timestamp()});

      const interchangedObjectRef = firestore.collection("pricings").doc(interchangedObject.id);
      batch.update(interchangedObjectRef,{...interchangedObject,updated:timestamp()})

      const result = await batch.commit();

   

    } catch (e) {
      console.log(e);
    }

  }



  const createTableRowWithPricings = (data) => {
    return (
      data.map((pricing) => {
        return (
          <tr key={pricing.id}>
            {
              isEdit && currentId && currentId === pricing.id ?
              <>
              <td>
               <input
               onChange={(e)=>onChangeValueHandler(e)}
               className="form-control"
               value={values.title}
               name="title"
               type="text"
               placeholder="Title" /> </td>
              <td> <input
               onChange={(e)=>onChangeValueHandler(e)}
               className="form-control"
               value={values.price}
               name="price"
               type="number"
               placeholder="Price" /> </td>

                <td> 
                 <div class="form-group">
                    <div class="form-check">
                      <input
                        onChange={e => onChangeCheckboxHandler(e)}
                        name="checkbox"
                       class="form-check-input" type="checkbox" checked={values.checkbox} id="checkbox" />
                      <label class="form-check-label" for="checkbox">
                        Contact for subscription
                      </label>
                    </div>
                  </div>

                </td>

                <td> 
                 <div class="form-group">
                    <div class="form-check">
                      <input
                        onChange={e => onChangeCheckboxHandler(e)}
                        name="isSingleUser"
                       class="form-check-input" type="checkbox" checked={values.isSingleUser} id="isSingleUser" />
                      <label class="form-check-label" for="checkbox">
                        For Single User
                      </label>
                    </div>
                  </div>

                </td>


                <td> <input
               onChange={(e)=>onChangeValueHandler(e)}
               className="form-control"
               value={values.numberOfUsers}
               name="numberOfUsers"
               type="number"
               placeholder="Number of Users" /> </td>

                

              <td colSpan="2"> <input
              onChange={(e)=>onChangeValueHandler(e)}
              className="form-control"
              name="description"
              value={values.description}
              type="text"
              placeholder="Description" /></td>
              </>
              :
              <>
              <td>{pricing.title}</td>
              <td>{pricing.price}</td>
              <td>{pricing.checkbox ? <p>Yes</p> : <p>No</p>}</td>
              <td>{pricing.isSingleUser ? <p>Yes</p> : <p>No</p>}</td>
              <td>{pricing.numberOfUsers}</td>
              <td>{pricing.description}</td>
              <td className="d-flex jusitify-content-around">
                <button
                 onClick={(e) => onEditHandler(pricing)}
                 style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-edit"></i></button>
                <button
                 onClick={(e) => onDeleteHandler(pricing)}
                 style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>

                 <button
                 onClick={e => onClickPositionChangeHandler(e,pricing)}
                 name="pushUp"
                 className="ml-2"
                 style={{padding:"3px",border:"0px"}}
                 ><i class="fas fa-arrow-circle-up"></i></button>
                 <button
                 name="pushDown"
                 onClick={e => onClickPositionChangeHandler(e,pricing)}
                 style={{padding:"3px",border:"0px"}}
                 ><i class="fas fa-arrow-circle-down"></i></button>

              </td>
              </>
            }
          </tr>
        );
      })
    );
  }

  const addNewHandler = () => {
    setIsAddNew(true);

  }

  const onDeleteHandler = async (pricing) => {
    setIsDelete(true)
    setCurrentId(pricing.id);


      const result = window.confirm("Are you sure you want to delete it?")
      if (!result) {
        setIsDelete(false);
        return ;
      }else{

        const rowsRef = firestore.collection("pricings");
        const snapshot = await rowsRef.where('rowNumber', '>',pricing.rowNumber).get();

        snapshot.docs.forEach(async (doc, i) => {
          let id = doc.id;
          await rowsRef.doc(id).update({
                rowNumber: firebase.firestore.FieldValue.increment(-1),
                updated: timestamp()
              });
        });


        const stripeResponse = await axios.post('/api/a/deactivate-pricing-plan-product',{prod_id:pricing.stripeProductId,price_id:pricing.stripePriceId});

        await firestore.collection("pricings").doc(pricing.id).delete();
        setIsDelete(false);
      }

  }

  const onEditHandler = (pricing) => {
    setIsEdit(true)
    setValues({...pricing});
    setCurrentId(pricing.id);
    setCurrentPricing({...pricing});
  }

  const updateHandler = async () => {
    try {
      if (currentId) {


        const stripeResponse = await axios.post("/api/a/update-pricing-plan-product",values);

        

        const response = await firestore
        .collection("pricings")
        .doc(currentId)
        .update({...values,
          stripeProductId: stripeResponse.data.productId,
          stripePriceId: stripeResponse.data.priceId,
          updated:timestamp()})
        await firestore.collection("pricings").doc("settings").set({orderBy,orderDirection});
      }else {
        let rowNumber = Number.parseInt(filteredData.length) + 1;

        const stripeResponse = await axios.post("/api/a/create-pricing-plan-product",values);


        const response = await firestore
        .collection("pricings")
        .doc()
        .set({...values,
          rowNumber,
          stripeProductId: stripeResponse.data.productId,
          stripePriceId: stripeResponse.data.priceId,
          created:timestamp(),
          updated:timestamp()})
        await firestore.collection("pricings").doc("settings").set({orderBy,orderDirection});
      }
    } catch (e) {
      setError(e.message);
      setTimeout(() => {
        setError("");
      },2000)
    } finally {
      if (isMounted) {
        if (currentId) {
          setCurrentId(null);
          setMessage("Data updated successfully");
        }else {
          setMessage("Data added successfully");
        }
        setTimeout(() => {
          setMessage("");
        },2000)
      }
    }
    setIsAddNew(false);
    setIsEdit(false);
    setValues(initValues);
  }

  const cancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setValues(initValues);
  }

  const onChangeCheckboxHandler = (e) => {
    console.log(e.target.name)
    setValues({...values,[e.target.name]:e.target.checked});
  }

  const onChangeValueHandler = (e) => {
    
    const {name,value} = e.target;
    if (name === "price") {
      setValues({...values,price:parseFloat(value)})
    }else {
      setValues({
        ...values,
        [name]: value
      })
    }

  }


  const onChangeFilteringHandler = (e) => {
      switch (e.target.name) {
        case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("rowNumber")
        }
        setOrderBy(e.target.value)
        setIsSetOrderSettings(true);
        break;
        case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("asc")
        }
        setOrderDirection(e.target.value);
        setIsSetOrderSettings(true);
        break;
        case "searchBy":
          setSearchBy(e.target.value)
          break;
      }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
  }

  const setOrderSettingsHandler = async () => {
    await firestore.collection("pricings").doc("settings").set({orderBy,orderDirection});
    setIsSetOrderSettings(false);
  }

  const canceOrderSettingslHandler = () => {
    setIsSetOrderSettings(false);
  }



  return (
    <>

    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-3">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="title">Title</option>
          <option value="description">Description</option>
        </select>
        </div>

        

      </div>
    </form>

    </div>

    {isSetOrderSettings ?
      <div className="ml-auto mt-3 btn-group">
      <button
      onClick={() => setOrderSettingsHandler()}
      className="btn btn-sm btn-primary">Set this order for front end</button>
      <button
       onClick={() => canceOrderSettingslHandler()}
       className="btn btn-sm btn-warning">Cancel</button>
       </div>
      : null
    }

    <div style={{position:"relative"}}>

    { isLoading && <div style={spinnerDivStyleSheet}><Spinner style={spinnerStyleSheet} /></div> }

    <div>
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Price</th>
          <th scope="col">Contact For Subscription</th>
          <th scope="col">For Single User</th>
          <th scope="col">Number of Users</th>
          <th scope="col">Description</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {createTableRowWithPricings(filteredData)}

        {
          isAddNew ?
            <tr>
                <td> <input
                 onChange={(e)=>onChangeValueHandler(e)}
                 className="form-control"
                 value={values.title}
                 name="title"
                 type="text"
                 placeholder="Title" /> </td>
                <td> <input
                 onChange={(e)=>onChangeValueHandler(e)}
                 className="form-control"
                 value={values.price}
                 name="price"
                 type="number"
                 min="0"
                 step="0.01"
                 placeholder="Price" /> </td>

                 <td> 
                 <div class="form-group">
                    <div class="form-check">
                      <input
                        onChange={e => onChangeCheckboxHandler(e)}
                        name="checkbox"
                       class="form-check-input" type="checkbox" checked={values.checkbox} id="checkbox" />
                      <label class="form-check-label" for="checkbox">
                        Contact for subscription
                      </label>
                    </div>
                  </div>

                </td>

                <td> 
                 <div class="form-group">
                    <div class="form-check">
                      <input
                        onChange={e => onChangeCheckboxHandler(e)}
                        name="isSingleUser"
                       class="form-check-input" type="checkbox" checked={values.isSingleUser} id="isSingleUser" />
                      <label class="form-check-label" for="checkbox">
                        For Single User
                      </label>
                    </div>
                  </div>

                </td>

                <td> <input
                 onChange={(e)=>onChangeValueHandler(e)}
                 className="form-control"
                 value={values.numberOfUsers}
                 name="numberOfUsers"
                 type="number"
                 min="0"
                 placeholder="Number of Users" /> </td>

                <td colSpan="2"> <input
                onChange={(e)=>onChangeValueHandler(e)}
                className="form-control"
                name="description"
                value={values.description}
                type="text"
                placeholder="Description" /></td>
            </tr>
          : null
        }

      </tbody>
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Price</th>
          <th scope="col">Contact For Subscription</th>
          <th scope="col">For Single User</th>
          <th scope="col">Number of Users</th>
          <th scope="col">Description</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      </table>
      </div>

      <div>
      {message && <Alert color="success">{message}</Alert>}
      {error && <Alert color="danger">{error}</Alert>}
      </div>

      <div>
      {
        isAddNew || isEdit ?
        <>
        <button
         onClick={() => updateHandler()}
         className="btn btn-sm btn-primary">Update</button>
         <button
          onClick={() => cancelHandler()}
          className="btn btn-sm btn-warning mx-3">Cancel</button>
          </>
         :
         <button
         onClick={() => addNewHandler()}
         className="btn btn-sm btn-primary">Add New</button>
      }
      </div>




    </div>

    </>
  );
}

export default Pricings;
