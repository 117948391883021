import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import CustomBackdrop from "./components/Loader";

export default function SalesPersonRoute({ component: Component, ...rest }) {
  const { currentUser, isAdmin, issalesPerson } = useAuth();

  let redirectURL = "/";

  if (!currentUser) return <Redirect to={redirectURL} />;

  if (issalesPerson === null) return <CustomBackdrop />;

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser && (isAdmin || issalesPerson) ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectURL} />
        );
      }}
    ></Route>
  );
}
