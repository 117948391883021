import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { useLocation } from "react-router-dom";
import { firestore } from "../../firebase";

import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(100),
      height: "100%",
    },
  },
}));

export default function SimplePaper(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let typeParam = queryParams.get("type");

  useEffect(async () => {
    let __collectionName =
      typeParam === "businesses" ? "index" : "individualIndex";

    const unsub = await firestore
      .collection(__collectionName)
      .doc("page")
      .get();
    const data = unsub.data();
    const { terms } = data;

    document.getElementById("termsHolder").innerHTML = terms;

    return () => unsub;
  }, [typeParam]);

  const onClickHandler = (e) => {
    dispatch({
      type: "DONT_SHOW_TERM_PAPER",
    });
  };

  return (
    <div className={classes.root}>
      <a
        onClick={(e) => onClickHandler(e)}
        id="backToSignUpText"
        class="btn mb-5"
        style={Style.backBtnStyle}
      >
        Back to sign up
      </a>

      <Paper>
        <div
          className="my-3 mx-2"
          id="termsHolder"
          style={{ padding: "0px 10px" }}
        ></div>
      </Paper>
      <a
        onClick={(e) => onClickHandler(e)}
        id="backToSignUpText"
        class="btn mb-5"
        style={Style.backBtnStyle}
      >
        Back to sign up
      </a>
      {/* <p  className="mb-4" style={{cursor:"pointer",fontWeight:"bold",color:"#600170",marginLeft:"8px"}} onClick={(e) => onClickHandler(e)}>Back to Sign up</p> */}
    </div>
  );
}

const Style = {
  backBtnStyle: {
    background:
      "linear-gradient(136.89deg,var(--theme-color-dark) 46.45%,var(--theme-color) 69.27%)",
    boxShadow: "0px 8px 14px rgba(124, 121, 121, 0.25)",
    borderRadius: "30px",
    color: "#fff",
    padding: "8px 25px",
    width: "fit-content",
  },
};
