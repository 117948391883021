import React,{useState,useEffect} from "react";
import {firestore,firebase} from "../../firebase";
import {Spinner} from "reactstrap";


const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom:"0",
  background: "#00000047",
  zIndex: "100"
}

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100"
}

const CompanySubscribersList = ({companyDoc}) => {


  const [companySubsInfo,setCompanySubsInfo] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const [orderDirection,setOrderDirection] = useState("asc");
  const [orderBy,setOrderBy] = useState("created");

  const [tempRows,setTempRows] = useState([]);
  const [searchBy,setSearchBy] = useState("firstName");

  const [filteredData,setFilteredData] = useState([]);
  const [searchedData,setSearchedData] = useState("");

  useEffect(() => {
    setCompanySubsInfo([]);
    if (companyDoc && companyDoc.companySubsId && companyDoc.companySubsId.length !== 0) {
      companyDoc.companySubsId.forEach((subId) => {
        firestore.collection("users").doc(subId).get().then(userSnapShot => {
          setCompanySubsInfo(companySubsInfo => [
            ...companySubsInfo,
            {
              uid: subId,
              ...userSnapShot.data()
            }
          ])
        })
      });
    }
  },[orderBy,orderDirection,companyDoc])

  useEffect(() => {
    setFilteredData(companySubsInfo.filter((subInfo) => {
      return (
        subInfo[`${searchBy}`].toLowerCase().includes(searchedData.toLowerCase())
      );
    }))
  },[searchedData,companySubsInfo])

  const onDeleteHandler = async (sub) => {
    const result = window.confirm("Are you sure you want to remove the user from company subscription list?");
    if (!result) {
      return ;
    }else {
      setIsLoading(true);
      await firestore.collection('users').doc(sub.uid).update({companyId:"",redemptionCode:"",pricingPlan:""})

      const companyRef = firestore.collection("companies").doc(companyDoc.id);
      const arrayRemove = await companyRef.update({
        companySubsId: firebase.firestore.FieldValue.arrayRemove(sub.uid),
        companySubLevel: firebase.firestore.FieldValue.increment(-1)
      });
    }
    setIsLoading(false);
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
  }

  const onChangeFilteringHandler = (e) => {
      switch (e.target.name) {
        case "searchBy":
          setSearchBy(e.target.value)
          break;
      }
  }

  return (
    <>

    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-6">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-6">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="phoneNumber">Phone Number</option>
        </select>
        </div>

      </div>
    </form>

    </div>


    <div className="mt-5 position-relative">
      { isLoading && <div style={spinnerDivStyleSheet}><Spinner style={spinnerStyleSheet} /></div> }

      <h4 className="text-center font-weight-bold">Subscribed Users</h4>
    </div>
    <table className="table table-striped table-dark">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Email</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>

          {
            filteredData.map(sub => (
              <tr key={sub.uid}>
                <th scope="row">{sub.firstName}</th>
                <td>{sub.lastName}</td>
                <td>{sub.phoneNumber}</td>
                <td>{sub.email}</td>
                <td>
                <button
                 onClick={(e) => onDeleteHandler(sub)}
                 style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>
                </td>
              </tr>
            ))
          }

        </tbody>
        </table>
    </>
  );
}

export default CompanySubscribersList;
