import React, { useState } from "react";
import { Alert } from "reactstrap";
import ContentTable from "../../admin/containers/CorpAdminContent/ContentTable";

const MyContent = ({ content, getAllContent }) => {
  const [contentActionError, setContentActionError] = useState("");
  const [message, setMessage] = useState("");

  const setMessageHandler = (text) => setMessage(text);
  const setErrorHandler = (error) => setContentActionError(error);

  return (
    <>
      <ContentTable
        getAllContent={getAllContent}
        content={content}
        messageHandler={setMessageHandler}
        errorHandler={setErrorHandler}
      />
      <div>
        {contentActionError && (
          <Alert color="danger">{contentActionError}</Alert>
        )}
        {message && <Alert color="success">{message}</Alert>}
      </div>
    </>
  );
};

export default MyContent;
