import { THEME_COLOR } from "../../util/theme";
import { SET_THEME } from "../actions/types";

let initalState = {
    logo: '',
    color: null,
  };
  
  function theme(state=initalState, action) {
    switch (action.type) {
      case SET_THEME:
        return ({
          ...state,
          ...action.payload
        });
      break;
      default:
        return state;
  
    }
  }
  
  export default theme;
  