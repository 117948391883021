import React from "react";
import GurusActions from "./GurusActions";
import ContentCreatorActions from "./GurusActions";
import GurusRowEditor from "./GurusRowEditor";
import ContentCreatorRowEditor from "./GurusRowEditor";

const GurusRow = ({
  name,
  profilePictureURL,
  description,
  id,
  guru,
  onShowHandler,
  onEditHandler,
  onDeleteHandler,
  onFileChangeHandler,
  onValueChangeHandler,
  onClickPositionChangeHandler,
  selectedGuruId,
  values,
  isEdit,
}) => {
  return (
    <>
      <tr>
        {selectedGuruId === id && isEdit ? (
          <>
            <GurusRowEditor
              values={values}
              onFileChangeHandler={onFileChangeHandler}
              onValueChangeHandler={onValueChangeHandler}
            />
          </>
        ) : (
          <>
            <td>{name}</td>
            <td>{description}</td>
            <td>
              <img
                src={`${profilePictureURL}`}
                style={{ width: "50px", height: "50px" }}
              />
            </td>
            <GurusActions
              id={id}
              guru={guru}
              onShowHandler={onShowHandler}
              onEditHandler={onEditHandler}
              onDeleteHandler={onDeleteHandler}
            />
          </>
        )}
      </tr>
    </>
  );
};

export default GurusRow;
