import React, { useState, useEffect } from "react";
import "./Gurus.css";
import { Spinner } from "reactstrap";
import FeatureCard from "../FeatureCard/FeatureCard";
import { useFirestoreGetAllAdventuresById } from "../../hooks/useFirestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { firestore } from "../../firebase";

import { useAuth } from "../../contexts/AuthContext";
import GuruItem from "../GuruItem/GuruItem";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import { useWindowSize } from "../../util/helpers";

// install Swiper modules
SwiperCore.use([Navigation]);

const Gurus = ({
  size = "large",
  heading = "Virtual Guru Recommendations",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dimensions = useWindowSize();

  const [gurus, setGurus] = useState([]);

  const history = useHistory();

  const { currentUser } = useAuth();

  useEffect(async () => {
    setIsLoading(true);

    const gurusRef = await firestore.collection("m_gurus").get();
    setGurus([]);

    if (!gurusRef.empty) {
      const docs = gurusRef.docs;

      let gurus = [];
      docs.forEach((doc) => {
        gurus.push({ ...doc.data(), id: doc.id });
      });
      setGurus(gurus);
    }

    setIsLoading(false);
  }, []);
  let perPage = dimensions.width > 750 ? 4 : 2;

  return (
    <section className="gurus-section">
      <h3>{heading}</h3>

      <div className={`gurus-list ${size} ${perPage === 4 && "web-view"}`}>
        {gurus?.length > perPage ? (
          <Swiper
            spaceBetween={0}
            slidesPerView={perPage}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            navigation={true}
          >
            {gurus.map((eachGuru) => (
              <SwiperSlide>
                <GuruItem key={eachGuru?.id} guru={eachGuru} size={size} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            {gurus.map((eachGuru) => (
              <GuruItem key={eachGuru?.id} guru={eachGuru} size={size} />
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default Gurus;
