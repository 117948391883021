import React,{useState,useEffect} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import {useSelector,useDispatch} from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {toggleShare} from "../../store/actions/indexActions";
import {Spinner} from "reactstrap";

const shareSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required")
  });

  const initialValues = {
    email: ""
  };

const ShareModal = (props) => {
    const {
        buttonLabel,
        className,
        contentTitle,
        postId,
        rowId,
        adventureId,
        show,
        toggleShow
      } = props;
      const [modal, setModal] = useState(false);
      const dispatch = useDispatch();
    
      const {isShareModalOpened} = useSelector(state => state.index)

      const [isLoading,setIsLoading] = useState(false);
    
      useEffect(() => {
        setModal(isShareModalOpened);
      },[])
    
      useEffect(() => {
        setModal(isShareModalOpened);
      },[isShareModalOpened])
     
      useEffect(() => {
        setModal(show);
      },[show])
    
      const toggle = () => {
        if(toggleShow) toggleShow();
        else
        dispatch(toggleShare());
      }
      
    return (
        <>
        <div>
        <Modal isOpen={modal} toggle={toggle} className={className} centered>
            <div className="modal-top"></div>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>



            <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={shareSchema}
      onSubmit={async (values) => {
        
        const {email} = values;

        let formattedBody = "Hi, "+ localStorage.getItem("firstName") +" shared this content from Leviosa with you:\n\n"+
        `https://leviosa.us/adventure/`+ adventureId+`/`+rowId+`/`+postId +`\n ${contentTitle}`+
        "\n\nNot a member yet? See why many others like "+localStorage.getItem("firstName")+" use Leviosa, the complete solution for curated content to improve physical and emotional well-being.Sign up is quick and easy, and you can start benefitting from hundreds of hours of content right away.";

        let mailToLink = `mailto:${email}?`;
        let mailContent = "Subject= "+ localStorage.getItem("firstName") +" shared this content from Leviosa with you…&";
        mailContent += "body=" + encodeURIComponent(formattedBody);

        let sent = mailToLink + mailContent

        window.location.href = sent;

      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty, values } = formik;

        

        return (
          <div className="container" id="signupForm">
            { isLoading ?
              <div className="spinner_container"><Spinner /></div> : null
            }

            <div className="row">
              <div className="col-12">
                <h2>Share Content</h2>
              </div>
            </div>

            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email Address"
                  className={
                    errors.email && touched.email ? "input-error form-control" : "form-control"
                  }
                />
                <ErrorMessage name="email" component="span" className="error" />
              </div>

              

             


              <div className="row my-4">
                <div className="col-6">
                
                <button
                  type="submit"
                  className={!(dirty && isValid) ? "disabled-btn customBtn" : "customBtn"}
                  disabled={!(dirty && isValid)}
                >
                  Share
                </button>
                </div>
              </div>


            </Form>
          </div>
        );
      }}
    </Formik>







            </ModalBody>
        </Modal>
        </div>
        </>
    );
}

export default ShareModal;