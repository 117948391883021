import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MultiLineTextField from "./MultiLineTextField";
import SingleLineTextField from "./SingleLineTextField";
import SaveButton from "./SaveButton";
import UploadButton from "./UploadButton";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "./AdminIndexTabs.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  //for text fields
  const {values,setValues,onChangeHandler,onClickHandler,lowerMidSectionFile,setLowerMidSectionFile} = props;

  const [previewImageFileSrc,setPreviewImageFileSrc] = useState("");

  useEffect(() => {
    lowerMidSectionFile && previewImageFile(lowerMidSectionFile);
  },[lowerMidSectionFile])

  const previewImageFile = (lowerMidSectionFile) => {
      const reader = new FileReader();
      reader.readAsDataURL(lowerMidSectionFile);
      reader.onloadend = () => {
          setPreviewImageFileSrc(reader.result);
      }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Top Section" {...a11yProps(0)} />
          <Tab label="Mid Section" {...a11yProps(1)} />
          <Tab label="Bottom Section" {...a11yProps(2)} />
          <Tab label="Lower Mid Section" {...a11yProps(3)} />
          <Tab label="Terms & Conditions Section" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SingleLineTextField
            name="topSectionHeading"
            values={values}
            label="Header Text"
            onChangeHandler={onChangeHandler}
           />
          <MultiLineTextField
          onChangeHandler={onChangeHandler}
          label="Description"
          name="topSectionParagraph"
          values={values}
           />
          <SingleLineTextField
          name="topSectionBtnText"
          values={values}
          label="Button Text"
          onChangeHandler={onChangeHandler}
           />
          <SingleLineTextField
          name="topSectionBtnLink"
          values={values}
          label="Button URL"
          onChangeHandler={onChangeHandler}
           />
          <SaveButton
          onClickHandler={onClickHandler}
          name="topSectionButton"
           />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SingleLineTextField
          name="midSectionHeading"
          values={values}
          label="Header Text"
          onChangeHandler={onChangeHandler}
           />
          <MultiLineTextField
          onChangeHandler={onChangeHandler}
          label="Description"
          name="midSectionParagraph"
          values={values}
           />
          <SingleLineTextField
          name="midSectionBtnText"
          values={values}
          label="Button Text"
          onChangeHandler={onChangeHandler}
           />
          <SingleLineTextField
          name="midSectionBtnLink"
          values={values}
          label="Button URL"
          onChangeHandler={onChangeHandler}
           />
          <SaveButton
          onClickHandler={onClickHandler}
          name="midSectionButton"
           />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SingleLineTextField
          name="bottomSectionHeading"
          values={values}
          label="Header Text"
          onChangeHandler={onChangeHandler}
           />
          <MultiLineTextField
          onChangeHandler={onChangeHandler}
          label="Description"
          name="bottomSectionParagraph"
          values={values}
           />
          <SaveButton
          onClickHandler={onClickHandler}
          name="bottomSectionButton"
           />
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <SingleLineTextField
          name="lowerMidSectionHeading"
          values={values}
          label="Header Text"
          onChangeHandler={onChangeHandler}
           />
          <MultiLineTextField
          onChangeHandler={onChangeHandler}
          label="Description"
          name="lowerMidSectionParagraph"
          values={values}
           />
          <SingleLineTextField
          name="lowerMidSectionBtnText"
          values={values}
          label="Button Text"
          onChangeHandler={onChangeHandler}
           />
          <SingleLineTextField
          name="lowerMidSectionBtnLink"
          values={values}
          label="Button URL"
          onChangeHandler={onChangeHandler}
           />

          <UploadButton
           setLowerMidSectionFile={setLowerMidSectionFile}
            />


        {previewImageFileSrc && (
            <img src={previewImageFileSrc} alt="chosen" style={{height:"300px"}} />
            )}

          <SaveButton
          onClickHandler={onClickHandler}
          name="lowerMidSectionButton"
           />
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <div id="terms">
          <CKEditor
            editor={ ClassicEditor }
            config={ {
                      toolbar: ['heading','|','alignment','bold','italic','link','bulletedList','numberedList','blockQuote','undo','redo']
                  } }
            data={values.terms}
            onReady={ editor => {
              // You can store the "editor" and use when it is needed.
              // console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
              const data = editor.getData();
              setValues({...values,terms:data})

            } }
            onBlur={ ( event, editor ) => {
              // console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
              // console.log( 'Focus.', editor );
            } }
            />
          </div>


          <SaveButton
          onClickHandler={onClickHandler}
          name="termsButton"
           />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
