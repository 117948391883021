import React from "react";

import Dashboard from "./admin/containers/Dashboard";
import Adventures from "./admin/containers/Adventures";
import Pricings from "./admin/containers/Pricings";
import ContentCreators from "./admin/containers/ContentCreators";
import Gurus from "./admin/containers/Gurus";
import CheckIn from "./admin/containers/CheckIn";
import SalesPerson from "./admin/containers/SalesPerson.js";
import PrepaidUsers from "./admin/containers/PrepaidUsers.js";
import GuruRecommendations from "./admin/containers/GurusRecommendations";
import { Route, Switch } from "react-router-dom";

const ContainerSwitcher = ({ historyPath }) => {
  let routes = {
    "/a/dashboard": Dashboard,
    "/a/categories": Adventures,
    "/a/pricings": Pricings,
    "/a/content-creators": ContentCreators,
    "/a/gurus": Gurus,
    "/a/guru-recommendations/:id": GuruRecommendations,
    "/a/check-in": CheckIn,
    "/a/sales-people": SalesPerson,
    "/a/prepaid-users": PrepaidUsers,
  };

  return (
    <Switch>
      {Object.entries(routes).map(([route, component]) => (
        <Route path={route} component={component} />
      ))}
    </Switch>
  );
};

export default ContainerSwitcher;
