let initalState = {
  isSidebarToggled: true,
  selectedAdventureId: {},
  selectedAdventureRowId: {},
  selectedAdventure:{},
  selectedPost: {},
  addNewPostRowNum: null
};

function admin(state=initalState, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return ({
        ...state,
        isSidebarToggled: !state.isSidebarToggled
      });
    break;
    case "SELECT_ADVENTURE":
      return ({
        ...state,
        selectedAdventure: {...state.selectedAdventure,...action.payload}
      });
      break;
    case "SELECT_ADVENTURE_ROW":
      return ({
        ...state,
        selectedAdventureRow: {...state.selectedAdventureRow,...action.payload}
      });
      break;
    case "SELECT_POST":
      return ({
        ...state,
        selectedPost: {...state.selectedPost,...action.payload}
      });
      break;
    case "ADD_NEW_POST_ROW_NUM":
      return ({
        ...state,
        addNewPostRowNum: action.payload
      });
    case "CLEAR_ADD_NEW_POST_ROW_NUM":
      return ({
        ...state,
        addNewPostRowNum: null
      });
    default:
      return state;

  }
}

export default admin;
