import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

import index from "./indexReducers";
import admin from "./adminReducers";
import theme from "./themeReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin','index','theme']
}

const reducers = combineReducers({
  index,
  admin,
  theme
})

export default persistReducer(persistConfig,reducers);
