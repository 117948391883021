import React from "react";
import TextBody from "./TextBody";
const AudioPlayer = ({fileUrl,contentType,post}) => {
  return (
    <>
    <div className="container">
      <div class="card" style={{width: "100%"}}>
        <audio style={{margin:"auto"}} controls>
          <source src={`${fileUrl}`} type={`${post.contentType}`} />
        </audio>
        <div class="card-body text-center">
        <TextBody post={post} />
        </div>
      </div>
    </div>
    </>
  );
}

export default AudioPlayer;
