import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./SignInModal.css";

import SignInForm from "../../../components/SignInForm/SignInForm";

import { useSelector, useDispatch } from "react-redux";

import { toggleSignin } from "../../../store/actions/indexActions";
import { useAuth } from "../../../contexts/AuthContext";

const SignInModal = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const { isSigninModalOpened } = useSelector((state) => state.index);

  useEffect(() => {
    if (currentUser) setModal(false);

    setModal(isSigninModalOpened);
  }, [isSigninModalOpened]);

  const toggle = () => {
    dispatch(toggleSignin());
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <SignInForm />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SignInModal;
