import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const PastInvoicesModal = ({
  open,
  close,
  data,
  next,
  previous,
  loading,
  disablePrevButton,
  hasMoreInvoices,
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={close}
      external={
        <button
          className="close"
          style={{ position: "absolute", top: "15px", right: "15px" }}
          onClick={close}
        >
          &times;
        </button>
      }
      centered
    >
      {loading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <ModalHeader toggle={close}>Past Invoices</ModalHeader>
      <ModalBody
        className="px-3"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
          background: "white",
        }}
      >
        {!loading && data.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            {data &&
              data.map((each) => {
                return (
                  <tbody>
                    <tr>
                      <td>{new Date(each.created * 1000).toLocaleString()}</td>
                      <td>
                        {each.amount_paid / 100} {each.currency.toUpperCase()}
                      </td>
                      <td>
                        <a href={each.hosted_invoice_url} target="_blank">
                          View Here
                        </a>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        ) : loading ? (
          <p style={{ color: "black", textAlign: "center" }}>Loading</p>
        ) : (
          <p style={{ color: "black" }}>No Invoices</p>
        )}

        <div className="d-flex justify-content-center mt-5">
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={!disablePrevButton}>
              <PaginationLink previous href="#" onClick={() => previous()} />
            </PaginationItem>
            <PaginationItem disabled={!hasMoreInvoices}>
              <PaginationLink next href="#" onClick={() => next()} />
            </PaginationItem>
          </Pagination>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-sm btn-light" onClick={() => close()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PastInvoicesModal;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
