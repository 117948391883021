import React, { useState, useEffect } from "react";
import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import EditIcon from "@material-ui/icons/Edit";

import usePagination from "../hooks/usePagination";
import { firestore, storage } from "../../firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import SortAscAndDesc from "../../components/SortAscAndDesc/SortAscAndDesc";
import {
  ASCENDING_ORDER,
  DESCENDING_ORDER,
  getSortConditionBySortOrder,
  validSalesPersonId,
} from "../../util/helpers";
import { Button, Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";

const Users = () => {
  const [users, setUsers] = useState([]);
  const itemsPerPage = 50;

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstName");
  const [tempRows, setTempRows] = useState([]);
  const [searchBy, setSearchBy] = useState("firstName");
  const [loading, setLoading] = useState(false);
  const [updatedId, setUpdatedId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [error, setError] = useState(false);
  const [actionDetails, setActionDetails] = useState({
    userId: "",
    salesPersonId: "",
  });

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchUsers(false);

    return () => (isMounted = false);
  }, [orderDirection, orderBy]);

  useEffect(() => {
    setFilteredData(
      users.filter((user) => {
        return user[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, users]);

  const fetchUsers = async (getAllUsers = false) => {
    setLoading(true);
    const usersList = [];
    let usersRef;
    if (getAllUsers) {
      usersRef = firestore.collection("users");
    } else {
      setUsers([]);
      usersRef = firestore.collection("users").orderBy(orderBy, orderDirection);
    }

    const validRedemptionCodesRef = await firestore
      .collection("validRedemptionCodes")
      .get();
    const validRedemptionCodesArr = [];
    validRedemptionCodesRef.docs.forEach((d) =>
      validRedemptionCodesArr.push(d.data())
    );

    let isOwnerExists = false;

    await usersRef
      .get()
      .then(async (querySnapshot) => {
        for (const userDoc of querySnapshot.docs) {
          isOwnerExists = false;
          let userData = { ...userDoc.data(), uid: userDoc.id };

          if (userData.isPrepaidUser) {
            try {
              const voucherData = await fetchVoucher(userData.voucherRef);
              userData = { ...userData, voucherData };
            } catch (error) {
              console.error("Error fetching voucher data: ", error);
            }
          }

          if (validRedemptionCodesArr.length === 0) {
            usersList.push({
              ...userData,
              owner: false,
            });
          } else {
            validRedemptionCodesArr.forEach((v) => {
              if (v.owner === userData.email) {
                usersList.push({
                  ...userData,
                  owner: true,
                  numberOfSubs: v?.subscribers?.length || 0,
                });
                isOwnerExists = true;
              }
            });
          }

          if (!isOwnerExists) {
            usersList.push({
              ...userData,
              owner: false,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setLoading(false);
      });

    if (getAllUsers) {
      setLoading(false);
      return usersList;
    } else {
      setUsers(usersList);
      setLoading(false);
    }
  };

  const convertTimestamp = (time) => {
    const timestamp = new Date(time * 1000);
    const date = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();

    return `${month}-${date}-${year}`;
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("firstName");
        }
        setOrderBy(e.target.value);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("desc");
        }
        setOrderDirection(e.target.value);
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
      default:
        console.log("default case");
    }
  };

  const handleOnClickSort = (fieldName, order) => {
    const nonSortingFields = [
      "subscriptionStatus",
      "invoiceStatus",
      "lastPaid",
      "numberOfSubs",
    ];
    if (nonSortingFields.includes(fieldName)) return;

    setOrderBy(fieldName);
    setOrderDirection(order);
  };

  function jsonToCsv(jsonData) {
    let csv = "";
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(",") + "\n";
    // Add the data
    jsonData.forEach(function (row) {
      let data = headers.map((header) => JSON.stringify(row[header])).join(","); // Add JSON.stringify statement
      csv += data + "\n";
    });
    return csv;
  }

  const downloadUsers = async () => {
    try {
      setLoading(true);
      let allUsers = await fetchUsers(true);
      allUsers = allUsers.map((user) => {
        return {
          "First Name": user.firstName,
          "Last Name": user.lastName,
          "Phone Number": user.phoneNumber,
          Email: user.email,
          "Pricing Plan": user.pricingPlan,
          "Subscription Status": user.stripeData
            ? user.stripeData.status
            : "N/A",
          "Invoice Status":
            user.stripeData &&
            (user.stripeData.paid || user.stripeData.latest_invoice.status),
          "Last Paid":
            user.stripeData &&
            user.package &&
            user.stripeData.status_transitions &&
            user.stripeData.status_transitions.paid_at &&
            user.stripeData.status === "paid"
              ? convertTimestamp(user.stripeData.status_transitions.paid_at)
              : user.stripeData && user.package
              ? "n/a"
              : user.stripeData &&
                user.stripeData.latest_invoice.status_transitions.paid_at
              ? convertTimestamp(
                  user.stripeData.latest_invoice.status_transitions.paid_at
                )
              : null,
          Company: user.companyName,
          "Redemption Code": user.owner ? user.redemptionCode : "N/A",
          "Total Subscribers": user.owner ? user.numberOfSubs : 0,
          Created: moment(
            user?.createdAt
              ? user.createdAt.toDate()
              : new Date(user?.createdAt?._seconds * 1000)
          ).format("MMM DD, YYYY"),
        };
      });
      let csvData = jsonToCsv(allUsers); // Add .items.data
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "users.csv";
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw error;
    }
  };

  const openModal = (userId, salesPersonId, actionType) => {
    setActionDetails({
      userId,
      salesPersonId,
      actionType,
    });
    setUpdatedId(salesPersonId);
    setOpenConfirmModal(true);
  };

  const closeModal = () => {
    setActionDetails({
      userId: "",
      salesPersonId: "",
      actionType: "",
    });
    setUpdatedId("");
    setError(false);
    setOpenConfirmModal(false);
  };

  const confirmAction = async () => {
    try {
      if (Object.values(actionDetails).length) {
        setLoading(true);
        let updatedObj = {
          updatedAt: new Date(),
          salesPersonId: null,
        };
        if (actionDetails.actionType === "edit") {
          if (validSalesPersonId(updatedId)) {
            updatedObj = {
              salesPersonId: updatedId,
            };
          } else {
            throw new Error("Invalid Sales Person ID");
          }
        }
        await firestore
          .collection("users")
          .doc(actionDetails.userId)
          .update(updatedObj);

        await fetchUsers(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
      closeModal();
    } catch (error) {
      console.log("Error: " + error.message);
      setLoading(false);
      setError(error.message);
    }
  };

  const tableHeader = [
    {
      title: "First Name",
      value: "firstName",
    },
    {
      title: "Last Name",
      value: "lastName",
    },
    {
      title: "Phone Number",
      value: "phoneNumber",
    },
    {
      title: "Email",
      value: "email",
    },
    {
      title: "From Sales",
      value: "fromSales",
    },
    {
      title: "Pricing Plan",
      value: "pricingPlan",
    },
    {
      title: "Subscription Status",
      value: "subscriptionStatus",
    },
    {
      title: "Invoice Status",
      value: "invoiceStatus",
    },
    {
      title: "Last Paid",
      value: "lastPaid",
    },
    {
      title: "Company Name",
      value: "companyName",
    },
    {
      title: "Redemption Code",
      value: "redemptionCode",
    },
    {
      title: "Total Subscribers",
      value: "numberOfSubs",
    },
    {
      title: "Created",
      value: "createdAt",
    },
  ];

  const handleFileInputChange = async (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      if (file) {
        var storageRef = storage.ref();

        const flyersRef = storageRef.child("flyers/" + file.name);

        const snapshot = await flyersRef.put(file);
        const url = await snapshot.ref.getDownloadURL();
        const metadata = await snapshot.ref.getMetadata();
        const { fullPath } = metadata;

        await firestore.collection("access").doc("flyerURL").set({
          createdAt: new Date(),
          url,
          fullPath,
        });
        setLoading(false);
        alert("File uploaded successfully!");
      } else {
        setLoading(false);
        alert("Please select a file to upload.");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error uploading file: ", error.message);
      alert("Error uploading file");
    }
  };

  const fetchVoucher = async (ref) => {
    if (!ref) return {};

    const v_ref = firestore.collection("prepaidCodes").doc(ref);
    const v_doc = await v_ref.get();
    if (v_doc.exists) {
      return v_doc.data();
    }
    return {};
  };

  return (
    <>
      <div className="container mb-5">
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => setSearchedData(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="searchBy"
                className="form-control"
                value={searchBy}
              >
                <option value="0">Search By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="email">Email</option>
              </select>
            </div>

            {/* <div className="col-sm-3">
        <select value={orderBy} onChange={(e) => onChangeFilteringHandler(e)} name="orderBy" className="form-control">
          <option value="0">Order By</option>
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="createdAt">Created</option>
        </select>
        </div>

        <div className="col-sm-3">
        <select value={orderDirection} onChange={(e) => onChangeFilteringHandler(e)} name="orderDirection" className="form-control">
          <option value="0">Order Direction</option>
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        </div> */}
          </div>
        </form>

        <Button
          variant="outlined"
          className="mt-2"
          onClick={downloadUsers}
          startIcon={loading ? <CircularProgress size={20} /> : <GetAppIcon />}
          disabled={loading}
        >
          Download CSV
        </Button>
      </div>
      <div className="container">
        <label htmlFor="fileInput" style={{ margin: "0 0.5rem 1rem 0" }}>
          Upload Flyer
        </label>
        <input
          id="fileInput"
          type="file"
          onChange={handleFileInputChange}
          accept=".pdf"
        />
      </div>

      {loading ? (
        <div className="spinner_container">
          <Spinner />
        </div>
      ) : null}
      <table class="table table-striped">
        <thead>
          <tr>
            {tableHeader.map((each) => (
              <th scope="col">
                {[
                  "subscriptionStatus",
                  "invoiceStatus",
                  "lastPaid",
                  "numberOfSubs",
                ].includes(each.value) ? (
                  <SortAscAndDesc
                    isActive={false}
                    isAscending={false}
                    title={each.title}
                  />
                ) : (
                  <SortAscAndDesc
                    isActive={orderBy === each.value}
                    onClickAsc={() =>
                      handleOnClickSort(each.value, ASCENDING_ORDER)
                    }
                    onClickDesc={() =>
                      handleOnClickSort(each.value, DESCENDING_ORDER)
                    }
                    isAscending={orderDirection === ASCENDING_ORDER}
                    title={each.title}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            currentData() &&
            currentData().map((user) => {
              return (
                <tr key={user.uid}>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.salesPersonId ? user.salesPersonId : "None"}{" "}
                    {!user.salesPersonId && (
                      <>
                        <Tooltip title="Add Sales Person ID to this user">
                          <EditIcon
                            fontSize="small"
                            onClick={() =>
                              openModal(user.uid, user.salesPersonId, "edit")
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </>
                    )}
                    {user.salesPersonId && (
                      <>
                        <EditIcon
                          fontSize="small"
                          onClick={() =>
                            openModal(user.uid, user.salesPersonId, "edit")
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <DeleteIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            openModal(user.uid, user.salesPersonId, "delete")
                          }
                        />
                      </>
                    )}
                  </td>
                  <td>{user.pricingPlan}</td>
                  <td>{user.stripeData && user.stripeData.status}</td>
                  <td>
                    {user?.isPrepaidUser
                      ? `Prepaid - ${new Date(
                          user?.voucherData.validTill.seconds * 1000
                        ).toLocaleDateString()}`
                      : user?.stripeData &&
                        (user?.stripeData.paid ||
                          user?.stripeData.latest_invoice.status)}
                  </td>
                  <td>
                    {user.isPrepaidUser
                      ? new Date(
                          user.createdAt.seconds * 1000
                        ).toLocaleDateString()
                      : user.stripeData &&
                        user.package &&
                        user.stripeData.status_transitions &&
                        user.stripeData.status_transitions.paid_at &&
                        user.stripeData.status === "paid"
                      ? convertTimestamp(
                          user.stripeData.status_transitions.paid_at
                        )
                      : user.stripeData && user.package
                      ? "n/a"
                      : user.stripeData &&
                        user.stripeData.latest_invoice.status_transitions
                          .paid_at
                      ? convertTimestamp(
                          user.stripeData.latest_invoice.status_transitions
                            .paid_at
                        )
                      : "N/A"}
                  </td>
                  <td>
                    <Link
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#007bff",
                      }}
                      to={`/a/company_details/${user.companyId}/`}
                    >
                      {user.companyName}
                    </Link>
                  </td>
                  <td>{user.owner ? user.redemptionCode : "N/A"}</td>
                  <td>{user.owner ? user.numberOfSubs : 0}</td>
                  <td>
                    {moment(
                      user?.createdAt
                        ? user.createdAt.toDate()
                        : new Date(user?.createdAt?._seconds * 1000)
                    ).format("MMM DD, YYYY")}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
      <Modal isOpen={openConfirmModal} toggle={closeModal}>
        <div className="modal-top"></div>
        <ModalHeader>
          Confirm{" "}
          {actionDetails.actionType === "delete" ? "Deletion" : "Update"}
        </ModalHeader>
        <ModalBody
          className="px-3"
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          {error && <Alert color="danger">{error}</Alert>}
          <p style={{ color: "black" }} className="pt-3">
            This Action cannot be undone!
          </p>
          {actionDetails.actionType === "edit" && (
            <>
              <p style={{ color: "black", fontSize: "small" }} className="pt-3">
                Edit Sales Person ID
              </p>
              <input
                onChange={(e) => setUpdatedId(e.target.value)}
                name="salesPersonId"
                type="text"
                className="form-control"
                value={updatedId}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ReactButton color="primary" onClick={confirmAction}>
            {actionDetails.actionType === "edit" ? "Save" : "Delete"}
          </ReactButton>
          <ReactButton color="secondary" onClick={closeModal}>
            Cancel
          </ReactButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Users;
