import React from "react";
import {useHistory} from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import TextBody from "./TextBody";
import "./VideoPlayer.css"

import Paper from "./Paper";

const VideoPlayer = ({fileUrl,post}) => {
  return (
    <>
    <div className="container">
      <Paper>
        <ReactPlayer
         url={`${fileUrl}`}
         width='100%'
         class="reactPlayer"
         playing={true}
         controls={true}
         volume={1}
         progressInterval={5000}
         pip={true}
          />

        <div className="textBody">
          <TextBody post={post} />
        </div>
      </Paper>
    </div>
    </>
  );
}

export default VideoPlayer;


// import React from "react";
// import {useHistory} from "react-router-dom";
// import ReactPlayer from "react-player/lazy";
// import TextBody from "./TextBody";
// import "./VideoPlayer.css"
//
// import Paper from "./Paper";
//
// const VideoPlayer = ({fileUrl,post}) => {
//   return (
//     <>
//     <div className="container">
//       <Paper>
//         <ReactPlayer
//          url={`${fileUrl}`}
//          width='100%'
//          class="reactPlayer"
//          playing={true}
//          controls={true}
//          volume={1}
//          progressInterval={5000}
//          pip={true}
//           />
//
//         <div className="textBody">
//           <TextBody post={post} />
//         </div>
//
//       </Paper>
//     </div>
//     </>
//   );
// }
//
// export default VideoPlayer;




// import React from "react";
// import {useHistory} from "react-router-dom";
// import ReactPlayer from "react-player/lazy";
// import TextBody from "./TextBody";
// import "./VideoPlayer.css"
// const VideoPlayer = ({fileUrl,post}) => {
//   return (
//     <>
//     <div className="container">
//       <div class="card" style={{width: "100%"}}>
//         <ReactPlayer
//          url={`${fileUrl}`}
//          width='100%'
//          playing={true}
//          controls={true}
//          volume={1}
//          progressInterval={5000}
//          pip={true}
//           />
//         <div class="card-body text-center">
//         <TextBody post={post} />
//         </div>
//       </div>
//     </div>
//     </>
//   );
// }
//
// export default VideoPlayer;
