import React from "react";
import "./SmallFooter.css";

const SmallFooter = () => {
  return (
    <footer
      id="smallFooter"
      className="position-relative"
      style={{ height: "auto" }}
    >
      <img
        src="./assets/home/mem_sm/footer_svg1.svg"
        alt="footer_svg1"
        className="mb-2 sm_footer_svg1_sm d-block d-sm-none"
      />
      <img
        src="./assets/index/hr_svg.svg"
        alt="footer-svg"
        className="footer_logo_svg"
      />
      <hr className="mb-2" />
      <img
        src="./assets/leviosa.svg"
        alt="footer-svg"
        className="footer_logo_svg"
      />
      <p>
        Copyright 2020-{new Date().getFullYear().toString().slice(-2)}, All
        Rights Reserved
      </p>
      <p>
        <a href="mailto: help@leviosa.us" target="_blank">
          Contact Us
        </a>
      </p>
    </footer>
  );
};

export default SmallFooter;
