import React, { useEffect, useState } from "react";
import "./CarouselItem.css";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import { firestore, firebase, auth } from "../../firebase";

import ShareModal from "../ShareModal/ShareModal";
import { toggleShare } from "../../store/actions/indexActions";

const CarouselItem = ({
  type,
  adventureId,
  post,
  likedPosts,
  watchedPosts,
}) => {
  const [userName, setUserName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [contentType, setContentType] = useState("");

  useEffect(() => {
    if (post.contentType) {
      const mimeType = post.contentType;
      setContentType(mimeType.split("/")[0]);
    }
  }, []);

  const openUpNewTab = (url) => {
    const win = window.open(url, "_blank");
  };

  const onClickHandler = async (e) => {
    openUpNewTab(post.fileUrl);

    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;

      let date = firebase.firestore.Timestamp.now();

      const arrUnion = documentRef.update({
        watchedPosts: firebase.firestore.FieldValue.arrayUnion({
          adventureId,
          rowId: post.rowId,
          postId: post.id,
          date,
        }),
      });
    }
  };

  const onMouseOverController = (e) => {
    const id = "id_" + post.id;
    document.querySelector(`#${id}`).classList.add("displayBlock");
  };

  const onMouseOutController = (e) => {
    const id = "id_" + post.id;
    document.querySelector(`#${id}`).classList.remove("displayBlock");
  };

  const onClickHeartHandler = async (e) => {
    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0];
      const documentRef = snapshot.ref;

      let date = firebase.firestore.Timestamp.now();
      let isAlreadyLikedPost = likedPosts.find(
        (likedPost) => likedPost.postId === post.id
      );

      if (isAlreadyLikedPost) {
        const arrRemove = documentRef.update({
          likedPosts:
            firebase.firestore.FieldValue.arrayRemove(isAlreadyLikedPost),
        });
      } else {
        const arrUnion = documentRef.update({
          likedPosts: firebase.firestore.FieldValue.arrayUnion({
            adventureId,
            rowId: post.rowId,
            postId: post.id,
            date,
          }),
        });
      }
    }
  };

  const onclickShareHandler = async (e) => {
    const u_email = auth?.currentUser?.email;
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", `${u_email}`)
      .get();
    if (!querySnapshot.empty) {
      const snapshot = querySnapshot.docs[0].data();
      localStorage.setItem("firstName", snapshot.firstName);
    }
    // dispatch(toggleShare());
    setShowModal(true);
  };

  return (
    <>
      <div
        className={
          type === "featured"
            ? "card relevant-container adventuresCarousel"
            : "card relevant-container adventuresCarousel"
        }
      >
        {!post.corporateAdminId && !post.isScheduled ? (
          <span class="badge badge-pill custom-badge">New</span>
        ) : null}
        {post.corporateAdminId && (
          <span class="badge badge-pill custom-badge">&#9733;</span>
        )}

        {post.isPaid ? (
          <>
            <div id={`id_${post.id}`} className="noticeOverlayHolder">
              <div className="noticeOverlay">
                <p className="noticeOverlayText">
                  The 3rd party provider may offer extra services for an
                  additional charge
                </p>
              </div>
            </div>

            <div
              class="starburst"
              onMouseOut={(e) => onMouseOutController(e)}
              onMouseOver={(e) => onMouseOverController(e)}
            >
              $
            </div>
          </>
        ) : null}

        <img
          src={`${post.thumbnailUrl}`}
          alt="details"
          className="card-img-top"
        />
        <div className="card-body">
          <div className="text_combined_icon">
            <h5 className="card-title">{post.title}</h5>
          </div>

          {post.isOpenNewTab ? (
            <a
              onClick={(e) => onClickHandler(e)}
              className="stretched-link"
            ></a>
          ) : (
            <Link
              to={{
                pathname: `/adventure/${adventureId}/${post.rowId}/${post.id}`,
                state: post,
              }}
              className="stretched-link"
            ></Link>
          )}
        </div>

        <div
          className="card-footer"
          style={{
            paddingTop: "3px",
            paddingBottom: "8px",
            background: "none",
            borderTop: "0",
          }}
        >
          <div className="d-flex align-items-center">
            {!post.contentLength || post.contentLength == "None" ? null : (
              <p
                style={{
                  color: "#265376",
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
                className="card-text font-weight-bold mr-1"
              >
                <span className="font-weight-normal">
                  {post.contentLength} |
                </span>
              </p>
            )}
            <p
              style={{ color: "#265376", fontSize: "12px", lineHeight: "20px" }}
              className="card-text font-weight-bold mr-1"
            >
              <span className="font-weight-normal">{post.audience} |</span>
            </p>
            <p
              style={{ color: "#265376", fontSize: "12px", lineHeight: "20px" }}
              className="card-text font-weight-bold"
            >
              <span className="font-weight-normal">{post.userPhase}</span>
            </p>
          </div>
          <div
            className="d-flex justify-content-end align-items-center"
            style={{ marginTop: "2px" }}
          >
            {
              {
                image: (
                  <i style={{ color: "#414545" }} className="fas fa-image"></i>
                ),
                video: (
                  <i style={{ color: "#414545" }} className="fas fa-video"></i>
                ),
                application: (
                  <i
                    style={{ color: "#414545" }}
                    className="fas fa-file-pdf"
                  ></i>
                ),
                audio: (
                  <i
                    style={{ color: "#414545" }}
                    className="fas fa-volume-up"
                  ></i>
                ),
                externalWebpage: (
                  <i style={{ color: "#414545" }} className="fas fa-pager"></i>
                ),
                externalVideo: (
                  <i style={{ color: "#414545" }} className="fas fa-video"></i>
                ),
                externalAudio: (
                  <i
                    style={{ color: "#414545" }}
                    className="fas fa-volume-up"
                  ></i>
                ),
                externalImage: (
                  <i style={{ color: "#414545" }} className="fas fa-image"></i>
                ),
                externalDoc: (
                  <i
                    style={{ color: "#414545" }}
                    className="fas fa-file-pdf"
                  ></i>
                ),
              }[post.contentType]
            }

            <button
              onClick={(e) => onClickHeartHandler(e)}
              className="heartBtn ml-auto"
            >
              {likedPosts
                .map((likedPost, index) => likedPost.postId)
                .includes(`${post.id}`) ? (
                <i class="fas fa-heart"></i>
              ) : (
                <i class="far fa-heart"></i>
              )}
            </button>
            <button
              onClick={(e) => onclickShareHandler(e)}
              className="shareBtn ml-auto"
            >
              <i class="fas fa-share"></i>
            </button>
          </div>
        </div>
      </div>

      <ShareModal
        contentTitle={post.title}
        postId={post.id}
        adventureId={post.adventureId}
        rowId={post.rowId}
        show={showModal}
        toggleShow={() => setShowModal((prev) => !prev)}
      />
    </>
  );
};

export default CarouselItem;
