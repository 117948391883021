import React,{useEffect,useState} from 'react';
import Navbar from "../components/Navbar/Navbar";
import FavoriteTable from "../components/FavouriteTable/FavouriteTable";
import SmallFooter from "../components/SmallFooter/SmallFooter";

const stylesheet = {
  overflow: "hidden"
}

const Favourites = () => {

  return (
    <>
      <Navbar />

      <FavoriteTable />
        
       <SmallFooter />

    </>
  );
}

export default Favourites;
