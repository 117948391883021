import React,{useState,useEffect} from "react";
import {Spinner,Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import {firestore,storage,timestamp} from "../../firebase";
import {useFirestoreGetAllCompanyDetails} from "../../hooks/useFirestore";
import usePagination from "../hooks/usePagination";

import CompanyTable from "../components/CompanyTable";
import CompanyRowData from "../components/CompanyRowData";

import {useHistory} from "react-router-dom";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom:"0",
  background: "#00000047",
  zIndex: "100"
}

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100"
}

const initValues = {
  title: "",
  category: "",
  description: "",
  thumbnail: "",
  fileType: ""
}

const CompanyDetails = () => {
  const history = useHistory();

  const [isMounted,setIsMounted] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [itemsPerPage,setItemsPerPage] = useState(10);





  const [error,setError] = useState("");
  const [message,setMessage] = useState("");

  const [isQuickEdit,setIsQuikEdit] = useState(false);
  const [isAddNew,setIsAddNew] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [isDelete,setIsDelete] = useState(false);

  const [currentId,setCurrentId] = useState(null);

  const [values,setValues] = useState({...initValues});

  const [companies,setCompanies] = useState([]);

  const [orderDirection,setOrderDirection] = useState("asc");
  const [orderBy,setOrderBy] = useState("created");

  const [tempRows,setTempRows] = useState([]);
  const [searchBy,setSearchBy] = useState("companyName");

  const [filteredData,setFilteredData] = useState([]);
  const [searchedData,setSearchedData] = useState("");

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(filteredData,itemsPerPage);

  useEffect(() => {
    setIsMounted(true);
    setIsLoading(true);
    return () => setIsMounted(false);
  },[])

  useEffect(() => {
      const unsub = firestore
                    .collection("companies")
                    .orderBy(orderBy,orderDirection)
                    .onSnapshot(snapshot => {
                      setCompanies([]);
                      snapshot.forEach((doc) => {
                        setCompanies(companies => [...companies,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false)
                    });
       return () => unsub;
  },[orderBy,orderDirection]);


  useEffect(() => {
    setFilteredData(companies.filter((company) => {
      return (
        company[`${searchBy}`].toLowerCase().includes(searchedData.toLowerCase())
      );
    }))
  },[searchedData,companies])



  const onAddNewHandler = () => {
    history.push({
      pathname:`/a/company_details/add`
    });
  }

  const onCancelHandler = () => {

  }

  const onSaveHadler = () => {

  }

  const onEditHandler = (doc) => {
    history.push({
      pathname:`/a/company_details/${doc.id}/edit`,
      state:{doc}
    });
  }

  const onDeleteHandler = async (doc) => {
    const result = window.confirm("Are you sure you want to delete the form?")
    if (!result) {
      return ;
    }
    // await firestore.collection("companies").doc(doc.id).delete();
    setIsLoading(true);
    try {
      const companyRef = firestore.collection('companies').doc(doc.id);
      const companySnapShot = await companyRef.get();
      const companyRedemption = companySnapShot.data().companyRedemption;

      const validRedemptionCodesRef = firestore.collection('validRedemptionCodes').where('redemption','==',companyRedemption);

      let validRedemptionCodeId;
      const validRedemptionCodesData = await validRedemptionCodesRef.get();
      validRedemptionCodesData.docs.forEach((doc) => {
        validRedemptionCodeId = doc.id;
      });

      await firestore.collection('validRedemptionCodes').doc(validRedemptionCodeId).delete();
      await companyRef.delete();

    } catch (e) {
      console.log(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

  }

  const onShowHandler = (doc) => {
    history.push({
      pathname:`/a/company_details/${doc.id}/`,
      state:{doc}
    })
  }

  const onQuickEditHandler = () => {

  }

  const onChangeFileHandler = () => {

  }

  const onChangeValueHandler = () => {

  }

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i+1 ? "active" : null}><PaginationLink href="#" onClick={()=>jump(i+1)}>{i + 1}</PaginationLink></PaginationItem>
      )
    }
    return arr;
  }


  const onChangeFilteringHandler = (e) => {
      switch (e.target.name) {
        case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("created")
        }
        setOrderBy(e.target.value)
        break;
        case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("asc")
        }
        setOrderDirection(e.target.value)
        break;
        case "search":

          break;
        case "searchBy":
          setSearchBy(e.target.value)
          break;
      }
  }


  return (
    <>
    <h3>Company Details</h3>
    <div style={{position:"relative"}}>
      { isLoading && <div style={spinnerDivStyleSheet}><Spinner style={spinnerStyleSheet} /></div> }

      <CompanyTable
      onAddNewHandler={onAddNewHandler}
      onChangeFilteringHandler={onChangeFilteringHandler}
      setSearchedData={setSearchedData}
      >
      {
        currentData() && currentData().map(doc => {
          return (
            <tr key={doc.id}>

                <CompanyRowData
                  doc={doc}
                  onAddNewHandler={onAddNewHandler}
                  onEditHandler={onEditHandler}
                  onDeleteHandler={onDeleteHandler}
                  onShowHandler={onShowHandler}
                 />
            </tr>
          );
        })
      }
      </CompanyTable>

    </div>


      <div className="d-flex justify-content-center mt-5">
      <Pagination aria-label="Page navigation example">
        <PaginationItem>
          <PaginationLink previous href="#" onClick={(e) => prev()} />
        </PaginationItem>
        {showPaginationItems()}
        <PaginationItem>
          <PaginationLink next href="#" onClick={() => next()} />
        </PaginationItem>
      </Pagination>
      </div>




    </>
  );
}

export default CompanyDetails;
