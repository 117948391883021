import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import SortAscAndDesc from "../../SortAscAndDesc/SortAscAndDesc";
import { useAuth } from "../../../contexts/AuthContext";
import { ASCENDING_ORDER, DESCENDING_ORDER } from "../../../util/helpers";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Tooltip } from "@material-ui/core";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const CompanyUsersModal = ({
  open,
  close,
  data,
  handleSorting,
  getSubs,
  _loading = false,
}) => {
  const { toggleUserAuth } = useAuth();
  const [orderBy, setOrderBy] = useState("firstName");
  const [orderDirection, setOrderDirection] = useState(ASCENDING_ORDER);
  const [loading, setLoading] = useState(false);

  const handleOnClickSort = async (fieldName, order) => {
    const nonSortingFields = [
      "subscriptionStatus",
      "invoiceStatus",
      "lastPaid",
      "numberOfSubs",
    ];
    if (nonSortingFields.includes(fieldName)) return;

    setOrderBy(fieldName);
    setOrderDirection(order);

    await handleSorting({
      orderValue: fieldName,
      orderDirection: order,
    });
  };

  async function toggleHandler(e, params) {
    e.preventDefault();
    const result = window.confirm(
      `Are you sure you want to ${
        params.disable ? "Disable" : "Enable"
      } this user?`
    );
    if (result) {
      try {
        setLoading(true);
        await toggleUserAuth(params);
        await getSubs();
        setLoading(false);
      } catch (error) {
        console.log("Error: ", error.message);
        setLoading(false);
        alert(error.message);
      }
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={close}
      external={
        <button
          className="close"
          style={{ position: "absolute", top: "15px", right: "15px" }}
          onClick={close}
        >
          &times;
        </button>
      }
      centered
    >
      {(loading || _loading) && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <ModalHeader toggle={close}>Company Users</ModalHeader>
      <ModalBody
        className="px-3"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
          background: "white",
        }}
      >
        {!loading && data.length && (
          <table class="table table-striped">
            <thead>
              <tr>
                {[
                  { title: "Email", value: "email" },
                  { title: "First Name", value: "firstName" },
                  { title: "Last Name", value: "lastName" },
                  { title: "Created", value: "createdAt" },
                  { title: "Last Login", value: "lastLogin" },
                  { title: "Action", value: "action" },
                ].map((each) => (
                  <th scope="col">
                    {["action"].includes(each.value) ? (
                      <SortAscAndDesc
                        isActive={false}
                        isAscending={false}
                        title={each.title}
                      />
                    ) : (
                      <SortAscAndDesc
                        isActive={orderBy === each.value}
                        onClickAsc={() =>
                          handleOnClickSort(each.value, ASCENDING_ORDER)
                        }
                        onClickDesc={() =>
                          handleOnClickSort(each.value, DESCENDING_ORDER)
                        }
                        isAscending={orderDirection === ASCENDING_ORDER}
                        title={each.title}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>Loading</tr>
              ) : data && data.length ? (
                data.map((each) => {
                  return (
                    <tr key={each}>
                      <td>{each.email || "N/A"}</td>
                      <td>{each.firstName || "N/A"}</td>
                      <td>{each.lastName || "N/A"}</td>
                      <td>
                        {moment(
                          each?.createdAt
                            ? each.createdAt.toDate()
                            : new Date(each?.createdAt?._seconds * 1000)
                        ).format("MMM DD, YYYY")}
                      </td>
                      <td>{each.lastLogin || "N/A"}</td>
                      <td>
                        {each.isActive ? (
                          <Tooltip title="Disable User">
                            <CancelIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                toggleHandler(e, {
                                  uid: each.uid,
                                  disable: true,
                                })
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Enable User">
                            <CheckCircleIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                toggleHandler(e, {
                                  uid: each.uid,
                                  disable: false,
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>No Data</tr>
              )}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-sm btn-light" onClick={() => close()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CompanyUsersModal;
