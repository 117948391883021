import React, { useEffect, useState } from "react";
import "./HomeHeader.css";
import { Link } from "react-router-dom";

import { Alert } from "reactstrap";

import { firestore } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

const initValues = {
  mainSectionHeading: "",
  mainSectionParagraph: "",
  mainSectionBtnText: "",
  mainSectionBtnLink: "",
  tileSectionHeading: "",
  tileSectionParagraph: "",
  tileSectionFileUrl: "",
};

const GREETINGS = ["Hi", "Hello", "Welcome", "Greetings", "Good Day", "Howdy"];

const HomeHeader = () => {
  const { currentUser } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [message, setMessage] = useState("");

  const [values, setValues] = useState({ ...initValues });

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    let isMounted = true;
    const docRef = firestore.collection("users").doc(currentUser.uid);

    docRef
      .get()
      .then(function (doc) {
        if (isMounted && doc.exists) {
          setFirstName(doc.data().firstName);
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    setGreeting(GREETINGS[generateRandomNum(0, 5)]);

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const unsub = firestore
      .collection("home")
      .doc("main")
      .get()
      .then((res) => {
        if (isMounted) {
          const data = res.data();
          setValues({ ...data });
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const resendEmailVerification = async () => {
    try {
      setMessage("");
      if (currentUser && currentUser.email) {
        const response = await axios.post("/api/resend-verification-link", {
          email: currentUser.email,
        });
        if (response.status === 200) {
          setMessage("Verification email has been sent again");
        } else {
          setMessage("Failed to send verification email");
        }
      }
    } catch (error) {
      setMessage("Unexpected Error Occurred: ", error.message);
      console.log("Error while sending email: ", error.message);
    }
  };

  const generateRandomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <>
      <hr
        className="my-5 d-none d-md-block"
        style={{ borderTop: "1px solid #fff0" }}
      />
      <hr
        className="my-5 d-none d-md-block"
        style={{ borderTop: "1px solid #fff0" }}
      />
      <hr
        className="my-3 d-block d-md-none"
        style={{ borderTop: "1px solid #fff0" }}
      />

      <header id="home_header" className="position-relative">
        <img
          src="./assets/home/mem_lg/header_svg3.svg"
          alt="header_svg3"
          className="header_svg3 d-none d-md-block"
        />
        <img
          src="./assets/home/mem_lg/header_svg5.svg"
          alt="header_svg5"
          className="header_svg5 d-none d-md-block"
        />
        <div className="container">
          <div className="row header_top_container position-relative">
            <div className="col-12">
              <h1>
                {greeting},{" "}
                <span style={{ color: "var(--theme-color)" }}>{firstName}</span>{" "}
              </h1>

              <Alert
                color="warning"
                style={
                  currentUser.emailVerified
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <h6>Check your email to verify the account.</h6>
                <a
                  onClick={() => resendEmailVerification()}
                  style={{ cursor: "pointer" }}
                >
                  Re-send verification email.
                </a>
                {message ? <p>{message}</p> : null}
              </Alert>
            </div>
            <img
              src="./assets/home/mem_lg/header_svg1.svg"
              alt="header_svg1"
              className="header_svg1 d-none d-md-block"
            />
            <img
              src="./assets/home/mem_lg/header_svg2.svg"
              alt="header_svg2"
              className="header_svg2 d-none d-md-block"
            />
            <img
              src="./assets/home/mem_sm/header_svg1.svg"
              alt="header_svg1"
              className="header_svg1_sm d-block d-md-none"
            />
          </div>

          <hr
            className="my-1 my-md-2"
            style={{ borderTop: "1px solid #fff0" }}
          />

          {values.isShowTile ? (
            <div className="row header_middle_container position-relative">
              <div className="col-2 col-md-1">
                <div className="">
                  {values.tileSectionFileUrl ? (
                    <img
                      src={`${values.tileSectionFileUrl}`}
                      alt="profile_icon_svg"
                      className="profile_icon_svg"
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-10 col-md-11">
                <div className="profile_holder">
                  {/*<a href={`${values.tileSectionPostUrl}`} >
               {values.tileSectionFileUrl ? <img style={{width:"100%",height:"250px"}} src={`${values.tileSectionFileUrl}`} /> : null}
              </a>*/}
                  <h5 style={{ fontSize: "17px", marginBottom: "0px" }}>
                    {values.tileSectionHeading}
                  </h5>
                  <p style={{ fontSize: "15px" }}>
                    {values.tileSectionParagraph}
                  </p>
                </div>
              </div>
              <Link
                to={{
                  pathname: `${values.tileSectionPostUrl.split(".com")[1]}`,
                }}
                className="stretched-link"
              ></Link>
            </div>
          ) : null}

          <hr className="my-3" style={{ borderTop: "1px solid #fff0" }} />

          <div className="row header_bottom_container position-relative">
            <img
              src="./assets/index/mem_sm/header_svg2.svg"
              alt="header_svg2"
              className="header_svg2_sm d-none"
            />

            <img
              src="./assets/home/mem_lg/header_svg4.svg"
              alt="header_svg4"
              className="header_svg4 d-none d-sm-block"
            />

            <div className="col-12 d-flex d-sm-block header_bottom_text_container_col">
              <div className="text_container header_bottom_text_container">
                <h3>{values.mainSectionHeading}</h3>
                <hr className="my-2" style={{ borderTop: "1px solid #fff0" }} />
                <p>{values.mainSectionParagraph}</p>
                {values.mainSectionBtnText ? (
                  <a href={`${values.mainSectionBtnLink}`} className="btn">
                    {values.mainSectionBtnText}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>

      <img
        src="./assets/home/mem_sm/footer_svg1.svg"
        style={{ opacity: "40%" }}
        alt="footer_svg1"
        className="footer_svg1_sm d-block d-sm-none position-absolute"
      />
    </>
  );
};

export default HomeHeader;
