import { setThemeColor } from "../../util/theme";
import { SET_THEME } from "./types";

export const setTheme = (theme, isDefaults = false) => (dispatch) => {
  if (theme.color) {
    setThemeColor(theme.color);
    setThemeColor(theme.color, "--theme-color-light");
    setThemeColor(theme.color, "--theme-color-dark");
    setThemeColor(theme.color, "--theme-color-light2");
  }
  if(isDefaults){
    setThemeColor('#7854DF');
    setThemeColor('#9A66EF','--theme-color-light');
    setThemeColor('#BB9CEE','--theme-color-light2');
    setThemeColor('#6144D6','--theme-color-dark');
  }
  dispatch({
    type: SET_THEME,
    payload: theme,
  });
};
