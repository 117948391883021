import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import CustomBackdrop from "./components/Loader";

export default function PublicRoute({ component: Component, ...rest }) {
  const { currentUser, issalesPerson } = useAuth();

  if (currentUser && issalesPerson === null) return <CustomBackdrop />;

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Redirect
            to={
              issalesPerson
                ? `/salesportal/dashboard/${currentUser.uid}`
                : "/welcome"
            }
          />
        ) : (
          <Component {...props} />
        );
      }}
    ></Route>
  );
}
