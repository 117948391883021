import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ContactFormModal.css";

import ContactForm from "../../components/ContactForm/ContactForm";

import {useSelector,useDispatch} from "react-redux";

import {toggleContact} from "../../store/actions/indexActions";

const ContactFormModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const {isContactModalOpened,isSigninModalOpened} = useSelector(state => state.index)


  useEffect(() => {
    setModal(isContactModalOpened);
  },[])

  useEffect(() => {
    setModal(isContactModalOpened);
  },[isContactModalOpened])

  const toggle = () => {
    dispatch(toggleContact());
  }

  // console.log(`isContactModalOpened ==== >>>`, isContactModalOpened, isSigninModalOpened)



  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>

          <ContactForm />

        </ModalBody>
      </Modal>
    </div>
  );
}

export default ContactFormModal;
