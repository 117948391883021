import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import IndexHeader from "../components/IndexHeader/IndexHeader";
import Features from "../components/Features/Features";
import Footer from "../components/Footer/Footer";
import Pricing from "../components/Pricing/Pricing";
import SignInModal from "../containers/signin/SignInModal/SignInModal";
import SignUpModal from "../containers/signup/SignUpModal/SignUpModal";
import ContactFormModal from "../containers/contact/ContactFormModal";
import PaymentFormModal from "../containers/payment/PaymentFormModal";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSignin } from "../store/actions/indexActions";
import CustomTabs from "../components/Tabs";

const Index = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  let typeParam = queryParams.get("type");

  useEffect(() => {
    if (id && (id === "sign-in" || id === "signin")) {
      dispatch(toggleSignin());
    }
  }, [id]);

  useEffect(() => {
    if (!typeParam) {
      typeParam = "businesses";
      queryParams.set("type", typeParam);
      history.push({ search: queryParams.toString() });
    }
  }, [typeParam, queryParams, history]);

  const tabChangeHandler = (val) => {
    typeParam = val == 0 ? "businesses" : "individual";
    queryParams.set("type", typeParam);
    history.push({ search: queryParams.toString() });
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <Navbar />
        <CustomTabs tabChangeHandler={tabChangeHandler} />
        <IndexHeader pageType={typeParam} />
        <hr className="mt-5" />
        <Features title="Categories" page="index" />
        <Pricing pageType={typeParam} />
        <Footer />
        <SignInModal />
        <SignUpModal />
        <ContactFormModal />
        <PaymentFormModal />
      </div>
    </>
  );
};

export default Index;
