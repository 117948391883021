import React, {useState,useEffect} from "react";
import {firestore,storage,timestamp} from "../../firebase";

import {Alert} from "reactstrap";

import AdminHomeTabs from "./AdminHome/AdminHomeTabs";
import Spinner from "./AdminHome/Spinner";
import Snackbar from "./AdminHome/SnackBar";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom:"0",
  background: "#00000047",
  zIndex: "100"
}

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100"
}

const initValues = {
  mainSectionHeading: "",
  mainSectionParagraph:"",
  mainSectionBtnText:"",
  mainSectionBtnLink:"",
  tileSectionHeading: "",
  tileSectionParagraph: "",
  tileSectionFileUrl: "",
  tileSectionFileFullPath: "",
  tileSectionPostUrl:"",
  isShowTile: false
}

const AdminHome = () => {
  const [values,setValues] = useState({...initValues});
  const [tileSectionFile,setTileSectionFile] = useState(null);

  const [isSnackBarOpen,setIsSnackBarOpen] = useState(false);
  const [snackBarType,setSnackBarType] = useState("");
  const [snackBarMessage,setSnackBarMessage] = useState("");

  const onChangeHandler = (e) => {
    const {name,value} = e.target;
    if (e.target.name === "isShowTile") {
      setValues({...values,[name]:e.target.checked})
    }else {
      setValues({...values,[name]:value})
    }
  }

  useEffect(async () => {
    const unsub = await firestore.collection("home").doc("main").get();
    const data = unsub.data();
    setValues({...data});

    return () => unsub;
  },[])



  const [isLoading,setIsLoading] = useState(false);
  const [message,setMessage] = useState("");
  const [error,setError] = useState("");




  const onClickHandler = async (e) => {

    switch (e.currentTarget.getAttribute('name')) {
      case "mainSectionButton":
        setIsLoading(true);
        setMessage("");
      await  firestore.collection("home").doc('main').set({
          ...values
        },{merge:true}).then(res => {
          setIsLoading(false);

          setSnackBarType("success");
          setSnackBarMessage("Updated Successfully");
          setIsSnackBarOpen(true);
        }).catch(err => {
          setIsLoading(false);

          setSnackBarType("error");
          setSnackBarMessage("Update Failed");
          setIsSnackBarOpen(true);
        })
        break;
        case "tileSectionButton":
          setIsLoading(true);
          setMessage("");

          if (tileSectionFile) {

            const doc = await firestore.collection("home").doc("main").get()
              const res = await doc.data();



              if (res && res.tileSectionFileUrl) {
                setIsLoading(true);
                const {tileSectionFileFullPath} =  res;
                const storageRefOld = storage.ref(tileSectionFileFullPath);
                await storageRefOld.delete();

              const times = Date.now();
              const storageRef = storage.ref(`adventures/${tileSectionFile.name}-${times}`);

              storageRef.put(tileSectionFile).on('state_changed', (snap) => {
                let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                // setProgress(percentage);
              }, (err) => {
                setIsLoading(false);
                setSnackBarType("error");
                setSnackBarMessage("Update Failed");
                setIsSnackBarOpen(true);
              }, async () => {
                const url = await storageRef.getDownloadURL();
                const metadata = await storageRef.getMetadata();
                const fullPath = metadata.fullPath;
                await firestore.collection("home").doc("main").set({...values,tileSectionFileUrl:url,tileSectionFileFullPath:fullPath,created:timestamp()},{merge:true});
                setSnackBarType("success");
                setSnackBarMessage("Updated Successfully");
                setIsSnackBarOpen(true);

                setValues(initValues);
                setTileSectionFile(null);
                setIsLoading(false);
              });


              }else{
                const times = Date.now();
                const storageRef = storage.ref(`adventures/${tileSectionFile.name}-${times}`);

                storageRef.put(tileSectionFile).on('state_changed', (snap) => {
                  let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                  // setProgress(percentage);
                }, (err) => {
                  setIsLoading(false);
                  setSnackBarType("error");
                  setSnackBarMessage("Update Failed");
                  setIsSnackBarOpen(true);
                }, async () => {
                  const url = await storageRef.getDownloadURL();
                  const metadata = await storageRef.getMetadata();
                  const fullPath = metadata.fullPath;
                  await firestore.collection("home").doc("main").set({...values,tileSectionFileUrl:url,tileSectionFileFullPath:fullPath,created:timestamp()},{merge:true});
                  setSnackBarType("success");
                  setSnackBarMessage("Updated Successfully");
                  setIsSnackBarOpen(true);

                  setValues(initValues);
                  setTileSectionFile(null);
                  setIsLoading(false);
                });
              }

          }else {
            ///
            firestore.collection("home").doc('main').set({
              tileSectionHeading: values.tileSectionHeading,
              tileSectionParagraph: values.tileSectionParagraph,
              tileSectionPostUrl: values.tileSectionPostUrl,
              tileSectionFileUrl: values.tileSectionFileUrl,
              tileSectionFileFullPath: values.tileSectionFileFullPath,
              isShowTile: values.isShowTile
            },{merge:true}).then(res => {
              setIsLoading(false);

              setSnackBarType("success");
              setSnackBarMessage("Updated Successfully");
              setIsSnackBarOpen(true);
            }).catch(err => {
              setIsLoading(false);

              setSnackBarType("error");
              setSnackBarMessage("Update Failed");
              setIsSnackBarOpen(true);
            })
            ////
          }


          break;

    }
  }

  const onFileSubmitHandler = async (e) => {
    const doc = await firestore.collection("home").doc("main").get()
      const res = await doc.data();



      if (res && res.tileSectionFileUrl) {
        setIsLoading(true);
        const {tileSectionFileFullPath} =  res;
        const storageRefOld = storage.ref(tileSectionFileFullPath);
        await storageRefOld.delete();

      const times = Date.now();
      const storageRef = storage.ref(`adventures/${tileSectionFile.name}-${times}`);

      storageRef.put(tileSectionFile).on('state_changed', (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        // setProgress(percentage);
      }, (err) => {
        setError(err.message);
        setTimeout(()=>setError(""),2000);
      }, async () => {
        const url = await storageRef.getDownloadURL();
        const metadata = await storageRef.getMetadata();
        const fullPath = metadata.fullPath;
        await firestore.collection("home").doc("main").set({...values,tileSectionFileUrl:url,tileSectionFileFullPath:fullPath,created:timestamp()},{merge:true});
        setMessage("Data added successfully");
        setTimeout(() => setMessage(""),2000);
        setValues(initValues);
        setTileSectionFile(null);
        setIsLoading(false);
      });


      }else{
        const times = Date.now();
        const storageRef = storage.ref(`adventures/${tileSectionFile.name}-${times}`);

        storageRef.put(tileSectionFile).on('state_changed', (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        }, (err) => {
          setError(err.message);
          setTimeout(()=>setError(""),2000);
        }, async () => {
          const url = await storageRef.getDownloadURL();
          const metadata = await storageRef.getMetadata();
          const fullPath = metadata.fullPath;
          await firestore.collection("home").doc("main").set({...values,tileSectionFileUrl:url,tileSectionFileFullPath:fullPath,created:timestamp()},{merge:true});
          setMessage("Data added successfully");
          setTimeout(() => setMessage(""),2000);
          setValues(initValues);
          setTileSectionFile(null);
          setIsLoading(false);
        });
      }



  }



  return (
    <>

    {
      isLoading ?
      <div style={{
        position: "absolute",
        background: "#f5f5f5",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: "100",
        opacity: "0.7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}><Spinner /></div>
      : null
    }

    <Snackbar
    isSnackBarOpen={isSnackBarOpen}
    setIsSnackBarOpen={setIsSnackBarOpen}
    snackBarMessage={snackBarMessage}
    snackBarType={snackBarType} />

    <div className="mb-4">
      <h4>Home Page</h4>
    </div>

    <AdminHomeTabs
    values={values}
    setValues={setValues}
    onClickHandler={onClickHandler}
    onChangeHandler={onChangeHandler}
    setTileSectionFile={setTileSectionFile}
    tileSectionFile={tileSectionFile}
     />

    </>
  );
}

export default AdminHome;
