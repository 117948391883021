import * as types from "../actions/types";

let initalState = {
  selectedPlanDetails: {},
  isSigninModalOpened: false,
  isSignupModalOpened: false,
  isContactModalOpened: false,
  isShareModalOpened: false,
  isPaymentModalOpened: false,
  selectedAdventure: {},
  userInfo: {},
  isShowTermPaper: false,
  userSignupForm: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    redemptionCode: "",
    email: "",
    password: "",
    confirmPassword: "",
    checkConfrm: false
  }
};

function index(state=initalState, action) {
  // console.log(action.payload)
  switch (action.type) {
    case types.TOGGLE_SIGNIN:
      return ({
        ...state,
        isSigninModalOpened: !state.isSigninModalOpened
      });
      break;
    case types.TOGGLE_SIGNUP:
      return ({
        ...state,
        isSignupModalOpened: !state.isSignupModalOpened
      });
      break;
    case types.TOGGLE_CONTACT:
      return ({
        ...state,
        isContactModalOpened: !state.isContactModalOpened
      });
      break;
    case types.TOGGLE_PAYMENT:
      return ({
        ...state,
        isPaymentModalOpened: !state.isPaymentModalOpened
      });
      break;
    case types.SELECT_PLAN:
      return ({
        ...state,
        selectedPlanDetails: {
          ...action.payload
        }
      });
    case types.SET_USER_INFO:
      return ({
        ...state,
        userInfo: {
          ...action.payload
        }
      });
      break;
    case "SELECT_ADVENTURE":
      return ({
        ...state,
        selectedAdventure: {
          ...action.payload
        }
      });
      break;
    case "CLEAR_SELECT_ADVENTURE":
      return ({
        ...state,
        selectedAdventure: {}
      });
    case "SHOW_TERM_PAPER":
      return ({
        ...state,
        isShowTermPaper: true
      });
    case "DONT_SHOW_TERM_PAPER":
      return ({
        ...state,
        isShowTermPaper: false
      });
    case "USER_SIGNUP_INFO":
      return ({
        ...state,
        userSignupForm: {
          ...state.userSignupForm,
          ...action.payload
        }
      });
    case types.TOGGLE_SHARE:
      return ({
        ...state,
        isShareModalOpened: !state.isShareModalOpened
      });
      break;
    default:
      return state;

  }
}

export default index;
