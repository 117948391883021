import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export default function MultilineTextField(props) {
  const classes = useStyles();


  const {label,onChangeHandler,values,name} = props;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="outlined-multiline-static"
          label={`${label}`}
          onChange={(e) => onChangeHandler(e)}
          value={values[`${name}`]}
          name={name}
          multiline
          rows={4}
          variant="outlined"
        />
      </div>
    </form>
  );
}
