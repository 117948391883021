import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
// import "./FeaturedContainer.css";
// import "./FeaturedContainer.css";

import { useFirestoreGetAllAdventureDocsById } from "../../hooks/useFirestore";

import { firestore } from "../../firebase";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CarouselItem from "../../components/CarouselItem/CarouselItem";

const featuredResponsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 2,
  },
  mobile: {
    breakpoint: {
      max: 564,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 564,
    },
    items: 2,
  },
};

// const relevantResponsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 1387 },
//     items: 5,
//     partialVisibilityGutter: 40
//   },
//   desktop: {
//       breakpoint: {
//         max: 1386,
//         min: 1025
//       },
//       items: 4,
//       partialVisibilityGutter: 40
//     },
//     tablet: {
//       breakpoint: {
//         max: 1024,
//         min: 565
//       },
//       items: 3,
//       partialVisibilityGutter: 40
//     },
//     mobile: {
//       breakpoint: {
//         max: 564,
//         min: 0
//       },
//       items: 1,
//       partialVisibilityGutter: 40
//     },
// };

const relevantResponsive = {
  monsterDesktop: {
    breakpoint: {
      max: 4000,
      min: 1920,
    },
    items: 7,
    partialVisibilityGutter: 40,
  },
  giantDesktop: {
    breakpoint: {
      max: 1920,
      min: 1668,
    },
    items: 7,
    partialVisibilityGutter: 25,
  },
  another_superLargeDesktop: {
    breakpoint: {
      max: 1668,
      min: 1441,
    },
    items: 6,
    partialVisibilityGutter: 25,
  },
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1440, min: 1387 },
    items: 5,
    partialVisibilityGutter: 25,
  },
  desktop1: {
    breakpoint: {
      max: 1386,
      min: 1301,
    },
    items: 5,
    partialVisibilityGutter: 10,
  },
  desktop: {
    breakpoint: {
      max: 1300,
      min: 1025,
    },
    items: 4,
    partialVisibilityGutter: 25,
  },
  another_tablet: {
    breakpoint: {
      max: 1024,
      min: 768,
    },
    items: 3,
    partialVisibilityGutter: 25,
  },
  tablet: {
    breakpoint: {
      max: 767,
      min: 426,
    },
    items: 2,
    partialVisibilityGutter: 25,
  },
  mobile: {
    breakpoint: {
      max: 425,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 25,
  },
};

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

const DetailsContainer = ({
  type,
  rowId,
  adventureId,
  likedPosts,
  watchedPosts,
  corpAdminPosts,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [posts, setPosts] = useState([]);
  const [filteredCorporateAdminPosts, setFilteredCorporateAdminPosts] =
    useState([]);

  // console.log(doc.data());
  // useEffect(() => {
  //   setIsMounted(true);
  //   // setIsLoading(true);
  //   return () => setIsMounted(false);
  // },[]);

  // console.log(doc);
  useEffect(async () => {
    type === "featured"
      ? require("./FeaturedContainer.css")
      : require("./RelevantContainer.css");

    const postsRef = await firestore
      .collection("m_posts")
      .where("rowId", "==", rowId.id)
      .orderBy("rowNumber", "asc")
      .get();

    const arr = [];

    postsRef.docs.forEach((doc) => {
      // const {id,title} = doc.data();
      // console.log(id)
      arr.push(doc.data());
      // console.log(doc.data())
    });
    setPosts((posts) => [...posts, ...arr]);
  }, []);

  useEffect(() => {
    let filteredAdminPosts = corpAdminPosts.filter((each) => {
      return each.adventureId === adventureId && each.rowId === rowId.id;
    });

    setFilteredCorporateAdminPosts(filteredAdminPosts);
  }, [posts, corpAdminPosts]);

  // console.log("Checking details container..",doc.id)

  return (
    <div
      className={
        type === "featured"
          ? "relevant-container position-relative"
          : "relevant-container position-relative"
      }
    >
      {isLoading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}

      {rowId && posts.length > 0 ? (
        <div className="row title no-gutters">
          <div className="col-12">
            <h2>
              {" "}
              {rowId.title} {/*<span className="subtitle">Relevant</span>*/}
            </h2>
          </div>
        </div>
      ) : null}

      <Carousel
        responsive={
          type === "featured" ? relevantResponsive : relevantResponsive
        }
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        partialVisible
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {[...filteredCorporateAdminPosts, ...posts].map((post, key) => (
          <CarouselItem
            adventureId={adventureId}
            type={type}
            post={post}
            key={key}
            likedPosts={likedPosts}
            watchedPosts={watchedPosts}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default DetailsContainer;
