import React from "react";
import {Alert} from "reactstrap";
import SingleAdventureRowEditor from "./SingleAdventureRowEditor";

const SingleAdventureTable = ({
  children
  ,isAddNew
  ,isEdit
  ,onSaveHandler
  ,onCancelHandler
  ,onAddNewHandler
  ,message
  ,error
  ,onValueChangeHandler
  ,onFileChangeHandler
  ,onChangeFilteringHandler
  ,setSearchedData
  ,values}) => {

    const onSubmitHandler = (e) => {
      e.preventDefault();
    }

  return (
    <>

    <div className="container mb-5">

    <form onSubmit={(e) => onSubmitHandler(e)}>
      <div className="form-row">
        <div className="col-sm-3">
          <input onChange={(e) => setSearchedData(e.target.value)} name="search" type="text" className="form-control" placeholder="Search" />
        </div>

        <div className="col-sm-3">
        <select onChange={(e) => onChangeFilteringHandler(e)} name="searchBy" className="form-control">
          <option value="0">Search By</option>
          <option value="title">Title</option>
        </select>
        </div>

       

      </div>
    </form>

    </div>

    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Row</th>
          <th scope="col">Title</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          children
        }

        {
          isAddNew ?
            <SingleAdventureRowEditor
            values={values}
            onFileChangeHandler={onFileChangeHandler}
            onValueChangeHandler={onValueChangeHandler}
             /> : null
        }

      </tbody>
    </table>

    {message && <Alert color="success">{message}</Alert>}
    {error && <Alert color="danger">{error}</Alert>}

    <div>
    {
      isAddNew || isEdit ?
      <>
      <button
       onClick={() => onSaveHandler()}
       className="btn btn-sm btn-primary">Save</button>
       <button
        onClick={() => onCancelHandler()}
        className="btn btn-sm btn-warning mx-3">Cancel</button>
        </>
       :
       <button
       onClick={() => onAddNewHandler()}
       className="btn btn-sm btn-primary">Add New</button>
    }
    </div>
    </>
  );
}

export default SingleAdventureTable;
