import React from "react";

const ContentCreatorRowEditor = ({
  values,
  onValueChangeHandler,
  onFileChangeHandler,
}) => {
  return (
    <>
      <td>
        {" "}
        <input
          onChange={(e) => onValueChangeHandler(e)}
          className="form-control"
          value={values.name}
          name="name"
          type="text"
          placeholder="Name"
        />{" "}
      </td>
      <td>
        {" "}
        <input
          onChange={(e) => onValueChangeHandler(e)}
          className="form-control"
          value={values.description}
          name="description"
          type="text"
          placeholder="Description"
        ></input>{" "}
      </td>
      <td>
        {" "}
        <input
          onChange={(e) => onValueChangeHandler(e)}
          className="form-control"
          value={values.website}
          name="website"
          type="text"
          placeholder="Website URL"
        />{" "}
      </td>
    </>
  );
};

export default ContentCreatorRowEditor;
