import { MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { firestore } from "../../../../firebase";
import CLoader from "../../CLoader/CLoader";

import "./ChangeCategoryModal.css";

const ChangeCategoryModal = (props) => {
  const {
    buttonLabel,
    className,
    show,
    onClose,
    selectedRows,
    selectedAdventureRow: initialselectedAdventureRow,
    selectedAdventure: initialAdventure,
  } = props;
  const [Adventures, setAdventures] = useState([]);
  const [Rows, setRows] = useState([]);
  const [selectedAdventure, setSelectedAdventure] = useState(
    initialAdventure?.id
  );
  const [selectedAdventureRow, setSelectedAdventureRow] = useState(
    initialselectedAdventureRow?.id
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    firestore
      .collection("m_adventures")
      .get()
      .then((res) => {
        setAdventures(res.docs.filter((it) => it.id !== "settings"));
      });

    firestore
      .collection("m_rows")
      .get()
      .then((res) => {
        setRows(res.docs);
      });
  }, []);

  const onUpdate = async () => {
    if (!selectedAdventureRow || !selectedAdventure) return null;
    console.log(selectedRows, selectedAdventure, selectedAdventureRow);

    setLoading(true);

    await Promise.all(selectedRows.map((each) =>
      firestore.collection("m_posts").doc(each).update({
        adventureId: selectedAdventure,
        rowId: selectedAdventureRow,
      })
    ));

    alert('Updated posts!');
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={!loading ? onClose:null} className={className} centered>
        <div className="modal-top"></div>
        <ModalHeader toggle={!loading ? onClose:null}></ModalHeader>
        <ModalBody>
          <div className="category-container">
          { loading && <CLoader />}
            <div>
              <h2>Change Adventure/Category</h2>
              <p>Selected Items: {selectedRows.length}</p>
            </div>

            <div>
              <label>Adventure </label>
              <select
                onChange={(e) => {
                  setSelectedAdventureRow("");
                  setSelectedAdventure(e.target.value);
                }}
                name="category"
                className="form-control"
                value={selectedAdventure}
                disabled={loading}
              >
                <option value="">Select a Adventure</option>
                {Adventures.map((eachAdventure) => (
                  <option key={eachAdventure.id} value={eachAdventure.id}>
                    {eachAdventure.data().title}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label>Category</label>
              <select
                onChange={(e) => setSelectedAdventureRow(e.target.value)}
                name="category"
                className="form-control"
                value={selectedAdventureRow}
                disabled={loading}
              >
                <option value="">Select a category</option>
                {Rows.filter(
                  (e) => e.data().adventureId === selectedAdventure
                ).map((eachAdventure) => (
                  <option key={eachAdventure.id} value={eachAdventure.id}>
                    {eachAdventure.data().title}
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={!selectedAdventureRow || !selectedAdventure || loading}
              onClick={onUpdate}
              className="btn btn-sm btn-primary mb-4"
            >
              Update
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangeCategoryModal;
