import React from "react";
import "./assets/css/custom.css";

import { useHistory } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import TopNavbar from "./components/TopNavbar";
import ContainerSwitcher from "../ContainerSwitcher";

const Admin = ({ children }) => {
  const history = useHistory();

  return (
    <div
      className="wrapper"
      style={{ background: "#fafafa", width: "max-content", minWidth: "100%" }}
    >
      <Sidebar />
      <div id="content">
        <TopNavbar />

        <ContainerSwitcher historyPath={history.location.pathname} />

        {children ? children : null}
      </div>
    </div>
  );
};

export default Admin;
