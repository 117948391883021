import React, { useEffect } from "react";
import "./TextBody.css";

const TextBody = ({ post }) => {
  useEffect(() => {
    // document.querySelector("#textBody_ckEditor_wwli").innerHTML = post.wwli
    document.querySelector("#textBody_ckEditor_desc").innerHTML =
      post.description;
  }, []);

  return (
    <>
      <div className="container pt-4 mb-2">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <h5 style={{ color: "#34373a" }} className="font-weight-bold">
              <span>{post.title}</span>
            </h5>
            <div class="mr-4 mb-2">
              <button
                onClick={(e) => post?.onclickShareHandler(e)}
                className="shareBtn mr-2"
              >
                <i class="fas fa-share"></i>
              </button>

              <button
                onClick={(e) => post?.onClickHeartHandler(e)}
                className="heartBtn ml-4"
              >
                {post?.likedPosts
                  .map((likedPost, index) => likedPost.postId)
                  .includes(`${post.id}`) ? (
                  <i class="fas fa-heart"></i>
                ) : (
                  <i class="far fa-heart"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="row">
          <div className="col-sm-3">
            <p
              style={{ color: "#34373a", fontSize: "14px", lineHeight: "20px" }}
              className="card-text font-weight-bold"
            >
              Category:{" "}
              <span className="font-weight-normal">
                {post.category || post.adventureTitle}
              </span>
            </p>
            <p
              style={{ color: "#34373a", fontSize: "14px", lineHeight: "20px" }}
              className="card-text font-weight-bold"
            >
              Audience:{" "}
              <span className="font-weight-normal">{post.audience}</span>
            </p>
            {!post.contentLength || post.contentLength == "None" ? null : (
              <p
                style={{
                  color: "#34373a",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                className="card-text font-weight-bold"
              >
                Content Length:{" "}
                <span className="font-weight-normal">{post.contentLength}</span>
              </p>
            )}
            {post?.contentCreator?.name && (
              <p
                style={{
                  color: "#34373a",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                className="card-text font-weight-bold"
              >
                Content Creator:{" "}
                <span className="font-weight-normal">
                  {post?.contentCreator?.name}
                </span>
              </p>
            )}
          </div>
          <div
            className="col-sm-9 textBody_border"
            style={{ wordBreak: "break-word" }}
          >
            <p
              style={{ color: "#34373a", fontSize: "14px", lineHeight: "20px" }}
              className="card-text font-weight-bold"
            >
              Why we like it?{" "}
              <span className="font-weight-normal">{post.wwli}</span>
            </p>
            <div style={{ color: "#34373a" }} id="textBody_ckEditor_wwli"></div>
            <div id="textBody_ckEditor_desc" className="mt-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextBody;

// <h5 style={{color:"#265376"}} className="card-title font-weight-bold"><span>{post.title}</span></h5>
// <p style={{color:"#265376"}} className="card-text font-weight-bold">Category: <span className="font-weight-normal">{post.category}</span></p>
// <p style={{color:"#265376"}} className="card-text font-weight-bold mt-2">Why we like it? <span className="font-weight-normal">{post.wwli}</span></p>
// <div style={{color:"#265376"}} id="textBody_ckEditor_wwli">
// </div>
//
// <div id="textBody_ckEditor_desc" className="mt-3"></div>
