import React from "react";
import "./Footer.css";

import { Link } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";

import { HashLink } from "react-router-hash-link";

import { useDispatch } from "react-redux";
import {
  toggleSignup,
  selectPricePlan,
} from "../../store/actions/indexActions";

const SvgRect = () => {
  return (
    <svg
      width="1918"
      height="872"
      viewBox="0 0 1918 872"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1917.84 95.4676C1917.84 -229.152 1944.35 396.557 1310.55 174.148C676.751 -48.2599 0 486.004 0 486.004V872H1917.84C1917.84 872 1917.84 420.087 1917.84 95.4676Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1033.63"
          y1="407.858"
          x2="894.84"
          y2="733.957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6144D6" />
          <stop offset="1" stopColor="#9965EE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const FooterContainer = () => {
  const dispatch = useDispatch();

  const svgString = encodeURIComponent(renderToStaticMarkup(<SvgRect />));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  const onClickScrollHandler = (e) => {
    e.preventDefault();
    const href = e.target.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop;

    window.scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  const handleOnClick = (e) => {
    dispatch({
      type: "SHOW_TERM_PAPER",
    });
    dispatch(toggleSignup());
    dispatch(selectPricePlan({}));
  };

  return (
    <>
      <footer
        style={{ backgroundImage: dataUri }}
        className="d-none d-md-block"
      >
        <div className="container footer_items_container">
          <div className="row footer_items_top_container">
            <div className="col-4"></div>

            <div className="col-4"></div>

            <div className="col-4">
              <div className="">
                <p>
                  <HashLink smooth to="#root" style={{ cursor: "pointer" }}>
                    Product
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    smooth
                    to="#features_section"
                    style={{ cursor: "pointer" }}
                  >
                    Features
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    smooth
                    to="#scrollToPricingPlans"
                    style={{ cursor: "pointer" }}
                  >
                    Pricing
                  </HashLink>
                </p>
                <p>
                  <a
                    onClick={(e) => handleOnClick(e)}
                    style={{ cursor: "pointer" }}
                  >
                    Terms of Use
                  </a>
                </p>
                <p>
                  <a href="mailto: help@leviosa.us" target="_blank">
                    Contact Us
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="logo text-center">
                <a href="#">
                  <img src="./assets/index/logo_svg.svg" alt="logo_svg" />
                </a>
              </div>
            </div>

            <div className="col-5 d-flex justify-content-center align-items-end">
              <div className="">
                <div className="copy_rights">
                  <p>
                    Copyright 2020-
                    {new Date().getFullYear().toString().slice(-2)}, All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>

            <div className="col-4"></div>
          </div>
        </div>

        <img
          src="./assets/index/mem_lg/footer_left_svg1.svg"
          alt="footer_left_svg1"
          className="footer_left_svg1"
        />
        <img
          src="./assets/index/mem_lg/footer_left_svg2.svg"
          alt="footer_left_svg2"
          className="footer_left_svg2"
        />
        <img
          src="./assets/index/mem_lg/footer_left_svg3.svg"
          alt="footer_left_svg3"
          className="footer_left_svg3"
        />

        <img
          src="./assets/index/mem_lg/footer_right_svg1.svg"
          alt="footer_right_svg1"
          className="footer_right_svg1"
        />
        <img
          src="./assets/index/mem_lg/footer_right_svg2.svg"
          alt="footer_right_svg2"
          className="footer_right_svg2"
        />
        <img
          src="./assets/index/mem_lg/footer_right_svg3.svg"
          alt="footer_right_svg3"
          className="footer_right_svg3"
        />
      </footer>

      <footer
        style={{ backgroundImage: dataUri }}
        id="mobile_view_footer"
        className="d-md-none d-block position-relative"
      >
        <img
          src="./assets/index/mem_sm/footer_left_svg1.svg"
          alt="footer_left_svg1"
          className="footer_left_svg1_sm"
        />
        <img
          src="./assets/index/mem_sm/footer_right_svg1.svg"
          alt="footer_right_svg1"
          className="footer_right_svg1_sm"
        />
        <img
          src="./assets/index/mem_sm/footer_right_svg2.svg"
          alt="footer_right_svg2"
          className="footer_right_svg2_sm"
        />

        <div className="container footer_items_container">
          <div className="row footer_items_top_container">
            <div className="col-6"></div>

            <div className="col-6"></div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="">
                <p>
                  <HashLink smooth to="#root" style={{ cursor: "pointer" }}>
                    Product
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    smooth
                    to="#features_section"
                    style={{ cursor: "pointer" }}
                  >
                    Features
                  </HashLink>
                </p>
                <p>
                  <HashLink
                    smooth
                    to="#scrollToPricingPlans"
                    style={{ cursor: "pointer" }}
                  >
                    Pricing
                  </HashLink>
                </p>
                <p>
                  <a
                    onClick={(e) => handleOnClick(e)}
                    style={{ cursor: "pointer" }}
                  >
                    Terms of Use
                  </a>
                </p>
                <p>
                  <a href="mailto: help@leviosa.us" target="_blank">
                    Contact Us
                  </a>
                </p>
              </div>
            </div>

            <div className="col-6">
              <div className="text-center">
                <div className="terms d-block"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="logo text-center">
                <a href="#">
                  <img
                    src="./assets/index/mobile_footer_logo_svg.svg"
                    alt="logo_svg"
                  />
                </a>
              </div>
              <div className="copy_rights text-center">
                <p>
                  Copyright 2020-{new Date().getFullYear().toString().slice(-2)}
                  , All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterContainer;
