import React from "react";
// import { useHistory } from "react-router-dom";

const ContentActions = ({ id, onDeleteHandler, onEditHandler }) => {
  return (
    <>
      <td>
        {/* <button
          //   onClick={(e) => history.push("/a/guru-recommendations/" + id)}
          style={{ marginRight: "3px", padding: "3px", border: "0px" }}
        >
          <i className="far fa-eye"></i>
        </button> */}
        <button
          onClick={() => onEditHandler(id)}
          style={{ marginRight: "3px", padding: "3px", border: "0px" }}
        >
          <i className="far fa-edit"></i>
        </button>
        <button
          onClick={() => onDeleteHandler(id)}
          style={{ padding: "3px", border: "0px" }}
        >
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </>
  );
};

export default ContentActions;
