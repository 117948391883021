import React,{useEffect,useState} from "react";
import {useHistory} from "react-router-dom";
import {firestore} from "../../firebase";
import CompanySubscribersList from "./CompanySubscribersList";

const CompanyDetailsPost = () => {

  const history = useHistory();
  const companyId = history.location.pathname.split("/")[3]

  const [doc,setDoc] = useState({});

  useEffect(async () => {
    const companyRef = await firestore.collection('companies').doc(companyId).get();
    setDoc({id:companyId,...companyRef.data()});
  },[])


  return (
    <>
    <div className="container">
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Company Name</th>
          <td>{doc.companyName}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Email</th>
          <td>{doc.companyContactEmail}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Name</th>
          <td>{doc.companyContactName}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Phone</th>
          <td>{doc.companyContactPhone}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Address Street</th>
          <td>{doc.companyAddressSt}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Address City</th>
          <td>{doc.companyAddressCity}</td>
        </tr>
        <tr>
          <th scope="row">Company Contact Address Country</th>
          <td>{doc.companyAddressCountry}</td>
        </tr>

        <tr>
          <th scope="row">Company Subscription Level</th>
          <td>{doc.companySubLevel}</td>
        </tr>

        <tr>
          <th scope="row">Company Max Users</th>
          <td>{doc.companyMaxUsers}</td>
        </tr>

        <tr>
          <th scope="row">Company Subscription Expiration</th>
          <td>{doc.companySubExpiration}</td>
        </tr>

        <tr>
          <th scope="row">Company Subscription Start</th>
          <td>{doc.companySubStart}</td>
        </tr>

        <tr>
          <th scope="row">Company Subscription Amount</th>
          <td>{doc.monthlySubAmount}</td>
        </tr>

        <tr>
          <th scope="row">Company Sales Person's First Name</th>
          <td>{doc.salesPersonFirstName}</td>
        </tr>

        <tr>
          <th scope="row">Company Sales Person's Last Name</th>
          <td>{doc.salesPersonLastName}</td>
        </tr>

        <tr>
          <th scope="row">Discount</th>
          <td>{doc.discount}</td>
        </tr>


        <tr>
          <th scope="row">Company Redemption</th>
          <td>{doc.companyRedemption}</td>
        </tr>

      </tbody>
    </table>
    </div>

    {doc && <CompanySubscribersList companyDoc={doc} />}
    </>
  );
}


export default CompanyDetailsPost;
