import React, { useState } from "react";
import SortAscAndDesc from "../SortAscAndDesc/SortAscAndDesc";
import moment from "moment";
import { ASCENDING_ORDER, DESCENDING_ORDER } from "../../util/helpers";

const SalesTable = ({ data, percentageValue, handleSorting }) => {
  const [orderBy, setOrderBy] = useState("firstName");
  const [orderDirection, setOrderDirection] = useState(ASCENDING_ORDER);

  const getPercentage = (amount, pkg) => {
    if (pkg !== "paid") return 0;
    const percentage = parseInt(percentageValue) / 100;

    return amount * percentage;
  };

  const handleOnClickSort = async (fieldName, order) => {
    const nonSortingFields = [
      "subscriptionStatus",
      "invoiceStatus",
      "lastPaid",
      "numberOfSubs",
    ];
    if (nonSortingFields.includes(fieldName)) return;

    setOrderBy(fieldName);
    setOrderDirection(order);

    await handleSorting({
      orderValue: fieldName,
      orderDirection: order,
    });
  };

  return (
    <>
      {/* FILTERS AND OTHERS BELOW */}
      {/* <div className="container mb-5">
        <form onSubmit={(e) => console.log(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => console.log(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => console.log(e)}
                name="searchBy"
                className="form-control"
                value={"searchBy"}
              >
                <option value="0">Search By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="email">Email</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                value={"orderBy"}
                // onChange={(e) => onChangeFilteringHandler(e)}
                name="orderBy"
                className="form-control"
              >
                <option value="0">Order By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="createdAt">Created</option>
              </select>
            </div>

            <div className="col-sm-3">
              <select
                value={"orderDirection"}
                // onChange={(e) => onChangeFilteringHandler(e)}
                name="orderDirection"
                className="form-control"
              >
                <option value="0">Order Direction</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </form>
      </div> */}

      <table class="table table-striped">
        <thead>
          <tr>
            {[
              { title: "Name", value: "firstName" },
              { title: "Subscription Date", value: "createdAt" },
              { title: "Account Status", value: "packageType" },
              { title: "Plan", value: "pricingPlan" },
              { title: "Monthly Subscription Amount", value: "pricingAmount" },
              { title: "Total Amount Paid", value: "pricingAmount" },
              { title: "Total Commission", value: "pricingAmount" },
            ].map((each) => (
              <th scope="col">
                {[
                  "subscriptionStatus",
                  "invoiceStatus",
                  "lastPaid",
                  "numberOfSubs",
                ].includes(each.value) ? (
                  <SortAscAndDesc
                    isActive={false}
                    isAscending={false}
                    title={each.title}
                  />
                ) : (
                  <SortAscAndDesc
                    isActive={orderBy === each.value}
                    onClickAsc={() =>
                      handleOnClickSort(each.value, ASCENDING_ORDER)
                    }
                    onClickDesc={() =>
                      handleOnClickSort(each.value, DESCENDING_ORDER)
                    }
                    isAscending={orderDirection === ASCENDING_ORDER}
                    title={each.title}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length ? (
            data.map((each) => {
              return (
                <tr key={each.email}>
                  <td>{each.firstName}</td>
                  <td>
                    {moment(
                      each?.createdAt
                        ? each.createdAt.toDate()
                        : new Date(each.createdAt?.seconds * 1000)
                    ).format("MMM DD, YYYY")}
                  </td>
                  <td>
                    {each.package &&
                    each.package.type &&
                    each.package.type === "paid"
                      ? "Active"
                      : each.package.type || "N/A"}
                  </td>
                  <td>{each.pricingPlan}</td>
                  <td>${each.pricingAmount}</td>
                  <td>${each.pricingAmount}</td>
                  <td>
                    ${getPercentage(each.pricingAmount, each.package.type)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>No Data</tr>
          )}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mt-5">
        {/* <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination> */}
      </div>
    </>
  );
};

export default SalesTable;
