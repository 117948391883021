import React from "react";
import SingleAdventureActions from "./SingleAdventureActions";
import SingleAdventureRowEditor from "./SingleAdventureRowEditor";

const SingleAdventureRow = ({selectedAdventureRowId,row,rowNumber,title,onShowHandler,onEditHandler,onDeleteHandler,onClickPositionChangeHandler,onValueChangeHandler,values,isEdit}) => {
  return (
    <>
        <tr>
      {selectedAdventureRowId === row.id && isEdit ?

        <>
        <SingleAdventureRowEditor
        onValueChangeHandler={onValueChangeHandler}
        values={values}
        row={row}
        selectedAdventureRowId={selectedAdventureRowId}
        />
        </>
        :
        <>
          <td>{rowNumber}</td>
          <td>{title}</td>
          <SingleAdventureActions
          row={row}
          onShowHandler={onShowHandler}
          onEditHandler={onEditHandler}
          onDeleteHandler={onDeleteHandler}
          onClickPositionChangeHandler={onClickPositionChangeHandler}
           />
          </>
        }
        </tr>

    </>
  );
}


export default SingleAdventureRow;
