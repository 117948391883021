import React from "react";

const AdventureRowEditor = ({values,onValueChangeHandler,onFileChangeHandler}) => {
  return (
    <>
    <td> <input
       onChange={(e)=>onValueChangeHandler(e)}
       className="form-control"
       value={values.title}
       name="title"
       type="text"
       placeholder="Title" /> </td>
      <td> <input
       onChange={(e)=>onValueChangeHandler(e)}
       className="form-control"
       value={values.description}
       name="description"
       type="text"
       placeholder="Description"></input> </td>
      <td colSpan="2"> <input
      onChange={(e)=>onFileChangeHandler(e)}
      className="form-control"
      name="thumbnail"
      type="file"
      placeholder="Thumbnail" /></td>
    </>
  );
}

export default AdventureRowEditor;
