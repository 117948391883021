import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import "./salesDashboard.css";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { copyToClipboard } from "../util/helpers";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SalesTable from "../components/SalesTable";
import { useParams, useHistory } from "react-router-dom";
import { firestore } from "../firebase";
import { Chip } from "@material-ui/core";
import { useAuth } from "../contexts/AuthContext";
import { Spinner } from "reactstrap";
import axios from "axios";
import { Alert as ReactstrapAlert } from "reactstrap";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialValues = {
  lastName: "",
  isActive: null,
  email: "N/A",
  uid: "N/A",
  salesPersonId: "N/A",
  firstName: "N/A",
  commissionRate: "N/A",
  phoneNumber: "N/A",
  mailingAddress: "N/A",
};

const SalesDashboard = () => {
  const appURL = window.location.origin;
  const { currentUser, issalesPerson } = useAuth();

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: "Copied to clipboard",
  });
  const [salesPersonData, setSalesPersonData] = useState(initialValues);
  const [isEdit, setIsEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [updatedValues, setUpdatedValues] = useState({
    phoneNumber: "",
    mailingAddress: "",
  });
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  // eslint-disable-next-line
  useEffect(async () => {
    if (id && currentUser.uid === id && issalesPerson) {
      setLoading(true);
      const data = await getSalesPerson(id);
      setSalesPersonData(data);
      setUpdatedValues({
        phoneNumber: data.phoneNumber,
        mailingAddress: data.mailingAddress,
      });
      const usersData = await getCustomerData(data.salesPersonId);
      const filteredUsers = filterUsersOlderThanOneYear(usersData);

      setAssociatedUsers(filteredUsers);
      // setAssociatedUsers(usersData);
      setLoading(false);
    } else history.push("/welcome");
  }, []);

  const getSalesPerson = async (id) => {
    const salesPerson = await firestore.collection("salesPerson").doc(id).get();
    if (!salesPerson.exists) return initialValues;

    const salesPersonData = salesPerson.data();
    return salesPersonData;
  };

  const copyText = (text) => {
    if (!text) {
      setSnackbarInfo({
        open: true,
        message: "Invalid Text",
      });
      return;
    }
    copyToClipboard(text);
    setSnackbarInfo({
      open: true,
      message: "Copied text to clipboard",
    });
  };

  const getCustomerData = async (salesID) => {
    try {
      const users = await firestore
        .collection("users")
        .where("salesPersonId", "==", salesID)
        .get();

      const usersData = [];
      if (!users.empty) {
        users.forEach((doc) => {
          usersData.push(doc.data());
        });
      }

      return usersData;
    } catch (error) {
      console.log("Error getting sales data", error.message);
      return [];
    }
  };

  const getTotalCommissionAndSubsAmount = (arr) => {
    let total = 0;
    arr.forEach((item) => {
      if (item.package.type !== "paid") {
        return; // Skip this object
      }
      total += parseFloat(item.pricingAmount);
    });
    const percentage = parseInt(salesPersonData.commissionRate) / 100;

    const percentageValue = total * percentage;

    return { total, percentageValue };
  };

  const editInformationHandler = (e) => {
    setUpdatedValues({ ...updatedValues, [e.target.name]: e.target.value });
  };

  const submitHandler = async (fieldName) => {
    try {
      setLoading(true);
      if (id && currentUser.uid === id && issalesPerson) {
        await firestore
          .collection("salesPerson")
          .doc(id)
          .update({
            updatedAt: new Date(),
            ...updatedValues,
          });

        const data = await getSalesPerson(id);
        setSalesPersonData(data);
        setUpdatedValues({
          phoneNumber: data.phoneNumber,
          mailingAddress: data.mailingAddress,
        });
      } else {
        setError({ isError: true, errorMessage: "Unauthorized Action." });
      }

      setIsEdit(null);
      setLoading(false);
    } catch (error) {
      setIsEdit(null);
      setLoading(false);
      console.log("Error from submitHandler: ", error.message);
      setError({ isError: true, errorMessage: error.message });
    }
  };

  const filterUsersOlderThanOneYear = (arr) => {
    const currentDate = new Date();

    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    // Filter the array based on the createdAt field
    const filteredArray = arr.filter((item) => {
      const createdAtDate = item?.createdAt
        ? item.createdAt.toDate()
        : new Date(item?.createdAt?._seconds * 1000);

      return createdAtDate > oneYearAgo;
    });
    return filteredArray;
  };

  async function handleSorting({ orderValue, orderDirection }) {
    // todo: fix filters
    // console.log(">>", orderValue, orderDirection);
    alert("Work in progress");
    return;
  }

  const resendEmailVerification = async () => {
    try {
      if (currentUser && currentUser.email) {
        const response = await axios.post("/api/resend-verification-link", {
          email: currentUser.email,
        });
        if (response.status === 200) {
          setSnackbarInfo({
            open: true,
            message: "Verification email has been sent again",
          });
        } else {
          setSnackbarInfo({
            open: true,
            message: "Failed to send verification email",
          });
        }
      }
    } catch (error) {
      setSnackbarInfo({
        open: true,
        message: "Unexpected Error: " + error.message,
      });

      console.log("Error while sending email: ", error.message);
    }
  };

  return (
    <>
      <Navbar fromSales={true} />
      {loading ? (
        <div className="spinner_container">
          <Spinner />
        </div>
      ) : null}
      <>
        <hr
          className="my-5 d-none d-md-block"
          style={{ borderTop: "1px solid #fff0" }}
        />
        <hr
          className="my-5 d-none d-md-block"
          style={{ borderTop: "1px solid #fff0" }}
        />
        <hr
          className="my-3 d-block d-md-none"
          style={{ borderTop: "1px solid #fff0" }}
        />

        <header id="home_header" className="position-relative">
          <div className="container">
            {error.isError && (
              <Snackbar
                open={error.isError}
                autoHideDuration={5000}
                onClose={() => setError({ isError: false, errorMessage: "" })}
              >
                <Alert
                  onClose={() => setError({ isError: false, errorMessage: "" })}
                  severity="error"
                >
                  {error.errorMessage}
                </Alert>
              </Snackbar>
            )}
            <div className="row header_top_container position-relative">
              <div className="col-12 statistics-header">
                <h2>
                  Welcome, {salesPersonData?.firstName}{" "}
                  {salesPersonData?.lastName}
                </h2>
              </div>
            </div>

            <div className="row header_top_container position-relative">
              <div className="col-12">
                <ReactstrapAlert
                  color="warning"
                  style={
                    currentUser.emailVerified
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  <h6>Check your email to verify the account.</h6>
                  <a
                    onClick={() => resendEmailVerification()}
                    style={{ cursor: "pointer" }}
                  >
                    Re-send verification email.
                  </a>
                </ReactstrapAlert>
              </div>
            </div>

            <div className="custom-box mt-2">
              <div>
                <p>Email: </p>
                <div className=" mb-3 text-container">
                  <span className="font-weight-bold">
                    {salesPersonData?.email}
                  </span>
                  <FileCopyIcon
                    fontSize="small"
                    onClick={() => copyText(salesPersonData?.email)}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <p>Sales Person ID: </p>
                <div className="mb-3 text-container">
                  <span className="font-weight-bold">
                    {salesPersonData?.salesPersonId}
                  </span>
                  <FileCopyIcon
                    fontSize="small"
                    onClick={() => copyText(salesPersonData?.salesPersonId)}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <p>Customers URL: </p>
                <div className="text-container mb-3">
                  <span className="font-weight-bold">
                    {appURL}/welcome/{salesPersonData?.salesPersonId}
                  </span>
                  <FileCopyIcon
                    fontSize="small"
                    onClick={() =>
                      copyText(
                        `${appURL}/welcome/${salesPersonData?.salesPersonId}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <p>Your Commission Rate: </p>
                <div className="text-container mb-3">
                  <span className="font-weight-bold">
                    {salesPersonData?.commissionRate}
                  </span>
                </div>
                <p>Phone: </p>
                <div className="text-container mb-3 ">
                  {isEdit === "phoneNumber" ? (
                    <>
                      <input
                        onChange={(e) => editInformationHandler(e)}
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                        value={updatedValues.phoneNumber}
                      />

                      <DoneIcon
                        fontSize="small"
                        onClick={() => submitHandler("phoneNumber")}
                        style={{ cursor: "pointer" }}
                      />
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setIsEdit(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <>
                      <span className="font-weight-bold">
                        {salesPersonData?.phoneNumber}
                      </span>
                      <EditIcon
                        fontSize="small"
                        onClick={() => setIsEdit("phoneNumber")}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  )}
                </div>

                <p>Address: </p>
                <div className="text-container ">
                  {isEdit === "mailingAddress" ? (
                    <>
                      <input
                        onChange={(e) => editInformationHandler(e)}
                        name="mailingAddress"
                        type="text"
                        className="form-control"
                        value={updatedValues.mailingAddress}
                      />
                      <DoneIcon
                        fontSize="small"
                        onClick={() => submitHandler("mailingAddress")}
                        style={{ cursor: "pointer" }}
                      />
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setIsEdit(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <>
                      <span className="font-weight-bold">
                        {salesPersonData?.mailingAddress}
                      </span>
                      <EditIcon
                        fontSize="small"
                        onClick={() => setIsEdit("mailingAddress")}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div>
                <Chip
                  size="small"
                  color={salesPersonData?.isActive ? "primary" : "secondary"}
                  label={`Status: ${
                    salesPersonData?.isActive ? "Active" : "Disabled"
                  }`}
                />

                <div className="mb-2 mt-4 statistics-header">
                  <h5>Totals</h5>
                </div>
                <div className="mb-3 info-box">
                  <p className="m-0 font-italic">Total Customers:</p>
                  <h5 className="m-0">{associatedUsers.length}</h5>
                </div>
                <div className="mb-3 info-box">
                  <p className="m-0 font-italic">Total Commission:</p>
                  <h5 className="m-0">
                    $
                    {getTotalCommissionAndSubsAmount(associatedUsers)
                      .percentageValue || "0"}
                  </h5>
                </div>
                <div className="mb-3 info-box">
                  <p className="m-0 font-italic">Total Subscription Amount:</p>
                  <h5 className="m-0">
                    $
                    {getTotalCommissionAndSubsAmount(associatedUsers).total ||
                      "0"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container mt-5">
          <div className="col-12 statistics-header">
            <h4>Your Sales</h4>
          </div>
          {associatedUsers && associatedUsers.length > 0 ? (
            <SalesTable
              data={associatedUsers}
              percentageValue={salesPersonData.commissionRate}
              handleSorting={handleSorting}
            />
          ) : (
            "No Associated Users"
          )}
        </div>
      </>
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={2000}
        onClose={() => setSnackbarInfo({ open: false })}
      >
        <Alert
          onClose={() => setSnackbarInfo({ open: false })}
          severity="success"
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SalesDashboard;
