import React from "react";

const CompanyRowData = ({doc,onEditHandler,onDeleteHandler,onShowHandler}) => {
  return (
    <>
    <td>{doc.companyName}</td>
    <td>{doc.companyContactEmail}</td>
    <td>{doc.companyContactPhone}</td>
    <td>{doc.companySubLevel}</td>
    <td>{doc.companyMaxUsers}</td>
    <td>{doc.monthlySubAmount}</td>
    <td>...</td>
    <td className="d-flex jusitify-content-around">
      <button
      onClick={(e) => onShowHandler(doc)}
      style={{marginRight:"5px",padding:"3px",border:"0px"}}><i className="far fa-eye"></i></button>
      <button
       onClick={(e) => onEditHandler(doc)}
       style={{marginRight:"5px",padding:"3px",border:"0px"}}><i className="far fa-edit"></i></button>
      <button
       onClick={(e) => onDeleteHandler(doc)}
       style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>
    </td>
    </>
  );
}

export default CompanyRowData;
