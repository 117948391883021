import React from 'react'
import { Spinner } from 'reactstrap'
const spinnerDivStyleSheet = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom:"0",
    background: "#00000047",
    zIndex: "100"
  }
  
  const spinnerStyleSheet = {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: "100"
  }

const CLoader = () => {
  return (
    <div style={spinnerDivStyleSheet}><Spinner style={spinnerStyleSheet} /></div>
  )
}

export default CLoader