import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import DetailsContainer from "../containers/details/DetailsContainer";
import SmallFooter from "../components/SmallFooter/SmallFooter";

import { auth, firestore } from "../firebase";

import { useSelector, useDispatch } from "react-redux";

const Details = () => {
  let { uid } = auth.currentUser;
  const [docs, setDocs] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const { selectedAdventure } = useSelector((state) => state.index);
  const [likedPosts, setLikedPosts] = useState([]);
  const [watchedPosts, setWatchedPosts] = useState([]);
  const [corporateAdminPosts, setCorporateAdminPosts] = useState([]);
  const dispatch = useDispatch();

  const [rowIds, setRowIds] = useState([]);

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      const querySnapshot = firestore
        .collection("users")
        .where("email", "==", `${localStorage.getItem("email")}`);

      querySnapshot.onSnapshot((doc) => {
        if (!doc.empty && doc.docs[0].data().likedPosts) {
          setLikedPosts([...doc.docs[0].data().likedPosts]);
        }

        if (!doc.empty && doc.docs[0].data().watchedPosts) {
          setWatchedPosts([...doc.docs[0].data().watchedPosts]);
        }
      });

      //adventure query
      const adventureId = selectedAdventure.id;

      const rowsRef = await firestore
        .collection("m_rows")
        .where("adventureId", "==", adventureId)
        .orderBy("rowNumber", "asc")
        .get();

      const arr = [];

      rowsRef.docs.forEach((doc) => {
        const { id, title } = doc.data();
        arr.push({ id, title });
      });

      setRowIds((rowIds) => [...rowIds, ...arr]);
    }

    return () => (isMounted = false);
  }, []);

  // this useEffect gets the user doc of the logged in user & checks if the doc has corporateAdminId or
  // if doc has corporate admin id then call getCorpAdminPosts
  useEffect(() => {
    async function getCorpAdminId() {
      var userDocRef = await firestore.collection("users").doc(uid);
      userDocRef
        .get()
        .then((doc) => {
          if (doc.exists && doc.data().corporateAdminId) {
            getCorpAdminPosts(doc.data().corporateAdminId);
          } else if (doc.exists && doc.data().isCorporateAdmin) {
            getCorpAdminPosts(doc.id);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
    getCorpAdminId();
  }, [uid]);

  // this function gets the post of the given corp admin id
  const getCorpAdminPosts = async (id) => {
    await firestore
      .collection("corp_a_posts")
      .where("corporateAdminId", "==", id)
      .get()
      .then((snapshot) => {
        setCorporateAdminPosts(snapshot.docs.map((doc) => doc.data()));
      });
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <div className="mt-5 ml-3 ml-md-5">
        {rowIds &&
          rowIds.map((rowId, key) => {
            return (
              <div key={key} className={key === 0 ? "row" : "row"}>
                <div className="col">
                  <DetailsContainer
                    type={key === 0 ? "featured" : "relevant"}
                    rowId={rowId}
                    adventureId={selectedAdventure.id}
                    likedPosts={likedPosts}
                    watchedPosts={watchedPosts}
                    corpAdminPosts={corporateAdminPosts}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <SmallFooter />
    </div>
  );
};

export default Details;
