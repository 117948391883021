import React, { useState, useEffect } from "react";
import "./Features.css";
import { Spinner } from "reactstrap";
import FeatureCard from "../FeatureCard/FeatureCard";
import { useFirestoreGetAllAdventuresById } from "../../hooks/useFirestore";

import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

import { firestore } from "../../firebase";

import { useAuth } from "../../contexts/AuthContext";
import MoodCheck from "../../containers/moodCheck/MoodCheck";
import AddToCalendar from "../../containers/addToCalendar/AddToCalendar";
import TrialModal from "../../containers/trialModal/trialModal";

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
const initValues = {
  title: "",
  category: "",
  userPhase: "",
  audience: "",
  wwli: "",
};

const Features = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [adventures, setAdventures] = useState([]);

  const [queryString, setQueryString] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const history = useHistory();

  const { currentUser } = useAuth();

  useEffect(async () => {
    setIsMounted(true);
    setIsLoading(true);

    const adventuresRef = await firestore
      .collection("m_adventures")
      .orderBy("rowNumber", "asc")
      .get();
    setAdventures([]);

    if (!adventuresRef.empty) {
      const docs = adventuresRef.docs;

      docs.forEach((doc) => {
        if (doc.id != "settings") {
          setAdventures((adventures) =>
            setAdventures([...adventures, doc.data()])
          );
        }
      });
    }

    setIsLoading(false);

    return () => setIsMounted(false);
  }, []);

  const searchOnSubmitHandler = async (e) => {
    e.preventDefault();

    if (!queryString) return;
    setIsSearching(true);

    const rowTitles = [];
    const postTitles = [];

    const rowsRef = await firestore.collection("m_rows").get();

    rowsRef.forEach((rowDoc) => {
      rowTitles.push({
        title: rowDoc.data().title,
        adventureId: rowDoc.data().adventureId,
      });
    });

    const postsRef = await firestore.collection("m_posts").get();

    postsRef.forEach((postDoc) => {
      postTitles.push({ ...postDoc.data() });
    });

    const postResults = postTitles.filter((postTitle) =>
      postTitle.title.toLowerCase().includes(queryString.toLowerCase())
    );
    const rowResults = rowTitles.filter((rowTitle) =>
      rowTitle.title.toLowerCase().includes(queryString.toLowerCase())
    );

    history.push({
      pathname: "/search/results",
      state: { postResults, rowResults },
    });
  };

  const { stylesheet } = props;

  return (
    <section
      id="features_section"
      className="position-relative"
      style={stylesheet}
    >
      {isLoading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}

      {/* memphis */}
      <img
        src="./assets/index/mem_lg/feature_mem_svg1.svg"
        alt="feature_mem_svg1"
        className="feature_mem_svg1 d-none d-sm-block"
      />
      <img
        src="./assets/index/mem_lg/feature_mem_svg2.svg"
        alt="feature_mem_svg2"
        className="feature_mem_svg2 d-none d-sm-block"
      />

      <div className="container">
        <div></div>

        <div className="section_title text-center d-flex justify-content-center">
          <h3 style={{ width: "300px" }}>{props.title}</h3>
        </div>
        <hr
          className={`mb-4 ${props.page === "home" ? "d-none" : "d-block"}`}
        />

        {currentUser && props.title !== "Features" ? (
          <>
            <div className="d-flex justify-content-center mt-3">
              <form
                onSubmit={(e) => searchOnSubmitHandler(e)}
                class="form-inline mt-2"
              >
                <input
                  style={{ width: "fit-content" }}
                  onChange={(e) => setQueryString(e.target.value)}
                  value={queryString}
                  class="searchField form-control mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button
                  style={{ height: "38px" }}
                  class="searchBtn btn my-2 my-sm-0 d-flex align-items-center"
                  type="submit"
                >
                  {!isSearching ? (
                    <i class="fa fa-search"></i>
                  ) : (
                    <>
                      <span
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              </form>
            </div>
          </>
        ) : null}

        {currentUser && props.title !== "Features" ? (
          <div
            className="d-flex justify-content-center align-items-center my-3 homeFeatureList"
            style={{ gap: 7 }}
          >
            <Link to="/favourites/all" className="mr-2">
              <div className="d-flex justify-content-center align-items-center">
                <button
                  style={{ marginRight: "5px", outline: "none" }}
                  className="heartBtnFeature"
                >
                  <i class="fas fa-heart"></i>
                </button>
                <p style={{ fontWeight: "500" }}>Favorites</p>
              </div>
            </Link>

            <Link to="/watched/all">
              <div className="d-flex justify-content-center align-items-center">
                <button
                  style={{ marginRight: "5px", outline: "none" }}
                  className="heartBtnFeature"
                >
                  <i class="fas fa-history"></i>
                </button>
                <p style={{ fontWeight: "500" }}>History</p>
              </div>
            </Link>

            <MoodCheck />

            <TrialModal />
            <AddToCalendar />
          </div>
        ) : null}

        <div className="row feature_cards_container">
          {adventures &&
            adventures.map((data) => {
              return (
                <FeatureCard
                  key={data.id}
                  title={data.title}
                  image={data.thumbnailUrl}
                  id={data.id}
                  adventure={data}
                >
                  <p className="card-text">{data.description}</p>
                </FeatureCard>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Features;
