import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import { useDispatch } from "react-redux";

import { toggleSignin } from "./store/actions/indexActions";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      dispatch(toggleSignin());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { target: history.location.pathname } }}
          />
        );
      }}
    ></Route>
  );
}
