import React, { useState, useEffect } from "react";
import { useFirestoreAdventuresGET } from "../hooks/useAdventures";

import { firebase, firestore, storage, timestamp } from "../../firebase";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { selectAdventure } from "../../store/actions/adminActions";
import usePagination from "../hooks/usePagination";

import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import GurusRowEditor from "./Gurus/GurusRowEditor";
import GurusRow from "./Gurus/GurusRow";

const initValues = {
  title: "",
  description: "",
};

const Gurus = () => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [values, setValues] = useState({ ...initValues });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedGuruId, setSelectedGuruId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [searchBy, setSearchBy] = useState("name");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  const [gurus, setGurus] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  const [settings, setSettings] = useState({
    orderBy: "rowNumber",
    orderDirection: "asc",
  });

  const [isSetOrderSettings, setIsSetOrderSettings] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const unsub = firestore
      .collection("m_gurus")
      .orderBy(orderBy, orderDirection)
      .onSnapshot((snapshot) => {
        setGurus(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

        setIsLoading(false);
      });
    return () => unsub;
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setFilteredData(
      gurus.filter((guru) => {
        return guru[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, gurus]);

  // console.log(filteredData)

  const onAddNewHandler = () => {
    setIsAddNew(true);
  };
  const onEditHandler = (guruID, guru) => {
    setIsEdit(true);
    setSelectedGuruId(guruID);
    setValues({ ...guru });
  };

  const onDeleteHandler = async (guruId, contentCreator) => {
    setIsDelete(true);
    setIsLoading(true);
    if (isMounted) {
      const result = window.confirm("Are you sure you want to delete it?");
      if (!result) {
        return;
      } else {
        try {
          const doc = await firestore.collection("m_gurus").doc(guruId);
          const res = await doc.get();
          const data = await res.data();
          const { profilePicturePath } = data;
          const storageRef = storage.ref(profilePicturePath);
          await storageRef.delete();
          await doc.delete();
          setMessage("Data deleted successfully");
          setTimeout(() => setMessage(""), 2000);
        } catch (e) {
          console.log(e);
          setError("Data delete failed");
          setTimeout(() => setError(""), 2000);
        } finally {
          setIsDelete(false);
          setValues(initValues);
          setIsLoading(false);
          setProfilePicture(null);
        }
      }
    }
  };

  const onSaveHandler = async () => {
    try {
      setIsLoading(true);
      if (isAddNew && profilePicture) {
        const times = Date.now();
        const storageRef = storage.ref(`gurus/${profilePicture.name}-${times}`);
        const snap = await storageRef.put(profilePicture);

        const url = await storageRef.getDownloadURL();
        const metadata = await storageRef.getMetadata();
        const fullPath = metadata.fullPath;

        await firestore
          .collection("m_gurus")
          .doc()
          .set({
            ...values,
            profilePicturePath: fullPath,
            profilePictureURL: url,
            created: timestamp(),
            updated: timestamp(),
          });
        setMessage("Data added successfully");
        setTimeout(() => setMessage(""), 2000);
        setIsAddNew(false);
        setValues(initValues);
        setIsLoading(false);
      }
      ////////////////////////////

      if (isEdit) {
        const doc = await firestore
          .collection("m_gurus")
          .doc(selectedGuruId)
          .get();
        const res = await doc.data();

        const times = Date.now();

        let newData = { ...values };

        if (profilePicture) {
          const times = Date.now();
          const storageRef = storage.ref(
            `gurus/${profilePicture.name}-${times}`
          );
          const snap = await storageRef.put(profilePicture);

          const url = await storageRef.getDownloadURL();
          const metadata = await storageRef.getMetadata();
          const fullPath = metadata.fullPath;

          newData.profilePicturePath = fullPath;
          newData.profilePictureURL = url;
        }

        await firestore
          .collection("m_gurus")
          .doc(selectedGuruId)
          .update({
            ...newData,
            updated: timestamp(),
          });
        setMessage("Data added successfully");
        setTimeout(() => setMessage(""), 2000);
        setIsEdit(false);
        setValues(initValues);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }

    setProfilePicture(null);

    ////////////////////////////////////
  };

  const onCancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onFileChangeHandler = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Gurus</a>
          </li>
        </ol>
      </nav>

      <div className="position-relative">
        {isLoading && (
          <div style={spinnerDivStyleSheet}>
            <Spinner style={spinnerStyleSheet} />
          </div>
        )}

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Profile Picture</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentData() &&
              currentData().map((guru) => (
                <GurusRow
                  name={guru.name}
                  description={guru.description}
                  profilePictureURL={guru.profilePictureURL}
                  id={guru.id}
                  guru={guru}
                  key={guru.id}
                  onDeleteHandler={onDeleteHandler}
                  onEditHandler={onEditHandler}
                  onFileChangeHandler={onFileChangeHandler}
                  onValueChangeHandler={onValueChangeHandler}
                  selectedGuruId={selectedGuruId}
                  values={values}
                  isEdit={isEdit}
                />
              ))}
            {isAddNew ? (
              <tr>
                <GurusRowEditor
                  values={values}
                  onValueChangeHandler={onValueChangeHandler}
                  onFileChangeHandler={onFileChangeHandler}
                />
              </tr>
            ) : null}
          </tbody>
        </table>
        <div>
          {isAddNew || isEdit ? (
            <>
              <button
                onClick={() => onSaveHandler()}
                className="btn btn-sm btn-primary"
              >
                Save
              </button>
              <button
                onClick={() => onCancelHandler()}
                className="btn btn-sm btn-warning mx-3"
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => onAddNewHandler()}
              className="btn btn-sm btn-primary"
            >
              Add New
            </button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default Gurus;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
