import React from "react";

const AdventureActions = ({id,adventure,onShowHandler,onEditHandler,onDeleteHandler, onClickPositionChangeHandler}) => {
  return (
    <>
    <td>
    <button
     onClick={(e) => onShowHandler(adventure)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-eye"></i></button>
    <button
     onClick={(e) => onEditHandler(id,adventure)}
     style={{marginRight:"3px",padding:"3px",border:"0px"}}><i className="far fa-edit"></i></button>
    <button
     onClick={(e) => onDeleteHandler(id,adventure)}
     style={{padding:"3px",border:"0px"}}><i className="fas fa-trash"></i></button>
    
    <button
    onClick={e => onClickPositionChangeHandler(e,adventure)}
    name="pushUp"
    className="ml-2"
    style={{padding:"3px",border:"0px"}}
    ><i class="fas fa-arrow-circle-up"></i></button>
    <button
    name="pushDown"
    onClick={e => onClickPositionChangeHandler(e,adventure)}
    style={{padding:"3px",border:"0px"}}
    ><i class="fas fa-arrow-circle-down"></i></button>

     </td>
    </>
  );
}

export default AdventureActions;
