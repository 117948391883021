import React from "react";
import { useHistory } from "react-router-dom";

const GurusActions = ({
  id,
  guru,
  onShowHandler,
  onEditHandler,
  onDeleteHandler,
}) => {
  const history = useHistory();
  return (
    <>
      <td>
        <button
          onClick={(e) => history.push('/a/guru-recommendations/'+ id)}
          style={{ marginRight: "3px", padding: "3px", border: "0px" }}
        >
          <i className="far fa-eye"></i>
        </button>
        <button
          onClick={(e) => onEditHandler(id, guru)}
          style={{ marginRight: "3px", padding: "3px", border: "0px" }}
        >
          <i className="far fa-edit"></i>
        </button>
        <button
          onClick={(e) => onDeleteHandler(id, guru)}
          style={{ padding: "3px", border: "0px" }}
        >
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </>
  );
};

export default GurusActions;
