import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import CheckoutForm from "../CheckoutForm/CheckoutForm";

import { Spinner } from "reactstrap";

import "./SignUpForm.css";

import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { firestore, timestamp, firebase } from "../../firebase";

import { useDispatch, useSelector } from "react-redux";
import {
  toggleSignin,
  toggleSignup,
  setUserInfo,
  togglePayment,
  storeUserSignupInfo,
} from "../../store/actions/indexActions";

import Alert from "../Alert";
import CustomAlert from "./Alert";
import { number } from "prop-types";
import axios from "axios";
import { auth } from "firebase";
import { validSalesPersonId } from "../../util/helpers";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),
  salesPersonId: Yup.string(),

  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),

  email: Yup.string().email().required("Email is required"),
  redemptionCode: Yup.string().required("Redemption code is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),

  checkConfrm: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
});

const SignUpSchemaWithoutRedemption = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),
  salesPersonId: Yup.string(),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),

  email: Yup.string().email().required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),

  checkConfrm: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
});

const values = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  redemptionCode: "",
  email: "",
  password: "",
  confirmPassword: "",
  checkConfrm: false,
  salesPersonId: "",
};

const SignUpForm = (props) => {
  const {
    isShowTerms,
    setIsShowTerms,
    sales_person_id = "",
    showSalesPersonErr,
  } = props;

  const isSalesPersonId = sales_person_id ? true : false;
  const [initialValues, setInitialValues] = useState({
    ...values,
    salesPersonId: sales_person_id ? sales_person_id : "",
  });

  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isShowCustomAlert, setIsShowCustomAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const history = useHistory();
  const { signup } = useAuth();

  const dispatch = useDispatch();
  const { selectedPlanDetails, userSignupForm } = useSelector(
    (state) => state.index
  );

  const [redemption, setRedemption] = useState(false);

  const [isAllowedToSignUp, setIsAllowedToSignUp] = useState(false);

  const [isSignUpFailed, setIsSignUpFailed] = useState(false);

  useEffect(() => {
    if (!selectedPlanDetails.title) {
      setIsAllowedToSignUp(true);
    }

    setInitialValues({ ...userSignupForm });
  }, []);

  useEffect(() => {
    if (isSignUpFailed) {
      setIsAllowedToSignUp(false);
    }
  }, [isSignUpFailed]);

  const getAccountCreationDate = () => {
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let seconds = date_ob.getSeconds();

    return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const onChangeHandler = () => {};

  const verifiedSalesPersonId = async (id) => {
    const salesDocument = await firestore
      .collection("salesPerson")
      .doc("validSalesIds")
      .get();
    const { validIDs } = salesDocument.data();
    return validIDs.some((item) => item.salesPersonId === id);
  };

  // user signup
  const submitHandler = async (values) => {
    try {
      if (
        values.salesPersonId &&
        !validSalesPersonId(values.salesPersonId)
        // (!validSalesPersonId(values.salesPersonId) ||
        //   !(await await verifiedSalesPersonId(values.salesPersonId)))
      ) {
        showSalesPersonErr(true, "Invalid sales person id");
        return;
      }

      showSalesPersonErr(false, "");
      setRedemption(values.redemptionCode);
      setError("");
      setIsLoading(true);

      if (!selectedPlanDetails.title && !values.redemptionCode) {
        setIsLoading(false);
        setIsShowCustomAlert(true);
        setAlertType("error");
        return setAlertMessage("Select a pricing plan first");
      }

      const res = await axios.post("/api/signup", {
        ...values,
        selectedPlanDetails,
      });

      if (res.data.status === 200) {
        if (res.data.togglePayment) {
          dispatch(setUserInfo(res.data.userInfo));
          setIsLoading(false);
          await auth().signInWithCustomToken(res.data.token);
          dispatch(toggleSignup()).then((res) => dispatch(togglePayment()));
          return;
        } else dispatch(toggleSignup()).then((res) => dispatch(toggleSignin()));
      } else {
        setIsLoading(false);
        setIsAlertOpen(true);
        setAlertType("error");
        setAlertMessage(res.data.error);
        setIsSignUpFailed(true);
        setIsAllowedToSignUp(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsAlertOpen(true);
      setAlertType("error");
      setAlertMessage("Something went wrong.Try again!");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          salesPersonId: sales_person_id ? sales_person_id : "",
        }}
        enableReinitialize
        validationSchema={
          !selectedPlanDetails.title
            ? SignUpSchema
            : SignUpSchemaWithoutRedemption
        }
        onChange={onChangeHandler}
        onSubmit={submitHandler}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty, values } = formik;

          return (
            <div className="container" id="signupForm">
              {isLoading ? (
                <div className="spinner_container">
                  <Spinner />
                </div>
              ) : null}

              <div className="row">
                <div className="col-12">
                  <h2>Sign Up</h2>
                </div>
              </div>

              <div className="row">
                {!selectedPlanDetails.title ? (
                  <div className="col-sm-8">
                    <p className="mb-3">Sign Up with Redemption Code.</p>
                  </div>
                ) : (
                  <>
                    <div className="col-sm-8">
                      <p className="mb-3">
                        Package {`${selectedPlanDetails.title}`}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="mb-3" style={{ color: "#0020E2" }}>
                        Price ${selectedPlanDetails.price}/mo
                      </p>
                    </div>
                  </>
                )}
              </div>

              <Form>
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter First Name"
                      className={
                        errors.firstName && touched.firstName
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="firstName"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter Last Name"
                      className={
                        errors.lastName && touched.lastName
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="lastName"
                      component="span"
                      className="error"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    className={
                      errors.email && touched.email
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <Field
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="123-456-7890"
                    className={
                      errors.phoneNumber && touched.phoneNumber
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="span"
                    className="error"
                  />
                </div>

                {!selectedPlanDetails.title ? (
                  <div className="form-group">
                    <label htmlFor="redemptionCode">
                      Redemption Code provided by Employer
                    </label>
                    <Field
                      type="text"
                      name="redemptionCode"
                      id="redemptionCode"
                      placeholder="Enter Redemption Code"
                      className={
                        errors.redemptionCode && touched.redemptionCode
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="redemptionCode"
                      component="span"
                      className="error"
                    />
                  </div>
                ) : null}

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    className={
                      errors.password && touched.password
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="error"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="span"
                    className="error"
                  />
                </div>
                {selectedPlanDetails.title ? (
                  <div className="form-group">
                    <label htmlFor="salesPersonId">
                      {isSalesPersonId
                        ? "Sales Executive ID"
                        : "Sales Executive ID (If Known)"}
                    </label>
                    <Field
                      type="text"
                      name="salesPersonId"
                      id="salesPersonId"
                      placeholder="Enter First Name"
                      className={
                        errors.salesPersonId && touched.salesPersonId
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="salesPersonId"
                      component="span"
                      className="error"
                    />
                  </div>
                ) : null}

                <div className="form-group">
                  <div className="form-check">
                    <Field
                      type="checkbox"
                      name="checkConfrm"
                      className={
                        "form-check-input " +
                        (errors.checkConfrm && touched.checkConfrm
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label className="form-check-label" htmlFor="checkConfrm">
                      By clicking I agree to the
                      <span
                        onClick={(e) => {
                          values.checkConfrm = false;
                          dispatch(storeUserSignupInfo(values)).then((res) =>
                            dispatch({ type: "SHOW_TERM_PAPER" })
                          );
                        }}
                        className="ml-1"
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#600170",
                        }}
                      >
                        terms and conditions
                      </span>
                      .
                    </label>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col">
                    {isShowCustomAlert ? null : (
                      <Alert
                        isAlertOpen={isAlertOpen}
                        setIsAlertOpen={setIsAlertOpen}
                        alertType={alertType}
                        alertMessage={alertMessage}
                        setAlertType={setAlertType}
                        setAlertMessage={setAlertMessage}
                      />
                    )}
                    {isShowCustomAlert ? (
                      <CustomAlert
                        selectedPlanDetails={selectedPlanDetails}
                        redemptionCode={redemption}
                        setIsAlertOpen={setIsAlertOpen}
                        alertType={alertType}
                        alertMessage={alertMessage}
                        setAlertType={setAlertType}
                        setAlertMessage={setAlertMessage}
                        setIsShowCustomAlert={setIsShowCustomAlert}
                        isShowCustomAlert={isShowCustomAlert}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="row my-4">
                  <div className="col-6">
                    <button
                      type="submit"
                      className={
                        !(dirty && isValid)
                          ? "disabled-btn customBtn"
                          : "customBtn"
                      }
                      disabled={!(dirty && isValid)}
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className="col-6">
                    <p>Already have an account? Start here...</p>
                    <a
                      onClick={() =>
                        dispatch(toggleSignup()).then((res) =>
                          dispatch(toggleSignin())
                        )
                      }
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#600170",
                      }}
                    >
                      Log In
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default SignUpForm;
