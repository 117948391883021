import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import HomeHeader from "../components/HomeHeader/HomeHeader";
import SmallFooter from "../components/SmallFooter/SmallFooter";
import Features from "../components/Features/Features";
import Gurus from "../components/Gurus/Gurus";

const stylesheet = {
  overflow: "hidden",
};

const Home = () => {
  return (
    <>
      <Navbar />
      <HomeHeader />
      <Gurus />
      <Features
        title="What would you like to explore today?"
        page="home"
        stylesheet={stylesheet}
      />
      <SmallFooter />
    </>
  );
};

export default Home;
