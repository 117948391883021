import React, { useState, useEffect } from "react";
import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import EditIcon from "@material-ui/icons/Edit";

import usePagination from "../hooks/usePagination";
import { firestore } from "../../firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import SortAscAndDesc from "../../components/SortAscAndDesc/SortAscAndDesc";
import {
  ASCENDING_ORDER,
  DESCENDING_ORDER,
  getSortConditionBySortOrder,
  validSalesPersonId,
} from "../../util/helpers";
import { Button, Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../contexts/AuthContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const SalesPersons = () => {
  const [salesPersons, setSalesPersons] = useState([]);
  const itemsPerPage = 50;
  const { toggleUserAuth } = useAuth();

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstName");

  const [searchBy, setSearchBy] = useState("firstName");
  const [loading, setLoading] = useState(false);
  const [updatedId, setUpdatedId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [error, setError] = useState(false);
  const [actionDetails, setActionDetails] = useState({
    uid: "",
    salesPersonId: "",
    actionType: "",
  });

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) fetchSalesPersons(false);

    return () => (isMounted = false);
  }, [orderDirection, orderBy]);

  useEffect(() => {
    setFilteredData(
      salesPersons.filter((user) => {
        return user[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, salesPersons]);

  const fetchSalesPersons = async (getAll = false) => {
    const list = [];
    let ref;
    if (getAll) {
      ref = firestore.collection("salesPerson");
    } else {
      setSalesPersons([]);
      ref = firestore
        .collection("salesPerson")
        .orderBy(orderBy, orderDirection);
    }

    await ref
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((userDoc) => {
          list.push({
            ...userDoc.data(),
            uid: userDoc.id,
          });
        });
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });

    if (getAll) return list;
    else setSalesPersons(list);
  };

  const convertTimestamp = (time) => {
    const timestamp = new Date(time * 1000);
    const date = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear();

    return `${month}-${date}-${year}`;
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("firstName");
        }
        setOrderBy(e.target.value);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("desc");
        }
        setOrderDirection(e.target.value);
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
      default:
        console.log("default case");
    }
  };

  const handleOnClickSort = (fieldName, order) => {
    const nonSortingFields = [
      "subscriptionStatus",
      "invoiceStatus",
      "lastPaid",
      "numberOfSubs",
    ];
    if (nonSortingFields.includes(fieldName)) return;

    setOrderBy(fieldName);
    setOrderDirection(order);
  };

  function jsonToCsv(jsonData) {
    let csv = "";
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(",") + "\n";
    // Add the data
    jsonData.forEach(function (row) {
      let data = headers.map((header) => JSON.stringify(row[header])).join(","); // Add JSON.stringify statement
      csv += data + "\n";
    });
    return csv;
  }

  const downloadSalesPersons = async () => {
    try {
      setLoading(true);
      let allSalesPersons = await fetchSalesPersons(true);
      allSalesPersons = allSalesPersons.map((each) => {
        return {
          "First Name": each.firstName,
          "Last Name": each.lastName,
          "Phone Number": each.phoneNumber,
          Email: each.email,
          "Commission Rate": each.commissionRate,
          "Sales Person ID": each.salesPersonId,
          Address: each.mailingAddress,
          Created: moment(
            each?.createdAt
              ? each.createdAt.toDate()
              : new Date(each?.createdAt?._seconds * 1000)
          ).format("MMM DD, YYYY"),
        };
      });
      let csvData = jsonToCsv(allSalesPersons); // Add .items.data
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "sales_persons.csv";
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw error;
    }
  };

  const openModal = (uid, salesPersonId, actionType, commission) => {
    setActionDetails({
      uid,
      salesPersonId,
      actionType,
    });
    setUpdatedId(
      actionType === "edit"
        ? salesPersonId
        : actionType === "editCommission"
        ? commission
        : null
    );
    setOpenConfirmModal(true);
  };

  const closeModal = () => {
    setActionDetails({
      uid: "",
      salesPersonId: "",
      actionType: "",
    });
    setUpdatedId("");
    setError(false);
    setOpenConfirmModal(false);
  };

  const updateUser = async (oldId, newData) => {
    try {
      const userRef = firestore
        .collection("users")
        .where("salesPersonId", "==", oldId);

      const userSnapshot = await userRef.get();

      if (userSnapshot.empty) {
        console.log("Users does not exist");
        return;
      }

      // Iterate over each document in the snapshot and update it
      userSnapshot.forEach(async (doc) => {
        try {
          const uid = doc.id;
          await firestore.collection("users").doc(uid).update(newData);

          console.log(`User updated successfully.`);
        } catch (error) {
          console.error(`Error updating user: ${error}`);
        }
      });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  function validatePercentage(value) {
    const regex = /^\d{2,3}%$/;
    return regex.test(value);
  }

  const confirmAction = async () => {
    try {
      if (Object.values(actionDetails).length) {
        setLoading(true);
        let updatedObj = {
          updatedAt: new Date(),
          salesPersonId: null,
        };
        if (actionDetails.actionType === "edit") {
          if (validSalesPersonId(updatedId)) {
            updatedObj = {
              salesPersonId: updatedId,
            };
          } else {
            throw new Error("Invalid Sales Person ID");
          }
        } else if (actionDetails.actionType === "editCommission") {
          if (validatePercentage(updatedId)) {
            updatedObj = {
              commissionRate: updatedId, // this will still set commission rate in updatedId
            };
          } else {
            throw new Error(
              `Invalid Percentage, Must be in this format: "10%"`
            );
          }
        }

        const promises = [
          firestore
            .collection("salesPerson")
            .doc(actionDetails.uid)
            .update(updatedObj),
          updateUser(actionDetails.salesPersonId, updatedObj),
        ];
        await Promise.allSettled(promises);

        await fetchSalesPersons(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
      closeModal();
    } catch (error) {
      console.log("Error: " + error.message);
      setLoading(false);
      setError(error.message);
    }
  };

  const tableHeader = [
    {
      title: "First Name",
      value: "firstName",
    },
    {
      title: "Last Name",
      value: "lastName",
    },
    {
      title: "Phone Number",
      value: "phoneNumber",
    },
    {
      title: "Email",
      value: "email",
    },
    {
      title: "Sales ID",
      value: "salesPersonId",
    },
    {
      title: "Address",
      value: "mailingAddress",
    },
    {
      title: "Commission Rate",
      value: "commissionRate",
    },
    {
      title: "Created",
      value: "createdAt",
    },
    {
      title: "Active",
      value: "active",
    },
    {
      title: "Action",
      value: "action",
    },
  ];

  async function toggleHandler(params) {
    try {
      setLoading(true);
      await toggleUserAuth(params);
      await fetchSalesPersons(false);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error.message);
      setLoading(false);
      alert(error.message);
    }
  }

  return (
    <>
      <div className="container mb-5">
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <div className="form-row">
            <div className="col-sm-3">
              <input
                onChange={(e) => setSearchedData(e.target.value)}
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>

            <div className="col-sm-3">
              <select
                onChange={(e) => onChangeFilteringHandler(e)}
                name="searchBy"
                className="form-control"
                value={searchBy}
              >
                <option value="0">Search By</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone Number</option>
                <option value="email">Email</option>
              </select>
            </div>

            {/* <div className="col-sm-3">
        <select value={orderBy} onChange={(e) => onChangeFilteringHandler(e)} name="orderBy" className="form-control">
          <option value="0">Order By</option>
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="createdAt">Created</option>
        </select>
        </div>

        <div className="col-sm-3">
        <select value={orderDirection} onChange={(e) => onChangeFilteringHandler(e)} name="orderDirection" className="form-control">
          <option value="0">Order Direction</option>
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        </div> */}
          </div>
        </form>

        <Button
          variant="outlined"
          className="mt-2"
          onClick={downloadSalesPersons}
          startIcon={loading ? <CircularProgress size={20} /> : <GetAppIcon />}
          disabled={loading}
        >
          Download CSV
        </Button>
      </div>

      {loading ? (
        <div className="spinner_container">
          <Spinner />
        </div>
      ) : null}
      <table class="table table-striped">
        <thead>
          <tr>
            {tableHeader.map((each) => (
              <th scope="col">
                {["action"].includes(each.value) ? (
                  <SortAscAndDesc
                    isActive={false}
                    isAscending={false}
                    title={each.title}
                  />
                ) : (
                  <SortAscAndDesc
                    isActive={orderBy === each.value}
                    onClickAsc={() =>
                      handleOnClickSort(each.value, ASCENDING_ORDER)
                    }
                    onClickDesc={() =>
                      handleOnClickSort(each.value, DESCENDING_ORDER)
                    }
                    isAscending={orderDirection === ASCENDING_ORDER}
                    title={each.title}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData() &&
            currentData().map((each) => {
              return (
                <tr key={each.salesPersonId}>
                  <td>{each.firstName}</td>
                  <td>{each.lastName}</td>
                  <td>{each.phoneNumber}</td>
                  <td>{each.email}</td>
                  <td>
                    {each.salesPersonId}{" "}
                    {each.salesPersonId && (
                      <EditIcon
                        fontSize="small"
                        onClick={() =>
                          openModal(each.uid, each.salesPersonId, "edit")
                        }
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </td>
                  <td>{each.mailingAddress}</td>
                  <td>
                    {each.commissionRate}{" "}
                    <EditIcon
                      fontSize="small"
                      onClick={() =>
                        openModal(
                          each.uid,
                          each.salesPersonId,
                          "editCommission",
                          each.commissionRate
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td>
                    {moment(
                      each?.createdAt
                        ? each.createdAt.toDate()
                        : new Date(each?.createdAt?._seconds * 1000)
                    ).format("MMM DD, YYYY")}
                  </td>
                  <td style={{ color: each?.isActive ? "blue" : "red" }}>
                    {each?.isActive ? "Active" : "In-Active"}
                  </td>
                  <td>
                    {each.isActive ? (
                      <Tooltip title="Disable User">
                        <CancelIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            toggleHandler({
                              uid: each.uid,
                              disable: true,
                            })
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Enable User">
                        <CheckCircleIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            toggleHandler({
                              uid: each.uid,
                              disable: false,
                            })
                          }
                        />
                      </Tooltip>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
      <Modal isOpen={openConfirmModal} toggle={closeModal}>
        <div className="modal-top"></div>
        <ModalHeader>
          Confirm{" "}
          {actionDetails.actionType === "delete" ? "Deletion" : "Update"}
        </ModalHeader>
        <ModalBody
          className="px-3"
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          {error && <Alert color="danger">{error}</Alert>}
          <p style={{ color: "black" }} className="pt-3">
            Enter Your Updated Values
          </p>
          {actionDetails.actionType.includes("edit") && (
            <>
              <p style={{ color: "black", fontSize: "small" }} className="pt-3">
                Edit{" "}
                {actionDetails.actionType === "editCommission"
                  ? "Commission Rate"
                  : "Sales Person ID"}
              </p>
              <input
                onChange={(e) => setUpdatedId(e.target.value)}
                name={
                  actionDetails.actionType === "editCommission"
                    ? "commissionRate"
                    : "salesPersonId"
                }
                type="text"
                className="form-control"
                value={updatedId}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ReactButton color="primary" onClick={confirmAction}>
            {actionDetails.actionType.includes("edit") ? "Save" : "Delete"}
          </ReactButton>
          <ReactButton color="secondary" onClick={closeModal}>
            Cancel
          </ReactButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SalesPersons;
