import * as types from "./types";

export const toggleSignin = () => dispatch => {
  dispatch({
    type: types.TOGGLE_SIGNIN
  });
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}

export const toggleSignup = () => dispatch => {
  dispatch({
    type: types.TOGGLE_SIGNUP
  });
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}

export const toggleContact = () => dispatch => {
  dispatch({
    type: types.TOGGLE_CONTACT
  });
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}

export const togglePayment = () => dispatch => {
  dispatch({
    type: types.TOGGLE_PAYMENT
  });
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}

export const selectPricePlan = (plan) => dispatch => {
  dispatch({
    type: types.SELECT_PLAN,
    payload: plan
  })
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}

export const setUserInfo = (user) => dispatch => {
  dispatch({
    type: types.SET_USER_INFO,
    payload: user
  })
}


export const selectAdventure = (adventure) => dispatch => {
  dispatch({
    type: "SELECT_ADVENTURE",
    payload: adventure
  })
}

export const storeUserSignupInfo = (info) => dispatch => {
  dispatch({
    type: "USER_SIGNUP_INFO",
    payload: info
  })

  return new Promise((resolve,reject) => resolve("work with it for later use"));
}


export const toggleShare = () => dispatch => {
  dispatch({
    type: types.TOGGLE_SHARE
  });
  return new Promise((resolve,reject) => resolve("work with it for later use"));
}