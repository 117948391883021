import React, { useState, useEffect } from "react";
import { timestamp, firestore } from "../../firebase";
import { useHistory } from "react-router-dom";

import { Spinner, Alert } from "reactstrap";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const companySchema = Yup.object().shape({
  salesPersonFirstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  salesPersonLastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  companyName: Yup.string().required(),
  companyContactName: Yup.string().required(),

  companyContactPhone: Yup.string()
    .required("Phone number is required")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
      "Invalid phone number"
    ),

  companyContactEmail: Yup.string().email().required("Email is required"),

  companyMaxUsers: Yup.number()
    .required("Subscription level is required")
    .max(999999, "Too large - should be maximum 6"),
});

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};

function getRedemptionKey(length) {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

const CompanyForm = () => {
  const [redemption, setRedemption] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [docId, setDocId] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    companyName: "",
    companyContactEmail: "",
    companyContactName: "",
    companyContactPhone: "",
    companyAddressSt: "",
    companyAddressCity: "",
    companyAddressState: "",
    companyAddressCountry: "",
    companySubLevel: 0,
    companyMaxUsers: "",
    companySubExpiration: "",
    companySubStart: "",
    monthlySubAmount: "",
    salesPersonFirstName: "",
    salesPersonLastName: "",
    discount: "",
    companyRedemption: "",
  });

  useEffect(() => {
    setIsMounted(true);
    if (history.location.state) {
      const { doc } = history.location.state;
      setInitialValues({ ...doc });
      setDocId(doc.id);
      setIsEdit(true);
    }
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    document.getElementById("redempKey").innerText = redemption;
  }, [redemption]);

  const onClickGenerateRedempKey = () => {
    const key = getRedemptionKey(25);
    setRedemption(key);
    document.getElementById("redempKeyBtn").style = "display:none";
  };

  return (
    <>
      <div className="col main pt-5 mt-3">
        <h1 className="display-5 d-none d-sm-block">Company Details</h1>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={companySchema}
          onSubmit={async (values) => {
            if (isEdit) {
              setIsLoading(true);
              firestore
                .collection("companies")
                .doc(docId)
                .set({
                  companyName: values.companyName,
                  companyContactEmail: values.companyContactEmail,
                  companyContactName: values.companyContactName,
                  companyContactPhone: values.companyContactPhone,
                  companyAddressSt: values.companyAddressSt,
                  companyAddressCity: values.companyAddressCity,
                  companyAddressState: values.companyAddressState,
                  companyAddressCountry: values.companyAddressCountry,
                  companySubLevel: values.companySubLevel,
                  companyMaxUsers: values.companyMaxUsers,
                  companySubExpiration: values.companySubExpiration,
                  companySubStart: values.companySubStart,
                  monthlySubAmount: values.monthlySubAmount,
                  salesPersonFirstName: values.salesPersonLastName,
                  salesPersonLastName: values.salesPersonLastName,
                  discount: values.discount,
                  companyRedemption: values.companyRedemption,
                  created: values.created,
                  updated: timestamp(),
                  updatedAt: new Date(),
                })
                .then(function () {
                  setIsLoading(false);
                  setMessage("Form updated");
                  setTimeout(() => setMessage(""), 2000);
                })
                .catch(function (error) {
                  setIsLoading(false);
                  setError(error.message);
                  setTimeout(() => setMessage(""), 2000);
                });
            } else {
              setIsLoading(true);
              await firestore.collection("validRedemptionCodes").add({
                redemptionCode: redemption,
                numberOfUsers: values.companyMaxUsers,
                owner: values.companyContactEmail,
                isBlocked: false,
                isCreatedByCompany: true,
                createdAt: new Date(),
              });

              await firestore
                .collection("companies")
                .add({
                  companyName: values.companyName,
                  companyContactEmail: values.companyContactEmail,
                  companyContactName: values.companyContactName,
                  companyContactPhone: values.companyContactPhone,
                  companyAddressSt: values.companyAddressSt,
                  companyAddressCity: values.companyAddressCity,
                  companyAddressState: values.companyAddressState,
                  companyAddressCountry: values.companyAddressCountry,
                  companySubLevel: values.companySubLevel,
                  companyMaxUsers: values.companyMaxUsers,
                  companySubExpiration: values.companySubExpiration,
                  companySubStart: values.companySubStart,
                  monthlySubAmount: values.monthlySubAmount,
                  salesPersonFirstName: values.salesPersonLastName,
                  salesPersonLastName: values.salesPersonLastName,
                  discount: values.discount,
                  companyRedemption: redemption,
                  created: timestamp(),
                  createdAt: new Date(),
                  updated: timestamp(),
                })
                .then(function (docRef) {
                  setIsLoading(false);
                  setMessage("Organization created");
                  setTimeout(() => setMessage(""), 2000);
                })
                .catch(function (error) {
                  setIsLoading(false);
                  setError(error.message);
                  setTimeout(() => setMessage(""), 2000);
                });
            }
          }}
        >
          {(formik) => {
            const { errors, touched, isValid, dirty } = formik;
            return (
              <div className="container position-relative">
                {isLoading && (
                  <div style={spinnerDivStyleSheet}>
                    <Spinner style={spinnerStyleSheet} />
                  </div>
                )}

                <Form>
                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="companyName">Company Name</label>
                      <Field
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Company Name"
                        className={
                          errors.companyName && touched.companyName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="companyName"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="companyContactName">
                        Company Contact Name
                      </label>
                      <Field
                        type="text"
                        name="companyContactName"
                        id="companyContactName"
                        placeholder="Company Contact Name"
                        className={
                          errors.companyContactName &&
                          touched.companyContactName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="companyContactName"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="companyContactEmail">
                      Company Contact Email
                    </label>
                    <Field
                      type="email"
                      name="companyContactEmail"
                      id="companyContactEmail"
                      placeholder="Company Contact Email"
                      className={
                        errors.companyContactEmail &&
                        touched.companyContactEmail
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="companyContactEmail"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="companyContactPhone">
                      Company Contact Phone
                    </label>
                    <Field
                      type="text"
                      name="companyContactPhone"
                      id="companyContactPhone"
                      placeholder="123-456-7890"
                      className={
                        errors.companyContactPhone &&
                        touched.companyContactPhone
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="companyContactPhone"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="companyAddress">Company Address</label>

                    <div className="form-row">
                      <div className="form-group col-6">
                        <Field
                          type="text"
                          name="companyAddressSt"
                          id="companyAddressSt"
                          placeholder="Street"
                          className={
                            errors.companyAddressSt && touched.companyAddressSt
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="companyAddressSt"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className="form-group col-6">
                        <Field
                          type="text"
                          name="companyAddressCity"
                          id="companyAddressCity"
                          placeholder="City"
                          className={
                            errors.companyAddressCity &&
                            touched.companyAddressCity
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="companyAddressCity"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className="form-group col-6">
                        <Field
                          type="text"
                          name="companyAddressState"
                          id="companyAddressState"
                          placeholder="State"
                          className={
                            errors.companyAddressState &&
                            touched.companyAddressState
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="companyAddressState"
                          component="span"
                          className="error"
                        />
                      </div>
                      <div className="form-group col-6">
                        <Field
                          type="text"
                          name="companyAddressCountry"
                          id="companyAddressCountry"
                          placeholder="Country"
                          className={
                            errors.companyAddressCountry &&
                            touched.companyAddressCountry
                              ? "input-error form-control"
                              : "form-control"
                          }
                        />
                        <ErrorMessage
                          name="companyAddressCountry"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="companySubLevel">
                      Company Subscription Level
                    </label>
                    <Field
                      type="number"
                      disabled
                      name="companySubLevel"
                      id="companySubLevel"
                      placeholder="Company Subscription Level"
                      className={
                        errors.companySubLevel && touched.companySubLevel
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="companySubLevel"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="companyMaxUsers">Company Max Users</label>
                    <Field
                      type="number"
                      name="companyMaxUsers"
                      id="companyMaxUsers"
                      placeholder="Company Max Users"
                      className={
                        errors.companyMaxUsers && touched.companyMaxUsers
                          ? "input-error form-control"
                          : "form-control"
                      }
                    />
                    <ErrorMessage
                      name="companyMaxUsers"
                      component="span"
                      className="error"
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label>Company Subscription Expiration</label>
                    </div>
                    <div className="form-group col-6">
                      <p>
                        <Field
                          name="companySubExpiration"
                          id="companySubExpiration"
                          type="date"
                          className="form-control"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label>Company Subscription Start</label>
                    </div>
                    <div className="form-group col-6">
                      <p>
                        <Field
                          name="companySubStart"
                          id="companySubStart"
                          type="date"
                          className="form-control"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label>Monthly Subscription Amount ($)</label>
                    </div>
                    <div className="form-group col-6">
                      <p>
                        <Field
                          name="monthlySubAmount"
                          id="monthlySubAmount"
                          type="number"
                          className="form-control"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label>Discount (%)</label>
                    </div>
                    <div className="form-group col-6">
                      <p>
                        <Field
                          name="discount"
                          id="discount"
                          type="number"
                          className="form-control"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <label>Subscriber Redemption Code</label>
                    </div>
                    <div className="form-group col-6">
                      <p id="redempKey">
                        {isEdit ? initialValues.companyRedemption : null}
                      </p>
                      {isEdit ? null : (
                        <button
                          id="redempKeyBtn"
                          onClick={() => onClickGenerateRedempKey()}
                          className="btn btn-warning"
                        >
                          Generate Key
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col">
                      <label htmlFor="salesPersonFirstName">
                        Sales Person First Name
                      </label>
                      <Field
                        type="text"
                        name="salesPersonFirstName"
                        id="salesPersonFirstName"
                        placeholder="Sales Person First Name"
                        className={
                          errors.salesPersonFirstName &&
                          touched.salesPersonFirstName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="salesPersonFirstName"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="salesPersonLastName">Last Name</label>
                      <Field
                        type="text"
                        name="salesPersonLastName"
                        id="salesPersonLastName"
                        placeholder="Sales Person First Name"
                        className={
                          errors.salesPersonLastName &&
                          touched.salesPersonLastName
                            ? "input-error form-control"
                            : "form-control"
                        }
                      />
                      <ErrorMessage
                        name="salesPersonLastName"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="row my-5">
                    <div className="col-12">
                      <button
                        type="submit"
                        className={
                          !(dirty && isValid)
                            ? "disabled-btn"
                            : "btn btn-primary"
                        }
                        disabled={!(dirty && isValid)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>

        {message && <Alert color="success">{message}</Alert>}
        {error && <Alert color="danger">{error}</Alert>}
      </div>
    </>
  );
};

export default CompanyForm;
