import React, { useState, useEffect } from "react";
import { useFirestoreAdventuresGET } from "../hooks/useAdventures";

import { firebase, firestore, storage, timestamp } from "../../firebase";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { selectAdventure } from "../../store/actions/adminActions";
import usePagination from "../hooks/usePagination";

import {
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import AdventureTable from "./Adventures/AdventureTable";
import AdventureRow from "./Adventures/AdventureRow";

const initValues = {
  title: "",
  description: "",
};

const Adventures = () => {
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [values, setValues] = useState({ ...initValues });
  const [thumbnail, setThumbnail] = useState(null);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedAdventureId, setSelectedAdventureId] = useState(null);

  const types = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];

  const history = useHistory();
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("rowNumber");

  const [tempRows, setTempRows] = useState([]);
  const [searchBy, setSearchBy] = useState("title");

  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState("");

  const [adventures, setAdventures] = useState([]);
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    filteredData,
    itemsPerPage
  );

  const [settings, setSettings] = useState({
    orderBy: "rowNumber",
    orderDirection: "asc",
  });

  const [isSetOrderSettings, setIsSetOrderSettings] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const unsub = firestore
      .collection("m_adventures")
      .doc("settings")
      .get()
      .then((res) => {
        const { orderBy, orderDirection } = res.data();
        setOrderBy(orderBy);
        setOrderDirection(orderDirection);
      });
    return () => unsub;
  }, []);

  useEffect(() => {
    const unsub = firestore
      .collection("m_adventures")
      .orderBy(orderBy, orderDirection)
      .onSnapshot((snapshot) => {
        setAdventures([]);
        snapshot.forEach((doc) => {
          setAdventures((adventures) => [
            ...adventures,
            { id: doc.id, ...doc.data() },
          ]);
        });
        setIsLoading(false);
      });
    return () => unsub;
  }, [orderBy, orderDirection]);

  useEffect(() => {
    setFilteredData(
      adventures.filter((adventure) => {
        return adventure[`${searchBy}`]
          .toLowerCase()
          .includes(searchedData.toLowerCase());
      })
    );
  }, [searchedData, adventures]);

  // console.log(filteredData)

  const onAddNewHandler = () => {
    setIsAddNew(true);
  };
  const onEditHandler = (adventureId, adventure) => {
    setIsEdit(true);
    setSelectedAdventureId(adventureId);
    setValues({ ...adventure });
  };

  const onDeleteHandler = async (adventureId, adventure) => {
    setIsDelete(true);
    setSelectedAdventureId(adventureId);

    if (isMounted) {
      firestore
        .collection("m_rows")
        .where("adventureId", "==", adventureId)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            const result = window.confirm(
              "Are you sure you want to delete it?"
            );
            if (!result) {
              return;
            } else {
              try {
                const doc = await firestore
                  .collection("m_adventures")
                  .doc(adventureId);
                const res = await doc.get();
                const data = await res.data();
                const { thumbnailFullPath } = data;
                const storageRef = storage.ref(thumbnailFullPath);
                await storageRef.delete();

                const rowsRef = firestore.collection("m_adventures");
                const snapshot = await rowsRef
                  .where("rowNumber", ">", adventure.rowNumber)
                  .get();

                snapshot.docs.forEach(async (docData, i) => {
                  let id = docData.id;
                  await rowsRef.doc(id).update({
                    rowNumber: firebase.firestore.FieldValue.increment(-1),
                  });
                });

                await doc.delete();
                setMessage("Data deleted successfully");
                setTimeout(() => setMessage(""), 2000);
              } catch (e) {
                console.log(e);
                setError("Data delete failed");
                setTimeout(() => setError(""), 2000);
              } finally {
                setIsDelete(false);
                setValues(initValues);
                setThumbnail(null);
                setIsLoading(false);
              }
            }
          } else {
            setMessage(
              "Delete everything inside manually before proceeding this step."
            );
          }
        });
    }
  };

  const onClickPositionChangeHandler = async (e, adventure) => {
    ////positions
    let selectedObjectPosition;
    let objectToBeInterchangedWithPosition;

    /////objects
    let selectedObject;
    let interchangedObject;

    switch (e.currentTarget.getAttribute("name")) {
      case "pushUp":
        if (adventure.rowNumber === 1) {
          console.log("cant push up");
          return;
        }

        selectedObjectPosition = currentData().indexOf(adventure);
        objectToBeInterchangedWithPosition = selectedObjectPosition - 1;

        selectedObject = {
          ...currentData()[selectedObjectPosition],
          rowNumber: currentData()[selectedObjectPosition].rowNumber - 1,
        };

        interchangedObject = {
          ...currentData()[objectToBeInterchangedWithPosition],
          rowNumber:
            currentData()[objectToBeInterchangedWithPosition].rowNumber + 1,
        };

        break;
      case "pushDown":
        if (adventure.rowNumber === currentData().length) {
          console.log("cant push down");
          return;
        }

        selectedObjectPosition = currentData().indexOf(adventure);
        objectToBeInterchangedWithPosition = selectedObjectPosition + 1;

        selectedObject = {
          ...currentData()[selectedObjectPosition],
          rowNumber: currentData()[selectedObjectPosition].rowNumber + 1,
        };

        interchangedObject = {
          ...currentData()[objectToBeInterchangedWithPosition],
          rowNumber:
            currentData()[objectToBeInterchangedWithPosition].rowNumber - 1,
        };

        break;
      default:
        return;
    }

    try {
      const batch = firestore.batch();

      const selectedObjectRef = firestore
        .collection("m_adventures")
        .doc(selectedObject.id);
      batch.update(selectedObjectRef, {
        ...selectedObject,
        updated: timestamp(),
      });

      const interchangedObjectRef = firestore
        .collection("m_adventures")
        .doc(interchangedObject.id);
      batch.update(interchangedObjectRef, {
        ...interchangedObject,
        updated: timestamp(),
      });

      const result = await batch.commit();
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveHandler = async () => {
    if (isAddNew) {
      let rowNumber = Number.parseInt(currentData().length) + 1;

      if (!thumbnail) {
        setTimeout(() => setError(""), 3000);
        return setError("Provide a thumbnail image");
      }

      if (!types.includes(thumbnail.type)) {
        setTimeout(() => setError(""), 3000);
        return setError("Only jpeg, jpg, png or svg files are accepted!");
      }

      const times = Date.now();
      const storageRef = storage.ref(`adventures/${thumbnail.name}-${times}`);

      storageRef.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },
        async () => {
          const url = await storageRef.getDownloadURL();
          const metadata = await storageRef.getMetadata();
          const fullPath = metadata.fullPath;
          await firestore
            .collection("m_adventures")
            .doc()
            .set({
              ...values,
              rowNumber,
              thumbnailUrl: url,
              thumbnailFullPath: fullPath,
              created: timestamp(),
              updated: timestamp(),
            });
          setMessage("Data added successfully");
          setTimeout(() => setMessage(""), 2000);
          setIsAddNew(false);
          setValues(initValues);
          setThumbnail(null);
          setIsLoading(false);
        }
      );
    }
    ////////////////////////////

    if (isEdit && thumbnail) {
      if (!types.includes(thumbnail.type)) {
        return setError("Select a jpeg, jpg, png or svg file");
      }
      const doc = await firestore
        .collection("m_adventures")
        .doc(selectedAdventureId)
        .get();
      const res = await doc.data();
      const { thumbnailFullPath } = res;
      const storageRef = storage.ref(thumbnailFullPath);
      try {
        await storageRef.delete();
      } catch (err) {
        console.log("Error deleting", err);
      }
      const times = Date.now();
      const storageRefNew = storage.ref(
        `adventures/${thumbnail.name}-${times}`
      );

      storageRefNew.put(thumbnail).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          // setProgress(percentage);
        },
        (err) => {
          setError(err.message);
          setTimeout(() => setError(""), 2000);
        },

        async () => {
          const url = await storageRefNew.getDownloadURL();
          const metadata = await storageRefNew.getMetadata();
          const fullPath = metadata.fullPath;
          await firestore
            .collection("m_adventures")
            .doc(selectedAdventureId)
            .update({
              ...values,
              thumbnailUrl: url,
              thumbnailFullPath: fullPath,
              updated: timestamp(),
            });

          setMessage("Data added successfully");
          setTimeout(() => setMessage(""), 2000);
          setIsEdit(false);
          setValues(initValues);
          setThumbnail(null);
          setIsLoading(false);
        }
      );
    }

    ////////////////////////////////////

    if (isEdit && !thumbnail) {
      try {
        await firestore
          .collection("m_adventures")
          .doc(selectedAdventureId)
          .update({ ...values, updated: timestamp() });
      } catch (e) {
        setError("Data update failed");
      } finally {
        setMessage("Data added successfully");
        setTimeout(() => setMessage(""), 2000);
        setIsEdit(false);
        setValues(initValues);
        setThumbnail(null);
        setIsLoading(false);
      }
    }
  };

  const onCancelHandler = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setThumbnail(null);
  };

  const onShowHandler = (adventure) => {
    dispatch(selectAdventure(adventure));
    history.push(`/a/categories/${adventure.id}`);
  };

  const onValueChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onFileChangeHandler = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);
  };

  const showPaginationItems = () => {
    const arr = [];

    for (let i = 0; i < maxPage; i++) {
      arr.push(
        <PaginationItem key={i} active={currentPage === i + 1 ? true : false}>
          <PaginationLink href="#" onClick={() => jump(i + 1)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return arr;
  };

  const onChangeFilteringHandler = (e) => {
    switch (e.target.name) {
      case "orderBy":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderBy("rowNumber");
        }
        setOrderBy(e.target.value);
        setIsSetOrderSettings(true);
        break;
      case "orderDirection":
        if (e.target.value === "0" || !e.target.value) {
          return setOrderDirection("asc");
        }
        setOrderDirection(e.target.value);
        setIsSetOrderSettings(true);
        break;
      case "searchBy":
        setSearchBy(e.target.value);
        break;
    }
  };

  const setOrderSettingsHandler = async () => {
    await firestore
      .collection("adventures")
      .doc("settings")
      .set({ orderBy, orderDirection });
    setIsSetOrderSettings(false);
  };

  const canceOrderSettingslHandler = () => {
    setIsSetOrderSettings(false);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Categories</a>
          </li>
        </ol>
      </nav>

      <div className="position-relative">
        {isLoading && (
          <div style={spinnerDivStyleSheet}>
            <Spinner style={spinnerStyleSheet} />
          </div>
        )}

        <AdventureTable
          onSaveHandler={onSaveHandler}
          onCancelHandler={onCancelHandler}
          isEdit={isEdit}
          isAddNew={isAddNew}
          onAddNewHandler={onAddNewHandler}
          message={message}
          setMessage={setMessage}
          error={error}
          onFileChangeHandler={onFileChangeHandler}
          onValueChangeHandler={onValueChangeHandler}
          onChangeFilteringHandler={onChangeFilteringHandler}
          values={values}
          setSearchedData={setSearchedData}
          setOrderSettingsHandler={setOrderSettingsHandler}
          canceOrderSettingslHandler={canceOrderSettingslHandler}
          isSetOrderSettings={isSetOrderSettings}
          orderBy={orderBy}
          orderDirection={orderDirection}
        >
          {currentData() &&
            currentData().map((adventure) => (
              <AdventureRow
                title={adventure.title}
                description={adventure.description}
                thumbnail={adventure.thumbnailUrl}
                id={adventure.id}
                adventure={adventure}
                key={adventure.id}
                onShowHandler={onShowHandler}
                onDeleteHandler={onDeleteHandler}
                onEditHandler={onEditHandler}
                onValueChangeHandler={onValueChangeHandler}
                onFileChangeHandler={onFileChangeHandler}
                onClickPositionChangeHandler={onClickPositionChangeHandler}
                selectedAdventureId={selectedAdventureId}
                values={values}
                isEdit={isEdit}
              />
            ))}
        </AdventureTable>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous href="#" onClick={(e) => prev()} />
          </PaginationItem>
          {showPaginationItems()}
          <PaginationItem>
            <PaginationLink next href="#" onClick={() => next()} />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default Adventures;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
