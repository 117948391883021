import {useState,useEffect} from "react";
import {firestore,storage} from "../firebase";

export const useFirestoreGetAllAdventuresById = (setIsLoading) => {
  const [adventures,setAdventures] = useState([]);
  const [settings,setSettings] = useState({orderBy:"rowNumber",orderDirection:"asc"});

  useEffect(() => {
    const unsub = firestore
                  .collection("adventures")
                  .doc("settings")
                  .get()
                  .then(res => {
                    setSettings({...settings,...res.data()})
                  });
    return () => unsub;
  },[])

  useEffect(() => {

      const unsub = firestore
                    .collection("adventures")
                    .orderBy(settings.orderBy,settings.orderDirection)
                    .onSnapshot(snapshot => {
                      setAdventures([]);
                      snapshot.forEach((doc) => {
                        setAdventures(adventures => [...adventures,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false);
                    });
       return () => unsub;
  },[settings]);
  return {adventures};
}



export const useFirestoreGetAllAdventureDocsById = (collection,setIsLoading) => {
  const [docs,setDocs] = useState([]);

  useEffect(async () => {
    let low_level_documents = [];

    const top_level_doc = await firestore.collection("adventures").doc(collection)
    let top_level_doc_id = await top_level_doc.id
    let top_level_doc_data;

    let low_level_col;
    let low_level_col_id;

    const unsub = top_level_doc.onSnapshot(snapshot => {
      top_level_doc_data = snapshot.data();

      low_level_col = top_level_doc.collection(`${collection}_col`);
      low_level_col_id = low_level_col.id;

      low_level_col.get().then(snapshot => {
        setDocs([])
        snapshot.forEach((doc) => {
          setDocs(docs => [...docs,{id:doc.id,...doc.data()}]);
        });
        setIsLoading(false)
      });
    });


       return () => unsub;
  },[collection]);

  return {docs};
}


export const useFirestoreGetAllPricings = (setIsLoading) => {
  const [pricings,setPricings] = useState([]);
  const [settings,setSettings] = useState({orderBy:"rowNumber",orderDirection:"asc"});

  useEffect(() => {
    const unsub = firestore
                  .collection("pricings")
                  .doc("settings")
                  .get()
                  .then(res => {
                    setSettings({...settings,...res.data()})
                  });
    return () => unsub;
  },[])

  useEffect(async () => {
    
    if (settings) {
      const unsub = await firestore
                    .collection("pricings")
                    .orderBy(settings.orderBy,settings.orderDirection)
                    .onSnapshot(snapshot => {
                      setPricings([]);
                      snapshot.forEach((doc) => {
                        setPricings(pricings => [...pricings,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false)
                    });
       return () => unsub;
    }
    
  },[settings]);
  return {pricings};
}

export const useFirestoreGetAllCompanyDetails = (setIsLoading) => {
  const [companies,setCompanies] = useState([]);

  useEffect(() => {
      const unsub = firestore
                    .collection("companies")
                    .onSnapshot(snapshot => {
                      setCompanies([]);
                      snapshot.forEach((doc) => {
                        setCompanies(companies => [...companies,{id:doc.id,...doc.data()}]);
                      });
                      setIsLoading(false)
                    });
       return () => unsub;
  },[]);
  return {companies};
}
