import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function TileSwitch(props) {

  const {labelDescription,value,onChangeHandler,name} = props;

  return (
    <div>
      <label>{labelDescription}</label>
      <Switch
        checked={value}
        onChange={(e) => onChangeHandler(e)}
        name={name}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
}
