import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, Tooltip } from "@material-ui/core";
import {
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";
import { firestore } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { TextField, MenuItem, Grid } from "@material-ui/core";

const tableHeader = [
  "Created At",
  "Valid Till",
  "Code",
  "Is Blocked",
  "Type",
  "Actions",
];

const PrepaidUsers = () => {
  const { isAdmin, currentUser } = useAuth();

  const [code, setCode] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [editCode, setEditCode] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [prepaidCodes, setPrepaidCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    isErr: false,
    errMessage: "Something went wrong",
  });

  useEffect(() => {
    fetchPrepaidCodes();
  }, []);

  const fetchPrepaidCodes = async () => {
    try {
      setLoading(true);
      const prepaidCodesCollection = await firestore
        .collection("prepaidCodes")
        .get();
      const prepaidCodesArray = prepaidCodesCollection.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));
      setPrepaidCodes(prepaidCodesArray);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error fetching prepaid codes:", error);
      setError({
        isError: true,
        errMessage: error.message,
      });
    }
  };

  const onSubmitHandler = async (e, docId = false) => {
    try {
      if (!code || !date) {
        setError({
          isErr: true,
          errMessage: "Please enter a complete details",
        });
        return;
      }

      if (!isAdmin) {
        setError({
          isErr: true,
          errMessage: "Unauthorized Request",
        });
        return;
      }
      if (docId) {
        await firestore
          .collection("prepaidCodes")
          .doc(docId)
          .update({
            updatedAt: new Date(),
            code,
            validTill: new Date(date),
          });
      } else {
        await firestore.collection("prepaidCodes").add({
          createdAt: new Date(),
          code,
          uid: currentUser.uid,
          isBlocked: false,
          isActive: true,
          validTill: new Date(date),
          type,
        });
      }

      await fetchPrepaidCodes();
      setCode("");
      setType("");
      setDate("");
      setEditCode({});
      setEditModal(false);
      setError({
        isErr: false,
        errMessage: "",
      });
      alert("Prepaid Code Uploaded");
    } catch (error) {
      console.log("Err from submitHandler: ", error.message);
      setError({
        isError: true,
        errMessage: error.message,
      });
    }
  };

  const openModal = (details) => {
    setEditCode(details);
    setCode(details.code);
    if (details.validTill.hasOwnProperty("seconds")) {
      const validTillDate = new Date(details.validTill.seconds * 1000);
      setDate(validTillDate.toISOString().split("T")[0]); // Extract the "YYYY-MM-DD" part
    } else {
      setDate(new Date().toISOString().split("T")[0]);
    }
    setEditModal(true);
  };

  const closeModal = () => {
    setEditCode({});
    setCode("");
    setEditModal(false);
  };

  const updateCodeStatus = async (details, wantToBlock) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${wantToBlock ? "block" : "unblock"} this code?`
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await firestore.collection("prepaidCodes").doc(details.docId).update({
        updatedAt: new Date(),
        isBlocked: wantToBlock,
        isActive: !wantToBlock,
      });
      await fetchPrepaidCodes();
    } catch (error) {
      console.log("Err deleting code: " + error.message);
      setError({
        isError: true,
        errMessage: error.message,
      });
    }
  };

  return (
    <>
      <div className="container mb-5">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <TextField
              onChange={(e) => setCode(e.target.value)}
              name="code"
              type="text"
              value={code}
              size="small"
              className="form-control"
              placeholder="Enter Code..."
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Valid Till"
              size="small"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              size="small"
              label="Select Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="form-control"
              variant="outlined"
              fullWidth
            >
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="family">Family</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button
              variant="outlined"
              onClick={onSubmitHandler}
              size="small"
              startIcon={
                loading ? <CircularProgress size={20} /> : <SaveIcon />
              }
              style={{ marginTop: "1rem" }}
              disabled={loading}
            >
              Upload Code
            </Button>
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <div className="spinner_container">
          <Spinner />
        </div>
      ) : null}
      {error.isErr && <Alert color="danger">{error.errMessage}</Alert>}

      <div className="container mb-5">
        <h3>Prepaid Codes</h3>
        <Box my={2}>
          <Divider />
        </Box>
        {loading ? (
          <p>Loading...</p>
        ) : !prepaidCodes.length ? (
          <p>No prepaid codes available.</p>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                {tableHeader.map((each, index) => (
                  <th scope="col" key={index}>
                    {each}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {prepaidCodes.map((code) => (
                <tr key={code.id}>
                  <td>
                    {new Date(code.createdAt.seconds * 1000).toLocaleString()}
                  </td>
                  <td>
                    {new Date(
                      code.validTill.seconds * 1000
                    ).toLocaleDateString() || "N/A"}
                  </td>
                  <td>{code.code}</td>
                  <td>
                    {code.isBlocked ? (
                      <Chip color="secondary" size="small" label="Yes" />
                    ) : (
                      <Chip size="small" color="primary" label="No" />
                    )}
                  </td>
                  <td>{code.type}</td>
                  <td>
                    <Tooltip title="Edit Code">
                      <EditIcon
                        fontSize="small"
                        onClick={() => openModal(code)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                    {code.isBlocked ? (
                      <Tooltip title="Unblock Code">
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          onClick={() => updateCodeStatus(code, false)}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Block Code">
                        <BlockIcon
                          fontSize="small"
                          onClick={() => updateCodeStatus(code, true)}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                      </Tooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <Modal isOpen={editModal} toggle={closeModal}>
        <div className="modal-top"></div>
        <ModalHeader>Update Code</ModalHeader>
        <ModalBody
          className="px-3"
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "1rem" }}
          >
            <input
              onChange={(e) => setCode(e.target.value)}
              name="code"
              type="text"
              className="form-control"
              value={code}
            />

            <TextField
              size="small"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ReactButton
            color="primary"
            onClick={(e) => onSubmitHandler(e, editCode.docId)}
          >
            Update Code
          </ReactButton>
          <ReactButton color="secondary" onClick={closeModal}>
            Cancel
          </ReactButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PrepaidUsers;
