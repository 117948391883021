import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { firestore, storage } from "../../../firebase";
import EditContentModal from "./AddContentModal";
import ContentRow from "./ContentRow";

const ContentTable = ({
  messageHandler,
  errorHandler,
  content,
  getAllContent,
}) => {
  const [editContentId, setEditContentId] = useState("");
  const [editContentModal, setEditContentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDeleteHandler = async (docId) => {
    setLoading(true);
    const result = window.confirm("Are you sure you want to delete it?");
    if (!result) {
      return;
    } else {
      try {
        const doc = await firestore.collection("corp_a_posts").doc(docId);
        const res = await doc.get();
        const data = await res.data();
        const { fileFullPath, thumbnailFullPath } = data;
        if (fileFullPath) {
          const storageFilePage = storage.ref(fileFullPath);
          await storageFilePage.delete();
        }
        const storageRef = storage.ref(thumbnailFullPath);
        await storageRef.delete();
        await doc.delete();
        messageHandler("Data deleted successfully");
        setTimeout(() => messageHandler(""), 2000);
      } catch (e) {
        console.log("Error from delete func", e);
        errorHandler("Failed to delete");
        setTimeout(() => errorHandler(""), 2000);
      } finally {
        getAllContent();
        // setIsDelete(false);
        // setValues(initValues);
        setLoading(false);
        // setProfilePicture(null);
      }
    }
  };

  const openEditContentModal = (docId) => {
    setEditContentId(docId);
    setEditContentModal(true);
  };
  const closeEditContentModal = () => setEditContentModal(false);

  return (
    <>
      {loading && (
        <div style={spinnerDivStyleSheet}>
          <Spinner style={spinnerStyleSheet} />
        </div>
      )}
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Thumbnail</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {content.map((each) => (
            <ContentRow
              getAllContent={getAllContent}
              contentData={each}
              editHandler={openEditContentModal}
              deleteHandler={onDeleteHandler}
            />
          ))}
        </tbody>
      </table>
      {editContentModal && (
        <EditContentModal
          editContentId={editContentId}
          isEdit={editContentModal}
          docId={editContentId}
          open={editContentModal}
          close={closeEditContentModal}
          getAllContent={getAllContent}
        />
      )}
    </>
  );
};

export default ContentTable;

const spinnerDivStyleSheet = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "#00000047",
  zIndex: "100",
};

const spinnerStyleSheet = {
  position: "absolute",
  left: "50%",
  top: "50%",
  zIndex: "100",
};
